import React, { Fragment, useEffect } from 'react';

import Form from './form';
import Table from './table';

const Cart = props => {
	useEffect(() => {
		props.id && props.getCart();
		// eslint-disable-next-line
	},[props.id]);

	return (
		<Fragment>
			<Form codeToClear={props.codeToClear} id={props.id} getCart={props.getCart} />
			<Table cartItems={props.cartItems} codeToClear={props.codeToClear} id={props.id} getCart={props.getCart} />
		</Fragment>
	);
}

export default Cart
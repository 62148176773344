import React, { useState, useEffect, Fragment } from 'react';

import { useQuery } from '@apollo/client';

import Module from './../../presentationals/Module';

import GQL from './_gql';

import FilterDialog from './filter.dialog';

const Rows = [
	{ label: 'Apertura', key: 'openedAt', format: 'dateTime' },
	{ label: 'Cierre', key: 'clientCreatedAt', format: 'dateTime' },
	{ label: 'Tarjeta', key: 'card', sort: 'text', align: 'right', format: 'currency' },
	{ label: 'Efectivo', key: 'virtualBalance', sort: 'text', align: 'right', format: 'currency' },
	{ label: 'EfectivoDeclarado', key: 'realBalance', sort: 'text', align: 'right', format: 'currency' },
	{ label: 'DiferenciaEfectivo', key: 'difference', sort: 'text', align: 'right', format: 'currency' },
	{ label: 'Usuario', key: 'User name', sort: 'text' },
	{ label: 'Sucursal', key: 'Branch name', sort: 'text' },
	{ label: 'Empresa', key: 'Branch companyId', sort: 'text', format: 'company' }
];

const Component = props => {

	const [ isOpen, setIsOpen ] = useState(false);
	const [ filter, setFilter ] = useState({});

	const { data, loading, refetch } = useQuery(GQL.GET);

	useEffect(() => {
		if(Object.keys(filter).length > 0){
			refetch(filter);
		}
	}, [filter, refetch]);

	const handleRows = (offset, limit) => {
		setFilter(filter => ({ ...filter, limit, offset }));
	}
	const handleFilter = () => setIsOpen(true);

	const handleAction = (action, data) => {
		switch(action){
			case 'close':
				setIsOpen(false);
				break;
			default:
				setFilter(filter => ({ ...filter, ...data }));
				break;
		}
	}

	const handleSearch = (value) => {
		setFilter(filter => ({ ...filter, user: value }));
	}
	
	return (
		<Fragment>
			<Module
				moduleCode='RECO01'
				isLoading={loading}
				name='Corte de Caja'
				singular='Corte de Caja'
				operation={['cashClosing','cashClosings']}
				Rows={Rows}
				get={GQL.GET}
				noCreate
				noEdit
				noDelete
				handleRows={handleRows}
				records={data && data.cashClosings}
				filter={handleFilter}
				handleSearch={handleSearch}
			/>
			<FilterDialog isOpen={isOpen} handleAction={handleAction} />
		</Fragment>
	);
}

export default Component;
import React, { useState } from 'react';

// import DeleteIcon from '@material-ui/icons/DeleteOutlined';

import Container from './../../presentationals/Module.v2/Container';

import GQL from './_gql';
import Form from './form.dialog';
// import DeleteDialog from './auth.dialog';
import Action from './_action';
import Filter from './_filter';

const Rows = [
	{ label: 'Fecha', key: 'createdAt', format: 'dateTime' },
	{ label: 'Tipo', key: 'type', sort: 'text', format: 'productionType' },
	{ label: 'Producto', key: 'Product name', sort: 'text' },
	{ label: 'Unidad', key: 'ProductUnit name', sort: 'text' },
	{ label: 'Cant.', key: 'quantity', align: 'center', sort: 'text' },
];

const title = 'Producción';
const operation = ['production', 'productions'];

const Component = props => {
	const [ formIsOpen, setFormIsOpen ] = useState(false);

	const handleCreate = () => setFormIsOpen(true);
	const handleCloseCreate = () => setFormIsOpen(false);

	return (
		<>
			<Container
				moduleCode='OPPR01'
				title={title}
				operation={operation}
				Rows={Rows}
				get={GQL.GET}
				noDelete
				noEdit
				filter
				handleCustomCreate={handleCreate}
			/>
			<Action />
			<Filter />
			<Form isOpen={formIsOpen} handleClose={handleCloseCreate} />
		</>
	);
}

export default Component;
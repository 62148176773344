import React, { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';

import Typography from '@material-ui/core/Typography';

import FilterDrawer from '../../presentationals/FilterDrawer.v2';
import Autocomplete from '../../presentationals/Autocomplete';

import { filter } from '../../apollo/cache';

import GQL_CLASIFICATION from '../classification/_gql';
import GQL_SUPPLIER from '../supplier/_gql';
import GQL_PRODUCTS from '../product/_gql';

const FilterDialog = () => {
  const [values, setValues] = useState({ productId: null, supplierId: null, clasificationId: null });
  const [suppliers, setSuppliers] = useState([]);
  const [products, setProducts] = useState([]);
  const [clasifications, setClasifications] = useState([]);

  const { data: dataClasifications } = useQuery(GQL_CLASIFICATION.GET, { variables: { limit: 0 } });
  const { data: dataSuppliers } = useQuery(GQL_SUPPLIER.GET, { variables: { limit: 0 } });
  const { data: dataProducts } = useQuery(GQL_PRODUCTS.GET_AUTOCOMPLETE, { variables: { limit: 0 } });

  useEffect(() => {
    if (dataClasifications) {
      const tmp = dataClasifications.clasifications.rows.map(el => ({ label: el.name, value: el.id }));
      setClasifications(tmp);
    }
  }, [dataClasifications]);

  useEffect(() => {
    if (dataSuppliers) {
      const tmp = dataSuppliers.suppliers.rows.map(el => ({ label: el.name, value: el.id }));
      setSuppliers(tmp);
    }
  }, [dataSuppliers]);

  useEffect(() => {
    if (dataProducts) {
      const tmp = dataProducts.products.rows.map(el => ({
        label: `${el.name} ${parseInt(el.companyId) === 2 ? ' - CASA DEL ELOTE' : ''}`.trim().toUpperCase(),
        value: el.id,
      }));
      setProducts(tmp);
    }
  }, [dataProducts]);

  const handleAction = (e, data) => {
    const { startDate: start, endDate: end } = data;
    filter({
      supplierId: values.supplierId ? values.supplierId.value : null,
      clasificationId: values.clasificationId ? values.clasificationId.value : null,
      productId: values.productId ? values.productId.value : null,
      start,
      end,
    });
  };

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <FilterDrawer
      handleAction={handleAction}
      showDates
    >
      <Typography variant='subtitle1'>
        Producto:
      </Typography>
      <Autocomplete
        name='productId'
        onChange={handleAutocompleteChange}
        options={products}
        value={values.productId}
        size='medium'
        variant='outlined'
        noFormat
      />
      <br />
      <Typography variant='subtitle1'>
        Proveedor:
      </Typography>
      <Autocomplete
        name='supplierId'
        onChange={handleAutocompleteChange}
        options={suppliers}
        value={values.supplierId}
        size='medium'
        variant='outlined'
        noFormat
      />
      <br />
      <Typography variant='subtitle1'>
        Clasificación:
      </Typography>
      <Autocomplete
        name='clasificationId'
        onChange={handleAutocompleteChange}
        options={clasifications}
        value={values.clasificationId}
        size='medium'
        variant='outlined'
        noFormat
      />
    </FilterDrawer>
  );
};

export default FilterDialog;

import React, { useEffect } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { currencyFormat } from './../../../helpers/general';

import IconButton from './../cart/table/helpers/iconButton';

import GQL from './../_gql';

const Component = props => {

	const [ getToppings, { data, loading } ] = useLazyQuery(GQL.GET_TOPPINGS);

	const [ deleteTopping ] = useMutation(GQL.DELETE_TOPPING, {
		update(cache, { data }) {
      try{
      	cache.evict({ id: 'ProductTopping:' + data.deleteProductTopping.id });
      	cache.gc();
      }catch(e){
        console.log(e.message);
      }
    }
	});

	useEffect(() => {
		if(props.id){
			getToppings({ variables: { id: props.id } });
		}
		// eslint-disable-next-line
	}, [props.id]);

	const handleDelete = async (e, id) => {
		try{
			console.log(id);
  		await deleteTopping({ variables: { id } });
  	}catch(e) {
  		console.log(e);
  	}
	}

	if(loading) return 'Cargando...';

	return (
		<TableContainer>
			<Table size='small'>
				<TableHead>
          <TableRow>
            <TableCell>Acciones</TableCell>
            <TableCell>Nombre</TableCell>
            <TableCell>Tipo</TableCell>
            <TableCell align="center">Cant.</TableCell>
            <TableCell align="right">Precio</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
				{
					data && data.product && data.product.Toppings && data.product.Toppings.map(el =>
						<TableRow key={el.id}>
							<TableCell>
								<IconButton 
    							label='Eliminar'
    							icon={<DeleteOutlineIcon />}
    							params={el.id}
    							action={handleDelete}
    							size='small'
    						/>
							</TableCell>
	            <TableCell>{el.name}</TableCell>
	            <TableCell>{el.type}</TableCell>
	            <TableCell align="center">{el.defaultQty}</TableCell>
	            <TableCell align="right">{currencyFormat(el.price)}</TableCell>
						</TableRow>
					)
				}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export default Component;
import { gql } from '@apollo/client';

const GET = gql`
	query Wastes(
		$limit: Int, $offset: Int, $start: Date, $end: Date, $branchId: ID
	){
		wastes(limit: $limit, offset: $offset, start: $start, end: $end, branchId: $branchId) {
			count
			totals {
				id
				name
				qty
				amount
			}
			rows {
				id
				createdAt
				folio
				serial
				cost
				quantity
				Product {
					name
				}
				ProductUnit {
					name
				}
				Branch {
					name
				}
				User {
					name
				}
			}
		}
	}
`;

const CREATE = gql`
	mutation CreateWaste(
		$branchId: ID!
		$productUnitId: ID!
		$quantity: Float!
		$description: String
	){
		createWaste(input: {
			branchId: $branchId
			productUnitId: $productUnitId
			quantity: $quantity
			description: $description
		}) {
			id
			createdAt
			folio
			serial
			cost
			quantity
			Product {
				name
			}
			ProductUnit {
				name
			}
			Branch {
				name
			}
			User {
				name
			}
		}
	}
`;

const object = { GET, CREATE }

export default object;

import { gql } from '@apollo/client';

const GET = gql`
	query ProductMovementLogReport($like: String, $limit: Int, $offset: Int, $start: Date, $end: Date, $productId: ID, $branchId: ID) {
		productMovementLogReport(like: $like, limit: $limit, offset: $offset, start: $start, end: $end, productId: $productId, branchId: $branchId) {
			count
			rows {
				id
				name
				start
				input
				output
				finish
				costTotal
			}
		}
	}
`;

export default { GET };

import React, { Fragment, useState, useEffect } from 'react';

import { useQuery, NetworkStatus } from '@apollo/client';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';

import Header from './../../presentationals/Table/header';
import Table from './../../presentationals/Table/helpers/Table';
import Loading from './../../presentationals/Loading';

import { currencyFormat } from './../../helpers';

import GQL from './_gql';
import FilterDialog from './filter.dialog';

import DownloadXLS from './xls.document';
// import SaleNotePDF from './../saleNote/pdf.a4';

const Component = props => {

	const [ isOpen, setIsOpen ] = useState(false);
	const [ filter, setFilter ] = useState({});
	const [ total, setTotal ] = useState([]);

	const { data, loading, refetch, networkStatus } = useQuery(GQL.GET, {
		notifyOnNetworkStatusChange: true,
	});

	useEffect(() => {
		if(data && data.paymentMethodSales){
			const tmp = data.paymentMethodSales.rows.map(el =>
				el.Sales.reduce((array, elCh, i) => {
					array[i] = { 
						total: parseFloat(array[i] ? array[i].total : 0) + parseFloat(elCh.total)
					}
					return array;
				}, [])
			);
			const tmp1 = tmp.reduce((array, el) => {
				el.forEach((elCh, i) => {
					array[i] = {
						total: parseFloat(array[i] ? array[i].total : 0) + parseFloat(elCh.total)
					}
				})
				return array;
			}, []);

			setTotal(tmp1);
		}
	}, [data]);

	useEffect(() => {
		if(Object.keys(filter).length > 0){
			refetch(filter);
		}
	}, [filter, refetch]);

	const handleFilter = () => {
		setIsOpen(true);
	}

	const handleAction = (action, data) => {
		switch(action){
			case 'close':
				setIsOpen(false);
				break;
			default:
				setFilter(filter => ({ ...filter, ...data }));
				break;
		}
	}

	const downloadXLS = () => {
		data && DownloadXLS([ ...data.paymentMethodSales.rows, { PaymentMethod: { name: 'TOTAL' }, Sales: total } ],'VENTAS_POR_FORMAS_DE_PAGO',filter);
	}

	const downloadPDF = () => {
		// data && DownloadPDF(data.branchSales.rows, filter);
	}

	// const handleNotePdf = (id) => {
	// 	if(!id) return false;
	// 	SaleNotePDF(id);
	// }

	if(loading || networkStatus === NetworkStatus.refetch) return <Loading />;

	return (
		<Fragment>
			<Header
				title="Ventas por Formas de Pago"
				noCreate
				customizedXls={downloadXLS}
				customizedPdf={downloadPDF}
				qty={data && data.paymentMethodSales.count}
				filter={handleFilter}
				moduleCode='REFP01'
			/>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Formas de Pago</TableCell>
						{
							(data && data.paymentMethodSales.count > 0) && 
								data.paymentMethodSales.rows[0].Sales.map((el, i) => 
									<TableCell key={i} align='center' 
										style={{ 
											background: (i % 2 === 0) ? '#c9c9c9' : '#fafafa',
											borderColor: (i % 2 === 0) ? '#989898' : '#ddd',
											minWidth: 10
										}}
									>
										<Typography variant="body2" noWrap>
											<strong>{el.Branch.id !== -1  ? el.Branch.name.toUpperCase() : 'Total'}</strong>
										</Typography>
									</TableCell>
								)
						}
					</TableRow>
				</TableHead>
				<TableBody>
					{ data && data.paymentMethodSales.rows.map((el, i) =>
							<TableRow key={i}>
								<TableCell>
									<Typography variant="body2" noWrap>
										{el.PaymentMethod.name.toUpperCase()}
									</Typography>
								</TableCell>
								{
									el.Sales.map((elCh, ii) => 
										<Fragment key={ii}>
											<TableCell
												align='right' 
												style={{ 
													background: (ii % 2 === 0) ? '#ddd' : '#fafafa',
													borderColor: (ii % 2 === 0) ? '#989898' : '#ddd'
												}}
											>
												{currencyFormat(elCh.total)}
											</TableCell>
										</Fragment>
									)
								}
							</TableRow>
						)
					}
					<TableRow>
						<TableCell style={{ position: 'sticky', bottom: 0, background: '#fafafa', borderTop: '1px solid #ddd' }}>
							<Typography variant="body2" noWrap>
								<strong>TOTAL</strong>
							</Typography>
						</TableCell>
						{
							(data && data.paymentMethodSales.count > 0) && 
								data.paymentMethodSales.rows[0].Sales.map((el, i) => 
									<Fragment key={i}>
										<TableCell 
											align='right' 
											style={{ 
												position: 'sticky', 
												bottom: 0, 
												background: (i % 2 === 0) ? '#a2a2a2' : '#c0c0c0',
												borderTop: '1px solid #ddd' 
											}}
										>
											{total[i] ? currencyFormat(total[i].total) : currencyFormat(0)}
										</TableCell>
									</Fragment>
								)
						}
					</TableRow>
				</TableBody>
			</Table>
			<FilterDialog isOpen={isOpen} handleAction={handleAction} />
		</Fragment>
	);
}

export default Component;
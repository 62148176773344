import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';

import FormDialog from './../../presentationals/FormDialog';
import SelectField from './../../presentationals/SelectField';

import Grid from '@material-ui/core/Grid';

import GQL from './_gql';

import Cart from './cart';

const ivaOptions = [
	{ value: 16, label: '16%' },
	{ value: 8, label: '8%' },
	{ value: 0, label: '0%' },
	{ value: -1, label: 'Exento' },
]

const Component = props => {
  const [ cartItems, setCartItems ] = useState([]);
	const [ iva, setIva ] = useState(16);
	const [ updateIva ] = useMutation(GQL.UPDATE_IVA);
  const [ getBranchOrder, { data } ] = useLazyQuery(GQL.GET_BY_ID,{ fetchPolicy: 'network-only' });

  const getCart = async () => {
    try {
      await getBranchOrder({ variables: { id: props.id } });
    }catch(e) {
      console.log(e);
    }
  }
  useEffect(() => {
    (data && data.branchOrder) && setCartItems(data.branchOrder.BranchOrderDetails);
    // eslint-disable-next-line
  },[data]);

	const codeToClear = () => {
	}	

	const handleClose = () => {
    setCartItems([]);
		props.handleClose();
	}

	const handleChange = async e => {
    const value = e.target.value;
    setIva(value);
    await updateIva({ variables: { id: props.id, iva: value } });
  }
	
	return (
    <FormDialog
      isOpen={props.isOpen}
      title={"Productos"}
      name="Products"
      singular="Product"
      disableEscape
      width="md"
      handleClose={handleClose}
      hideAction
      rightContent={
      	<Grid container alignItems='center' spacing={1}>
        	<Grid item xs={6} />
          <Grid item xs={6}>
            <SelectField 
              noFormat
              label='IVA'
              name='iva'
              value={iva}
              onChange={handleChange}
              options={ivaOptions}
              variant='outlined'
              size='small'
            />
          </Grid>
        </Grid>
      }
    >
      <Cart getCart={getCart} cartItems={cartItems} codeToClear={codeToClear} id={props.id} />
    </FormDialog>
  );
}

export default Component;
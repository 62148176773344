import React from 'react';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import makeStyles from '@material-ui/core/styles/makeStyles';

import TableSortLabel from './../helpers/TableSortLabel';

const useStyles = makeStyles((theme: Theme) => ({
	tableCell: { 
		color: ({ color }) => color || '#000',
		background: ({ background }) => background || '#fff' 
	}
}));

const Component = props => {
	const classes = useStyles();
	return (
		<TableHead>
			<TableRow>
				{ props.dropDownOption && <TableCell padding='none' className={classes.tableCell} />}
				{ props.Rows && props.Rows.map(el => 
					<RowCell 
						key={el.key}
						_key={el.key}
						color={el.color}
						background={el.background}
						align={el.align}
						sort={el.sort}
						label={el.label}
						direction={props.direction}
						handleSort={props.handleSort}
					/>
				)}
				{
					!props.noActionButtons && <TableCell className={classes.tableCell} />
				}
				
			</TableRow>
		</TableHead>
	);
}

const RowCell = props => {
	const classes = useStyles({ color: props.color, background: props.background });
	return (
		<TableCell 
			className={classes.tableCell} 
			align={ props.align || 'left' }
		>
			{ 
				props.sort 
					? <TableSortLabel 
							id={props._key}  
							label={props.label} 
							direction={props.direction[props._key]}
							onClick={props.handleSort}
						/>
					: props.label
			} 
		</TableCell>
	)
}

export default Component;
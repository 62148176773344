import React, { useState, useEffect } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import Autocomplete from './../../presentationals/Autocomplete';
import TextField from './../../presentationals/TextField';
import FormDialog from './../../presentationals/FormDialog';

import GQL from './_gql';
import GQL_PRODUCT from '../reportProductInventory/_gql';

import { parseError } from './../../helpers';
import { snackbar } from './../../apollo/cache';

import Cart from './cart';

const defaultValues = {
	productUnit: null,
	quantity: 1,
	initialUnits: 0,
	waste: 0,
	UnitCompounds: []
}

const Component = props => {
	const [values, setValues] = useState(defaultValues);
	const [codeToClear, setCodeToClear] = useState(null);
	const [products, setProducts] = useState([]);
	const [errors, setErrors] = useState([]);
	const [lastProduction, setLastProduction] = useState([[],0]);
	const [productUnitType, setProductUnitType] = useState(1);

	const { data: productsData } = useQuery(GQL_PRODUCT.GET, { limit: 0 });

	const [createProduction, { loading: loadingPro }] = useMutation(GQL.CREATE_PRODUCTION, {
		update(cache, { data }) {
			try{
				const oldQuery = cache.readQuery({ query: GQL.GET });
				cache.writeQuery({
          query: GQL.GET,
          data: { productions: {
          	...oldQuery.productions,
          	count: parseInt(oldQuery.productions.count) + 1,
          	rows: [data.createProduction, ...oldQuery.productions.rows]
          } }
        });
			}catch(e){
				console.log(e.message);
			}
		},
	});
	const [getLastProduction, { data: productionData }] = useLazyQuery(GQL.GET_LAST_PRODUCTION);

	useEffect(() => {
		if(productionData) {
			if(productionData.productions.rows.length > 0) {
				const tmp = productionData.productions.rows[0].ProductionDetails.map(el => {
					return {
						unitCompoundId: { value: el.ProductUnit.id, label: `${el.Product.name.trim()} ${el.ProductUnit.name.trim()}`.toUpperCase() },
		  			quantity: parseFloat(el.quantity),
		  			waste: parseFloat(el.wasteBase)
					}
				});
				setLastProduction([tmp,productionData.productions.rows[0].quantity]);
				setValues(values => ({ ...values, quantity: productionData.productions.rows[0].quantity }))
			}else {
				setLastProduction([[],0]);
			}
		}
	},[productionData])
	
	useEffect(() => {
		if(productsData){
			const tmpProducts = productsData.productInventories.rows.map(el => {
				const tag = parseInt(el.companyId) === 1 ? '| NRC' : '| CDE';
        const label = `${el.name.toUpperCase()} ${tag}`;
				return {
					value: el.id,
					label,
					productId: el.productId,
					type: el.type,
				};
			}).filter(tmp => tmp);
			setProducts(tmpProducts);
		}
	}, [productsData]);

	useEffect(() => {
		if(lastProduction) {
			if(values.quantity && lastProduction[0].length > 0) {
				const tmp = lastProduction[0].map(el => {
					const unitQuantity = parseFloat(el.quantity) / parseFloat(lastProduction[1]);
					const unitQuantityFix = parseFloat(unitQuantity) * parseFloat(values.quantity);

					const unitWaste = parseFloat(el.waste) / parseFloat(lastProduction[1]);
					const unitWasteFix = parseFloat(unitWaste) * parseFloat(values.quantity);

					return { ...el, quantity: unitQuantityFix, waste: unitWasteFix };
				});
				setLastProduction([tmp,values.quantity]);
			}
		}
		// eslint-disable-next-line
	},[values.quantity])

	useEffect(() => {
		if(values.productUnit) {
    	getLastProduction({ variables: { limit: 1, type: [2], productUnitId: values.productUnit.value } })
    }
    // eslint-disable-next-line
	},[values.productUnit]);

	const handleClose = () => {
		setValues(defaultValues);
		setCodeToClear(null);
		setLastProduction([[],0]);
		setProductUnitType(1);
		props.handleClose();
	}

	const handleAction = async () => {
		try {
			if(!values.productUnit) {
				const e = new Error('Seleccione la unidad que se producira');
  			e.name = 'productUnit';
  			e.input = true;
  			throw e; 
			}
			if (productUnitType === 2) {
				if(values.UnitCompounds.length <= 0) {
					const e = new Error('Seleccione las unidades que conforman la producción');
	  			e.name = 'GENERAL_ERROR';
	  			e.fields = true;
	  			throw e; 
				}
			}

			const variables = {
				productUnitId: values.productUnit.value,
				quantity: parseFloat(values.quantity),
				initialUnits: parseFloat(values.initialUnits),
				waste: parseFloat(values.waste),
				UnitCompounds: values.UnitCompounds.map(el => ({
					...el,
					unitCompoundId: el.unitCompoundId.value,
				}))
			};

			if(parseFloat(values.quantity) <= 0) {
				const e = new Error('La cantidad debe ser un número positivo')
  			e.name = 'quantity';
  			e.input = true;
  			throw e; 
			}
		
			await createProduction({ variables: { ...variables, type: 1 } });

			setValues(defaultValues);
			setCodeToClear(null);
			setLastProduction([[],0]);
			setErrors({});
			props.handleClose();
		}catch(e) {
			console.log(e);
			if(e.fields) {
				snackbar({
	      	isOpen: true, 
	        time: 3000,
	        label: e.message, 
	        severity: 'error'
	      });
	      return true;
			}
			if(e.input) {
				setErrors({ [e.name]: e.message });
				return true;
			}
			const parseErrors = parseError(e);
      if(parseErrors[0].name === 'GENERAL_ERROR'){
      	snackbar({
	      	isOpen: true, 
	        time: 3000,
	        label: parseErrors[0].message, 
	        severity: 'error'
	      });
      }
		}
	}

	const handleChange = e => {
    const name = e.target.name;
    let value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
    setProductUnitType(value.type);
  }

  const getCart = data => {
    setValues(values => ({ ...values, UnitCompounds: data }));
  }

	return (
		<FormDialog
			isOpen={props.isOpen}
			isLoading={loadingPro}
      title="Nueva Producción"
      name="cart"
      singular="Cart"
      disableEscape
      handleClose={handleClose}
      handleAction={handleAction}
      width="md"
		>	
			<Grid container spacing={1}>
				<Grid item xs={productUnitType === 1 ? 8 : 10}>
					<Autocomplete 
		        name='productUnit'
		        label='Producto a producir' 
		        variant='outlined'
		        onChange={handleAutocompleteChange}
		        options={products}
		        value={values.productUnit}
		        error={errors.productUnit}
		        noFormat
		        size='medium'
		      />
				</Grid>
				<Grid item xs={2}>
					<TextField 
		        name='quantity'
		        label='Cant.'
		        type='number' 
		        variant='outlined'
		        onChange={handleChange}
		        value={values.quantity}
		        error={errors.quantity}
		        noFormat
		        size='medium'
		      />
				</Grid>
				{ productUnitType === 1 &&
					<Grid item xs={2}>
			    	<TextField 
			        name='waste'
			        label='Merma'
			        variant='outlined'
			        type='number'
			        onChange={handleChange}
			        value={values.waste}
			        error={errors.waste}
			        noFormat
			        size='medium'
			      />
			    </Grid>
				}
				
			</Grid>
			{productUnitType === 2 &&
				<div>
					<br/>
					<Divider/>
					<br/>
					<Cart
		        getCart={getCart}
		        codeToClear={codeToClear}
		        products={products}
		        lastProduction={lastProduction}
		      />
	      </div>
			}
		</FormDialog>
	);
}

export default Component;
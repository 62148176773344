import React, { useState, useEffect, Fragment } from 'react';

import { useQuery, useMutation
 } from '@apollo/client';

import PersonIcon from '@material-ui/icons/PersonOutline';
import AppsIcon from '@material-ui/icons/AppsOutlined';
import LockIcon from '@material-ui/icons/LockOutlined';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCartOutlined';
import LocalShippingIcon from '@material-ui/icons/LocalShippingOutlined';
import DoneAllIcon from '@material-ui/icons/DoneAllOutlined';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import ListAltIcon from '@material-ui/icons/ListAltOutlined';
import CloseIcon from '@material-ui/icons/Close';

import Module from './../../presentationals/Module';
import ConfirmDialog1 from './../../presentationals/ConfirmDialog';
import ConfirmDialog2 from './../../presentationals/ConfirmDialog';
import ConfirmDialog3 from './../../presentationals/ConfirmDialog';
import ConfirmDialog4 from './../../presentationals/ConfirmDialog';
import DelteConfirmDialog from './../../presentationals/ConfirmDialog';

import { parseError } from './../../helpers';
import { snackbar } from './../../apollo/cache';

import GQL from './_gql';
import GQL_BRANCH from './../branch/_gql';

import PDF_NOTE from './../saleNote/pdf.a4';
import ProductDialog from './product.dialog';
import SummaryDialog from './summary.dialog';
import FilterDialog from './filter.dialog';

const Rows = [
	{ label: 'Creado el', key: 'createdAt', format: 'dateTime' },
	{ label: 'Folio', key: 'folio', sort: 'text' },
	{ label: 'Cliente', key: 'Branch name', sort: 'text' },
	{ label: 'Registrado por', key: 'User name', sort: 'text' },
	{ label: 'Estatus', key: 'status', sort: 'text', format: 'statusBranchOrder' }
];

const defaultConfirm = [false,{ id: null, status: 1 }];
const defaultConfirmSale = [false,{ id: null }];
const defaultConfirmTransfer = [false,{ id: null, status: null }];
const defaultSummaryDialog = [false,0,{ folio: ''}];
const defaultConfirmDelete = [false,null];

const Component = props => {
	const [isOpen, setIsOpen] = useState(false);
	const [isOpenSummary, setIsOpenSummary] = useState(defaultSummaryDialog);
	const [branchOptions, setBranchOptions] = useState([]);
	const [filter, setFilter] = useState({});
	const [branchOrders, setBranchOrders] = useState({ count: 0, rows: [] });
	const [isOpenProductDialog, setIsOpenProductDialog] = useState([false, 0]);
	const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(defaultConfirm);
	const [isOpenConfirmDialogSale, setIsOpenConfirmDialogSale] = useState(defaultConfirmSale);
	const [isOpenConfirmDialogMigrate, setIsOpenConfirmDialogMigrate] = useState(defaultConfirmSale);
	const [isOpenConfirmDialogTransfer, setIsOpenConfirmDialogTransfer] = useState(defaultConfirmTransfer);
	const [isOpenConfirmDialogDelete, setIsOpenConfirmDialogDelete] = useState([false,null]);

	const { data, loading, refetch } = useQuery(GQL.GET,{ variables: { status: [1,2,3,4] } });
	const { data: branches } = useQuery(GQL_BRANCH.GET,{ variables: { owner: false } });

	const [ updateOrderStatus ] = useMutation(GQL.UPDATE_STATUS,{
		onCompleted: () => setIsOpenConfirmDialog(defaultConfirm)
	});
	const [ generateSaleNote ] = useMutation(GQL.GENERATE_SALE_NOTE,{
		onCompleted: data => {
			setIsOpenConfirmDialogSale(defaultConfirmSale);
			PDF_NOTE(data.generateSaleNote.id);
		}
	});
	const [ migrateBranchOrder ] = useMutation(GQL.MIGRATE_BRANCH_ORDER,{
		update(cache, { data }) {
			const oldQuery = cache.readQuery({ query: GQL.GET, variables: { status: [1,2,3,4] } });
			const newQuery = oldQuery.branchOrders.rows.map(el => {
				if(parseInt(el.id) === parseInt(data.migrateBranchOrder.id)){
					return { ...el, status: 4 };
				}
				return el;
			})
			cache.writeQuery({
        query: GQL.GET,
        variables: { status: [1,2,3,4] },
        data: { branchOrders: {
        	...oldQuery.branchOrders,
        	rows: newQuery
        } }
      });
    },
		onCompleted: () => setIsOpenConfirmDialogMigrate(defaultConfirmSale)
	});
	const [ confirmTransferBranchOrder ] = useMutation(GQL.CONFIRM_TRANSFER,{
		update(cache, { data }) {
      cache.evict({ id: 'BranchOrder:' + data.confirmTransferBranchOrder.id });
      cache.gc();
    },
		onCompleted: () => setIsOpenConfirmDialogTransfer(defaultConfirmTransfer)
	});
	const [ deleteBranchOrder ] = useMutation(GQL.DELETE,{
		update(cache, { data }) {
      cache.evict({ id: 'BranchOrder:' + data.deleteBranchOrder.id });
      cache.gc();
    },
		onCompleted: () => setIsOpenConfirmDialogDelete(defaultConfirmDelete)
	});

	useEffect(() => {
		if(data) {
			const rows = data.branchOrders.rows.map(el => {
				return { 
					...el, folio: `${el.serial}-${el.folio}`
				}
			});
			setBranchOrders({ count: data.branchOrders.count, rows })
		}
	},[data]);

	useEffect(() => {
		if (branches && branches.branches) {
			const tmpBranches = branches.branches.rows.map(el => ({  value: el.id, label: el.name }));
			setBranchOptions(tmpBranches);
		}
	},[branches])


	useEffect(() => {
		if(Object.keys(filter).length > 0){
			refetch(filter);
		}
	}, [filter, refetch]);

	const handleRows = (offset, limit) => {
		setFilter(filter => ({ ...filter, limit, offset }));
	}

	const handleSearch = (value) => {
		setFilter(filter => ({ ...filter, like: value }));
	}


  const handleCloseProductDialog = () => setIsOpenProductDialog([false, 0]);

  const handleOpenOrder = (e,{id}) => setIsOpenConfirmDialog([true,{ id, status: 1 }]);
  const handleCloseOrder = (e,{id}) => setIsOpenConfirmDialog([true,{ id, status: 2 }]);

  const handleProducts = (e, { id }) => setIsOpenProductDialog([true, id]);

  const handleSale = (e,{id}) => setIsOpenConfirmDialogSale([true,{ id }]);
  const handleMigrate = (e,{id}) => setIsOpenConfirmDialogMigrate([true,{ id }]);
  const handleConfirmTransfer = (e,{id}) => setIsOpenConfirmDialogTransfer([true,{ id, status: 3 }]);
  const handleDeclineTransfer = (e,{id}) => setIsOpenConfirmDialogTransfer([true,{ id, status: 0 }]);

  const handleCloseSummary = () => setIsOpenSummary(defaultSummaryDialog);
  const handleSummary = (e,{ id, folio }) => {
  	setIsOpenSummary([true,id,{folio}]);
  };

  const handleActionConfirmMigrate = async ({id}) => {
  	try {
  		await migrateBranchOrder({ variables: { id } });
  	}catch(e) {
  		console.log(1, e);
  	} 
  }

  const handleActionConfirmTransfer = async ({id,status}) => {
  	try {
  		await confirmTransferBranchOrder({ variables: { id, status } });
  	}catch(e) {
  		console.log(e);
  		const parseErrors = parseError(e);
      if(parseErrors[0].name === 'GENERAL_ERROR'){
      	snackbar({
	      	isOpen: true, 
	        time: 0,
	        label: parseErrors[0].message,
	        severity: 'error'
	      });
      }
  	} 
  }

  const handleActionConfirm = async ({id,status}) => {
  	try {
  		await updateOrderStatus({ variables: { id, status } });
  	}catch(e) {
  		handleCloseConfirm()
      snackbar({
        isOpen: true,
        time: 3000,
        label: e.message,
        severity: 'error'
      });
  	}
  }

  const handleActionConfirmSale = async ({id}) => {
  	try {
  		await generateSaleNote({ variables: { id } });
  		refetch({ variables: { status: [1,2,3,4] } });
  	}catch(e) {
  		console.log(3, e);
  	} 
  }

  const handleAction = (action, data1) => {
		switch(action){
			case 'close':
				setIsOpen(false);
				break;
			default:
				const { status, ...data } = data1;
				setFilter(filter => ({ 
					...filter, 
					...data,
					status: status === -1 ? [] : [status]
				}));
				break;
		}
	}

	const handleActionConfirmDelete = async id => {
  	try {
  		await deleteBranchOrder({ variables: { id } });
  	}catch(e) {
  		const parseErrors = parseError(e);
      if(parseErrors[0].name === 'GENERAL_ERROR'){
      	snackbar({
	      	isOpen: true, 
	        time: 0,
	        label: parseErrors[0].message, 
	        severity: 'error'
	      });
      }
  	} 
  }



  const handleCloseConfirm = () => setIsOpenConfirmDialog(defaultConfirm);
  const handleCloseConfirmSale = () => setIsOpenConfirmDialogSale(defaultConfirmSale);
  const handleCloseConfirmMigrate = () => setIsOpenConfirmDialogMigrate(defaultConfirmSale);
  const handleCloseConfirmTransfer = () => setIsOpenConfirmDialogTransfer(defaultConfirmTransfer);
	
	const handleDelete = (e,{id}) => setIsOpenConfirmDialogDelete([true, id]);
	const handleCloseConfirmDelete = () => setIsOpenConfirmDialogDelete(defaultConfirmDelete);

	const handleFilter = () => setIsOpen(true);

	return (
		<Fragment>
			<Module
				moduleCode='OPOR01'
				isLoading={loading}
				name='Orden de Compra para Franquicias'
				singular='orden'
				operation={['branchOrder', 'branchOrders']}
				Rows={Rows}
				get={GQL.GET}
				handleRows={handleRows}
				create={GQL.CREATE}
				noEdit
				cacheVariables={{ status: [1,2,3,4] }}
				noDelete
				records={branchOrders}
				handleSearch={handleSearch}
				filter={handleFilter}
				actions={[
					{ 
						icon: <LockIcon fontSize="small" />,
						label: "Cerrar Orden de Compra",
						onClick: handleCloseOrder,
						hide: { key: 'status', value: ['2','3','4','5','0'] }
					},
          { 
          	icon: <LockOpenIcon fontSize="small" />,
          	label: "Abrir Orden de Compra",
          	onClick: handleOpenOrder,
          	hide: { key: 'status', value: ['1','3','4','5','0'] }
          },
          { 
          	icon: <ShoppingCartIcon fontSize="small" />,
          	label: "Emitir Nota de Venta",
          	onClick: handleSale,
          	hide: { key: 'status', value: ['1','3','4','5','0'] }
          },
          { 
          	icon: <LocalShippingIcon fontSize="small" />,
          	label: "Traspasar a Sucursal",
          	onClick: handleMigrate,
          	hide: { key: 'status', value: ['1','2','4','5','0'] }
          },
          { 
          	icon: <DoneAllIcon fontSize="small" />,
          	label: "Confirmación de traspaso",
          	onClick: handleConfirmTransfer,
          	hide: { key: 'status', value: ['1','2','3','5','0'] }
          },
          { 
          	icon: <CancelIcon fontSize="small" />,
          	label: "Declinar traspaso",
          	onClick: handleDeclineTransfer,
          	hide: { key: 'status', value: ['1','2','3','5','0'] }
          },
					{ 
						icon: <AppsIcon fontSize="small" />,
						label: "Productos",
						onClick: handleProducts,
						hide: { key: 'status', value: ['2','3','4','5','0'] }
					},
					{ icon: <ListAltIcon/>, onClick: handleSummary, label: 'Resumen del Traspaso' },
					{ icon: <CloseIcon/>, onClick: handleDelete, label: 'Eliminar', hide: { key: 'status', value: ['4','5','6','0'] } },
				]}
				fields={[
					{ 
						icon: <PersonIcon/>,
						name: 'branchId',
						label: 'Sucursal',
						field: 'autocomplete',
						options: branchOptions,
						defaultValue: null
					}
				]}
			/>
			<ProductDialog 
				isOpen={isOpenProductDialog[0]}
				id={isOpenProductDialog[1]}
				handleClose={handleCloseProductDialog}
			/>
			<ConfirmDialog1
				isOpen={isOpenConfirmDialog[0]}
				handleClose={handleCloseConfirm}
				handleAction={handleActionConfirm}
				params={isOpenConfirmDialog[1]}
			/>
			<ConfirmDialog2
				isOpen={isOpenConfirmDialogSale[0]}
				handleClose={handleCloseConfirmSale}
				handleAction={handleActionConfirmSale}
				params={isOpenConfirmDialogSale[1]}
			/>
			<ConfirmDialog3
				isOpen={isOpenConfirmDialogMigrate[0]}
				handleClose={handleCloseConfirmMigrate}
				handleAction={handleActionConfirmMigrate}
				params={isOpenConfirmDialogMigrate[1]}
			/>
			<ConfirmDialog4
				isOpen={isOpenConfirmDialogTransfer[0]}
				handleClose={handleCloseConfirmTransfer}
				handleAction={handleActionConfirmTransfer}
				params={isOpenConfirmDialogTransfer[1]}
			/>
			<DelteConfirmDialog
				isOpen={isOpenConfirmDialogDelete[0]}
				handleClose={handleCloseConfirmDelete}
				handleAction={handleActionConfirmDelete}
				params={isOpenConfirmDialogDelete[1]}
			/>
			<FilterDialog isOpen={isOpen} handleAction={handleAction} />
			<SummaryDialog isOpen={isOpenSummary[0]} handleClose={handleCloseSummary} id={isOpenSummary[1]} extraData={isOpenSummary[2]} />
		</Fragment>
	);
}

export default Component;
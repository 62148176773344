import React from 'react';

import { useReactiveVar } from '@apollo/client';

import makeStyles from '@material-ui/core/styles/makeStyles';

import { actions } from '../../../../apollo/cache';
import IconButton from '../../../IconButton';

const useStyles = makeStyles(Theme => ({
  actionButton: { padding: Theme.spacing(0.4) },
}));

const ActionButtonComponent = props => {

	const classes = useStyles();

	const actionsVar = useReactiveVar(actions);

	const __function = (e, { onClick, ...params }) => {
		if (typeof onClick === 'function') {
			onClick(e, params);
		} else if (typeof onClick === 'object') {
			const tmpKey = Object.keys(onClick).reduce((string, key) => {
				const _split = key.split(':');
				const tmpKey = _split[0];
				const tmpValue = _split[1];
				return (tmpValue === `${params[tmpKey]}`) ? key : string;
			}, '');
			onClick[tmpKey](e, params);
		}
  	};
	return (
		<>
			{actionsVar && actionsVar.map(el => {
				const tmp = el.disable
					? el.disable.key.includes(' ')
						? el.disable.key.split(' ').reduce((string, elCh) => {
							const tmpData = string[elCh] || el.ifNull || '-';
							return tmpData;
						}, props.data)
						: props.data[el.disable.key]
					: null;
				return (
					<IconButton
						key={el.label}
						label={el.label}
						disabled={(el.disable && (Array.isArray(el.disable.value) ? el.disable.value.map(el => String(el)).includes(String(tmp)) : tmp === el.disable.value))}
						action={__function}
						params={{ id: props.data.id, ...el.params, ...props.data, onClick: el.onClick }}
						className={classes.actionButton}
						icon={el.icon}
					/>
				);
			})}
		</>
	);
};

export default ActionButtonComponent;

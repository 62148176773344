import dateFormat from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { currencyFormat } from '../../../../helpers';

export const transferStatus = ['Cancelado','Pendiente','Autorizado','Confirmado'];
export const productType = ['-', 'Unidad', 'Compuesto', 'Compuesto Personalizado', 'Paquete por Eventos', 'Componente'];
export const productTypeV2 = ['-', 'Producto para Venta Directa', 'Producto Elaborado', 'Compuesto Personalizado', 'Paquete', 'Insumo / Materia Prima', 'Servicio'];
export const yesNoType = value => (value ? 'SI' : 'NO');
export const printer = ['No Imprimir', 'Impresora 1', 'Impresora 2'];
export const action = ['-', 'Retiro', 'Depósito', 'Caja Fuerte'];
export const orderStatus = ['Cancelada', 'Abierta', 'En Proceso', 'Cerrada', 'Completado'];
export const typeSale = ['-', 'Local', 'Domicilio', 'Ordene y Recoja'];
export const receivableType = ['-', 'Venta', 'Cargo'];
export const companyFormat = ['-', 'NARACHIPS', 'CASA DEL ELOTE'];
const daysOfWeek = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', '-'];
const orderAuthorizationStatus = ['Sin Código', 'Vigente', 'Expirado', 'Finalizado'];
const promotionAvailableDays = value => {
	const array = value.split(',');
	return array.map((key, i) => `${daysOfWeek[key]}${i < (array.length - 1) ? i === (array.length - 2) ? ' y ' : ', ' : ''}`);
};

const paymentFormOptions = [ 
  {value: "00", label: "Caja Chica"},
  {value: "01", label: "Efectivo"},
  {value: "100", label: "Depósito"},
  {value: "02", label: "Cheque nominativo"},
  {value: "03", label: "Transferencia electrónica de fondos"},
  {value: "04", label: "Tarjeta de crédito"},
  {value: "05", label: "Monedero electrónico"},
  {value: "06", label: "Dinero electrónico"},
  {value: "15", label: "Condonación"},
  {value: "17", label: "Compensación"},
  {value: "23", label: "Novación"},
  {value: "28", label: "Tarjeta de débito"},
  {value: "30", label: "Aplicación de anticipos"},
  {value: "31", label: "Intermediario pagos"},
  {value: "99", label: "Por definir"}
];
const paymentFormFormat = value => {
	let paymentFormat = paymentFormOptions.find(tmp => tmp.value === value);
	if(!paymentFormat) paymentFormat = {label: 'Indefinido'};
	return paymentFormat.label;
}

const format = (value, type) => {
	switch (type) {
		case 'date':
			return value && value !== '-' ? dateFormat(parseISO(value), 'dd/MM/yyyy') : '-';
		case 'dateTime':
			return value && value !== '-' ? dateFormat(parseISO(value), 'dd/MM/yyyy HH:mm:ss') : '-';
		case 'time':
			return value && value !== '-' ? dateFormat(parseISO(value), 'HH:mm:ss') : '-';
		case 'currency':
			return currencyFormat(value);
		case 'percent':
			return !Number.isNaN(parseInt(value)) ? `${currencyFormat(value)}%` : '-';
		case 'balance':
			return (parseFloat(value) === 0) ? '-' : currencyFormat(value);
		case 'productType':
			return productType[value];
		case 'transferStatus':
			return transferStatus[value];
		case 'productTypeV2':
			return productTypeV2[value];
		case 'yesNoType':
			return yesNoType(value);
		case 'printer':
			return printer[value];
		case 'action':
			return action[value];
		case 'orderStatus':
			return orderStatus[value];
		case 'typeSale':
			return typeSale[value];
		case 'receivableType':
			return receivableType[value];
		case 'promotionAvailableDays':
			return promotionAvailableDays(value);
		case 'orderAuthorizationStatus':
			return orderAuthorizationStatus[value];
		case 'orderAuthorizationAction':
			return parseInt(value) === 1 ? 'Cancelación de Cuenta' : 'Cancelación de Producto';
		case 'companyFormat':
			return companyFormat[value];
		case 'paymentFormFormat':
			return paymentFormFormat(value);
		default: return (typeof value === 'undefined' || value === '') ? '-' : value;
	}
};

export default format;

import React, { useState, Fragment } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import TextField from '@material-ui/core/TextField';

import AddCircleIcon from '@material-ui/icons/AddOutlined';
import EventIcon from '@material-ui/icons/EventOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVertOutlined';
import SearchIcon from '@material-ui/icons/SearchOutlined';

import IconButton from './../../IconButton';

import history from './../../../config/routes/history';
import { actionForm } from './../../../apollo/cache';

import Menu from './menu';

import useStyles from './style';
import SearchDialog from './search.dialog.js';

const Component = props => {

  const classes = useStyles();

  const [ value, setValue ] = useState('');
  const [ openMenu, setOpenMenu ] = useState(null);
  const [ isOpen, setIsOpen ] = useState(false);

  const handleOpenMenu = e => { setOpenMenu(e.currentTarget); }
  const handleCloseMenu = () => { setOpenMenu(null); }
  const handleOpenCreate = () => { 
    const variables = { isOpen: true, action: 'create' };
    actionForm({ ...actionForm(), ...variables });
  }
  const handleSearch = e => {
    const value = e.target.value;
    setValue(value);
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      props.handleSearch(value);
    }
  }

  const handleRedirect = e => {
    history.goBack();
  }

  const handleOpenSearch = e => {
    setIsOpen(true)
  }

  const handleClose = e => {
    setIsOpen(false);
  }

  const handleAction = tmpValue => {
    setValue(tmpValue);
    props.handleSearch(tmpValue);
  }

  return (
    <Fragment>
      <Grid container alignItems="center" className={ classes.header } spacing={0}>
        <Grid item md={7} xs={6}>
          <Typography variant="subtitle2" display="block">
            { props.title && props.title.toUpperCase() }  ({ props.qty || 0 })
            <br/> <small>{ props.subTitle || "" }</small>
          </Typography>
        </Grid>
        <Grid container item justifyContent="flex-end" alignItems='center' spacing={0} md={5} xs={6}>
          { props.moduleCode && <div className={classes.moduleCode}>{props.moduleCode}</div> }
          { props.handleSearch &&
            <Hidden smDown>
              <TextField 
                label="Búsqueda"
                variant="outlined"
                margin="dense"
                size="small"
                color="secondary"
                value={value}
                onKeyDown={handleKeyDown}
                onChange={handleSearch}
                className={ classes.marginRight }
                autoComplete="off"
              />
            </Hidden>
          }
          {
            (!props.noCreate && (!!props.create || props.handleCustomCreate)) && (
              <IconButton 
                color="secondary" 
                label="Crear" 
                action={ props.handleCustomCreate || handleOpenCreate } 
                className={ classes.paddingX }
                icon={<AddCircleIcon />}
              />
            )
          }
          { props.handleSearch &&
              <Hidden smUp>
                <IconButton 
                  label="Buscar" 
                  action={ handleOpenSearch } 
                  className={ classes.paddingX }
                  icon={<SearchIcon />}
                />
              </Hidden>
          }
          { props.filter &&
            <IconButton 
              label="Filtrar" 
              action={ props.filter } 
              className={ classes.paddingX }
              icon={<EventIcon />}
            />
          }
          {
            !props.noMenu &&
              <Hidden smDown>
                <IconButton 
                  label="Más Opciones" 
                  action={ handleOpenMenu } 
                  className={ classes.paddingX }
                  aria-controls="module-menu" 
                  aria-haspopup="true" 
                  icon={<MoreVertIcon />}
                />
                  <Menu
                    title={props.title}
                    Rows={props.Rows}
                    get={props.get}
                    records={props.records}
                    return={ !!props.return && handleRedirect }
                    anchorEl={ openMenu }
                    onClose={ handleCloseMenu }
                    customizedPdf={props.customizedPdf}
                    customizedXls={props.customizedXls}
                  />
              </Hidden>
          }
        </Grid>
      </Grid>
      <SearchDialog isOpen={isOpen} handleClose={handleClose} handleAction={handleAction} />
    </Fragment>
  )
}

export default Component;
import React, { Fragment, useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import Grid from '@material-ui/core/Grid';

import LinearForm from './../../../../presentationals/LinearForm';
import TextField from './../../../../presentationals/TextField';
import Autocomplete from './../../../../presentationals/Autocomplete';

import GQL from './../../_gql';
import GQL_PRODUCT from './../../../product/_gql'

const defaultValues = {
	quantity: 1,
	productId: null,
}

const Form = props => {
	const [ values, setValues ] = useState(defaultValues);
	const [ errors, setErrors ] = useState({});
  const [ products, setProducts ] = useState([]);
  
	const { data: productsData } = useQuery(GQL_PRODUCT.GET, { variables: { limit: 0 } });
  const [ createProduct ] = useMutation(GQL.CREATE_PRODUCT,{
    onCompleted: data => {
      props.getCart();
    }
  });

  useEffect(() => {
    if(productsData && productsData.products){
      const tmpProducts = productsData.products.rows.map(el => ({ value: el.id, label: el.name, cost: el.cost }));
      setProducts(tmpProducts);
    }
  }, [productsData]);

	useEffect(() => {
    setValues(defaultValues);
  }, [props.codeToClear]);

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAction = async e => {
  	try{
  		if(isNaN(parseFloat(values.quantity)) || parseFloat(values.quantity) <= 0){ 
  			const e = new Error('Proporciona una cantidad mayor a cero.');
  			e.name = 'quantity';
  			throw e; 
  		}

  		if(!values.productId || isNaN(values.productId.value)){ 
  			const e = new Error('Selecciona un producto.')
  			e.name = 'productId';
  			throw e; 
  		}

  		await createProduct({
        variables: { ...values, quantity: parseFloat(values.quantity), eventPackageId: props.id, productId: values.productId.value },
      });
  		setValues(defaultValues);
  		setErrors({});
  	}catch(e) {
      console.log(e);
  		setErrors({ [e.name]: e.message });
  	}
  	
  }

	return (
		<Fragment>
			<div>
				<LinearForm handleAdd={handleAction} align='center'>
					<Grid container spacing={1}>
						<Grid item xs={12} md={8}>
              <Autocomplete 
                name='productId'
                label='Producto' 
                variant='outlined'
                onChange={handleAutocompleteChange}
                options={products}
                value={values.productId}
                error={errors.productId}
                noFormat
              />
						</Grid>
						<Grid item xs={12} md={4}>
							<TextField 
				        name='quantity'
				        label='Cantidad'
				        type='number' 
				        variant='outlined'
				        onChange={handleChange}
				        value={values.quantity}
				        error={errors.quantity}
				        noFormat
				      />
						</Grid>
					</Grid>
				</LinearForm>
			</div>
		</Fragment>
	);
}

export default Form;
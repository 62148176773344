import { gql } from '@apollo/client';

const GET = gql`
  query BranchInventoryBases($productId: ID){
    branchInventoryBases(productId: $productId) {
      id
      minimum
      maximum
      productId
      branchId
    }
  }
`;

const UPDATE = gql`
  mutation UpdateBranchInventoryBaseTotals(
    $minimum: Float! 
    $maximum: Float! 
    $productId: ID! 
    $branchId: ID!
  ){
    updateBranchInventoryBaseTotals(input: { minimum: $minimum maximum: $maximum productId: $productId branchId:$branchId }) {
      id
      minimum
      maximum
      productId
      branchId
    }
  }
`;

const object = { GET, UPDATE };
export default object;

import { gql } from '@apollo/client';

const GET = gql`
  query Users($limit: Int $offset: Int $owner: Boolean $branchId: ID $role: [Int] $like: String){
    users(limit: $limit offset: $offset owner: $owner branchId: $branchId role: $role like: $like) {
      count
      rows {
        id
        name
        username
        role
        Branch {
          id
          name
        }
      }
    }
  }
`;

const GET_AUTOCOMPLETE = gql`
  query UsersAuto($offset: Int, $limit: Int, $like: String $role: [Int]){
    users(offset: $offset limit: $limit like: $like role: $role){
      rows {
        id
        name
      }
    }
  }
`;

const GET_BY_ID = gql`
  query User($id: ID!){
    user(id: $id) {
      id
      name
      username
      role
      pin
      branchId
    }
  }
`;

const DELETE = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
    }
  }
`;

const CREATE = gql`
  mutation CreateUser(
    $name: String!
    $username: String!
    $password: String!
    $pin: String!
    $role: Int!
    $branchId: ID!
  ) {
    createUser(input: {
      name: $name
      username: $username
      password: $password
      pin: $pin
      role: $role
      branchId: $branchId
    }) {
      id
      name
      username
      role
    }
  }
`
const UPDATE = gql`
  mutation UpdateUser(
    $id: ID!
    $name: String!
    $username: String!
    $pin: String!
    $role: Int!
    $branchId: ID!
  ) {
    updateUser(
      id: $id, 
      input: {
        name: $name
        username: $username
        pin: $pin
        role: $role
        branchId: $branchId
      }
    ) {
      id
      name
      username
      role
    }
  }
`
const CHANGE_PASSWORD = gql`
  mutation UpdatePassword($userId: ID! $password: String!) {
    updatePassword(input: { userId: $userId password: $password }) {
      id
    }
  }
`;

const obj = { GET, GET_BY_ID, DELETE, CREATE, UPDATE, GET_AUTOCOMPLETE, CHANGE_PASSWORD };
export default obj;


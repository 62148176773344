import React, { useState, useEffect, Fragment } from 'react';

import { useQuery } from '@apollo/client';

import Module from './../../presentationals/Module';

import GQL from './_gql';

const yesNoOptions = [
	{ value: true, label: 'Sí' },
	{ value: false, label: 'No' },
]

const companyOptions = [
	{ value: 1, label: 'Narachips' },
	{ value: 2, label: 'CasaElote' },
]

const Rows = [
	{ label: 'Clasificación', key: 'name', sort: 'text' },
	{ label: '¿Se vende?', key: 'show', sort: 'text', format: 'yesNoType' },
	{ label: '¿Aplica inventario?', key: '', sort: 'text', format: 'yesNoType' },
	{ label: 'Empresa', key: 'companyId', format: 'company' }
];

const Component = props => {

	const [ filter, setFilter ] = useState({});
	
	const { data, loading, refetch } = useQuery(GQL.GET);

	useEffect(() => {
		if(Object.keys(filter).length > 0){
			refetch(filter);
		}
	}, [filter, refetch]);

	const handleRows = (offset, limit) => {
		setFilter(filter => ({ ...filter, limit, offset }));
	}

	const handleSearch = (value) => {
		setFilter(filter => ({ ...filter, like: value }));
	}

	return (
		<Fragment>
			<Module
				moduleCode='CACLA01'
				isLoading={loading}
				name='Clasificaciones'
				singular='Clasificación'
				operation={['clasification', 'clasifications']}
				Rows={Rows}
				get={GQL.GET}
				handleRows={handleRows}
				getById={GQL.GET_BY_ID}
				create={GQL.CREATE}
				update={GQL.UPDATE}
				delete={GQL.DELETE}
				records={data && data.clasifications}
				handleSearch={handleSearch}
				fields={[
					{
						name: 'name',
						label: 'Nombre de la clasificación',
					},
					{
						name: 'show',
						label: '¿Se vende?',
						field: 'select',
						options: yesNoOptions
					},
					{
						name: 'inventory',
						label: '¿Lleva inventario?',
						field: 'select',
						options: yesNoOptions
					},
					{
						name: 'companyId',
						label: 'Empresa',
						field: 'select',
						options: companyOptions
					},
				]}
			/>
		</Fragment>
	);
}

export default Component;
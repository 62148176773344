import { gql } from '@apollo/client';

const GET_BRANCHES = gql`
  query Branches($owner: Boolean) {
    branches(owner:$owner) {
      count
      rows {
        id
        name
      }
    }
  }
`;

const obj = { GET_BRANCHES };
export default obj;
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import locale from 'date-fns/locale/es';

const xls = (data, name, filter) => {

	const start = filter.start 
  	? format(filter.start, 'dd-MMM-yyyy', { locale }).toUpperCase()
  	: format(new Date(), 'dd-MMM-yyyy', { locale }).toUpperCase();
  const end = filter.end 
  	? format(filter.end, 'dd-MMM-yyyy', { locale }).toUpperCase()
  	: format(new Date(), 'dd MMM yyyy', { locale }).toUpperCase();

	const filename = `${name}_${start}_AL_${end}.xlsx`;
		
	const firstHeader = [
		'DIA',
		...data[0].Sales.reduce((array,el) => (
			[ ...array, el.Branch.name.toUpperCase(), '', '' ]
		),[])
	];	
	const secondHeader = [
		'',
		...data[0].Sales.reduce((array,el) => (
			[ ...array, "Cant.", "Importe", "Descuento" ]
		),[])
	];
	let index = 0;
	const merge = [
		{ s: { r:0, c:0 }, e: { r:1, c:0 } },
		...data[0].Sales.reduce((array,el,i) => {
			const tmp1 = index + 1;
			const tmp2 = index + 3;
			index += 3;
			return [ ...array, { s: { r: 0, c: tmp1 }, e: { r: 0, c: tmp2 } }]
		},[])
	];

	const dataRows = data.map(el => {
		const totals = el.Sales.reduce((array,tmp) => (
			[
				...array,
				parseFloat(tmp.qty),
				parseFloat(tmp.total),
				parseFloat(tmp.discount),
			]
		),[]);
		return [ el.Discount.name.toUpperCase(), ...totals ] 
	}); 

	const rows = [
	  firstHeader,
	  secondHeader,
	  ...dataRows
	];

	const ws = XLSX.utils.aoa_to_sheet(rows);

	if(!ws['!merges']) ws['!merges'] = [];
	ws['!merges'] = merge;

	var wb = XLSX.utils.book_new();

	XLSX.utils.book_append_sheet(wb,ws,'PROMOCIONES');
	
	XLSX.writeFile(wb, filename);
}

export default xls;
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f6f6f6',
    padding: theme.spacing(2, 4, 3, 4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 0, 1),
      marginBottom: theme.spacing(1),
    },
  },
  spaceBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  body: {
    margin: theme.spacing(2, 0, 3, 0),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, 0, 1, 0),
    },
  },
  marginX: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  marginRight: { marginRight: theme.spacing(1) },
	paddingX: {
		marginTop: theme.spacing(0.5),
		paddingLeft: theme.spacing(1.6),
		paddingRight: theme.spacing(1.6),
	},
	icon: {
		fontSize: 28,
	},
	flex: {
		display: 'flex',
    alignItems: 'center',
	},
}));

export default useStyles;

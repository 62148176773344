import React, { Fragment, useState, useEffect } from 'react';

import { useQuery, NetworkStatus } from '@apollo/client';

// import format from 'date-fns/format';
// import { es } from 'date-fns/locale';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import Header from './../../presentationals/Table/header';
import Table from './../../presentationals/Table/helpers/Table';
import Loading from './../../presentationals/Loading';

import { currencyFormat } from './../../helpers';

import GQL from './_gql';
import FilterDialog from './filter.dialog';

import DownloadXLS from './xls.document';
import DownloadPDF from './pdf.document';
import SaleNotePDF from './../saleNote/pdf.a4';

const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
// const currentDate = format(new Date(), 'dd MMM yyyy', { locale: es }).toUpperCase();

const Component = props => {

	const [ isOpen, setIsOpen ] = useState(false);
	const [ filter, setFilter ] = useState({});
	const [ total, setTotal ] = useState([]);

	const { data, loading, refetch, networkStatus } = useQuery(GQL.GET, {
		notifyOnNetworkStatusChange: true,
		variables: { owner: false }
	});

	useEffect(() => {
		if(data && data.royalties){
			const tmp = data.royalties.rows.map(el =>
				el.Sales.reduce((array, elCh, i) => {
					array[i] = { 
						royalty: parseInt(array[i] ? array[i].royalty : 0) + parseInt(elCh.royalty),
						totalSale: parseFloat(array[i] ? array[i].totalSale : 0) + parseFloat(elCh.totalSale)
					}
					return array;
				}, [])
			);
			const tmp1 = tmp.reduce((array, el) => {
				el.forEach((elCh, i) => {
					array[i] = {
						royalty: parseInt(array[i] ? array[i].royalty : 0) + parseInt(elCh.royalty),
						totalSale: parseFloat(array[i] ? array[i].totalSale : 0) + parseFloat(elCh.totalSale)
					}
				})
				return array;
			}, []);

			setTotal(tmp1);
		}
	}, [data]);

	useEffect(() => {
		if(Object.keys(filter).length > 0){
			refetch(filter);
		}
	}, [filter, refetch]);

	const handleFilter = () => {
		setIsOpen(true);
	}

	const handleAction = (action, data) => {
		switch(action){
			case 'close':
				setIsOpen(false);
				break;
			default:
				setFilter(filter => ({ ...filter, ...data }));
				break;
		}
	}

	const downloadXLS = () => {
		data && DownloadXLS([...data.royalties.rows, { Branch: { name: 'TOTAL' }, Sales: total }], 'REGALÍAS_DE_CLIENTES');
	}

	const downloadPDF = () => {
		data && DownloadPDF(data.royalties.rows, filter);
	}

	const handleNotePdf = (id) => {
		if(!id) return false;
		SaleNotePDF(id);
	}

	if(loading || networkStatus === NetworkStatus.refetch) return <Loading />;

	return (
		<Fragment>
			<Header
				title="Regalías de Clientes"
				noCreate
				customizedXls={downloadXLS}
				customizedPdf={downloadPDF}
				qty={data && data.royalties.count}
				filter={handleFilter}
				moduleCode='RERE01'
			/>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell />
						{
							(data && data.royalties.count > 0) && 
								data.royalties.rows[0].Sales.map((el, i) => 
									<TableCell key={el.month} align='center' colSpan={2} 
										style={{ 
											background: (i % 2 === 0) ? '#c9c9c9' : '#fafafa',
											borderColor: (i % 2 === 0) ? '#989898' : '#ddd',
											minWidth: 10
										}}
									>
										<Typography variant="body2" noWrap>
											<strong>{months[el.month]}</strong>
										</Typography>
									</TableCell>
								)
						}
					</TableRow>
					<TableRow>
						<TableCell style={{top: 33}}>Sucursales</TableCell>
						{
							(data && data.royalties.count > 0) && 
								data.royalties.rows[0].Sales.map((el, i) => 
									<Fragment key={el.month}>
										<TableCell 
											align='right' 
											style={{ 
												background: (i % 2 === 0) ? '#c9c9c9' : '#fafafa',
												borderColor: (i % 2 === 0) ? '#989898' : '#ddd',
												top: 33
											}}
										>
											Regalias
										</TableCell>
										<TableCell 
											align='right' 
											style={{ 
												background: (i % 2 === 0) ? '#c9c9c9' : '#fafafa',
												borderColor: (i % 2 === 0) ? '#989898' : '#ddd',
												top: 33,
											}}
										>
											Venta
										</TableCell>
									</Fragment>
								)
						}
					</TableRow>
				</TableHead>
				<TableBody>
					{ data && data.royalties.rows.map((el, i) =>
							<TableRow key={i}>
								<TableCell>
									<Typography variant="body2" noWrap>
										{el.Branch.name}
									</Typography>
								</TableCell>
								{
									el.Sales.map((elCh, ii) => 
										<Fragment key={ii}>
											<TableCell
												align='right' 
												style={{ 
													background: (ii % 2 === 0) ? '#ddd' : '#fafafa',
													borderColor: (ii % 2 === 0) ? '#989898' : '#ddd'
												}}
											>
											{
												elCh.saleNoteId
												 ? <Link onClick={() => handleNotePdf(elCh.saleNoteId)}>{currencyFormat(elCh.royalty)}</Link>
												 : currencyFormat(elCh.royalty)
											}
											</TableCell>
											<TableCell
												align='right' 
												style={{ 
													background: (ii % 2 === 0) ? '#ddd' : '#fafafa',
													borderColor: (ii % 2 === 0) ? '#989898' : '#ddd'
												}}
											>
												{currencyFormat(elCh.totalSale)}
											</TableCell>
										</Fragment>
									)
								}
							</TableRow>
						)
					}
					<TableRow>
						<TableCell style={{ position: 'sticky', bottom: 0, background: '#fafafa', borderTop: '1px solid #ddd' }}>
							<Typography variant="body2" noWrap>
								<strong>TOTAL</strong>
							</Typography>
						</TableCell>
						{
							(data && data.royalties.count > 0) && 
								data.royalties.rows[0].Sales.map((el, i) => 
									<Fragment key={el.month}>
										<TableCell 
											align='right' 
											style={{ 
												position: 'sticky', 
												bottom: 0, 
												background: (i % 2 === 0) ? '#a2a2a2' : '#c0c0c0',
												borderTop: '1px solid #ddd' 
											}}
										>
											{total[i] ? currencyFormat(total[i].royalty) : currencyFormat(0)}
										</TableCell>
										<TableCell 
											align='right' 
											style={{ 
												position: 'sticky', 
												bottom: 0, 
												background: (i % 2 === 0) ? '#a2a2a2' : '#c0c0c0',
												borderTop: '1px solid #ddd' 
											}}
										>
											{total[i] ? currencyFormat(total[i].totalSale) : currencyFormat(0)}
										</TableCell>
									</Fragment>
								)
						}
					</TableRow>
				</TableBody>
			</Table>
			<FilterDialog isOpen={isOpen} handleAction={handleAction} />
		</Fragment>
	);
}

export default Component;
import jsPDF from 'jspdf';
import format from 'date-fns/format';
import locale from 'date-fns/locale/es';

import client from '../../apollo';

import GQL from './_gql';

const getDataFromServer = async id => {
  try {
    const { data } = await client.mutate({
      mutation: GQL.GET_BY_ID,
      variables: { id },
    });
    return data.requisition;
  } catch (e) {
    console.log(e);
  };
};

const Pdf = async (id) => {
  const data = await getDataFromServer(id);

  const folio = data.folio;
  const createdAt = format(new Date(data.createdAt), 'dd/MM/yyyy HH:mm', { locale });
  const requisitionBranch = data.Branch.name;

    const doc = new jsPDF({
        unit: 'mm',
        format: [80, 3500],
    });

  doc.setProperties({
    title: `requisicion_${folio}`,
  });

  let breakLine = 10;

  doc.setFontSize(14);
  doc.setFont('times', 'bold');

  doc.text('REQUISICIÓN DE PRODUCTOS', 40, breakLine, 'center');

  doc.text(`REQ ${folio}`, 3, 18, 'left');

  doc.setFontSize(10);

  doc.setFont('times', 'bold');
  doc.text('Fecha de Emisión', 3, 24, 'left');
  doc.setFont('times', 'normal');
  doc.text(createdAt, 3, 28, 'left');

  doc.setFont('times', 'bold');
  doc.text('Sucursal Destino', 3, 42, 'left');
  doc.setFont('times', 'normal');
  doc.text(requisitionBranch, 3, 46, 'left');

  breakLine = 53;

  doc.setFontSize(9);
  doc.setFont('times', 'bold');

  doc.setDrawColor(30, 30, 30);
  doc.setFillColor(30, 30, 30);
  doc.rect(3, breakLine - 3, 74, 4, 'FD');
  doc.setTextColor(255, 255, 255);
  doc.text('Conceptos', 40, breakLine, 'center');

  breakLine += 5;

  doc.setDrawColor(30, 30, 30);
  doc.setFillColor(30, 30, 30);
  doc.rect(3, breakLine - 3, 74, 4, 'FD');
  doc.setTextColor(255, 255, 255);

  doc.text('Cant.', 8, breakLine, 'center');
  doc.text('Descripción', 15, breakLine);

  doc.setFont('times', 'normal');
  doc.setFontSize(9);
  doc.setTextColor(0);

  breakLine += 4;

  data.ProductRequisitions.forEach(el => {
    const name = el.Product ? el.Product.name : '-';
    const unit = el.ProductUnit ? el.ProductUnit.name : '-';

    doc.setDrawColor(255, 255, 255);
    doc.setFillColor(255, 255, 255);
    doc.rect(3, (breakLine - 3), 74, 10, 'FD');

    const quantity = !isNaN(parseFloat(el.sendQuantity))
      ? el.sendQuantity.toFixed(2)
      : !isNaN(parseFloat(el.quantity))
        ? el.quantity
        : 0;

    doc.text(String(quantity), 8, breakLine, 'center');
    doc.text(name, 15, breakLine);
    breakLine += 3;
    doc.text(unit, 15, breakLine);
    breakLine += 5;
  });

  breakLine += 4;
  doc.setFontSize(10);

  doc.setFont('times', 'bold');
  doc.text('Comentarios:', 3, breakLine);

  const array = doc.splitTextToSize(data.description, 50);
  const spaces = parseInt(array.length) * 4;
  
  doc.text('Emitido por:', 3, breakLine + parseInt(spaces) + 4);

  doc.setFont('times', 'normal');
  doc.text(array, 28, breakLine);
  doc.text(`${data.User.name}`, 28, breakLine + parseInt(spaces) + 4);

  doc.setDrawColor(0);
  doc.setFillColor(0);

  doc.line(5, (breakLine + parseInt(spaces) + 26), 30, (breakLine + parseInt(spaces) + 26));
  doc.text('Entregado', 10, (breakLine + parseInt(spaces) + 30));

  doc.line(50, (breakLine + parseInt(spaces) + 26), 75, (breakLine + parseInt(spaces) + 26));
  doc.text('Recibido', 55, (breakLine + parseInt(spaces) + 30));

  window.open(doc.output('bloburl'), '_blank');
  // doc.save(`traspaso_${folio}_${data.Branch.name}_a_${data.BranchTransfer.name}.pdf`);
};

export default Pdf;

import React, { Fragment, useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';

import Typography from '@material-ui/core/Typography';

import FormDialog from './../../presentationals/FormDialog';
import Button from './../../presentationals/Button';

import { snackbar } from './../../apollo/cache';

import GQL from './_gql';

const defaultSnackbar = { isOpen: true, time: 3000 };
const defaultCredentials = ['', ''];

const ProductProperties = ({ branchId, ...props }) => {

  const [ credentials, setCredentials ] = useState(defaultCredentials);

  const [ createCredentials, { data, loading } ] = useMutation(GQL.CREATE_CREDENTIALS, {
    onCompleted: () => {
      snackbar({ ...defaultSnackbar, label: 'Los credenciales fueron creadas con éxito.', severity: 'success' });
    }
  });

  useEffect(() => {
    (data && data.generateLocalCredentials) 
      && setCredentials([
        data.generateLocalCredentials.token, 
        data.generateLocalCredentials.secret
      ]);
  }, [data]);

 
  const handleClose = () => {
    props.handleClose();
  }

  const handleCredentials = () => {
    createCredentials({ variables: { id: branchId } });
  }

  return (
    <FormDialog 
      title='Genera tus Credenciales'
      isLoading={loading}
      isOpen={props.isOpen || false}
      handleClose={handleClose}
      hideAction={true}
		>
      <Typography variant="body2" gutterBottom>
        Las credenciales podrán ser utilizadas para configurar la aplicación de escritorio, 
        ten cuidado, si genera nuevas credenciales, cualquiera que hayas generado anteriormente serán dadas de baja.
      </Typography>
      <Button
        variant='outlined'
        onClick={handleCredentials}
        label='Generar nuevas credenciales'
        color='secondary'
      />
      {
        credentials[0] !== '' &&
        <Fragment>
          <Typography variant="body2" gutterBottom>
            Token: <br/><strong>{credentials[0]}</strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Secret: <br/><strong>{credentials[1]}</strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Copia y almacena estas credenciales en un lugar seguro pues no las podrás recuperar.
          </Typography>
        </Fragment>
      }
    </FormDialog>
   )
}

export default ProductProperties;
import React from 'react';

import CloudDownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardArrowLeftOutlined';
import PrintIcon from '@material-ui/icons/PrintOutlined';

import Menu from '@material-ui/core/Menu';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import history from '../../../../../config/routes/history';
import { exportPdf, exportXls, exportCustomXls } from '../../../../../apollo/cache';

const HeaderMenu = props => {
  const handleRedirect = () => {
    history.goBack();
  };
  const downloadPDF = () => {
    exportPdf({ export: true });
  };
  const downloadXLS = () => {
    props.customXls ? exportCustomXls(true) : exportXls({ export: true });
  };
  return (
    <Menu
      id='header-menu'
      anchorEl={props.anchorEl}
      keepMounted
      open={Boolean(props.anchorEl)}
      onClose={props.onClose}
    >
      <MenuItem onClick={downloadPDF}>
        <ListItemIcon>
          <PrintIcon />
        </ListItemIcon>
        <ListItemText primary='Exportar | PDF' />
      </MenuItem>
      <MenuItem onClick={downloadXLS}>
        <ListItemIcon>
          <CloudDownloadIcon />
        </ListItemIcon>
        <ListItemText primary='Exportar | XLS' />
      </MenuItem>
      { props.return && <Divider /> }
      { props.return
          && <MenuItem onClick={handleRedirect}>
            <ListItemIcon>
              <KeyboardReturnIcon />
            </ListItemIcon>
            <ListItemText primary='Regresar' />
             </MenuItem>}
    </Menu>
  );
};

export default HeaderMenu;

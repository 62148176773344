import React from 'react';

import DeleteIcon from '@material-ui/icons/DeleteOutlined';

import makeStyles from '@material-ui/core/styles/makeStyles';

import { deleteForm } from '../../../../apollo/cache';

import IconButton from '../../../IconButton';

const useStyles = makeStyles(Theme => ({
  actionButton: { padding: Theme.spacing(0.4) },
}));

const DeleteButtonComponent = props => {

	const classes = useStyles();

	const handleOpenDelete = (e, params) => {
		deleteForm({ isOpen: true, params });
	};

	return (
		<IconButton
			label='Eliminar'
			action={handleOpenDelete}
			params={{ id: props.data.id, ...props.data }}
			className={classes.actionButton}
			icon={<DeleteIcon fontSize='small' />}
		/>
	);
};

export default DeleteButtonComponent;

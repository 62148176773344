import React from 'react';

import Card from '@material-ui/core/Card';

import Copyright from '../Copyright';

import Table from '../Table';
import Totals from '../Totals';

import Title from './Title';
import SearchButton from './SearchButton';
import CreateButton from './CreateButton';
import FilterButton from './FilterButton';
import MenuButton from './MenuButton';
import useStyles from './style';
import Snackbar from './../../Snackbar';

const ContainerComponent = props => {

	const classes = useStyles();

	return (
		<div className={classes.container}>
			<div className={classes.spaceBetween}>
				<div className={classes.flex}>
          <Title name={props.title} subTitle={props.subTitle} />
          { !props.noSearch && <SearchButton name={props.altSearchButtonKey} title={props.title} /> }
        </div>
        <div className={classes.flex}>
          { (!!props.create || props.handleCustomCreate) && <CreateButton handleCustomCreate={props.handleCustomCreate} /> }
          { props.filter && <FilterButton /> }
          { !props.noMenu && <MenuButton customXls={props.customXls} /> }
        </div>
			</div>
			<Totals />
			<Card className={classes.body}>
				{
					!props.noTable
						&& <Table
							defaultVariables={props.defaultVariables}
							title={props.title}
							operation={props.operation}
							Rows={props.Rows}
							delete={props.delete}
							edit={props.edit}
							get={props.get}
							noPagination={props.noPagination}
						/>
				}
				{props.children}
			</Card>
			<Copyright />
			<Snackbar horizontal='center' />
		</div>
	);
};

export default ContainerComponent;

import React, { useState, useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';

import SearchIcon from '@material-ui/icons/SearchOutlined';

import Hidden from '@material-ui/core/Hidden';
import IconButtonMUI from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';

import { filter } from '../../../../apollo/cache';

import useStyles from './style';

const SearchButtonComponent = props => {

  const classes = useStyles();

  const filterVar = useReactiveVar(filter);

  const title = `Buscar ${props.title && props.title.toLowerCase()}`;

  const [value, setValue] = useState('');

  useEffect(() => {
    return () => {
      setValue('');
      filter({});
    };
  }, []);

  const handleChange = e => {
    const { value } = e.target;
    setValue(value);
  };

  const handleClick = () => {
    if (value && value !== '') {
      filter({ ...filterVar, name: value });
    } else {
      const newLocalFilter = Object.keys(filterVar).reduce((object, key) => {
        return key !== 'name' ? { ...object, [key]: filterVar[key] } : object;
      }, {});
      filter(newLocalFilter);
    }
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleClick();
    }
  };

  return (
    <Hidden smDown>
      <Paper className={classes.paper}>
        <InputBase
          className={classes.input}
          placeholder={title}
          inputProps={{ 'aria-label': title }}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
        <IconButtonMUI onClick={handleClick} className={classes.iconButton} aria-label='search'>
          <SearchIcon />
        </IconButtonMUI>
      </Paper>
    </Hidden>
  );
};

export default SearchButtonComponent;

import React, { useState, useEffect, Fragment } from 'react';
import { useMutation } from '@apollo/client';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { parseError } from './../../../../helpers';
import { snackbar } from './../../../../apollo/cache';

import { currencyFormat } from './../../../../helpers/general';

import IconButton from './helpers/iconButton';
import GQL from './../../_gql';

const defaultValues = {
	subtotal: 0,
	discount: 0,
	iva: 0,
	total: 0
}

const TableComponent = props => {

	const [ values, setValues ] = useState(defaultValues);

	const [ deleteProductOrder ] = useMutation(GQL.DELETE_PRODUCT_ORDER);

	useEffect(() => {
		if(props.cartItems.length > 0) {
			const tmp = props.cartItems.reduce((object, el) => {
				const total = parseFloat(object.total) + parseFloat(el.total);
				const discount = parseFloat(object.discount) + parseFloat(el.discount);
				const subtotal = parseFloat(object.subtotal) + parseFloat(el.subtotal);
				const iva = parseFloat(object.iva) + parseFloat(el.taxes);
				
				return {
					total,
					subtotal,
					discount,
					iva
				}
			}, defaultValues);
			setValues(tmp);
		}
	}, [props.cartItems, props.iva]);


	const handleDeleteItem = async (e, id) => {
		try{
  		await deleteProductOrder({ variables: { branchOrderDetailId: id } });
  		await props.getCart();
  	}catch(e) {
  		const parseErrors = parseError(e);
      if(parseErrors[0].name === 'GENERAL_ERROR'){
      	snackbar({
	      	isOpen: true, 
	        time: 3000,
	        label: parseErrors[0].message, 
	        severity: 'error'
	      });
      }
  	}
	}
	
	return (
		<Fragment>
			<TableContainer>
				<Table size="small">
					<TableHead>
	          <TableRow>
	            <TableCell>Acciones</TableCell>
	            <TableCell>Producto</TableCell>
	            <TableCell>Unidad</TableCell>
	            <TableCell align="center">Cant.</TableCell>
	            <TableCell align="center">Descto.</TableCell>
	            <TableCell align="right">{'P. Unitario'}</TableCell>
	            <TableCell align="right">{'Importe'}</TableCell>
	          </TableRow>
	        </TableHead>
	        <TableBody>
	        	{
	        		props.cartItems.map((el, i) => 
	      				<TableRow key={i}>
	      					<TableCell>
	      						<IconButton 
	      							label='Eliminar'
	      							icon={<DeleteOutlineIcon />}
	      							params={el.id}
	      							action={handleDeleteItem}
	      							size='small'
	      						/>
	      					</TableCell>
			            <TableCell>{el.Product.name}</TableCell>
			            <TableCell>{el.ProductUnit.name}</TableCell>
			            <TableCell align="center">{el.quantity}</TableCell>
			            <TableCell align="center">{currencyFormat(el.discount)}</TableCell>
			            <TableCell align="right">{currencyFormat(el.unitPrice)}</TableCell>
			            <TableCell align="right">{currencyFormat(el.total)}</TableCell>
	      				</TableRow>
	      			)
	        	}
	        	<TableRow>
	        		<TableCell colSpan={6} align="right">Subtotal:</TableCell>
	            <TableCell align="right">{props.currency} {currencyFormat(values.subtotal)}</TableCell>
	        	</TableRow>
	        	<TableRow>
	        		<TableCell colSpan={6} align="right">Dscto:</TableCell>
	            <TableCell align="right">{props.currency} {currencyFormat(values.discount)}</TableCell>
	        	</TableRow>
	        	<TableRow>
	        		<TableCell colSpan={6} align="right">IVA:</TableCell>
	            <TableCell align="right">{props.currency} {currencyFormat(values.iva)}</TableCell>
	        	</TableRow>
	        	<TableRow>
	        		<TableCell colSpan={6} align="right"><strong>Total:</strong></TableCell>
	            <TableCell align="right"><strong>{props.currency} {currencyFormat(values.total)}</strong></TableCell>
	        	</TableRow>
	        </TableBody>
				</Table>
			</TableContainer>
		</Fragment>
	)
}

export default TableComponent;
import { gql } from '@apollo/client';

const GET = gql`
  query InventoryBranchReport($limit: Int $offset: Int $like: String $start: Date $end: Date $branchId: ID){
    inventoryBranchReport(limit: $limit offset: $offset like: $like start: $start end: $end branchId: $branchId) {
      count
      rows {
        id
        name
        Product {
          name
        }
        Ins {
          quantity
          total
        }
        Outs {
          quantity
          total
        }
        Start {
          quantity
          total
        }
        End {
          quantity
          total
        }
      }
    }
  }
`;

const object = {
  GET
};

export default object;

import React, { useEffect, useState } from 'react';

import ListAltIcon from '@material-ui/icons/ListAltOutlined';

import { actions } from '../../apollo/cache';
import ProductionDialog from './production.dialog';

const defaultProductDialog = [false, null];

const ActionComponent = () => {
  const [ isOpen, setIsOpen ] = useState(defaultProductDialog);

  const handleClose = () => setIsOpen(defaultProductDialog);
  const handleSummary = (e,{ id }) => setIsOpen([true,id]);

  useEffect(() => {
    actions([
      { icon: <ListAltIcon/>, onClick: handleSummary, label: 'Revisión'  },
    ].filter(el => el));
  }, []);

  return (
    <>
      <ProductionDialog isOpen={isOpen[0]} id={isOpen[1]} handleClose={handleClose} />
    </>
  );
};

export default ActionComponent;

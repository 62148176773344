import { gql } from '@apollo/client';

const GET = gql`
  query BranchMovements($start: Date $end: Date $limit: Int $offset: Int $branchId: ID){
    branchMovements(start: $start end: $end limit: $limit offset: $offset branchId: $branchId) {
      count
      rows {
        id
        createdAt
        type
        concept
        charge
        payment
        balance
      }
    }
  }
`;

const object = { GET };

export default object;


import { gql } from '@apollo/client';

const GET = gql`
  query Expenses($limit: Int $offset: Int $like: String $type: [Int] $form: Int $start: Date $end: Date $paid: Boolean){
    expenses(limit: $limit offset: $offset like: $like type: $type form: $form start: $start end: $end paid: $paid) {
      count
      rows {
        id
        type
        subtype
        folio
        serial
        status
        date
        physicalFolio
        description
        total
        form
        paid
        Branch {
          name
        }
        Supplier {
          name
        }
        ExpensePayments {
          id
          folio
          serial
          paymentForm
          subtotal
          taxes
          total
          expenseId
          createdAt
        }
        ExpenseDetails {
          quantity
          unitPrice
          total
          Product {
            name
          }
          ProductUnit {
            name
          }
        }
      }
    }
  }
`;

const GET_BY_ID = gql`
  query Expense($id: ID!){
    expense(id: $id) {
      id
      type
      subtype
      folio
      serial
      status
      date
      createdAt
      physicalFolio
      description
      total
      iva
      form
      paid
      Supplier {
        phone
        name
        accountName
        account
        bank
        clabe
      }
      ExpensePayments {
        id
        folio
        serial
        paymentForm
        subtotal
        taxes
        total
        expenseId
        createdAt
      }
      ExpenseDetails {
        concept
        quantity
        unitPrice
        discount
        taxes
        total
        Product {
          id
          name
        }
        ProductUnit {
          id
          name
        }
      }
    }
  }
`;

const DELETE = gql`
  mutation DeleteExpense($id: ID!) {
    deleteExpense(id: $id) {
      id
    }
  }
`;

const CREATE = gql`
  mutation CreateExpense(
    $branchId: ID
    $type: Int!
    $subtype: Int
    $iva: Int
    $date: Date!
    $physicalFolio: String!
    $description: String
    $supplierId: ID
    $paymentForm: String!
    $form: Int!
    $advance: Float
    $Products: [ExpenseDetailData]
  ) {
    createExpense(input: {
      branchId: $branchId
      type: $type
      subtype: $subtype
      iva: $iva
      date: $date
      physicalFolio: $physicalFolio
      description: $description
      supplierId: $supplierId
      paymentForm: $paymentForm
      form: $form
      advance: $advance
      Products: $Products
    }) {
      id
      type
      subtype
      folio
      serial
      status
      date
      physicalFolio
      description
      total
      form
      paid
      Supplier {
        name
      }
      ExpenseDetails {
        quantity
        unitPrice
        total
        Product {
          name
        }
        ProductUnit {
          name
        }
      }
    }
  }
`;

const CREATE_EXPENSE_PAYMENT = gql`
  mutation CreateExpensePayment($total: Float!, $paymentForm: String!, $expenseId: ID!) {
    createExpensePayment(input: {
      total: $total
      paymentForm: $paymentForm
      expenseId: $expenseId
    }) {
      id
      folio
      serial
      paymentForm
      subtotal
      taxes
      total
      expenseId
      createdAt
    }
  }
`;

const DELETE_EXPENSE_PAYMENT = gql`
  mutation DeleteExpensePayment($id: ID!) {
    deleteExpensePayment(id: $id) {
      id
      folio
      serial
      paymentForm
      subtotal
      taxes
      total
      expenseId
      createdAt
    }
  }
`;

const object = { GET, GET_BY_ID, DELETE, CREATE, CREATE_EXPENSE_PAYMENT, DELETE_EXPENSE_PAYMENT };

export default object;
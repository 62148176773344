import React, { useState } from 'react';

import { useQuery } from '@apollo/client';

import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import FilterDrawer from './../../presentationals/FilterDrawer';
import Autocomplete from './../../presentationals/Autocomplete';

import { filter } from '../../apollo/cache';

import GQL_PRODUCT from '../product/_gql';

const defaultValues = {
  productId: null,
};

const FilterDialog = props => {
	const [values, setValues] = useState(defaultValues);

	const { data } = useQuery(GQL_PRODUCT.GET_AUTOCOMPLETE, { variables: { limit: 0 } });

	const handleClose = () => {
		props.handleAction('close');
	};

	const handleAction = (e, data) => {
		const { startDate: start, endDate: end, branchId } = data;
		const { productId } = values;
		props.handleAction(null, { start, end, productId: productId ? productId.value : null, branchId });
		filter({ start, end, productId: productId ? productId.value : null, branchId });
	};

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }

	return (
		<FilterDrawer
			isOpen={props.isOpen}
			handleClose={handleClose}
			handleAction={handleAction}
			showDates
			showBranches
		>
			<Typography variant="subtitle1">
        Producto:
      </Typography>
      <br />
      <Autocomplete
        name='productId'
        label='Selecciona uno'
        variant='outlined'
        value={values.productId}
        onChange={handleAutocompleteChange}
        options={data ? data.products.rows.map(el => ({ value: el.id, label: `${el.name} ${parseInt(el.companyId) === 2 ? ' - Casa del Elote' : ''}`.trim() })) : []}
        noFormat
      />
      <Divider/>
		</FilterDrawer>
	);
}

export default FilterDialog;
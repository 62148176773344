import React, { useState, useEffect } from 'react';

import { startOfDay, endOfDay } from 'date-fns';

import { useQuery, useReactiveVar } from '@apollo/client';

import CloseIcon from '@material-ui/icons/Close';

import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

import Button from './../Button';
import DateField from './../DateField';
import SelectField from './../SelectField';

import { filterForm } from '../../apollo/cache';

import useStyles from './style';
import GQL from './_gql';

const defaultBranches = [
  { value: -1, label: 'Todos los Establecimientos' },
  { value: -2, label: 'Sucursales Propias' },
  { value: -3, label: 'Franquicias (Temporal)' },
];

const defaultCompanies = [
  { value: -1, label: 'Todas las Empresas' },
  { value: 1, label: 'Narachips' },
  { value: 2, label: 'CasaElotes' },
]

// const defaultBranchesCustomer = [
//   { value: -1, label: 'Sucursales no Propias' }
// ];

const defaultValues = {
  startDate: startOfDay(new Date()),
  endDate: endOfDay(new Date()),
  branchId: -1,
  companyId: -1
}

const FilterDrawer = props => {
  const classes = useStyles();

  const filterFormVar = useReactiveVar(filterForm);
  const [ values, setValues ] = useState(defaultValues);
  const [ branches, setBranches ] = useState(defaultBranches);

  const { data } = useQuery(GQL.GET_BRANCHES,{ variables: { owner: props.owner } });
  
  useEffect(() => {
    if(data && data.branches && data.branches.count > 0){
      const tmpBranches = data.branches.rows.map(el => ({ value: el.id, label: el.name }));
      setBranches([ ...defaultBranches, ...tmpBranches ]);
    }
  }, [data]);

  const handleClick = e => {
    props.handleAction && props.handleAction(e, values, props.params);
    props.handleClose();
  }

  const handleDateChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  // const handleCheckBoxChange = (name, checked, id) => {
  //   const tmp = values[name].map(el => el.params === id ? ({ ...el, checked }) : el);
  //   setValues(values => ({ ...values, [name]: tmp }));
  // }

  return (
    <Drawer anchor='right' open={filterFormVar || props.isOpen} onClose={props.handleClose} classes={{ paper: classes.container }}>
      <div className={classes.title}>
        <Typography variant="h6">
          Filtrar Información
        </Typography>
        <IconButton 
          aria-label="close" 
          onClick={props.handleClose} 
          className={classes.closeButton} 
          size="medium"
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </div>
      <Divider/>
      {
        props.showDates &&
          <div>
            <div className={classes.dateContent}>
              <Typography variant="subtitle1">
                Rango de fechas:
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <DateField 
                    variant='outlined' 
                    name="startDate" 
                    value={values.startDate} 
                    onChange={handleDateChange} 
                    noFormat 
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DateField 
                    variant='outlined'
                    name='endDate'
                    value={values.endDate} 
                    onChange={handleDateChange} 
                    noFormat 
                  />
                </Grid>
              </Grid>
            </div>
            <Divider/>
          </div>
      }
      {
        props.showCompanies &&
          <div>
            <div className={classes.dateContent}>
              <Typography variant="subtitle1">
                Empresa:
              </Typography>
              <SelectField
                name='companyId'
                variant='outlined'
                value={values.companyId}
                onChange={handleChange}
                options={defaultCompanies}
                noFormat
              />
            </div>
            <Divider/>
          </div>
      }
      {
        props.showBranches && branches.length > 1 &&
          <div>
            <div className={classes.dateContent}>
              <Typography variant="subtitle1">
                Sucursales:
              </Typography>
              <SelectField
                name='branchId'
                variant='outlined'
                value={values.branchId}
                onChange={handleChange}
                options={branches}
                noFormat
              />
            </div>
            <Divider/>
          </div>
      }
      <div className={classes.content}>
        {props.children}
      </div>
      <Divider/>
      <div className={classes.footer}>
        <Button 
          label='Aplicar Filtro' 
          size='large' 
          color='secondary'
          variant='outlined' 
          onClick={handleClick} 
          className={classes.actionButton} 
        />
      </div>
    </Drawer>
  )
}

export default FilterDrawer;
import { gql } from '@apollo/client';

const GET = gql`
  query Customers($limit: Int $offset: Int $like: String){
    customers(offset: $offset limit: $limit like: $like){
      count
      rows {
        id
        name
        phone
      }
    }
  }
`;

const GET_BY_ID = gql`
  query Customer($id: ID!){
    customer(id: $id) {
      id
      name
      phone
    }
  }
`;

const GET_AUTOCOMPLETE = gql`
  query CustomerAutocomplete($offset: Int, $limit: Int, $like: String){
    customers(offset: $offset limit: $limit like: $like){
      count
      rows {
        id
        name
        phone
      }
    }
  }
`;

const CREATE = gql`
  mutation CreateCustomer(
    $name: String!
    $phone: String!
  ) {
    createCustomer(input: {
      name: $name
      phone: $phone
    }) {
      id
      name
      phone
    }
  }
`

const UPDATE = gql`
  mutation UpdateCustomer(
    $id: ID!
    $name: String!
    $phone: String!
  ) {
    updateCustomer(id: $id, input: {
      name: $name
      phone: $phone
    }) {
      id
      name
      phone
    }
  }
`

const DELETE = gql`
  mutation DeleteCustomer($id: ID!) {
    deleteCustomer(id: $id) {
      id
    }
  }
`

const obj = { GET, GET_BY_ID, CREATE, UPDATE, DELETE, GET_AUTOCOMPLETE }

export default obj;
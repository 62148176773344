import React, { useState, useEffect, Fragment } from 'react';

import { useQuery } from '@apollo/client';

import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import SecurityIcon from '@material-ui/icons/SecurityOutlined';
import BusinessIcon from '@material-ui/icons/BusinessOutlined';
import KeyboardIcon from '@material-ui/icons/KeyboardOutlined';
import ListAltIcon from '@material-ui/icons/ListAltOutlined';

import Module from './../../presentationals/Module';

import GQL from './_gql';
import GQL_BRANCH from './../branch/_gql';

import FilterDialog from './filter.dialog';
import Dialog from './password.dialog';

const Rows = [
	{ label: 'Nombre', key: 'name', sort: 'text', filter: true },
	{ label: 'Usuario', key: 'username', sort: 'text', filter: true },
	{ label: 'Permisos', key: 'role', sort: 'text', format: 'privileges' },
	{ label: 'Sucursal', key: 'Branch name', sort: 'text' }
];

const privilegesOptions = [
	{ value: 1, label: 'Administrador' },
	{ value: 2, label: 'Operativo' }
];

const Component = props => {

	const [ branchOptions, setBranchOptions ] = useState([]);
	const [ isOpen, setIsOpen ] = useState(false);
	const [ filter, setFilter ] = useState({});
	const [ openDialog, setOpenDialog ] = useState([false,0]);

	const { data, loading, refetch } = useQuery(GQL.GET);
	const { data: branches } = useQuery(GQL_BRANCH.GET);

	useEffect(() => {
		if(branches && branches.branches){
			const tmpBranches = branches.branches.rows.map(el => ({ value: el.id, label: el.name }));
			setBranchOptions(tmpBranches);
		}
	}, [branches]);

	useEffect(() => {
		if(Object.keys(filter).length > 0){
			refetch(filter);
		}
	}, [filter, refetch]);

	const handleRows = (offset, limit) => {
		setFilter(filter => ({ ...filter, limit, offset }));
	}

	const handleFilter = () => {
		setIsOpen(true);
	}

	const handleAction = (action, data) => {
		switch(action){
			case 'close':
				setIsOpen(false);
				break;
			default:
				setFilter(filter => ({ ...filter, ...data }));
				break;
		}
	}

	const handleSearch = (value) => {
		setFilter(filter => ({ ...filter, like: value }));
	}

	const handleDialog = (e,{ id }) => setOpenDialog([true,id]);
	const closeDialog = () => setOpenDialog([false,0]);
	
	return (
		<Fragment>
			<Module
				moduleCode='CAEM01'
				isLoading={loading}
				name='Empleados'
				singular='Empleado'
				operation={['user', 'users']}
				Rows={Rows}
				get={GQL.GET}
				handleRows={handleRows}
				getById={GQL.GET_BY_ID}
				create={GQL.CREATE}
				update={GQL.UPDATE}
				delete={GQL.DELETE}
				records={data && data.users}
				filter={handleFilter}
				handleSearch={handleSearch}
				actions={[
					{ icon: <ListAltIcon/>, onClick: handleDialog, label: 'Cambiar Contraseña'  },
				]}
				fields={[
					{ 
						icon: <AccountCircleIcon />,
						name: 'name', 
						label: 'Nombre',
						autoFocus: true
					},
					{ 
						icon: <SecurityIcon />,
						name: 'username', 
						label: 'Usuario'
					},
					{ 
						name: 'password', 
						label: 'Contraseña',
						type: 'password',
						noShowOnUpdate: true
					},
					{ 
						field: 'select',
						name: 'role', 
						label: 'Permisos',
						defaultValue: 1,
						options: privilegesOptions
					},
					{ 
						icon: <KeyboardIcon />,
						name: 'pin', 
						label: 'Pin de Comandero',
						type: 'password'
					},
					{ 
						icon: <BusinessIcon />,
						field: 'select',
						name: 'branchId', 
						label: 'Sucursal Predeterminada',
						options: branchOptions
					},
				]}
			/>
			<FilterDialog isOpen={isOpen} handleAction={handleAction} />
			<Dialog
        isOpen={openDialog[0]}
        userId={openDialog[1]}
        handleClose={closeDialog}
      />
		</Fragment>
	);
}

export default Component;
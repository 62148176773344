import { gql } from '@apollo/client';

const GET = gql`
  query PurchaseOrderDetailReport($limit: Int, $offset: Int, $status: [Int], $start: Date, $end: Date, $branchId: [ID], $classificationId: ID, $productId: ID){
    purchaseOrderDetailReport(limit: $limit offset: $offset status: $status start: $start end: $end branchId: $branchId classificationId: $classificationId productId: $productId) {
      count
      rows {
        id name
        Clasification { name }
        PurchaseOrderDetails {
          Branch { id name }
          quantity
          cost
        }
      }
      totals {
        quantity cost
      }
    }
  }
`;

const obj = { GET };
export default obj;
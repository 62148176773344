import React, { useState, useEffect, Fragment } from 'react';

import { useQuery } from '@apollo/client';

import TodayIcon from '@material-ui/icons/TodayOutlined';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import Module from './../../presentationals/Module';

import GQL from './_gql';
import GQL_BRANCH from './../branch/_gql';

import FilterDialog from './filter.dialog';
// import PDF from './pdf';

const Rows = [
	{ label: 'Fecha', key: 'date', format: 'date' },
	{ label: 'Folio', key: 'folio', sort: 'text' },
	{ label: 'Sucursal', key: 'Branch name', sort: 'text' },
	{ label: 'Importe', key: 'total', format: 'currency', align: 'right' },
];

const Component = props => {

	const [ branchOptions, setBranchOptions ] = useState([]);
	const [ isOpen, setIsOpen ] = useState(false);
	const [ filter, setFilter ] = useState({});
	const [ movements, setMovements ] = useState({ count: 0, rows: [] });

	const { data, loading, refetch } = useQuery(GQL.GET);
	const { data: branches } = useQuery(GQL_BRANCH.GET);

	useEffect(() => {
		if(data) {
			const rows = data.financialMovements.rows.map(el => ({ ...el, folio: `${el.serial}-${el.folio}` }));
			const count = data.financialMovements.count;
			setMovements({ count, rows });
		}
	},[data]);

	useEffect(() => {
		if(branches && branches.branches){
			const tmpBranches = branches.branches.rows.map(el => ({ value: el.id, label: el.name }));
			setBranchOptions(tmpBranches);
		}
	},[branches]);

	useEffect(() => {
		if(Object.keys(filter).length > 0){
			refetch(filter);
		}
	}, [filter, refetch]);

	const handleRows = (offset, limit) => {
		setFilter(filter => ({ ...filter, limit, offset }));
	}

	const handleFilter = () => {
		setIsOpen(true);
	}

	const handleAction = (action, data) => {
		switch(action){
			case 'close':
				setIsOpen(false);
				break;
			default:
				setFilter(filter => ({ ...filter, ...data }));
				break;
		}
	}

	const handleSearch = (value) => {
		setFilter(filter => ({ ...filter, like: value }));
	}

	// const handlePDF = (e,{ id }) => PDF(id);
	
	return (
		<Fragment>
			<Module
				moduleCode='ADAP01'
				isLoading={loading}
				name='Aportaciones de Socios'
				singular='Aportación de Socios'
				operation={['financialMovement', 'financialMovements']}
				Rows={Rows}
				get={GQL.GET}
				handleRows={handleRows}
				create={GQL.CREATE}
				noEdit
				delete={GQL.DELETE}
				records={movements}
				filter={handleFilter}
				handleSearch={handleSearch}
				fields={[
					{ 
						icon: <TodayIcon />,
						field: 'date',
						name: 'date', 
						defaultValue: new Date(),
						label: 'Fecha'
					},
					{ 
						name: 'associated', 
						label: 'Nombre del Socio',
						multiline: true
					},
					{
						name: 'branchId',
						label: 'Sucursal',
						field: 'autocomplete',
						options: branchOptions,
					},
					{ 
						icon: <AttachMoneyIcon />,
						name: 'total', 
						label: 'Importe',
						type: 'number'
					},
					{ 
						name: 'type', 
						label: 'Tipo',
						defaultValue: 1,
						show: true,
					},
					{ 
						name: 'description', 
						label: 'Descripción',
						multiline: true
					},
				]}
			/>
			<FilterDialog isOpen={isOpen} handleAction={handleAction} />
		</Fragment>
	);
}

export default Component;
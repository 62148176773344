import React, { Fragment, useState } from 'react';
import { useMutation } from '@apollo/client';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import GQL from './../../_gql';

import IconButton from './helpers/iconButton';

import { currencyFormat } from './../../../../helpers/general';
import UpdateDialog from './update.dialog';

const TableComponent = props => {

	const [ open, setOpen ] = useState([false, null, {}]);
	
	const [ deleteProductUnit ] = useMutation(GQL.DELETE_PRODUCT_UNIT, {
		update(cache, { data }) {
      try{
      	cache.evict({ id: 'ProductUnit:' + data.deleteProductUnit.id });
      	cache.gc();
      }catch(e){
        console.log(e.message);
      }
    }
	});

	const handleDeleteProductUnit = async (e, id) => {
		try{
  		await deleteProductUnit({ variables: { id } });
  	}catch(e) {
  		console.log(e);
  	}
	}

	const handleUpdateProductUnit = (e, id, extraData) => {
		setOpen([true, id, extraData]);
	}

	const handleClose = () => setOpen([false, null, {}]);
	
	return (
		<Fragment>
			<TableContainer>
				<Table size="small">
					<TableHead>
	          <TableRow>
	            <TableCell>Acciones</TableCell>
	            <TableCell>Nombre</TableCell>
	            <TableCell align="center">Unidades</TableCell>
	            <TableCell align="center">Armado</TableCell>
	            <TableCell align="center">Costo</TableCell>
	            <TableCell align="right">Costo Base</TableCell>
	          </TableRow>
	        </TableHead>
	        <TableBody>
	        	{
	        		props.cartItems.map((el, i) => 
	      				<TableRow key={i}>
	      					<TableCell>
	      						<IconButton 
	      							label='Eliminar'
	      							icon={<DeleteOutlineIcon />}
	      							params={el.id}
	      							action={handleDeleteProductUnit}
	      							size='small'
	      							disabled={el.UnitCompound ? false : true}
	      						/>
	      						<IconButton 
	      							label='Actualizar'
	      							icon={<EditIcon />}
	      							params={el.id}
	      							action={handleUpdateProductUnit}
	      							size='small'
	      							extraData={el}
	      						/>
	      					</TableCell>
			            <TableCell>{el.name}</TableCell>
			            <TableCell align="center">{`${el.UnitCompound ? `${el.unit} ${el.UnitCompound.name}` : 'Unidad Base'}`}</TableCell>
			            <TableCell align="center">{parseInt(el.type) === 1 ? 'NO' : 'SI'}</TableCell>
			            <TableCell align="center">{currencyFormat(el.cost)}</TableCell>
			            <TableCell align="right">{currencyFormat(el.baseCost)}</TableCell>
	      				</TableRow>
	      			)
	        	}
	        </TableBody>
				</Table>
			</TableContainer>
			<UpdateDialog isOpen={open[0]} handleClose={handleClose} id={open[1]} extraData={open[2]}/>
		</Fragment>
	)
}

export default TableComponent;
import React, { useState } from 'react';

import { useQuery } from '@apollo/client';

import Typography from '@material-ui/core/Typography';

import FilterDrawer from '../../presentationals/FilterDrawer.v2';
import SelectField from '../../presentationals/SelectField';
import Autocomplete from '../../presentationals/Autocomplete';

import { filter } from '../../apollo/cache';
import GQL_SUPPLIER from '../supplier/_gql';

const statusOptions = [
  { value: -1, label: 'Todos' },
  { value: 1, label: 'Pendientes' },
  { value: 2, label: 'Autorizados' },
  { value: 0, label: 'Declinados' }
];

const paymentFormOptions = [
  { value: -1, label: 'Todos' },
  { value: "00", label: "Caja Chica" },
  { value: "01", label: "Efectivo" },
  { value: "100", label: "Depósito" },
  { value: "02", label: "Cheque nominativo" },
  { value: "03", label: "Transferencia electrónica de fondos" },
  { value: "04", label: "Tarjeta de crédito" },
  { value: "05", label: "Monedero electrónico" },
  { value: "06", label: "Dinero electrónico" },
  { value: "15", label: "Condonación" },
  { value: "17", label: "Compensación" },
  { value: "23", label: "Novación" },
  { value: "28", label: "Tarjeta de débito" },
  { value: "30", label: "Aplicación de anticipos" },
  { value: "31", label: "Intermediario pagos" },
  { value: "99", label: "Por definir" }
];

const FilterDialog = () => {
  const [values, setValues] = useState({ status: -1, paymentForm: -1, supplierId: null, });

  const { data } = useQuery(GQL_SUPPLIER.GET, { variables: { limit: 0 } });

  const handleAction = (e, data) => {
    const { startDate: start, endDate: end } = data;
    filter({
      paymentForm: parseInt(values.paymentForm) !== -1 ? [values.paymentForm] : [],
      status: parseInt(values.status) !== -1 ? [values.status] : [],
      supplierId: values.supplierId ? values.supplierId.value : null,
      start,
      end,
    });
  };

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <FilterDrawer
      handleAction={handleAction}
      showDates
    >
      <Typography variant="subtitle1">
        Estatus:
      </Typography>
      <SelectField
        name='status'
        variant='outlined'
        value={values.status}
        onChange={handleChange}
        options={statusOptions}
        noFormat
      />
      <Typography variant="subtitle1">
        Forma de Pago:
      </Typography>
      <SelectField
        name='paymentForm'
        variant='outlined'
        value={values.paymentForm}
        onChange={handleChange}
        options={paymentFormOptions}
        noFormat
      />
      <Typography variant="subtitle1">
        Proveedor:
      </Typography>
      <Autocomplete
        size='medium'
        name='supplierId'
        variant='outlined'
        value={values.supplierId}
        onChange={handleAutocompleteChange}
        options={data ? data.suppliers.rows.map(el => ({ value: el.id, label: el.name.trim() })) : []}
        noFormat
      />
    </FilterDrawer>
  );
};

export default FilterDialog;

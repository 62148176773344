import React from 'react';

import AddCircleIcon from '@material-ui/icons/AddOutlined';

import { actionForm } from '../../../../apollo/cache';

import IconButton from '../../../IconButton';

import useStyles from './style';

const CreateButtonComponent = props => {
	const classes = useStyles();
	const handleCreate = () => {
		if (props.handleCustomCreate) {
			props.handleCustomCreate();
			return;
		}
		const variables = { isOpen: true, action: 'create' };
    actionForm({ ...actionForm(), ...variables });
	};
	return (
		<IconButton
      color='secondary'
      label='Crear'
      action={handleCreate}
      className={classes.paddingX}
      icon={<AddCircleIcon className={classes.icon} />}
    />
	);
};

export default CreateButtonComponent;

import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import FormDialog from './../../presentationals/FormDialog';
import { currencyFormat } from './../../helpers/general';

import GQL from './_gql';

const Component = props => {
	const [ getData, { data: expenseData } ] = useLazyQuery(GQL.GET_BY_ID,{
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if(props.id){
			getData({ variables: { id: props.id } });
		}
	}, [props.id, getData]);

	const handleClose = () => props.handleClose();

	return (
		<FormDialog 
			isOpen={props.isOpen}
      title="Conceptos"
      name="concepts"
      singular="Concept"
      disableEscape
      handleClose={handleClose}
      hideAction
		>	
			<TableContainer>
				<Table size="small">
					<TableHead>
						{
							(expenseData && expenseData.expense) && 
		          <TableRow>
		            <TableCell>Producto</TableCell>
		            {parseInt(expenseData.expense.type) === 1 && <TableCell>Unidad</TableCell>}
		            <TableCell align="center">Cant.</TableCell>
		            <TableCell align="center">Descto.</TableCell>
		            <TableCell align="right">P.Unitario</TableCell>
		            <TableCell align="right">Importe</TableCell>
		          </TableRow>
						}
	        </TableHead>
	        <TableBody>
	        	{
	        		(expenseData && expenseData.expense) && expenseData.expense.ExpenseDetails.map((el, i) => 
	      				<TableRow key={i}>
			            <TableCell>{parseInt(expenseData.expense.type) === 1 ? el.Product.name : el.concept}</TableCell>
			            {parseInt(expenseData.expense.type) === 1 && <TableCell>{el.ProductUnit.name}</TableCell>}
			            <TableCell align="center">{el.quantity}</TableCell>
			            <TableCell align="center">{currencyFormat(el.discount)}</TableCell>
			            <TableCell align="right">{currencyFormat(el.unitPrice)}</TableCell>
			            <TableCell align="right">{currencyFormat(el.total)}</TableCell>
	      				</TableRow>
	      			)
	        	}
	        </TableBody>
				</Table>
			</TableContainer>
		</FormDialog>
	);
}

export default Component;
import { gql } from '@apollo/client';

const GET = gql`
  query BranchOrders($start: Date $end: Date $limit: Int $offset: Int $like: String $status: [Int]){
    branchOrders(start: $start end: $end limit: $limit offset: $offset like: $like status: $status) {
      count
      rows {
        id
        status
        folio
        serial
        description
        total
        branchId
        userId
        createdAt
        iva
        Branch{
          name
        }
        User{
          name
        }
      }
    }
  }
`;

const GET_BY_ID = gql`
  query BranchOrder($id: ID!){
    branchOrder(id: $id) {
      id
      status
      folio
      serial
      description
      total
      branchId
      userId
      createdAt
      iva
      Branch{
        name
      }
      User{
        name
      }
      BranchOrderDetails{
        id
        status
        concept
        quantity
        baseUnitQuantity
        unitPrice
        withoutTaxes
        subtotal
        discount
        taxes
        total
        productId
        productUnitId
        ProductUnit {
          name
        }
        Product {
          name
          Clasification {
            name
          }
          BranchInventoryBases {
            quantity
            maximum
            minimum
          }
        }
        BaseUnit {
          name
        }
      }
    }
  }
`;

const CREATE = gql`
  mutation CreateBranchOrder(
    $branchId: ID!
    $description: String
  ) {
    createBranchOrder(input: {
      branchId: $branchId
      description: $description
    }) {
      id
      status
      folio
      serial
      description
      total
      branchId
      userId
      createdAt
      iva
      Branch{
        name
      }
      User{
        name
      }
    }
  }
`;

const DELETE = gql`
  mutation DeleteBranchOrder(
    $id: ID!
  ){
    deleteBranchOrder(
      id:$id
    ){
      id
      status
      folio
      serial
      description
      total
      branchId
      userId
      createdAt
      iva
      Branch{
        name
      }
      User{
        name
      }
    }
  }
`;

const ADD_PRODUCT_ORDER = gql`
  mutation AddProductOrder(
    $id: ID!
    $quantity: Float!
    $unitPrice: Float!
    $discount: Float!
    $productId: ID!
    $productUnitId: ID!
  ){
    addProductOrder(
      id:$id
      input:{
        quantity: $quantity
        unitPrice: $unitPrice
        discount: $discount
        productId: $productId
        productUnitId: $productUnitId
      }
    ){
      id
      status
      concept
      quantity
      unitPrice
      withoutTaxes
      subtotal
      discount
      taxes
      total
      productId
      productUnitId
      Product {
        name
      }
      ProductUnit {
        name
      }
    }
  }
`;

const UPDATE_STATUS = gql`
  mutation UpdateOrderStatus(
    $id: ID!
    $status: Int!
  ){
    updateOrderStatus(
      id: $id
      status: $status
    ){
      id
      status
      folio
      serial
      description
      total
      branchId
      userId
      createdAt
      Branch{
        name
      }
      User{
        name
      }
    }
  }
`;

const GENERATE_SALE_NOTE = gql`
  mutation GenerateSaleNote($id: ID!){
    generateSaleNote(id: $id){
      id
    }
  }
`;

const DELETE_PRODUCT_ORDER = gql`
  mutation DeleteProductOrder(
    $branchOrderDetailId: ID!
  ){
    deleteProductOrder(
      branchOrderDetailId: $branchOrderDetailId
    ){
      id
      status
      concept
      quantity
      unitPrice
      withoutTaxes
      subtotal
      discount
      taxes
      total
      productId
      Product {
        name
      }
      ProductUnit {
        name
      }
    }
  }
`;

const MIGRATE_BRANCH_ORDER = gql`
  mutation MigrateBranchOrder(
    $id: ID!
  ){
    migrateBranchOrder(
      id: $id
    ){
      id
    }
  }
`;

const CONFIRM_TRANSFER = gql`
  mutation ConfirmTransferBranchOrder(
    $id: ID! $status: Int!
  ){
    confirmTransferBranchOrder(
      id: $id status: $status
    ){
      id
    }
  }
`;

const UPDATE_IVA = gql`
  mutation UpdateIva($id: ID!, $iva: Float!){
    updateIva(id: $id, iva: $iva) {
      id
      status
      folio
      serial
      description
      total
      branchId
      userId
      createdAt
      iva
      Branch{
        name
      }
      User{
        name
      }
      BranchOrderDetails{
        id
        status
        concept
        quantity
        unitPrice
        withoutTaxes
        subtotal
        discount
        taxes
        total
        productId
        productUnitId
        Product{
          name
        }
        ProductUnit {
          name
        }
      }
    }
  }
`;

const object = {
  GET, GET_BY_ID, CREATE, DELETE, ADD_PRODUCT_ORDER, UPDATE_STATUS,
  DELETE_PRODUCT_ORDER, GENERATE_SALE_NOTE, MIGRATE_BRANCH_ORDER, UPDATE_IVA,
  CONFIRM_TRANSFER
};

export default object;

import { gql } from '@apollo/client';

const GET_CUSTOMER = gql`
  query CustomerReceivable($start: Date, $end: Date, $offset: Int, $limit: Int, $like: String, $customerId: ID, $paid: Boolean) {
    customerReceivable(start: $start, end: $end, offset: $offset, limit: $limit, like: $like, customerId: $customerId, paid: $paid){
      count
      rows {
        id
        Customer {
          name
        }
        charge
        payment
        balance
      }
    }
  }
`;

const GET = gql`
  query SaleNotes($limit: Int $offset: Int $paid: Boolean $customerId: ID){
    saleNotes(limit: $limit offset: $offset paid: $paid customerId: $customerId) {
      count
      rows {
        id
        folio
        serial
        status
        type
        charge
        payment
        balance
        createdAt
        Customer {
          name
        }
        Branch {
          name
        }
      }
    }
  }
`;

const GET_BY_ID = gql`
  query SaleNote($id: ID!){
    saleNote(id: $id) {
      SaleNotePayments {
        id
        createdAt
        folio
        serial
        paymentForm
        total
      }
    }
  }
`;

const DELETE_SALE_NOTE_PAYMENT = gql`
  mutation DeleteSaleNotePayment($id: ID!) {
    deleteSaleNotePayment(id: $id) {
      id
      total
    }
  }
`;

const CREATE_SALE_NOTE_PAYMENT = gql`
  mutation CreateSaleNotePayment(
    $total: Float!
    $paymentForm: String!
    $saleNoteId: ID!
  ) {
    createSaleNotePayment(input: {
      total: $total
      paymentForm: $paymentForm
      saleNoteId: $saleNoteId
    }) {
      id
      folio
      serial
      total
    }
  }
`

const object = { GET, GET_BY_ID, DELETE_SALE_NOTE_PAYMENT, CREATE_SALE_NOTE_PAYMENT, GET_CUSTOMER };

export default object;


import React, { useState, useEffect, Fragment } from 'react';

import { useQuery } from '@apollo/client';
import ListAltIcon from '@material-ui/icons/ListAltOutlined';

import Module from './../../presentationals/Module';

import GQL from './_gql';

import FilterDialog from './filter.dialog';
import ProductDialog from './products.dialog';

const Rows = [
	{ label: 'Fecha', key: 'clientCreatedAt', format: 'dateTime' },
	{ label: 'Folio', key: 'folio', sort: 'text' },
	{ label: 'Descripción', key: 'description', sort: 'text' },
	{ label: 'Subtotal', key: 'subtotal', sort: 'text', format: 'currency' },
	{ label: 'Descuento', key: 'discount', sort: 'text', format: 'currency' },
	{ label: 'Importe', key: 'total', sort: 'text', format: 'currency' },
	{ label: 'Estatus', key: 'status', sort: 'text', format: 'status' },
	{ label: 'Usuario', key: 'User name', sort: 'text' },
	{ label: 'Sucursal', key: 'Branch name', sort: 'text' },
	{ label: 'Empresa', key: 'Branch companyId', sort: 'text', format: 'company' }
];

const Component = props => {

	const [ isOpen, setIsOpen ] = useState(false);
	const [ isOpenProduct, setIsOpenProduct ] = useState([0, false]);
	const [ filter, setFilter ] = useState({});

	const { data, loading, refetch } = useQuery(GQL.GET);

	useEffect(() => {
		if(Object.keys(filter).length > 0){
			refetch(filter);
		}
	}, [filter, refetch]);

	const handleRows = (offset, limit) => {
		setFilter(filter => ({ ...filter, limit, offset }));
	}

	const handleFilter = () => {
		setIsOpen(true);
	}

	const handleAction = (action, data) => {
		switch(action){
			case 'close':
				setIsOpen(false);
				break;
			default:
				setFilter(filter => ({ ...filter, ...data }));
				break;
		}
	}

	const handleSearch = (value) => {
		setFilter(filter => ({ ...filter, like: value }));
	}

	const handleCloseProduct = () => setIsOpenProduct([0,false]);
	const handleProducts = (e, { id }) => {
		setIsOpenProduct([id, true]);
	};
	
	return (
		<Fragment>
			<Module
				moduleCode='REVE04'
				isLoading={loading}
				name='Ventas por Folio'
				singular='Venta por folio'
				operation={['sale','sales']}
				Rows={Rows}
				get={GQL.GET}
				noCreate
				noEdit
				noDelete
				handleRows={handleRows}
				records={(data && data.sales) ? { count: data.sales.count, rows: data.sales.rows.map(el => ({ ...el, subtotal: parseFloat(el.total), total: parseFloat(el.total) - parseFloat(el.discount || 0) })) } : null}
				filter={handleFilter}
				handleSearch={handleSearch}
				actions={[
					{ icon: <ListAltIcon/>, onClick: handleProducts, label: 'Productos'  }
				]}
			/>
			<FilterDialog isOpen={isOpen} handleAction={handleAction} />
			<ProductDialog isOpen={isOpenProduct[1]} handleClose={handleCloseProduct} id={isOpenProduct[0]} />
		</Fragment>
	);
}

export default Component;
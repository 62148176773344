import React from 'react';

import Container from '../../presentationals/Module.v2/Container';

import Filter from './_filter';
import GQL from './_gql';

const Rows = [
	{ label: 'Fecha', key: 'createdAt', sort: 'text', format: 'dateTime' },
	{ label: 'Folio', key: 'PurchaseOrder folio && PurchaseOrder serial', strcat: '-' },
	{ label: 'Proveedor', key: 'PurchaseOrder Supplier name', strcat: '-' },
	{ label: 'Producto', key: 'Product name', strcat: '-', sort: 'text' },
	{ label: 'Clasificación', key: 'Product Clasification name', strcat: '-', sort: 'text' },
	{ label: 'Cant.', key: 'quantity', strcat: '-', sort: 'text', align: 'center' },
	{ label: 'P.Unitario', key: 'unitPrice', strcat: '-', align: 'center', sort: 'text', format: 'currency' },
	{ label: 'Subtotal', key: 'subtotal', strcat: '-', format: 'currency' },
	{ label: 'Descuento', key: 'discount', strcat: '-', format: 'currency' },
	{ label: 'Importe', key: 'total', sort: 'text', format: 'currency', align: 'right' },
];

const title = 'Detalle Compras';
const operation = ['purchaseOrderProducts', 'purchaseOrderProducts'];

const Component = () => {
	return (
		<>
			<Container
				title={title}
				operation={operation}
				Rows={Rows}
				get={GQL.GET}
				filter
				noDelete
			/>
			<Filter />
		</>
	);
};

export default Component;

import { InMemoryCache, makeVar } from '@apollo/client';

export const requisitionProducts = makeVar([]);

export const snackbar = makeVar({
  isOpen: false,
  time: null,
  label: '',
  severity: 'success'
});

export const actionForm = makeVar({
  isOpen: false,
  action: '',
  defaultData: {}
});

export const deleteForm = makeVar({
	isOpen: false,
  params: {}
});


export const form = makeVar({});

export const filter = makeVar({});

export const sort = makeVar({});
export const count = makeVar(0);
export const totals = makeVar(null);
export const actions = makeVar([]);
export const subTitle = makeVar('');
export const exportCustomXls = makeVar(false);
export const exportPdf = makeVar({ export: false });
export const exportXls = makeVar({ export: false });
export const filterForm = makeVar(false);

export const cache = new InMemoryCache({
	typePolicies: {
		Product: {
			fields: {
				ProductUnits: {
					merge: false
				},
				Measures: {
					merge: false
				},
				Types: { merge: false },
				Toppings: { merge: false }
			}
		},
		Query: {
			fields: {
				cediPhysicalInventories: {
					merge: false
				},
				purchaseOrders: {
					merge: false
				},
				snackbar: {
					read() {
						return snackbar();
					}
				},
				actionForm: {
					read() {
						return actionForm();
					}
				},
				deleteForm: {
					read() {
						return deleteForm();
					}
				},
				form: {
					read() {
						return form();
					}
				},
				filter: {
					read() {
						return filter();
					},
				},
				exportXls: {
					read() {
						return exportXls();
					},
				},
				product:{
					merge: true
				},
				totals: {
					read() {
						return totals();
					},
				},
				sort: {
					read() {
						return sort();
					},
				},
				count: {
					read() {
						return count();
					},
				},
				actions: {
					read() {
						return actions();
					},
				},
				subTitle: {
					read() {
						return subTitle();
					},
				},
				exportCustomXls: {
					read() {
						return exportCustomXls();
					},
				},
				exportPdf: {
					read() {
						return exportPdf();
					},
				},
				filterForm: {
					read() {
						return filterForm();
					},
				},
			}
		}
	}
});


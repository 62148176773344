import { gql } from '@apollo/client';

const GET = gql`
	query WasteReport($start: Date, $end: Date, $branchId: ID) {
		wasteReport(start: $start, end: $end, branchId: $branchId) {
			count
			rows {
				Product {
					id
					name
				}
				Wastes {
					Branch {
						name
					}
					qty
					cost
				}
			}
		}
	}
`;

export default { GET };

import React, { Fragment, useState, useEffect } from 'react';

import Form from './form';
import Table from './table';

const Cart = props => {
	const [cartItems, setCartItems] = useState([]);

	useEffect(() => {
		if (props.branchId && props.products.length === 0) {
			setCartItems([]);
		}
	}, [props.branchId, props.products]);

	useEffect(() => {
		if (props.products.length > 0) {
			setCartItems(props.products);
		}
	}, [props.products]);

	const addItemToCart = data => {
		const total = parseFloat(data.quantity) * parseFloat(data.unitPrice);
		const tmpDiscount = parseFloat(total) * (parseFloat(data.discount) / 100);
		setCartItems(cartItems => ([ ...cartItems, { ...data, total, tmpDiscount } ]));
		props.getCart([ ...cartItems, { ...data, total, tmpDiscount } ]);
	};

	const removeItemFromCart = position => {
		const tmpCartItems = cartItems
			.map((el, i) => (parseInt(i) !== parseInt(position)) && el)
			.filter(el => el);
		setCartItems(tmpCartItems);
		props.getCart(tmpCartItems);
	}

	return (
		<Fragment>
			<Form handleAdd={addItemToCart} codeToClear={props.codeToClear} branchId={props.branchId} />
			<Table
				iva={props.iva}
				cartItems={cartItems}
				removeItem={removeItemFromCart}
				codeToClear={props.codeToClear}
			/>
		</Fragment>
	);
}

export default Cart
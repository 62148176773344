import React from 'react';

import Container from '../../presentationals/Module.v2/Container';

import GQL from './_gql';
import Filter from './_filter';

const title = 'Reporte de Movimientos';
const operation = ['productMovementLogReport', 'productMovementLogReport'];
const Rows = [
  { label: 'Producto', key: 'name', filter: true },
  { label: 'Cant. Inicial', key: 'start', align: 'right', filter: true },
  { label: 'Entrada', key: 'input', align: 'right', filter: true },
  { label: 'Salida', key: 'output', align: 'right', filter: true },
  { label: 'Cant. Final', key: 'finish', align: 'right', filter: true },
  { label: 'Importe', key: 'costTotal', align: 'right', filter: true },
];

const ProductMovementComponent = () => (
  <>
    <Container
      title={title}
      operation={operation}
      Rows={Rows}
      get={GQL.GET}
      noCreate
      noDelete
      noEdit
      filter
      noPagination
      noSearch
    />
    <Filter />
  </>
);

export default ProductMovementComponent;

import { gql } from '@apollo/client';

const GET = gql`
  query FinancialMovements($start: Date $end: Date $limit: Int $offset: Int $like: String){
    financialMovements(start: $start end: $end limit: $limit offset: $offset like: $like) {
      count
      rows {
        id
        folio
        serial
        date
        associated
        description
        total
        Branch {
          name
        }
      }
    }
  }
`;

const GET_BY_ID = gql`
  query FinancialMovement($id: ID!){
    financialMovement(id: $id) {
      id
      folio
      serial
      date
      associated
      description
      total
      Branch {
        name
      }
    }
  }
`;

const DELETE = gql`
  mutation DeleteFinancialMovement($id: ID!) {
    deleteFinancialMovement(id: $id) {
      id
    }
  }
`;

const CREATE = gql`
  mutation CreateFinancialMovement(
    $type: Int!
    $date: Date!
    $associated: String
    $description: String
    $total: Float!
    $branchId: ID!
  ) {
    createFinancialMovement(input: {
      type: $type
      date: $date
      associated: $associated
      description: $description
      total: $total
      branchId: $branchId
    }) {
      id
      folio
      serial
      date
      associated
      description
      total
      Branch {
        name
      }
    }
  }
`

const obj = { GET, GET_BY_ID, DELETE, CREATE };
export default obj;
import React, { useState, useEffect } from 'react';

import { useMutation } from '@apollo/client';

import SaveIcon from '@material-ui/icons/SaveOutlined';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import LinearForm from './../../../presentationals/LinearForm';
import TextField from './../../../presentationals/TextField';

import { snackbar } from './../../../apollo/cache';

import GQL from './_gql';

const defaultValues = {
	minimum: '',
	maximum: '',
};

const Element = props => {

	const [ values, setValues ] = useState(defaultValues);

	const [ updateMinMax, { loading: loadingUpdate } ] = useMutation(GQL.UPDATE, {
		update(cache, { data: response }) {
      try{
        const oldQuery = cache.readQuery({ query: GQL.GET, variables: { productId: props.productId } });
      	
        cache.writeQuery({
          query: GQL.GET,
          variables: { productId: props.productId },
          data: { 
            branchInventoryBases: [
              ...oldQuery.branchInventoryBases,
              { ...response.updateBranchInventoryBaseTotals }
            ]
          }
        });
      }catch(e){
        console.log(e.message);
      }
    },
	});

	useEffect(() => {
		const tmpMaximum = props.maximum ? parseFloat(props.maximum) : '';
		const tmpMinimum = props.minimum ? parseFloat(props.minimum) : '';
		setValues(value => ({ ...value, maximum: tmpMaximum }));
		setValues(value => ({ ...value, minimum: tmpMinimum }));
	}, [props.maximum, props.minimum]);
	
	const handleAction = async e => {
		try {
			const minimum = parseFloat(values.minimum);
			const maximum = parseFloat(values.maximum);

			if(isNaN(minimum) || minimum < 0){
				throw new Error('El valor mínimo debe ser numérico, mayor o igual a 0.');
			}

			if(isNaN(maximum) || maximum < 0){
				throw new Error('El valor máximo debe ser numérico, mayor o igual a 0.');
			}

			if(minimum > maximum){
				throw new Error('El valor mínimo no puede ser mayor que el máximo.');
			}

			await updateMinMax({ variables: { minimum, maximum, branchId: props.branch.id, productId: props.productId } });

			snackbar({ isOpen: true, time: 3000, label: 'El valor fue almacenado con éxito', severity: 'success' });

		}catch (e){
			console.log(e.message)
			snackbar({ isOpen: true, time: 3000, label: e.message, severity: 'error' });
		}
	}

	const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

	return (
		<LinearForm handleAdd={handleAction} loading={(loadingUpdate) && true} icon={<SaveIcon />}>
			<Grid container alignItems='center' spacing={1}>
				<Grid item xs={4}>
					<Typography variant="subtitle1">
		        {props.branch.label}
		      </Typography>
				</Grid>
				<Grid item xs={4}>
					<TextField 
						label='Mínimo'
						value={values.minimum}
						name='minimum'
						onChange={handleChange}
						variant='outlined'
						type='number'
						noFormat
					/>
				</Grid>
				<Grid item xs={4}>
					<TextField 
						label='Máximo'
						value={values.maximum}
						name='maximum'
						onChange={handleChange}
						variant='outlined' 
						type='number'
						noFormat
					/>
				</Grid>
			</Grid>
		</LinearForm>
	)
}

export default Element
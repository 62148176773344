import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

const Component = props => {
	return (
		<TableCell align={props.align || 'left'} variant='body'>
			{
				props.format === 'avatar'
					? <Avatar alt={props.children} src={props.url} />
					: <Typography variant='body2' noWrap={props.noWrap}>{props.children}</Typography>
			}
		</TableCell>
	);
};

export default Component;

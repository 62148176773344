import { gql } from '@apollo/client';

const GET = gql`
	query WasteReport($offset: Int, $limit: Int, $start: Date, $end: Date) {
		wasteReport(offset: $offset, limit: $limit, start: $start, end: $end) {
			count
			totals {
				id
				name
				qty
				amount
			}
			rows {
				id
				Product {
					name
					BaseUnit {
						name
					}
				}
				quantity
				cost
			}
		}
	}
`;

export default { GET };

import React, { useState } from 'react';

import { useReactiveVar } from '@apollo/client';

import TablePaginationMui from '@material-ui/core/TablePagination';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { count, filter } from '../../../../apollo/cache';

const useStyles = makeStyles(() => ({
	container: {
		background: '#fff',
		borderTop: '1px solid #e0e0e0',
	},
	root: {
		height: 40,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		align: 'right',
		overflow: 'hidden',
	},
	actions: {
		height: 40,
		display: 'flex',
		alignItems: 'center',
	},
}));

const TablePagination = () => {
	const classes = useStyles();

	const countVar = useReactiveVar(count);
	const filterVar = useReactiveVar(filter);

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);

	const labelDisplayedRows = ({ from, to, count }) => `${from}-${to} de ${count}`;
	const handleChangePage = (e, newPage) => {
    setPage(newPage);
    filter({ ...filterVar, limit: rowsPerPage, offset: newPage });
  };

  const handleChangeRowsPerPage = e => {
    setRowsPerPage(parseInt(e.target.value));
    setPage(0);
    filter({ ...filterVar, limit: parseInt(e.target.value), offset: 0 });
  };

	return (
		<div className={classes.container}>
			<TablePaginationMui
				rowsPerPageOptions={[25, 50, 100]}
				component='div'
				labelRowsPerPage='Filas por página:'
				backIconButtonText='Página anterior'
				nextIconButtonText='Página siguiente'
				labelDisplayedRows={labelDisplayedRows}
	      count={countVar || 0}
	      rowsPerPage={rowsPerPage}
	      page={countVar > 0 ? page : 0}
	      onPageChange={handleChangePage}
	      onRowsPerPageChange={handleChangeRowsPerPage}
				classes={{
					root: classes.root,
					actions: classes.actions,
				}}
			/>
		</div>
	);
};

export default TablePagination;

import React, { useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import FormDialog from './../../presentationals/FormDialog';
import Autocomplete from './../../presentationals/Autocomplete';
import TextField from './../../presentationals/TextField';
import SelectField from './../../presentationals/SelectField';
import Button from './../../presentationals/Button';
import { productType } from './../../presentationals/Table/helpers/Format';

import { parseError } from './../../helpers';
import { snackbar } from './../../apollo/cache';

import GQL_BRANCH from './../branch/_gql';
import GQL_PRODUCT from './../product/_gql';

import GQL from './_gql';

const defaultValues = {
	branchId: null,
	description: '',
	Products: []
}

const FormComponent = props => {

	const [ branches, setBranches ] = useState([]);
	const [ products, setProducts ] = useState([]);
	const [ values, setValues ] = useState(defaultValues);
	const [ errors, setErrors ] = useState({});

	const { data: dataBranches } = useQuery(GQL_BRANCH.GET_AUTOCOMPLETE, { variables: { limit: 0 } });
	const { data: dataProducts} = useQuery(GQL_PRODUCT.GET_INVENTORY, { variables: { type: [1,2,3] } });

	const [ createBranchPhysicalInventory, { loading }] = useMutation(GQL.CREATE, {
		update(cache, { data }) {
			try{
				const oldQuery = cache.readQuery({ query: GQL.GET });

				cache.writeQuery({
          query: GQL.GET,
          data: { branchPhysicalInventories: {
          	count: parseInt(oldQuery.branchPhysicalInventories.count) + 1,
          	rows: [ data.createBranchPhysicalInventory, ...oldQuery.branchPhysicalInventories.rows ]
          } }
        });
			}catch(e){
				console.log(e.message);
			}
		},
		onCompleted: data => {
			props.handleCreate && props.handleCreate(data);
		}
	});

	useEffect(() => {
		if(dataBranches) {
			const tmp = dataBranches.branches.rows.map(el => ({ value: el.id, label: el.name }));
			setBranches(tmp);
		}
	},[dataBranches]);

	useEffect(() => {
		if(dataProducts) {
			const tmp = dataProducts.products.rows.map(el => { 
				const { type, ProductUnits, ...data } = el;
				const tmpProductUnits = ProductUnits.find(elCh => !elCh.unitCompoundId);
				const tmpQtyInventory = tmpProductUnits && tmpProductUnits.BranchInventories.find(elCh => parseInt(elCh.branchId) === parseInt(values.branchId));
				const qty = tmpQtyInventory ? tmpQtyInventory.quantity : 0;
				return { 
					...data,
					type: productType[type],
					unit: tmpProductUnits ? qty + ' ' + tmpProductUnits.name : '-',
					quantity: qty,
					units: ProductUnits.map(elCh => ({ label: elCh.name, value: elCh.id }))
				}
			});
			setProducts(tmp);

			const tmpValues = dataProducts.products.rows.map(el => {
				const { type, ProductUnits, ...data } = el;
				const tmpProductUnits = ProductUnits.find(elCh => !elCh.unitCompoundId);
				const tmpQtyInventory = tmpProductUnits && tmpProductUnits.BranchInventories.find(elCh => parseInt(elCh.branchId) === parseInt(values.branchId));
				const qty = tmpQtyInventory ? tmpQtyInventory.quantity : 0;
				return {
					quantity: qty,
					productId: data.id,
					productUnitId: tmpProductUnits ? tmpProductUnits.id : '',
					unitPrice: 0
				}
			});
			setValues(values => ({ ...values, Products: tmpValues }))
		}
	},[dataProducts, values.branchId]);

	const handleAction = async e => {
		try {
			if(!values.branchId) {
				const e = new Error('')
  			e.name = 'branchId';
  			e.input = true;
  			throw e; 
			}

			console.log(values.branchId);

			if(parseInt(values.branchId.value) === 8) {
				const e = new Error('');
  			e.name = 'branchId';
  			e.input = true;
  			throw e; 
			}

			if(!values.description || values.description.trim() === '') {
				const e = new Error('El comentario es obligatorio.')
  			e.name = 'description';
  			e.input = true;
  			throw e; 
			}

			const Products = values.Products.map((el, i) => {
				if(parseInt(el.productUnitId) > 0){
					return {
						quantity: !isNaN(parseFloat(el.quantity)) ? parseFloat(el.quantity) : 0,
						productId: el.productId,
						productUnitId: el.productUnitId,
						unitPrice: !isNaN(parseFloat(el.unitPrice)) ? parseFloat(el.unitPrice) : 0,
					}
				} 
				return false;
			}).filter(el => el);

			const variables = {
				branchId: values.branchId.value,
				description: values.description,
				Products
			};

			await createBranchPhysicalInventory({ variables });

			setValues(defaultValues);
			props.handleClose();
			setErrors({});
		}catch(e) {
			if(e.input) {
				setErrors({ [e.name]: e.message });
				return true;
			}
			const parseErrors = parseError(e);
      if(parseErrors[0].name === 'GENERAL_ERROR'){
      	snackbar({
	      	isOpen: true, 
	        time: 3000,
	        label: parseErrors[0].message, 
	        severity: 'error'
	      });
      }
		}
	}

	const handleClose = () => {
		setValues(defaultValues);
		props.handleClose();
	}

	const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleElementChange = (name, value, id) => {
		const tmpProducts = values.Products.map(el => {
			if(parseInt(el.productId) === parseInt(id)){
				return { 
					...el, 
					productUnitId: name === 'unit' ? parseInt(value) : el.productUnitId,
					quantity: name === 'quantity' ? value : el.quantity,
					unitPrice: name === 'unitPrice' ? value : el.unitPrice
				}
			}
			return el;
		});
		setValues(values => ({ ...values, Products: tmpProducts }));
  }

  const handleReset = () => {
  	const tmp = values.Products.map(el => ({ ...el, quantity: 0, unitPrice: 0 }))
  	setValues(values => ({ ...values, Products: tmp }));
  }

	return (
		<FormDialog 
			isOpen={props.isOpen}
			isLoading={loading}
      title='Nuevo ajuste de inventario'
      name="cart"
      singular="Cart"
      disableEscape
      width="md"
      handleClose={handleClose}
      handleAction={handleAction}
      rightContent={
      	<Grid container spacing={1}>
      		<Grid item md={3} xs={false} />
      		<Grid item md={6} xs={12}>
		        <Autocomplete 
			        name='branchId'
			        label='Sucursal' 
			        variant='outlined'
			        onChange={handleAutocompleteChange}
			        options={branches}
			        value={values.branchId}
			        error={errors.branchId}
			        noFormat
			        size='small'
			      />
			    </Grid>
      		<Grid item md={3} xs={12}>
      			<Button 
		      		label='Resetear'
		      		variant='outlined'
		      		onClick={handleReset}
		      		color='secondary'
		      	/>
      		</Grid>
			  </Grid>
      }
		>
			<Grid container spacing={1}>
				<Grid item xs={2 /*Cambiar a 3*/}><strong>Producto</strong></Grid>
				<Grid item xs={2 /*Cambiar a 3*/}><strong>Clasificación</strong></Grid>
				<Grid item xs={2}><strong>Inventario</strong></Grid>
				<Grid item xs={2}><strong>Unidad Ajuste</strong></Grid>
				<Grid item xs={2}><strong>Cant. Ajuste</strong></Grid>
				{<Grid item xs={2}><strong>Precio Unitario</strong></Grid> /*Eliminar*/}
			</Grid>
			{
				products.map(el => {
					return (
						<Element 
							key={el.id} 
							id={el.id}
							name={el.name} 
							classification={el.Clasification.name}
							unit={el.unit}
							units={el.units}
							values={values.Products.find(elCh => parseInt(elCh.productId) === parseInt(el.id))}
							onChange={handleElementChange}
						/>
					);
				})
			}
			<br/>
			<Divider />
			<br/>
			<TextField 
	      name='description'
	      label='Añadir comentarios' 
	      variant='outlined'
	      onChange={handleChange}
	      value={values.description}
	      error={errors.description}
	      multiline
	      rows={2}
	      noFormat
	    />
		</FormDialog>
	)

}

const Element = props => {
	const handleChange = e => {
		const name = e.target.name;
		const value = e.target.value;
		props.onChange(name, value, props.id);
	}
	return (
		<Grid container alignItems='center' spacing={1}>
			<Grid item xs={2 /*Cambiar a 3*/}>{props.name}</Grid>
			<Grid item xs={2 /*Cambiar a 3*/}>{props.classification}</Grid>
			<Grid item xs={2}>{props.unit}</Grid>
			<Grid item xs={2}>
				<SelectField
					label=''
					name='unit'
					value={props.values ? props.values.productUnitId : ''}
					options={props.units}
					noFormat
					size='small'
					variant='outlined'
					onChange={handleChange}
				/>
			</Grid>
			<Grid item xs={2}>
				<TextField
					label=''
					name='quantity'
					value={props.values ? props.values.quantity : ''}
					noFormat
					type='number'
					variant='outlined'
					onChange={handleChange}
				/>
			</Grid>
			{ //Eliminar
				<Grid item xs={2}>
					<TextField
						label=''
						name='unitPrice'
						value={props.values ? props.values.unitPrice : ''}
						noFormat
						type='number'
						variant='outlined'
						onChange={handleChange}
					/>
				</Grid>
				// Hasta aqui
			}
		</Grid>
	);
}

export default FormComponent;
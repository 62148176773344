import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';

import ListAltIcon from '@material-ui/icons/ListAltOutlined';
import AppsIcon from '@material-ui/icons/AppsOutlined';
import DoneAllIcon from '@material-ui/icons/DoneAllOutlined';
import CloseIcon from '@material-ui/icons/Close';
import LockIcon from '@material-ui/icons/LockOutlined';
// import ReceiptIcon from '@material-ui/icons/Receipt';

import { actions, snackbar } from '../../apollo/cache';

import { parseError } from './../../helpers';
import ConfirmDialog from './../../presentationals/ConfirmDialog';
import AuthConfirmDialog from './../../presentationals/ConfirmDialog';

import DeleteDialog from './delete.dialog';
import SummaryDialog from './summary.dialog';
import FormEdit from './form.dialog';

import GQL from './_gql';

// import Ticket from './_pdf.80mm';

const defaultSummaryDialog = [false,0,{ folio: ''}];
const defaultConfirm = [false, { id: null }];
const defaultConfirmDelete = [false,null];

const ActionComponent = () => {
  const [formIsOpen, setFormIsOpen] = useState([false, null]);
  const [isOpenSummary, setIsOpenSummary] = useState(defaultSummaryDialog);
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(defaultConfirm);
  const [isOpenConfirmDialogDelete, setIsOpenConfirmDialogDelete] = useState([false,null]);
  const [isOpenAuthDialog, setIsOpenAuthDialog] = useState(defaultConfirm);

  const [confirmTransfer] = useMutation(GQL.CONFIRM_TRANSFER, {
    update(cache, { data }){
      cache.evict({ id: `Transfer:${data.confirmTransfer.id}` });
      cache.gc();
    },
    onCompleted: () => setIsOpenConfirmDialog(defaultConfirm)
  });
  const [authTransfer] = useMutation(GQL.AUTH_TRANSFER, {
    onCompleted: () => setIsOpenAuthDialog(defaultConfirm)
  });
  
  const handleCloseCreate = () => setFormIsOpen([false, true]);

  const handleCloseSummary = () => setIsOpenSummary(defaultSummaryDialog);
  const handleSummary = (e,{ id, folio }) => {
    setIsOpenSummary([true,id,{folio}]);
  };

  const handleAuth = (e,{id}) => setIsOpenAuthDialog([true, { id }]);
  const handleCloseAuth = () => setIsOpenAuthDialog(defaultConfirm);

  const handleConfirm = (e,{id}) => setIsOpenConfirmDialog([true, { id }]);
  const handleCloseConfirm = () => setIsOpenConfirmDialog(defaultConfirm);

  const handleActionConfirm = async ({ id }) => {
    try {
      await confirmTransfer({ variables: { id } });
    }catch(e) {
      console.log(e);
      const parseErrors = parseError(e);
      if(parseErrors[0].name === 'GENERAL_ERROR'){
        snackbar({
          isOpen: true, 
          time: 0,
          label: parseErrors[0].message,
          severity: 'error'
        });
      }
    } 
  }

  const handleActionAuth = async ({ id }) => {
    try {
      await authTransfer({ variables: { id } });
    }catch(e) {
      console.log(e);
      const parseErrors = parseError(e);
      if(parseErrors[0].name === 'GENERAL_ERROR'){
        snackbar({
          isOpen: true, 
          time: 0,
          label: parseErrors[0].message, 
          severity: 'error'
        });
      }
    } 
  }
  
  const handleDelete = (e,{id}) => setIsOpenConfirmDialogDelete([true, id]);
  const handleCloseConfirmDelete = () => setIsOpenConfirmDialogDelete(defaultConfirmDelete);

  const handleEdit = (e,{id}) => setFormIsOpen([true, id]);

  // const handleTicket = (e, { id }) => {
  //   Ticket(id, true);
  // };
  // const handleTicketNoCosts = (e, { id }) => {
  //   Ticket(id);
  // };

  useEffect(() => {
    actions([
      {
        icon: <ListAltIcon/>,
        onClick: handleSummary,
        label: 'Resumen del Traspaso'
      },
      {
        icon: <AppsIcon/>,
        onClick: handleEdit,
        label: 'Editar Traspaso',
        disable: { key: 'status', value: ['0','3'] }
      },
      {
        icon: <LockIcon/>,
        onClick: handleAuth,
        label: 'Autorizar Traspaso',
        disable: { key: 'status', value: ['0','2','3'] }
      },
      {
        icon: <DoneAllIcon/>,
        onClick: handleConfirm,
        label: 'Confirmar Traspaso',
        disable: { key: 'status', value: ['1', '0','3'] }
      },
      {
        icon: <CloseIcon/>,
        onClick: handleDelete,
        label: 'Declinar Traspaso',
        disable: { key: 'status', value: ['3', '0'] }
      },
    ].filter(el => el));
  }, []);

  return (
    <>
      <ConfirmDialog
        isOpen={isOpenConfirmDialog[0]}
        handleClose={handleCloseConfirm}
        handleAction={handleActionConfirm}
        params={isOpenConfirmDialog[1]}
      />
      <AuthConfirmDialog
        isOpen={isOpenAuthDialog[0]}
        handleClose={handleCloseAuth}
        handleAction={handleActionAuth}
        params={isOpenAuthDialog[1]}
      />
      <DeleteDialog 
        isOpen={isOpenConfirmDialogDelete[0]}
        params={isOpenConfirmDialogDelete[1]}
        handleClose={handleCloseConfirmDelete}
      />
      <SummaryDialog
        isOpen={isOpenSummary[0]}
        handleClose={handleCloseSummary}
        id={isOpenSummary[1]}
        extraData={isOpenSummary[2]}
      />
      <FormEdit isOpen={formIsOpen[0]} handleClose={handleCloseCreate} transferId={formIsOpen[1]} />
    </>
  );
};

export default ActionComponent;

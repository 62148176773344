import React from 'react';

import FormDialog from './../../../presentationals/FormDialog';

import Form from './form';
import Table from './table';

const Component = props => {

	return (
		<FormDialog 
			isOpen={props.isOpen}
      title={`Toppings de ${props.name}`}
      disableEscape
      handleClose={props.handleClose}
      hideAction
		>	
			<Form id={props.id} />
			<Table id={props.id} />
		</FormDialog>
	)
} 

export default Component;
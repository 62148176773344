import React, { Fragment, useState, useEffect } from 'react';

import { useQuery, NetworkStatus } from '@apollo/client';

import format from 'date-fns/format';
import { es } from 'date-fns/locale';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';

import Header from './../../presentationals/Table/header';
import Table from './../../presentationals/Table/helpers/Table';
import Loading from './../../presentationals/Loading';

import { currencyFormat } from './../../helpers';

import GQL from './_gql';
import FilterDialog from './filter.dialog';

import DownloadXLS from './xls.document';
import DownloadPDF from './pdf.document';

const currentDate = format(new Date(), 'dd MMM yyyy', { locale: es }).toUpperCase();

const Component = props => {

	const [ isOpen, setIsOpen ] = useState(false);
	const [ filter, setFilter ] = useState({});
	const [ title, setTitle ] = useState([currentDate, currentDate]);
	const [ total, setTotal ] = useState([]);

	const { data, loading, refetch, networkStatus } = useQuery(GQL.GET, {
		notifyOnNetworkStatusChange: true,
	});

	useEffect(() => {
		if(data && data.chipsCombination){
			const tmp = data.chipsCombination.rows.map(el =>
				el.Sales.reduce((array, elCh, i) => {
					array[i] = { 
						qty: parseInt(array[i] ? array[i].qty : 0) + parseInt(elCh.qty),
						amount: parseFloat(array[i] ? array[i].total : 0) + parseFloat(elCh.total)
					}
					return array;
				}, [])
			);
			const tmp1 = tmp.reduce((array, el) => {
				el.forEach((elCh, i) => {
					array[i] = {
						qty: parseInt(array[i] ? array[i].qty : 0) + parseInt(elCh.qty),
						amount: parseFloat(array[i] ? array[i].amount : 0) + parseFloat(elCh.amount)
					}
				})
				return array;
			}, []);

			setTotal(tmp1);
		}
	}, [data]);

	useEffect(() => {
		if(Object.keys(filter).length > 0){
			refetch(filter);
		}
	}, [filter, refetch]);

	const handleFilter = () => {
		setIsOpen(true);
	}

	const handleAction = (action, data) => {
		switch(action){
			case 'close':
				setIsOpen(false);
				break;
			default:
				const start = format(data.start, 'dd MMM yyyy', { locale: es }).toUpperCase();
				const end = format(data.end, 'dd MMM yyyy', { locale: es }).toUpperCase();
				
				setTitle([start, end]);

				setFilter(filter => ({ ...filter, ...data }));
				break;
		}
	}

	const downloadXLS = () => {
		const totalFix = total.map(el => {
			return {
				qty: el.qty,
				total: el.amount
			}
		})
		DownloadXLS([...data.chipsCombination.rows, { Combination: ['TOTAL'], Sales: totalFix }], filter);
	}

	const downloadPDF = () => {
		data.chipsCombination.rows.length > 0 && DownloadPDF(data.chipsCombination.rows, filter);
	}

	if(loading || networkStatus === NetworkStatus.refetch) return <Loading />;

	return (
		<Fragment>
			<Header
				moduleCode='RETR01'
				title={`Trazabilidad de Narachips ${title[0]} al ${title[1]}`}
				noCreate
				customizedXls={downloadXLS}
				customizedPdf={downloadPDF}
				qty={data && data.chipsCombination.count}
				filter={handleFilter}
			/>
			{
				(data && data.chipsCombination.count > 0) && 
				<Table>
				
					<TableHead>
						<TableRow>
							<TableCell />
							{
								(data && data.chipsCombination.count > 0) && 
									data.chipsCombination.rows[0].Sales.map((el, i) => 
										<TableCell key={el.Branch.name} align='center' colSpan={2} 
											style={{ 
												background: (i % 2 === 0) ? '#c9c9c9' : '#fafafa',
												borderColor: (i % 2 === 0) ? '#989898' : '#ddd',
												minWidth: 10
											}}
										>
											<Typography variant="body2" noWrap>
												<strong>{el.Branch.name}</strong>
											</Typography>
										</TableCell>
									)
							}
						</TableRow>
						<TableRow>
							<TableCell style={{top: 33}}>Papas</TableCell>
							{
								(data && data.chipsCombination.count > 0) && 
									data.chipsCombination.rows[0].Sales.map((el, i) => 
										<Fragment key={el.Branch.name}>
											<TableCell 
												align='center' 
												style={{ 
													background: (i % 2 === 0) ? '#c9c9c9' : '#fafafa',
													borderColor: (i % 2 === 0) ? '#989898' : '#ddd',
													top: 33
												}}
											>
												Cant.
											</TableCell>
											<TableCell 
												align='right' 
												style={{ 
													background: (i % 2 === 0) ? '#c9c9c9' : '#fafafa',
													borderColor: (i % 2 === 0) ? '#989898' : '#ddd',
													top: 33,
												}}
											>
												Importe
											</TableCell>
										</Fragment>
									)
							}
						</TableRow>
					</TableHead>
					<TableBody>
						{ data && data.chipsCombination.rows.map((el, i) =>
								<TableRow key={i}>
									<TableCell>
										<Typography variant="body2" noWrap>
											{el.Combination.join(', ')}
										</Typography>
									</TableCell>
									{
										el.Sales.map((elCh, ii) => 
											<Fragment key={ii}>
												<TableCell
													align='center' 
													style={{ 
														background: (ii % 2 === 0) ? '#ddd' : '#fafafa',
														borderColor: (ii % 2 === 0) ? '#989898' : '#ddd'
													}}
												>
													{elCh.qty}
												</TableCell>
												<TableCell
													align='right' 
													style={{ 
														background: (ii % 2 === 0) ? '#ddd' : '#fafafa',
														borderColor: (ii % 2 === 0) ? '#989898' : '#ddd'
													}}
												>
													{currencyFormat(elCh.total)}
												</TableCell>
											</Fragment>
										)
									}
								</TableRow>
							)
						}
						<TableRow>
							<TableCell style={{ position: 'sticky', bottom: 0, background: '#fafafa', borderTop: '1px solid #ddd' }}>
								<Typography variant="body2" noWrap>
									<strong>TOTAL</strong>
								</Typography>
							</TableCell>
							{
								(data && data.chipsCombination.count > 0) && 
									data.chipsCombination.rows[0].Sales.map((el, i) => 
										<Fragment key={el.Branch.name}>
											<TableCell 
												align='center' 
												style={{ 
													position: 'sticky', 
													bottom: 0, 
													background: (i % 2 === 0) ? '#a2a2a2' : '#c0c0c0',
													borderTop: '1px solid #ddd' 
												}}
											>
												{total[i] ? total[i].qty : 0}
											</TableCell>
											<TableCell 
												align='right' 
												style={{ 
													position: 'sticky', 
													bottom: 0, 
													background: (i % 2 === 0) ? '#a2a2a2' : '#c0c0c0',
													borderTop: '1px solid #ddd' 
												}}
											>
												{total[i] ? currencyFormat(total[i].amount) : currencyFormat(0)}
											</TableCell>
										</Fragment>
									)
							}
						</TableRow>
					</TableBody>
				</Table>
			}
			<FilterDialog isOpen={isOpen} handleAction={handleAction} />
		</Fragment>
	);
}

export default Component;
import React, { useState } from 'react';

import Container from './../../presentationals/Module.v2/Container';

import Filter from './_filter';
import Action from './_action';
import GQL from './_gql';
import Form from './form.dialog';

const Rows = [
	{ label: 'Fecha', key: 'createdAt', format: 'date' },
	{ label: 'Folio', key: 'serial && folio', strcat: '-', sort: 'text' },
	{ label: 'Total', key: 'total', sort: 'text', format: 'currency' },
	{ label: 'Suc. Origen', key: 'Branch name', sort: 'text' },
	{ label: 'Suc. Destino', key: 'TransferBranch name', sort: 'text' },
	{ label: 'Responsable', key: 'User name', sort: 'text' },
	{ label: 'Estatus', key: 'status', sort: 'text', format: 'transferStatus' },
];
const title = 'Traspaso de mercancía';
const operation = ['transfer', 'transfers'];

const Component = () => {
	const [formIsOpen, setFormIsOpen] = useState([false, true]);

	const handleCreate = () => setFormIsOpen([true, true]);
  const handleCloseCreate = () => setFormIsOpen([false, true]);

	return (
		<>
			<Container
				title={title}
				operation={operation}
				Rows={Rows}
				get={GQL.GET}
				filter
				noDelete
				handleCustomCreate={handleCreate}
				defaultVariables={{ status:[1, 2], type: [1] }}
			/>
			<Filter />
			<Action />
			<Form isOpen={formIsOpen[0]} handleClose={handleCloseCreate} isCreate={formIsOpen[1]} />
		</>
	);
};

export default Component;

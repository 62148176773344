import React, { useState, useEffect, Fragment } from 'react';

import { useQuery, useMutation } from '@apollo/client';

import BusinessIcon from '@material-ui/icons/BusinessOutlined';
import PhoneIcon from '@material-ui/icons/PhoneOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import RoomIcon from '@material-ui/icons/RoomOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';

import Module from './../../presentationals/Module';

import GQL from './../branch/_gql';

import CutomerDialog from './../customer/customer.dialog.js';

const Rows = [
	{ label: 'Nombre', key: 'name', sort: 'text', filter: true },
	{ label: 'Teléfono', key: 'phone', sort: 'text' },
	{ label: 'Tipo', key: 'type', sort: 'text', format: 'branchType' },
	{ label: 'Establecimiento', key: 'owner', sort: 'text', format: 'branchOwner' },
	{ label: 'Representante Legal', key: 'Customer name', sort: 'text' },
];

const typeOptions = [
	{ value: 1, label: 'Punto de Venta' },
	{ value: 2, label: 'Almacén' }
];

const stateOptions = [
	{ value: 1, label: 'Sinaloa' },
	{ value: 2, label: 'Sonora' }
];

const Component = props => {

	const [ filter, setFilter ] = useState({});
	const [ isOpen, setIsOpen ] = useState([false, null]);

	const { data, loading, refetch } = useQuery(GQL.GET,{ variables: { owner: false } });
	const [ updateBranch, { loading: loadingUpdateBranch } ] = useMutation(GQL.UPDATE);
	
	useEffect(() => {
		if(Object.keys(filter).length > 0){
			refetch(filter);
		}
	}, [filter, refetch]);

	const handleRows = (offset, limit) => {
		setFilter(filter => ({ ...filter, limit, offset }));
	}

	const handleSearch = (value) => {
		setFilter(filter => ({ ...filter, like: value }));
	}

	const handleCustomer = (e, params) => {
		setIsOpen([true, params.id]);
	}

	const handleClose = () => {
		setIsOpen([false, null]);
	}

	const handleAction = async customerId => {
		await updateBranch({ variables: { id: isOpen[1], customerId } });
		setIsOpen([false, null]);
	}
	
	return (
		<Fragment>
			<Module
				moduleCode='CAFR01'
				isLoading={loading || loadingUpdateBranch}
				name='Sucursales de Socios'
				singular='Sucursal'
				operation={['branch', 'branches']}
				Rows={Rows}
				get={GQL.GET}
				handleRows={handleRows}
				getById={GQL.GET_BY_ID}
				create={GQL.CREATE}
				update={GQL.UPDATE}
				delete={GQL.DELETE}
				records={data && data.branches}
				handleSearch={handleSearch}
				actions={[
					{ icon: <PersonIcon />, label: 'Representante Legal', disable: { key: 'owner', value: 'true' }, onClick: handleCustomer }
				]}
				fields={[
					{ 
						icon: <BusinessIcon />,
						name: 'name', 
						label: 'Nombre',
						autoFocus: true
					},
					{ 
						name: 'prefix', 
						label: 'Prefijo (Serie de documentos)'
					},
					{ 
						name: 'description', 
						label: 'Descripción'
					},
					{ 
						icon: <PhoneIcon />,
						name: 'phone', 
						label: 'Teléfono'
					},
					{ 
						icon: <SettingsIcon />,
						field: 'select',
						name: 'owner', 
						label: 'Establecimiento',
						defaultValue: false,
						show: true
					},
					{ 
						field: 'select',
						name: 'type', 
						label: 'Tipo',
						defaultValue: 1,
						options: typeOptions
					},
					{ 
						icon: <RoomIcon />,
						name: 'addressLine1', 
						label: 'Calle y Número'
					},
					{ 
						name: 'addressLine2', 
						label: 'Colonia'
					},
					{ 
						name: 'city', 
						label: 'Ciudad'
					},
					{ 
						name: 'zipCode', 
						label: 'Código Postal'
					},
					{ 
						field: 'select',
						name: 'stateId', 
						label: 'Estado',
						defaultValue: 1,
						options: stateOptions
					},
					{ 
						show: true,
						name: 'countryId', 
						defaultValue: 1,
					},

				]}
			/>
			<CutomerDialog isOpen={isOpen[0]} handleClose={handleClose} handleAction={handleAction} />
		</Fragment>
	);
}

export default Component;
import React, { useState } from 'react';

import { useQuery } from '@apollo/client';

import Typography from '@material-ui/core/Typography';

import FilterDrawer from '../../presentationals/FilterDrawer.v2';
import SelectField from '../../presentationals/SelectField';

import { filter } from '../../apollo/cache';
import GQL_BRANCHES from '../branch/_gql';

const statusOptions = [
  { value: -1, label: 'Todos' },
  { value: 1, label: 'Pendientes' },
  { value: 2, label: 'Autorizados' },
  { value: 3, label: 'Confirmados' },
  { value: 0, label: 'Declinados' }
];

const FilterDialog = () => {
  const [values, setValues] = useState({ branchId: -1, transferBranchId: -1, status: -1 });

  const { data: dataBranches } = useQuery(GQL_BRANCHES.GET, { variables: { limit: 0 } });

  const handleAction = (e, data) => {
    const { startDate: start, endDate: end } = data;
    filter({
      branchId: parseInt(values.branchId) !== -1 ? values.branchId : null,
      transferBranchId: parseInt(values.transferBranchId) !== -1 ? values.transferBranchId : null,
      status: parseInt(values.status) !== -1 ? [values.status] : [],
      start,
      end,
    });
  };

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <FilterDrawer
      handleAction={handleAction}
      showDates
    >
      <Typography variant="subtitle1">
        Estatus:
      </Typography>
      <SelectField
        name='status'
        variant='outlined'
        value={values.status}
        onChange={handleChange}
        options={statusOptions}
        noFormat
      />
      <Typography variant='subtitle1'>
        Suc. Origen:
      </Typography>
      <SelectField
        name='branchId'
        variant='outlined'
        onChange={handleChange}
        size='medium'
        value={values.branchId}
        options={[
          { value: -1, label: 'AMBAS' },
          { value: 8, label: 'BODEGA NARACHIPS' },
          { value: 24, label: 'BODEGA CASA DEL ELOTE' },
        ]}
        noFormat
      />
      <Typography variant='subtitle1'>
        Suc. Destino:
      </Typography>
      <SelectField
        name='transferBranchId'
        variant='outlined'
        onChange={handleChange}
        size='medium'
        value={values.transferBranchId}
        options={dataBranches ? [
          { value: -1, label: 'TODAS' },
          ...dataBranches.branches.rows.map(el => ({
            value: el.id,
            label: `${el.name} ${parseInt(el.companyId) === 2 ? ' - CASA DEL ELOTE' : ''}`.trim().toUpperCase(),
          }))
        ] : []}
        noFormat
      />
    </FilterDrawer>
  );
};

export default FilterDialog;

import { gql } from '@apollo/client';

const GET = gql`
  query PaymentMethodSales($start: Date $end: Date $branchId: ID $companyId: Int $paymentMethodId: ID){
    paymentMethodSales(start: $start, end: $end, branchId: $branchId, companyId: $companyId, paymentMethodId: $paymentMethodId) {
      count
      rows {
        PaymentMethod {
          id
          name
        }
        Sales {
          Branch {
            id
            name
          }
          total
        }
      }
    }
  }
`;

const obj = { GET };
export default obj;
import { gql } from '@apollo/client';

const GET_BY_ID_PDF = gql`
  query SaleNote($id: ID!) {
    saleNote(id: $id) {
      id
      folio
      serial
      iva
      subtotal
      discount
      taxes
      total
      createdAt
      Customer {
        name
        phone
      }
      Branch {
        name
        phone
      }
      SaleNoteDetails {
        concept
        quantity
        unitPrice
        discount
        total
        taxes
        Product {
          name
        }
        ProductUnit {
          name
        }
      }
    }
  }
`;

const object = { GET_BY_ID_PDF };

export default object;

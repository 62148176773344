import React, { useState, useEffect } from 'react';

import { startOfDay, endOfDay } from 'date-fns';

import { useQuery, useReactiveVar } from '@apollo/client';

import CloseIcon from '@material-ui/icons/Close';

import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';

import { filterForm } from '../../apollo/cache';

import Button from '../Button';
import DateField from '../DateField';
import SelectField from '../SelectField';

import useStyles from './style';
import GQL from './_gql';

const defaultValues = {
  startDate: startOfDay(new Date()),
  endDate: endOfDay(new Date()),
  date: endOfDay(new Date()),
  branchId: -1,
  useDates: false,
};

const FilterDrawer = props => {

  const classes = useStyles();

  const filterFormVar = useReactiveVar(filterForm);
  const [values, setValues] = useState(defaultValues);
  const [branches, setBranches] = useState([]);

  const { data } = useQuery(GQL.GET_BRANCHES, { variables: { justLinked: true } });

  useEffect(() => {
    if (data && data.branches.count > 0) {
      const tmpBranches = props.includeAllOption
        ? [{ value: -1, label: 'Todas las Sucursales' }, ...data.branches.rows.map(el => ({ value: el.id, label: el.name }))]
        : data.branches.rows.map(el => ({ value: el.id, label: el.name }));
      setBranches([...tmpBranches]);
      setValues(values => ({ ...values, branchId: -1 }));
    }
  }, [data]);

  const handleClose = () => filterForm(false);

  const handleClick = e => {
    props.handleAction && props.handleAction(e, {
      ...values,
      startDate: (props.optionalDates && values.useDates)
        ? values.startDate
        : props.showDates
          ? values.startDate
          : null,
      endDate: (props.optionalDates && values.useDates)
        ? values.endDate
        : props.showDates
          ? values.endDate
          : null,
      date: (props.optionalDates && values.useDates)
        ? values.date
        : props.showOneDate
          ? values.date
          : null,
      branchId: values.branchId !== '' ? values.branchId : null,
    });
    handleClose();
  };

  const handleDateChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleSwitchChange = e => {
    const { name } = e.target;
    setValues(values => ({ ...values, [name]: e.target.checked }));
  };

  const handleChange = e => {
    const { name } = e.target;
    const { value } = e.target;
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <Drawer anchor='right' open={filterFormVar} onClose={handleClose} classes={{ paper: classes.container }}>
      <div className={classes.title}>
        <Typography variant='h6'>
          Filtrar Información
        </Typography>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          className={classes.closeButton}
          size='medium'
        >
          <CloseIcon fontSize='inherit' />
        </IconButton>
      </div>
      <Divider />
      {
        (props.showDates || props.showOneDate || props.optionalDates)
          && <div>
            <div className={classes.dateContent}>
              {
                props.optionalDates
                  && <>
                      <Typography variant='subtitle1'>
                        Usar Fechas
                      </Typography>
                      <Switch
                        checked={values.useDates}
                        onChange={handleSwitchChange}
                        name='useDates'
                      />
                     </>
              }
              {
                (props.showDates || values.useDates)
                  && <div>
                      <Typography variant='subtitle1'>
                        Rango de fechas:
                      </Typography>
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                          <DateField
                            variant='outlined'
                            name='startDate'
                            value={values.startDate}
                            onChange={handleDateChange}
                            noFormat
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <DateField
                            variant='outlined'
                            name='endDate'
                            value={values.endDate}
                            onChange={handleDateChange}
                            noFormat
                          />
                        </Grid>
                      </Grid>
                     </div>

              }
              {
                (props.showOneDate || values.useDates)
                  && <div>
                      <Typography variant='subtitle1'>
                        Fecha:
                      </Typography>
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                          <DateField
                            variant='outlined'
                            name='date'
                            value={values.date}
                            onChange={handleDateChange}
                            noFormat
                          />
                        </Grid>
                      </Grid>
                     </div>

              }
            </div>
            <Divider />
             </div>
      }
      {
        (props.showBranches && branches.length > 0)
          && <div>
            <div className={classes.dateContent}>
              <Typography variant='subtitle1'>
                Sucursales:
              </Typography>
              <SelectField
                name='branchId'
                variant='outlined'
                value={values.branchId}
                onChange={handleChange}
                options={branches}
                noFormat
              />
            </div>
            <Divider />
             </div>
      }
      <div className={classes.content}>
        {props.children}
      </div>
      <Divider />
      <div className={classes.footer}>
        <Button
          label='Aplicar Filtro'
          size='large'
          color='secondary'
          variant='outlined'
          onClick={handleClick}
          className={classes.actionButton}
        />
      </div>
    </Drawer>
  );
};

export default FilterDrawer;

import React, { useEffect } from 'react';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

import { sort } from '../../../../apollo/cache';

import useStyles from './style';
import TableSortLabel from './TableSortLabel';

const Component = props => {
	const classes = useStyles();

	useEffect(() => {
		return () => {
			sort({});
		};
	}, []);

	return (
		<TableHead>
			<TableRow>
				{ props.Rows.map(el => (
					<TableCell className={classes.tableCell} key={el.key} align={el.align || 'left'}>
						<Typography variant='body2' noWrap={true}>
							{
								el.sort
									? <TableSortLabel id={el.key} label={el.label} />
									: <strong>{el.label}</strong>
							}
						</Typography>
     </TableCell>
        ))}
        <TableCell className={classes.tableCell} />
			</TableRow>
		</TableHead>
	);
};

export default Component;

import React, { Fragment } from 'react';

import { useQuery } from '@apollo/client';

import Module from './../../presentationals/Module';

import GQL from './_gql';

const Rows = [
	{ label: 'Nombre', key: 'name', sort: 'text', filter: true },
];

const yesNoOptions = [
	{ value: true, label: 'Si' },
	{ value: false, label: 'No' }
];

const Component = props => {

	const { data, loading } = useQuery(GQL.GET);
	
	return (
		<Fragment>
			<Module
				moduleCode='MEP01'
				isLoading={loading}
				name='Metodos de Pago'
				singular='Metodos de Pago'
				operation={['paymentMethod', 'paymentMethods']}
				Rows={Rows}
				get={GQL.GET}
				getById={GQL.GET_BY_ID}
				create={GQL.CREATE}
				update={GQL.UPDATE}
				delete={GQL.DELETE}
				records={data && data.paymentMethods}
				fields={[
					{
						name: 'name',
						label: 'Nombre',
						autoFocus: true
					},
					{
						name: 'isCash',
						label: 'Efectivo',
						field: 'select',
						options: yesNoOptions
					}
				]}
			/>
		</Fragment>
	);
}

export default Component;
import React from 'react';

import { Link } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  subOption: {
  	padding: theme.spacing(0.3, 3, 0.5, 2),
  },
  ListItemIcon: {
    minWidth: '40px',
  },
  margin: {
  	marginLeft: '20px'
  }
}));

const LinkButton = props => {

	const classes = useStyles();

	return (
		<ListItem button className={classes.subOption} component={Link} to={props.url}>
	    { props.icon && <ListItemIcon className={classes.ListItemIcon}>{props.icon}</ListItemIcon> }
	    <ListItemText className={props.margin && classes.margin} primary={props.label} />
	  </ListItem>
	);

}

export { LinkButton };
import dateFormat from 'date-fns/format';
import parseISO from 'date-fns/parseISO'

import { currencyFormat } from './../../../helpers';

const format = (value, type) => {
	switch(type){
		case 'date':
			return value && value !== '-' 
				? new Date('2040/01/01 00:00:00') < new Date(value)
					? 'No Aplica' 
					: value 
						? dateFormat(parseISO(value), 'dd/MM/yyyy') 
						: '-'
				: '-';
		case 'dateSurgery':
			return value && value !== '-' 
				? new Date('2040/01/01 00:00:00') < new Date(value)
					? 'Es Espera' 
					: value 
						? dateFormat(parseISO(value), 'dd/MM/yyyy HH:mm:ss') 
						: '-'
				: '-';
		case 'dateTime':
			return value && value !== '-' ? dateFormat(parseISO(value), 'dd/MM/yyyy HH:mm:ss') : '-';
		case 'time':
			return value && value !== '-' ? dateFormat(parseISO(value), 'HH:mm:ss') : '-';
		case 'currency':
			return currencyFormat(value);
		case 'percent':
			return !isNaN(parseInt(value)) ? `${currencyFormat(value)}%` : '-';
		case 'balance':
			return (parseFloat(value) === 0) ? '-' : currencyFormat(value);
		case 'productType': 
			return productType[value];
		case 'yesNoType': 
			return yesNoType(value);
		case 'printer':
			return printer[value];
		case 'action':
			return action[value];
		case 'orderStatus':
			return orderStatus[value];
		case 'typeSale':
			return typeSale[value];
		case 'privileges':
			return privileges[value];
		case 'catchment':
			return catchment[value];
		case 'branchType':
			return branchType(value);
		case 'branchOwner':
			return branchOwner(value);
		case 'status':
			return status[value];
		case 'expenseType':
			return expenseType[value];
		case 'supplierType':
			return supplierType[value];
		case 'statusBranchOrder':
			return statusBranchOrder[value];
		case 'supportTicketType':
			return supportTicketType[value];
		case 'productionType':
			return productionType[value];
		case 'cediInventoryMovementType':
			return cediInventoryMovementType[value];
		case 'company':
			return company[parseInt(value)];
		case 'transferStatus':
			return transferStatus[value];
		case 'companyFormat':
			return companyFormat[value];
		default: return (typeof value === 'undefined' || value === '') ? '-' : value;
	}
}

export const company = ['-','Narachips','CasaElotes'];
export const status = ['Cancelado','Activo'];
export const transferStatus = ['Cancelado','Pendiente','Autorizado','Confirmado'];
export const productType = ['-', 'Elaborado', 'Final', 'Insumos'];
export const expenseType = ['-', 'Compras de Insumos', 'Producción', 'Venta', 'Administración'];
export const yesNoType = value => value ? 'SI' : 'NO'; 
export const printer = ['No Imprimir', 'Impresora 1', 'Impresora 2'];
export const action = ['-','Retiro','Depósito','Caja Fuerte'];
export const orderStatus = ['Cancelada','Abierta','En Proceso','Cerrada'];
export const typeSale = ['-','Local','Domicilio','Ordene y Recoja'];
export const privileges = ['-','Administrador','Operativo'];
export const branchType = value => value ? 'Punto de Venta' : 'Almacén'; ;
export const branchOwner = value => value ? 'Sucursal' : 'Franquicia'; ;
export const catchment = ['-', 'WhatsApp', 'Facebook', 'Instagram', 'Recomendación', 'Contacto del Doctor', 'Cliente', 'No Identificado'];
export const supplierType = ['-', 'Servicio', 'Insumos', 'Mantenimiento', 'Publicidad', 'Otros'];
export const statusBranchOrder = ['-', 'Abierta', 'Cerrada', 'Facturado', 'Traspasado', 'Confirmado'];
export const supportTicketType = ['-', 'GENERAL', 'RENDIMIENTO', 'DISEÑO', 'INFORMATIVO'];
export const productionType = ['-', 'Transformación', 'Producción'];
export const cediInventoryMovementType = ['-', 'Entrada', 'Salida'];
export const companyFormat = ['-', 'NARACHIPS', 'CASA DEL ELOTE'];

export default format;
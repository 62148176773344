import React, { Fragment, useState } from 'react';

import Divider from '@material-ui/core/Divider';
import MenuMUI from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';

import blue from '@material-ui/core/colors/blue';

import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCameraOutlined';

import { withStyles, makeStyles } from '@material-ui/core/styles';

import Button from './../../presentationals/Button';

import ProfileEditor from './profileEditor.dialog.js';

const owner = localStorage.getItem('name');
const username = localStorage.getItem('phone');

const Menu = withStyles(theme => ({
  paper: { 
    marginTop: 45, 
    padding: theme.spacing(2, 0, 0, 0),
    width: 320,
    textAlign: 'center'
  }
}))(props => (
  <MenuMUI
    anchorOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
    {...props}
  />
));

const useStyles = makeStyles(theme => ({
  chooseProfile: {
    background: '#fff',
    padding: 5,
    borderStyle: 'solid',
    borderColor: '#e1e1e1',
    borderWidth: '1px',
    boxShadow: '0px 0px 0px 3px rgba(255,255,255,1)',
    '&:hover': {
      background: '#fff',
      color: blue[500]
    }
  },
  profile: {
    width: 80,
    height: 80,
    fontSize: 40,
    background: blue[600]
  },
  owner: {
    fontWeight: '500',
    padding: 0,
    margin: theme.spacing(3, 0, 0, 0),
    lineHeight: 0.9
  },
  account: {
    color: '#585858'
  },
  configProfile: {
    margin: theme.spacing(2, 0, 3, 0),
  },
  logout: {
    margin: theme.spacing(2, 0, 2, 0),
    padding: theme.spacing(1, 0, 1, 0),
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    }
  },
  elementContainer: {
    flexGrow: 1,
    padding: theme.spacing(0, 5, 0, 5),
  },
  terms: {
    fontSize: 12,
    textTransform: 'none',
    marginTop: theme.spacing(1),
    color: '#585858',
    letterSpacing: 0
  }
}));

const ProfileMenu = props => {
  
  const classes = useStyles();

  const [ isProfileEditorOpen, setIsProfileEditorOpen ] = useState(false);

  const handleProfileEditor = e => {
    setIsProfileEditorOpen(!isProfileEditorOpen);
  }

  const handleConfig = () => {
    
  }

  const handleLogout = e => {
    localStorage.clear();
    window.location.reload();
    return false;
  }

	return (
    <Fragment>
      <ProfileEditor isOpen={isProfileEditorOpen} closeDialog={handleProfileEditor} />
  		<Menu
        id="profile-menu"
        anchorEl={props.anchorEl}
        keepMounted
        open={props.isOpen}
        onClose={props.handleClose}
       >
        <div className={classes.elementContainer}>
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            badgeContent={
              <IconButton aria-label="Choose profile" component="span" onClick={handleProfileEditor} className={classes.chooseProfile}>
                <PhotoCamera fontSize="small" />
              </IconButton>
            }
          >
            <Avatar alt={owner} src="/static/images/avatar/2.jpg" className={classes.profile} />
          </Badge>
          <Typography variant="subtitle1" display='block' className={classes.owner}>
            {owner}
          </Typography>
          <Typography variant="subtitle1" display='block' className={classes.account}>
            {username}
          </Typography>
          
          <Button 
            label="Gestionar mi Cuenta" 
            fullWidth
            variant="outlined"
            color="default"
            onClick={handleConfig}
            className={classes.configProfile}
          />
        </div>
        <Divider />
        <div className={classes.elementContainer}>
          <Button 
            label="Cerrar Sesión" 
            fullWidth
            color="secondary"
            onClick={handleLogout}
            className={classes.logout}
          />
        </div>
        <Divider />
        <Button 
          label="Términos y Condiciones" 
          color="inherit"
          variant="text"
          size="small"
          onClick={handleConfig}
          className={classes.terms}
        /> <Button 
          label="Políticas de Privacidad" 
          color="inherit"
          variant="text"
          size="small"
          onClick={handleConfig}
          className={classes.terms}
        />
      </Menu>
    </Fragment>
	)
}

export default ProfileMenu;
import React, { Fragment, useState, useEffect } from 'react';

import { useQuery, NetworkStatus } from '@apollo/client';

import format from 'date-fns/format';
import { es } from 'date-fns/locale';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';

import Header from './../../presentationals/Table/header';
import Table from './../../presentationals/Table/helpers/Table';
import Loading from './../../presentationals/Loading';

import { currencyFormat } from './../../helpers';

import GQL from './_gql';
import FilterDialog from './filter.dialog';
import DownloadXLS from './xls.document';

const currentDate = format(new Date(), 'dd MMM yyyy', { locale: es }).toUpperCase();

const defualtTotals = {
	Ins: {
		quantity: 0,
		total: 0,
	},
	Outs: {
		quantity: 0,
		total: 0,
	},
	Start: {
		quantity: 0,
		total: 0,
	},
	End: {
		quantity: 0,
		total: 0,
	}
}

const Component = props => {

	const [ isOpen, setIsOpen ] = useState(false);
	const [ filter, setFilter ] = useState({});
	const [ title, setTitle ] = useState([currentDate, currentDate]);
	const [ totals, setTotals ] = useState(defualtTotals);

	const { data, loading, refetch, networkStatus } = useQuery(GQL.GET, {
		notifyOnNetworkStatusChange: true,
	});

	useEffect(() => {
		if(data && data.inventoryReport){
			const tmp = data.inventoryReport.rows.reduce((object, el) => {
				return {
					Ins: { 
						quantity: parseFloat((parseFloat(object.Ins.quantity) + parseFloat(el.Ins.quantity)).toFixed(3)),
						total: parseFloat((parseFloat(object.Ins.total) + parseFloat(el.Ins.total)).toFixed(2)),
					},
					Outs: {
						quantity: parseFloat((parseFloat(object.Outs.quantity) + parseFloat(el.Outs.quantity)).toFixed(3)),
						total: parseFloat((parseFloat(object.Outs.total) + parseFloat(el.Outs.total)).toFixed(2)),
					},
					Start: {
						quantity: parseFloat((parseFloat(object.Start.quantity) + parseFloat(el.Start.quantity)).toFixed(3)),
						total: parseFloat((parseFloat(object.Start.total) + parseFloat(el.Start.total)).toFixed(2)),
					},
					End: {
						quantity: parseFloat((parseFloat(object.End.quantity) + parseFloat(el.End.quantity)).toFixed(3)),
						total: parseFloat((parseFloat(object.End.total) + parseFloat(el.End.total)).toFixed(2)),
					}
				};
			}, defualtTotals);
			
			setTotals(tmp);
		}
	}, [data,refetch]);

	useEffect(() => {
		if(Object.keys(filter).length > 0 && refetch){
			refetch(filter);
		}
	}, [filter, refetch]);

	const handleFilter = () => {
		setIsOpen(true);
	}

	const handleAction = (action, data) => {
		switch(action){
			case 'close':
				setIsOpen(false);
				break;
			default:
				const start = format(data.start, 'dd MMM yyyy', { locale: es }).toUpperCase();
				const end = format(data.end, 'dd MMM yyyy', { locale: es }).toUpperCase();
				
				setTitle([start, end]);

				setFilter(filter => ({ ...filter, ...data }));
				break;
		}
	}

	const downloadXLS = () => {
		data && DownloadXLS(data.inventoryReport.rows, totals, 'MOV_INVENTARIO_CEDI', filter);
	}

	const downloadPDF = () => {
		console.log();
	}

	if(loading || networkStatus === NetworkStatus.refetch) return <Loading />;

	return (
		<Fragment>
			<Header
				title={`Inventario del ${title[0]} al ${title[1]}`}
				noCreate
				customizedXls={downloadXLS}
				customizedPdf={downloadPDF}
				qty={data && data.inventoryReport.count}
				filter={handleFilter}
				moduleCode='REMO01'
			/>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell />
						<TableCell key='Start' align='center' colSpan={2} 
							style={{ 
								background: '#c9c9c9',
								borderColor: '#989898',
								minWidth: 10
							}}
						>
							<Typography variant="body2" noWrap>
								<strong>Cant. Inicial</strong>
							</Typography>
						</TableCell>
						<TableCell key='Ins' align='center' colSpan={2} 
							style={{ 
								background: '#fafafa',
								borderColor: '#ddd',
								minWidth: 10
							}}
						>
							<Typography variant="body2" noWrap>
								<strong>Entradas</strong>
							</Typography>
						</TableCell>
						<TableCell key='Outs' align='center' colSpan={2} 
							style={{ 
								background: '#c9c9c9',
								borderColor: '#989898',
								minWidth: 10
							}}
						>
							<Typography variant="body2" noWrap>
								<strong>Salidas</strong>
							</Typography>
						</TableCell>
						<TableCell key='End' align='center' colSpan={2} 
							style={{ 
								background: '#fafafa',
								borderColor: '#ddd',
								minWidth: 10
							}}
						>
							<Typography variant="body2" noWrap>
								<strong>Cant. Final</strong>
							</Typography>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell style={{top: 33}}>Producto</TableCell>
						<Fragment key='StartAmout'>
							<TableCell 
								align='center' 
								style={{ 
									background: '#c9c9c9',
									borderColor: '#989898',
									top: 33
								}}
							>
								Cant.
							</TableCell>
							<TableCell 
								align='right' 
								style={{ 
									background: '#c9c9c9',
									borderColor: '#989898',
									top: 33
								}}
							>
								Importe
							</TableCell>
						</Fragment>
						<Fragment key='InsAmout'>
							<TableCell 
								align='center' 
								style={{ 
									background: '#fafafa',
									borderColor: '#ddd',
									top: 33
								}}
							>
								Cant.
							</TableCell>
							<TableCell 
								align='right' 
								style={{ 
									background: '#fafafa',
									borderColor: '#ddd',
									top: 33
								}}
							>
								Importe
							</TableCell>
						</Fragment>
						<Fragment key='OutsAmout'>
							<TableCell 
								align='center' 
								style={{ 
									background: '#c9c9c9',
									borderColor: '#989898',
									top: 33
								}}
							>
								Cant.
							</TableCell>
							<TableCell 
								align='right' 
								style={{ 
									background: '#c9c9c9',
									borderColor: '#989898',
									top: 33
								}}
							>
								Importe
							</TableCell>
						</Fragment>
						<Fragment key='EndAmout'>
							<TableCell 
								align='center' 
								style={{ 
									background: '#fafafa',
									borderColor: '#ddd',
									top: 33
								}}
							>
								Cant.
							</TableCell>
							<TableCell 
								align='right' 
								style={{ 
									background: '#fafafa',
									borderColor: '#ddd',
									top: 33
								}}
							>
								Importe
							</TableCell>
						</Fragment>
					</TableRow>
				</TableHead>
				<TableBody>
					{ data && data.inventoryReport.rows.map((el, i) =>
							<TableRow key={i}>
								<TableCell>
									<Typography variant="body2" noWrap>
										{el.Product.name} ({el.name})
									</Typography>
								</TableCell>
								<Fragment key={`Start-${i}`}>
									<TableCell
										align='center' 
										style={{ 
											background: '#c9c9c9',
											borderColor:'#989898',
										}}
									>
										{el.Start.quantity}
									</TableCell>
									<TableCell
										align='right' 
										style={{ 
											background: '#c9c9c9',
											borderColor:'#989898',
										}}
									>
										{currencyFormat(el.Start.total)}
									</TableCell>
								</Fragment>
								<Fragment key={`Ins-${i}`}>
									<TableCell
										align='center' 
										style={{ 
											background: '#fafafa',
											borderColor:'#ddd',
										}}
									>
										{el.Ins.quantity}
									</TableCell>
									<TableCell
										align='right' 
										style={{ 
											background: '#fafafa',
											borderColor:'#ddd',
										}}
									>
										{currencyFormat(el.Ins.total)}
									</TableCell>
								</Fragment>
								<Fragment key={`Outs-${i}`}>
									<TableCell
										align='center' 
										style={{ 
											background: '#c9c9c9',
											borderColor:'#989898',
										}}
									>
										{el.Outs.quantity}
									</TableCell>
									<TableCell
										align='right' 
										style={{ 
											background: '#c9c9c9',
											borderColor:'#989898',
										}}
									>
										{currencyFormat(el.Outs.total)}
									</TableCell>
								</Fragment>
								<Fragment key={`End-${i}`}>
									<TableCell
										align='center' 
										style={{ 
											background: '#fafafa',
											borderColor:'#ddd',
										}}
									>
										{el.End.quantity}
									</TableCell>
									<TableCell
										align='right' 
										style={{ 
											background: '#fafafa',
											borderColor:'#ddd',
										}}
									>
										{currencyFormat(el.End.total)}
									</TableCell>
								</Fragment>
							</TableRow>
						)
					}
					<TableRow>
						<TableCell style={{ position: 'sticky', bottom: 0, background: '#fafafa', borderTop: '1px solid #ddd' }}>
							<Typography variant="body2" noWrap>
								<strong>TOTAL</strong>
							</Typography>
						</TableCell>
						<Fragment key='TotalStart'>
							<TableCell
								align='center' 
								style={{ 
									position: 'sticky', 
									bottom: 0, 
									background: '#a2a2a2',
									borderTop: '1px solid #ddd' 
								}}
							>
								{parseFloat(totals.Start.quantity).toFixed(2)}
							</TableCell>
							<TableCell
								align='right' 
								style={{ 
									position: 'sticky', 
									bottom: 0, 
									background: '#a2a2a2',
									borderTop: '1px solid #ddd' 
								}}
							>
								{currencyFormat(totals.Start.total)}
							</TableCell>
						</Fragment>
						<Fragment key='TotalIns'>
							<TableCell
								align='center' 
								style={{ 
									position: 'sticky', 
									bottom: 0, 
									background: '#c0c0c0',
									borderTop: '1px solid #ddd' 
								}}
							>
								{parseFloat(totals.Ins.quantity).toFixed(2)}
							</TableCell>
							<TableCell
								align='right' 
								style={{ 
									position: 'sticky', 
									bottom: 0, 
									background: '#c0c0c0',
									borderTop: '1px solid #ddd' 
								}}
							>
								{currencyFormat(totals.Ins.total)}
							</TableCell>
						</Fragment>
						<Fragment key='TotalOuts'>
							<TableCell
								align='center' 
								style={{ 
									position: 'sticky', 
									bottom: 0, 
									background: '#a2a2a2',
									borderTop: '1px solid #ddd' 
								}}
							>
								{parseFloat(totals.Outs.quantity).toFixed(2)}
							</TableCell>
							<TableCell
								align='right' 
								style={{ 
									position: 'sticky', 
									bottom: 0, 
									background: '#a2a2a2',
									borderTop: '1px solid #ddd' 
								}}
							>
								{currencyFormat(totals.Outs.total)}
							</TableCell>
						</Fragment>
						<Fragment key='TotalEnd'>
							<TableCell
								align='center' 
								style={{ 
									position: 'sticky', 
									bottom: 0, 
									background: '#c0c0c0',
									borderTop: '1px solid #ddd' 
								}}
							>
								{parseFloat(totals.End.quantity).toFixed(2)}
							</TableCell>
							<TableCell
								align='right' 
								style={{ 
									position: 'sticky', 
									bottom: 0, 
									background: '#c0c0c0',
									borderTop: '1px solid #ddd' 
								}}
							>
								{currencyFormat(totals.End.total)}
							</TableCell>
						</Fragment>
					</TableRow>
				</TableBody>
			</Table>
			<FilterDialog isOpen={isOpen} handleAction={handleAction} />
		</Fragment>
	);
}

export default Component;
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1, 0, 0, 0),
    padding: theme.spacing(0, 0.5, 0, 0.5),
    display: 'flex',
    overflowX: 'auto',
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, 0, 1, 0),
    },
  },
  container: {
    padding: theme.spacing(1),
    margin: theme.spacing(1, 0.5, 1, 0.5),
    minWidth: '120px',
  },
}));

export default useStyles;

import React from 'react';

import Container from '../../presentationals/Module.v2/Container';

import Filter from './_filter';
import GQL from './_gql';

const Rows = [
	{ label: 'Fecha', key: 'createdAt', sort: 'text', format: 'dateTime' },
	{ label: 'Folio', key: 'Transfer folio && Transfer serial', strcat: '-' },
	{ label: 'Producto', key: 'Product name', sort: 'text' },
	{ label: 'Clasificación', key: 'Product Clasification name', sort: 'text' },
	{ label: 'Cant.', key: 'quantity', sort: 'text', align: 'center' },
	{ label: 'Importe', key: 'total', sort: 'text', align: 'right', format: 'currency' },
	{ label: 'Origen', key: 'Transfer Branch name', align: 'center' },
	{ label: 'Destino', key: 'Transfer TransferBranch name', sort: 'text' },
	{ label: 'Responsable', key: 'Transfer User name', sort: 'text' },
];
const title = 'Detalle de traspaso';
const operation = ['transferProductDetails', 'transferProductDetails'];

const Component = () => {
	return (
		<>
			<Container
				title={title}
				operation={operation}
				Rows={Rows}
				get={GQL.GET}
				filter
				noDelete
			/>
			<Filter />
		</>
	);
};

export default Component;

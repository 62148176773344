import { gql } from '@apollo/client';

const GET = gql`
  query PurchaseOrderProducts(
    $order: [String], $limit: Int, $offset: Int, $clasificationId: ID, $supplierId: ID, $productId: ID, $start: Date, $end: Date
  ) {
    purchaseOrderProducts(
      order: $order, limit: $limit, offset: $offset, start: $start, end: $end,
      clasificationId: $clasificationId, supplierId: $supplierId, productId: $productId
    ) {
      count
      totals {
        id name qty amount
      }
      rows {
        id
        unitPrice
        subtotal
        discount
        quantity
        total
        createdAt
        Product {
          name
          Clasification {
            name
          }
        }
        PurchaseOrder {
          id
          folio
          serial
          Supplier {
            name
          }
        }
      }
    }
  }
`;

export default { GET };

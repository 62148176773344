import React from 'react';

import AddIcon from '@material-ui/icons/Add';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';

import useStyles from './style';

const LinearForm = props => {

	const classes = useStyles();

  const handleClick = e => {
    props.handleAdd(e, props.params);
  }

	return (
		<Grid container spacing={1} alignItems='center'>
      <Grid item xs={11}>
      	{ props.children }
      </Grid>
      <Grid item xs={1} align={ props.align || 'right' }>
        <div className={classes.wrapper}>
          <Fab 
            color={props.color || 'secondary'}
            size='small' 
            onClick={ handleClick }
            className={classes.addButton}
            disabled={props.loading && true}
          >
            {props.icon || <AddIcon fontSize={ props.size || "medium" } />}
          </Fab>
          { props.loading && <CircularProgress color='secondary' size={52} className={classes.fabProgress} /> }
        </div>
      </Grid>
    </Grid>
	);
}

export default LinearForm;
import { gql } from '@apollo/client';

const GET = gql`
  query ChipsCombination($start: Date $end: Date $productId: ID){
    chipsCombination(start: $start end: $end productId: $productId) {
      count
      rows {
        Combination
        Sales {
          Branch {
            name
          }
          qty
          total
        }      
      }
    }
  }
`;

const obj = { GET };
export default obj;
import React from 'react';

import Container from './../../presentationals/Module.v2/Container';

import Form from './_form';
import Filter from './_filter';
import GQL from './_gql';

const Rows = [
	{ label: 'Fecha', key: 'createdAt', format: 'date' },
	{ label: 'Folio', key: 'serial && folio', strcat: '-', sort: 'text' },
	{ label: 'Producto', key: 'Product name && ProductUnit name', strcat: ' ', sort: 'text' },
	{ label: 'Sucursal', key: 'Branch name', sort: 'text' },
	{ label: 'Responsable', key: 'User name', sort: 'text' },
	{ label: 'Cant.', key: 'quantity', sort: 'text', align: 'center' },
	{ label: 'Total', key: 'cost', sort: 'text', format: 'currency' },
];
const title = 'Mermas';
const operation = ['waste', 'wastes'];

const Component = () => {
	return (
		<>
			<Container
				title={title}
				operation={operation}
				Rows={Rows}
				get={GQL.GET}
				create={GQL.CREATE}
				filter
				noDelete
				noEdit
			/>
			<Filter />
			<Form operation={operation[1]} />
		</>
	);
};

export default Component;

import React, { useState, useEffect } from 'react';

import { useLazyQuery, useReactiveVar } from '@apollo/client';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import IconButton from './cart/table/helpers/iconButton';

import { requisitionProducts } from '../../apollo/cache';

import LinearForm from '../../presentationals/LinearForm';
import Autocomplete from '../../presentationals/Autocomplete';
import TextField from '../../presentationals/TextField';

import GQL_PRODUCT from '../reportProductInventory/_gql';

const defaultValues = {
	quantity: 0,
	productUnit: null,
};

const ExtraItems = props => {

  const requisitionProductsVar = useReactiveVar(requisitionProducts);
  
  const [values, setValues] = useState(defaultValues);
	const [products, setProducts] = useState([]);
	const [errors, setErrors] = useState({});

  const [getProducts, { data: dataProducts }] = useLazyQuery(GQL_PRODUCT.GET, { variables: { limit: 0 } });

  useEffect(() => {
		if (props.branchId) {
			setValues(defaultValues);
			setErrors({});
			getProducts({ variables: { limit: 0, branchId: props.branchId } });
		}
	}, [props.branchId]);

  useEffect(() => {
		if(dataProducts){
			const data = dataProducts.productInventories.rows.map((el,i) => {
        const tag = parseInt(el.companyId) === 1 ? '| NRC' : '| CDE';
        const label = `${el.name.toUpperCase()} ${tag}`;
				return {
					value: el.id,
					label,
					productId: el.productId,
				};
			}).filter(tmp => tmp);
			setProducts(data);
		}
	}, [dataProducts]);

  const handleAutocompleteChangeProduct = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAddProduct = () => {
    try{
  		if(!values.productUnit){
  			const e = new Error('Selecciona una unidad de medida.')
  			e.name = 'productUnit';
  			throw e; 
  		}
  		if(parseFloat(values.quantity) <= 0 || isNaN(parseFloat(values.quantity))){ 
  			const e = new Error('Proporciona la cantidad de producto.')
  			e.name = 'quantity';
  			throw e; 
  		}
      requisitionProducts([...requisitionProductsVar, {
        quantity: parseFloat(values.quantity),
  			productUnit: values.productUnit,
      }]);
  		setValues(defaultValues);
  		setErrors({});
  	}catch(e) {
  		setErrors({ [e.name]: e.message });
  	}
  };

  const handleDeleteItem = (e, position) => {
		const tmpCartItems = requisitionProductsVar
			.map((el, i) => (parseInt(i) !== parseInt(position)) && el)
			.filter(el => el);
    requisitionProducts(tmpCartItems);
	}

  return (
  <div>
    <LinearForm handleAdd={handleAddProduct} align='center'>
      <Grid container spacing={1}>
        <Grid item xs={12} md={10}>
          <Autocomplete 
            name='productUnit'
            label='Producto'
            variant='outlined'
            onChange={handleAutocompleteChangeProduct}
            options={products}
            value={values.productUnit}
            error={errors.productUnit}
            noFormat
            size='medium'
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField 
            name='quantity'
            label='Cant.'
            type='number' 
            variant='outlined'
            onChange={handleChange}
            value={values.quantity}
            error={errors.quantity}
            noFormat
            size='medium'
          />
        </Grid>
      </Grid>
    </LinearForm>
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Acciones</TableCell>
            <TableCell>Producto</TableCell>
            <TableCell align="center">Cant.</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            requisitionProductsVar.map((el, i) => 
              <TableRow key={i}>
                <TableCell>
                  <IconButton 
                    label='Eliminar'
                    icon={<DeleteOutlineIcon />}
                    params={i}
                    action={handleDeleteItem}
                    size='small'
                  />
                </TableCell>
                <TableCell>{el.productUnit.label}</TableCell>
                <TableCell align="center">{el.quantity}</TableCell>
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    </TableContainer>
  </div>
  );
};

export default ExtraItems;

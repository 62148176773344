import React, { Fragment, useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';

import Grid from '@material-ui/core/Grid';

import LinearForm from './../../../../presentationals/LinearForm';
import SelectField from './../../../../presentationals/SelectField';
import TextField from './../../../../presentationals/TextField';

import GQL from './../../_gql';

const defaultValues = {
	total: 0,
	paymentForm: '01',
}

const paymentFormOptions = [ 
  {value: "01", label: "Efectivo"},
  {value: "100", label: "Depósito"},
  {value: "02", label: "Cheque nominativo"},
  {value: "03", label: "Transferencia electrónica de fondos"},
  {value: "04", label: "Tarjeta de crédito"},
  {value: "05", label: "Monedero electrónico"},
  {value: "06", label: "Dinero electrónico"},
  {value: "15", label: "Condonación"},
  {value: "17", label: "Compensación"},
  {value: "23", label: "Novación"},
  {value: "28", label: "Tarjeta de débito"},
  {value: "30", label: "Aplicación de anticipos"},
  {value: "31", label: "Intermediario pagos"},
  {value: "99", label: "Por definir"}
];

const Form = props => {
	const [ values, setValues ] = useState(defaultValues);
	const [ errors, setErrors ] = useState({});
  
	const [ createSaleNotePaymant ] = useMutation(GQL.CREATE_SALE_NOTE_PAYMENT,{
    update(cache, { data }) {
      try{
        const oldQuery = cache.readQuery({ query: GQL.GET, variables: { customerId: props.customerId, paid: false } });
        const payment = parseFloat(data.createSaleNotePayment.total);
        const newQuery = oldQuery.saleNotes.rows.map(el => {
          if(parseInt(el.id) === parseInt(props.id)){
            const newPayment = parseFloat(el.payment) + payment;
            const newBalance = parseFloat(el.balance) - payment;
            return { ...el, balance: newBalance, payment: newPayment };
          }
          return el
        });
        cache.writeQuery({
          query: GQL.GET,
          variables: { customerId: props.customerId, paid: false },
          data: { saleNotes: { ...oldQuery.saleNotes, rows: newQuery } }
        });

        const oldQuery1 = cache.readQuery({ query: GQL.GET_CUSTOMER });
        const newQuery1 = oldQuery1.customerReceivable.rows.map(el => {
          if(parseInt(el.id) === parseInt(props.customerId)){
            const newPayment = parseFloat(el.payment) + payment;
            const newBalance = parseFloat(el.balance) - payment;
            return { ...el, payment: newPayment, balance: newBalance };
          }
          return el;
        });
        cache.writeQuery({
          query: GQL.GET_CUSTOMER,
          data: { customerReceivable: { ...oldQuery1.customerReceivable, rows: newQuery1 } }
        });
      }catch(e){
        console.log(e.message);
      }
    },
    onCompleted: data => {
      // console.log(data);
      props.getCart();
    }
  });

	useEffect(() => {
    setValues(defaultValues);
  }, [props.codeToClear]);

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAction = async e => {
  	try{
  		if(parseFloat(values.total) <= 0 ||  isNaN(parseFloat(values.total))){ 
  			const e = new Error('Proporciona un total mayor a cero.')
  			e.name = 'total';
  			throw e; 
  		}

  		if(!values.paymentForm){ 
  			const e = new Error('Selecciona una forma de pago.')
  			e.name = 'paymentForm';
  			throw e; 
  		}
  		
  		await createSaleNotePaymant({
        variables: { ...values, total: parseFloat(values.total), saleNoteId: props.id },
      });
  		setValues(defaultValues);
  		setErrors({});
  	}catch(e) {
  		setErrors({ [e.name]: e.message });
  	}
  	
  }

	return (
		<Fragment>
			<div>
				<LinearForm handleAdd={handleAction} align='center'>
					<Grid container spacing={1}>
						<Grid item xs={12} md={4}>
							<TextField 
				        name='total'
				        label='Abono'
				        type='number' 
				        variant='outlined'
				        onChange={handleChange}
				        value={values.total}
				        error={errors.total}
				        noFormat
				      />
						</Grid>
						<Grid item xs={12} md={8}>
							<SelectField 
				        name='paymentForm'
				        label='Forma de Pago' 
				        variant='outlined'
				        onChange={handleChange}
				        options={paymentFormOptions}
				        value={values.paymentForm}
				        error={errors.paymentForm}
                size='small'
				        noFormat
				      />
						</Grid>
					</Grid>
				</LinearForm>
			</div>
		</Fragment>
	);
}

export default Form;
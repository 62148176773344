import { gql } from '@apollo/client';

const GET = gql`
  query EventPackages($limit: Int $offset: Int $like: String){
    eventPackages(limit: $limit offset: $offset like: $like) {
      count
      rows {
        id
        name
        description
        price
        cost
        company
      }
    }
  }
`;

const GET_AUTOCOMPLETE = gql`
  query EventPackageAutocomplete($limit: Int $offset: Int $like: String){
    eventPackages(limit: $limit offset: $offset like: $like) {
      count
      rows {
        id
        name
        description
        price
        company
      }
    }
  }
`;

const GET_BY_ID = gql`
  query EventPackage($id: ID!){
    eventPackage(id: $id) {
      id
      name
      description
      price
      cost
      company
      EventPackageProducts {
        id
        quantity
        Product {
          id
          cost
          name
        }
        User {
          id
          name
        }
      }
    }
  }
`;

const DELETE = gql`
  mutation DeleteEventPackage($id: ID!) {
    deleteEventPackage(id: $id) {
      id
    }
  }
`;

const CREATE = gql`
  mutation CreateEventPackage(
    $name: String!
    $description: String!
    $price: Float!
    $company: Int!
  ) {
    createEventPackage(input: {
      name: $name
      description: $description
      price: $price
      company: $company
    }) {
      id
      name
      description
      price
      cost
      company
    }
  }
`

const UPDATE = gql`
  mutation UpdateEventPackage(
    $id: ID!
    $name: String!
    $description: String!
    $price: Float!
    $company: Int!
  ) {
    updateEventPackage(id: $id, input: {
      name: $name
      description: $description
      price: $price
      company: $company
    }) {
      id
      name
      description
      price
      cost
      company
    }
  }
`;

const CREATE_PRODUCT = gql`
  mutation AddProduct($quantity: Float!, $productId: ID!, $eventPackageId: ID!) {
    addProduct(input: {
      quantity: $quantity
      productId: $productId
      eventPackageId: $eventPackageId
    }){
      id
      name
      description
      price
      cost
      EventPackageProducts {
        id
        quantity
        Product {
          id
          cost
          name
        }
        User {
          id
          name
        }
      }
    }
  }
`;

const DELTE_PRODUCT = gql`
  mutation DeleteEventPackageProduct($id: ID!) {
    deleteEventPackageProduct(id: $id) {
      id
      name
      description
      price
      cost
      EventPackageProducts {
        id
        quantity
        Product {
          id
          cost
          name
        }
        User {
          id
          name
        }
      }
    }
  }
`;

const object = { GET, GET_BY_ID, DELETE, CREATE, UPDATE, GET_AUTOCOMPLETE, CREATE_PRODUCT, DELTE_PRODUCT };
export default object;
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { filter } from '../../apollo/cache';

const token = localStorage.getItem('token');

const Authenticated = ({ component: C, ...rest }) => {
	filter({});
	return (
		token 
			? <Route {...rest} render={props => <C {...props} />} /> 
			: <Redirect to="/public/login" />
	);
};

const Unauthenticated = ({ component: C, ...rest }) => {
	filter({});
  return (
  	<Route {...rest} render={ props => !token ? <C {...props} /> : <Redirect to="/" /> } />
  );
};

export { Authenticated, Unauthenticated };
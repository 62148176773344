import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import Autocomplete from './../../presentationals/Autocomplete';
import SelectField from './../../presentationals/SelectField';
import DateField from './../../presentationals/DateField';
import TextField from './../../presentationals/TextField';
import FormDialog from './../../presentationals/FormDialog';

import { parseError } from './../../helpers';
import { snackbar } from './../../apollo/cache';

import GQL from './_gql';
import GQL_SUPPLIER from './../supplier/_gql';

import Cart from './cart';

const defaultValues = {
	branchId: '',
	iva: 16,
	date: new Date(),
	physicalFolio: '',
	description: '',
	supplierId: null,
	paymentForm: '01',
	type: 2,
	subType: '',
	Products: [],
	form: 1,
	advance: null,
}

const typeOptions = [
	{ value: 2, label: 'Producción' },
	{ value: 3, label: 'Ventas' },
	{ value: 4, label: 'Administración' },
	{ value: 5, label: 'Otros' },
];

const ivaOptions = [
	{ value: 16, label: '16%' },
	{ value: 8, label: '8%' },
	{ value: 0, label: '0%' },
	{ value: -1, label: 'Exento' },
]

const paymentFormOptions = [ 
  {value: "01", label: "Efectivo"},
  {value: "100", label: "Depósito"},
  {value: "02", label: "Cheque nominativo"},
  {value: "03", label: "Transferencia electrónica de fondos"},
  {value: "04", label: "Tarjeta de crédito"},
  {value: "05", label: "Monedero electrónico"},
  {value: "06", label: "Dinero electrónico"},
  {value: "15", label: "Condonación"},
  {value: "17", label: "Compensación"},
  {value: "23", label: "Novación"},
  {value: "28", label: "Tarjeta de débito"},
  {value: "30", label: "Aplicación de anticipos"},
  {value: "31", label: "Intermediario pagos"},
  {value: "99", label: "Por definir"}
];

const formOptions = [ 
  {value: 1, label: "Contado"},
  {value: 2, label: "Crédito"},
];

const Component = props => {

	const [ values, setValues ] = useState(defaultValues);
	const [ codeToClear, setCodeToClear ] = useState(null);
	const [ suppliers, setSuppliers ] = useState([]);
	const [ subTypeOptions, setSubTypeOptions ] = useState([]);

	const [ createPurchase, { loading }] = useMutation(GQL.CREATE, {
		onCompleted: data => {
			if(data.createExpense && !data.createExpense.paid) {
				props.handleOpenPayment('', { id: data.createExpense.id, Supplier: { name: data.createExpense.Supplier.name }, folio: data.createExpense.physicalFolio });
			}
			if(props.handleCreate) {
				props.handleCreate(data);
			}
		}
	});
	useQuery(GQL_SUPPLIER.GET,{
		onCompleted: data => {
			const tmp = data.suppliers.rows.map(el => ({ value: el.id, label: el.name }));
			setSuppliers(tmp);
		}
	});

	useEffect(() => {
		const tmpOptions = getSubTypeOptions(values.type);
		setSubTypeOptions(tmpOptions.options);
		setValues(values => ({ ...values, subType: tmpOptions.defaultValue }));
	}, [values.type]);

	const handleClose = () => {
		setValues(defaultValues);
		setCodeToClear(null);
		props.handleClose();
	}

	const handleAction = async () => {
		try {
			const variables = {
				...values,
				type: props.isPurchase ? 1 : values.type,
				branchId: parseInt(values.branchId) !== '' ? parseFloat(values.branchId) : null,
				iva: parseInt(values.iva) !== -1 ? parseFloat(values.iva) : null,
				supplierId: values.supplierId.value,
				paid: values.paid ? true : false,
				advance: values.advance ? parseFloat(values.advance) : null,
				Products: values.Products.map(el => ({
					quantity: el.quantity,
					unitPrice: el.unitPrice,
					discount: el.discount,
					productId: props.isPurchase ? el.product.value : null,
					productUnitId: props.isPurchase ? el.productUnit.value : null,
					concept: !props.isPurchase ? el.concept : null
				}))
			};
			await createPurchase({ variables });
			setValues(defaultValues);
			setCodeToClear(null);
			props.handleClose();
		}catch(e) {
			console.log(e);
			const parseErrors = parseError(e);
      if(parseErrors[0].name === 'GENERAL_ERROR'){
      	snackbar({
	      	isOpen: true, 
	        time: 3000,
	        label: parseErrors[0].message, 
	        severity: 'error'
	      });
      }
		}
	}

	const getCart = data => {
    setValues(values => ({ ...values, Products: data }));
  }

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleDateChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }

	return (
		<FormDialog 
			isOpen={props.isOpen}
			isLoading={loading}
      title={props.isPurchase ? "Nueva Compra" : "Nueva CxP"}
      name="cart"
      singular="Cart"
      disableEscape
      width="md"
      handleClose={handleClose}
      handleAction={handleAction}
      rightContent={
      	<Grid container spacing={1}>
	        <Grid item xs={4}>
	          <SelectField 
	            noFormat
	            label='Cuenta'
	            name='type'
	            value={values.type}
	            onChange={handleChange}
	            options={typeOptions}
	            variant='outlined'
	            size='small'
	          />
	        </Grid>
	        <Grid item xs={4}>
	          <SelectField 
	            noFormat
	            label='Subcuenta'
	            name='subType'
	            value={values.subType}
	            onChange={handleChange}
	            options={subTypeOptions}
	            variant='outlined'
	            size='small'
	          />
	        </Grid>
					<Grid item xs={12} md={4}>
						<SelectField 
			        name='form'
			        label='Método Pago' 
			        variant='outlined'
			        onChange={handleChange}
			        options={formOptions}
			        value={values.form}
			        size='small'
			        noFormat
			      />
					</Grid>
				</Grid>
      }
		>	
			<Grid container spacing={1}>
				<Grid item xs={12} md={3}>
					<Autocomplete 
		        name='supplierId'
		        label='Proveedor' 
		        variant='outlined'
		        onChange={handleAutocompleteChange}
		        options={suppliers}
		        value={values.supplierId}
		        noFormat
		      />
				</Grid>
				<Grid item xs={12} md={3}>
					<DateField 
		        variant='outlined'
            name='date'
            value={values.date} 
            onChange={handleDateChange} 
            size='small'
            noFormat 
		      />
				</Grid>
				<Grid item xs={12} md={2}>
					<SelectField 
		        name='paymentForm'
		        label='Forma de Pago' 
		        variant='outlined'
		        onChange={handleChange}
		        options={paymentFormOptions}
		        value={values.paymentForm}
		        size='small'
		        noFormat
		      />
				</Grid>
				<Grid item xs={12} md={2}>
					<SelectField 
		        name='iva'
		        label='IVA' 
		        variant='outlined'
		        onChange={handleChange}
		        options={ivaOptions}
		        value={values.iva}
		        size='small'
		        noFormat
		      />
				</Grid>
				<Grid item xs={12} md={2}>
					<TextField 
		        name='physicalFolio'
		        label='Folio' 
		        variant='outlined'
		        onChange={handleChange}
		        value={values.physicalFolio}
		        noFormat
		      />
				</Grid>
			</Grid>
			<br/>
			<Divider/>
			<br/>
			<Cart
				isPurchase={props.isPurchase}
        getCart={getCart}
        iva={parseInt(values.iva) !== -1 ? parseInt(values.iva) : 0}
        codeToClear={codeToClear}
      />
      <br/>
      <Grid container spacing={1}>
			  <Grid item xs={12} md={12}>
					
				</Grid>
			</Grid>
		</FormDialog>
	);
}

function getSubTypeOptions(type){
	switch(parseInt(type)){
		case 2:
			return {
				options: [
					{ value: 1, label: 'SubCuentaProducción1' },
					{ value: 2, label: 'SubCuentaProducción2' },
					{ value: 3, label: 'SubCuentaProducción3' },
				], 
				defaultValue: 1
			};
		case 3:
			return {
				options: [
					{ value: 4, label: 'SubCuentaVentas4' },
					{ value: 5, label: 'SubCuentaVentas5' },
					{ value: 6, label: 'SubCuentaVentas6' },
				],
				defaultValue: 4
			};
		case 4:
			return {
				options: [
					{ value: 7, label: 'SubCuentaAdministración7' },
					{ value: 8, label: 'SubCuentaAdministración8' },
					{ value: 9, label: 'SubCuentaAdministración9' },
				],
				defaultValue: 7
			};
		case 5:
			return {
				options: [
					{ value: 10, label: 'SubCuentaOtros10' },
					{ value: 11, label: 'SubCuentaOtros11' },
					{ value: 12, label: 'SubCuentaOtros12' },
				],
				defaultValue: 10
			};
		default: 
			return {
				options: [],
				defaultValue: ''
			};
	}
}

export default Component;
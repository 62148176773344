import React, { useState } from 'react';

import { endOfDay } from 'date-fns';

import Typography from '@material-ui/core/Typography';

import FilterDrawer from './../../presentationals/FilterDrawer';

import DateField from './../../presentationals/DateField';
import SelectField from './../../presentationals/SelectField';

import { filter } from '../../apollo/cache';

const branchOptions = [
	{ label: 'TODAS', value: -1 },
	{ label: 'BODEGA NARACHIPS', value: 8 },
	{ label: 'BODEGA CASA DEL ELOTE', value: 24 },
	{ label: 'PRODUCCIÓN', value: 23 },
];

const defaultValues = {
	branchId: 8,
	date: endOfDay(new Date()),
};

const FilterDialog = props => {
	const [values, setValues] = useState(defaultValues);

	const handleClose = () => props.handleClose();
	const handleAction = (e, data) => {
		props.handleAction(values.branchId, values.date ? values.date : null);
		filter({ branchId: values.branchId, date: values.date ? values.date : null });
	}

	const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleDateChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

	return (
		<FilterDrawer 
			isOpen={props.isOpen} 
			handleClose={handleClose} 
			handleAction={handleAction}
		>
			<Typography variant="subtitle1">
        Fecha:
      </Typography>
			<DateField
        variant='outlined'
        name='date'
        value={values.date}
        onChange={handleDateChange}
        noFormat
      />
      <Typography variant="subtitle1">
        Sucursal:
      </Typography>
			<SelectField
        name='branchId'
        variant='outlined'
        label=''
        value={values.branchId}
        onChange={handleChange}
        options={branchOptions}
        noFormat
      />
		</FilterDrawer>
	);
}

export default FilterDialog;
import * as XLSX from 'xlsx';

const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre','TOTAL'];

const xls = (data, name) => {

	const filename = `${name}.xlsx`;
		
	const firstHeader = [
		'SUCURSALES',
		...months.reduce((array,el) => (
			[ ...array, el.toUpperCase(), '' ]
		),[])
	];	
	const secondHeader = [
		'',
		...data[0].Sales.reduce((array,el) => (
			[ ...array, "Venta" ]
		),[])
	];
	let index = 0;
	const merge = [
		{ s: { r:0, c:0 }, e: { r:1, c:0 } },
		...data[0].Sales.reduce((array,el,i) => {
			const tmp1 = index + 1;
			const tmp2 = index + 1;
			index += 2;
			return [ ...array, { s: { r: 0, c: tmp1 }, e: { r: 0, c: tmp2 } }]
		},[])
	];

	const dataRows = data.map(el => {
		const totals = el.Sales.reduce((array,tmp) => (
			[ ...array, parseFloat(tmp.royalty), parseFloat(tmp.totalSale) ]	
		),[]);
		return [ el.Branch.name.toUpperCase(), ...totals ] 
	}); 
	
	const rows = [
	  firstHeader,
	  secondHeader,
	  ...dataRows
	];
	
	const ws = XLSX.utils.aoa_to_sheet(rows);

	if(!ws['!merges']) ws['!merges'] = [];
	ws['!merges'] = merge;

	var wb = XLSX.utils.book_new();

	XLSX.utils.book_append_sheet(wb,ws,name);
	
	XLSX.writeFile(wb, filename);
}

export default xls;
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	paper: {
		padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 500,
    marginLeft: theme.spacing(5),
	},
	input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
	iconButton: {
    padding: 10,
  },
}));

export default useStyles;

import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import FormDialog from './../../presentationals/FormDialog';
import TextField from './../../presentationals/TextField';

import GQL from './_gql';

const defaultValues = {
	authCommentary: '',
}

const Component = props => {
	const [ values, setValues ] = useState(defaultValues);
	const [ errors, setErrors ] = useState({});

	const [ updateStatus, { loading } ] = useMutation(GQL.UPDATE_STATUS, {
		update(cache, { data }) {
			try{
				const oldQuery = cache.readQuery({ query: GQL.GET });
				const newQuery = oldQuery.cediPhysicalInventories.rows.map(el => {
					if(parseInt(el.id) === parseInt(data.updateCediPhysicalInventoryStatus.id)){
						return { ...el, status: 2 };
					}
					return el;
				});
				cache.writeQuery({
					query: GQL.GET,
					data: newQuery
				});
			}catch(e){
				console.log(e.message);
			}
		},
		onCompleted: () => handleClose()
	});
	const [ deletePhysicalInventory ] = useMutation(GQL.DELETE, {
		update(cache, { data }) {
			try{
				const oldQuery = cache.readQuery({ query: GQL.GET });
				cache.writeQuery({
					query: GQL.GET,
					data: { cediPhysicalInventories: {
						...oldQuery.cediPhysicalInventories,
						count: parseInt(oldQuery.cediPhysicalInventories.count) - 1
					} }
				});
				cache.evict({ id: `CediPhysicalInventory:${data.deleteCediPhysicalInventory.id}` });
				cache.gc();
			}catch(e){
				console.log(e.message);
			}
		},
		onCompleted: () => handleClose()
	});

	const handleClose = () => {
		props.handleClose();
		setValues(defaultValues);
	}

	const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAction = async () => {
  	try {
		if (values.authCommentary === '') {
			const tmpError = new Error('Escribe un comentario.');
			tmpError.name = 'authCommentary';
			tmpError.input = true;
			throw tmpError;
		}
  		parseInt(props.action) === 1
			? await updateStatus({ variables: { authCommentary: values.authCommentary, id: props.params.id, status: 2 } })
			: await deletePhysicalInventory({ variables: { id: props.params.id, authCommentary: values.authCommentary } });

  		handleClose();
  		setErrors({});
  	}catch(e) {
  		if(e.input) {
			setErrors({ [e.name]: e.message });
			return true;
		}
		console.log(e);
  	}
  }

  return (
		<FormDialog 
			isOpen={props.isOpen}
      title={`${parseInt(props.action) === 1 ? 'Autorizar' : 'Eliminar'} Inventario`}
      name="payment"
      singular="payment"
      disableEscape
      handleClose={handleClose}
      actionLabel={`${parseInt(props.action) === 1 ? 'Autorizar' : 'Eliminar'}`}
      handleAction={handleAction}
      isLoading={loading}
		>	
		<TextField
			name='authCommentary'
      label='Comentario'
      value={values.authCommentary}
      error={errors.authCommentary}
      onChange={handleChange}
      variant='outlined'
      noFormat
		/>
		</FormDialog>
	);
}

export default Component;
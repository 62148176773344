import { gql } from '@apollo/client';

const GET = gql`
	query CediPhysicalInventories(
		$limit: Int $offset: Int
		$start: Date $end: Date $status: [Int]
	){
		cediPhysicalInventories(
			start: $start end: $end status: $status limit: $limit offset: $offset
		) {
			count
			rows {
				id
				status
				folio
				serial
				createdAt
				Branch {
					name
				}
				User {
					name
				}
			}
		}
	}
`;

const CREATE = gql`
	mutation CreateCediPhysicalInventory(
		$description: String!
		$Products: [CediInventoryDetailData]
		$branchId: ID
	) {
		createCediPhysicalInventory(input: {
			description: $description
			Products: $Products
			branchId: $branchId
		}) {
			id
			status
			folio
			serial
			createdAt
			User {
				name
			}
		}
	}
`;

const GET_BY_ID = gql`
	query CediPhysicalInventory($id: ID!){
		cediPhysicalInventory(id: $id) {
			id
			CediPhysicalInventoryDetails {
				id
				quantity
				Product {
					name
				}
				ProductUnit {
					name
				}
			}
			description
		}
	}
`;

const DELETE = gql`
	mutation DeleteCediPhysicalInventory($id: ID!, $authCommentary: String){
		deleteCediPhysicalInventory(id: $id, authCommentary: $authCommentary) {
			id
			status
			folio
			serial
			createdAt
			User {
				name
			}
		}
	}
`;

const UPDATE_STATUS = gql`
	mutation UpdateCediPhysicalInventoryStatus($id: ID!, $status: Int!, $authCommentary: String){
		updateCediPhysicalInventoryStatus(id: $id, status: $status, authCommentary: $authCommentary) {
			id
			status
			folio
			serial
			createdAt
			User {
				name
			}
		}
	}
`;

const object = { CREATE, GET, GET_BY_ID, DELETE, UPDATE_STATUS }

export default object;

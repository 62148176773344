import { makeStyles } from '@material-ui/core/styles';
import background from "./../../files/back.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${background})`,
    minHeight: "100vh",
    margin: 0
  },
  paper: {
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%',
    padding: theme.spacing(3, 5, 3, 5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
    }
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    height: 45,
    borderRadius: 40,
    backgroundColor: '#2ab859',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#26a851',
      boxShadow: 'none',
    }
  },
  box: { 
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  logo: {
    width: '50%',
    margin: 0,
    marginBottom: theme.spacing(2)
  },
  createAccount: {
    marginTop: theme.spacing(0.5)
  },
  title: {
    fontWeigth: 'bold',
    fontSize: 20,
    letterSpacing: -1,
    lineHeight: 1,
    marginBottom: theme.spacing(1),
    color: '#666'
  }
}));

export default useStyles;
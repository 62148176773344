import React, { Fragment, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import FormDialog from './../../presentationals/FormDialog';

import GQL from './_gql';

const Component = props => {
	const [ getInventory, { data }] = useLazyQuery(GQL.GET_BY_ID,{
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		props.id && getInventory({ variables: { id: props.id } });
	},[props.id]); // eslint-disable-line

	const handleClose = () => props.handleClose();

	return (
		<FormDialog 
			isOpen={props.isOpen}
      title={`Resumen de Ajuste (${props.extraData.folio})`}
      name="adjustment"
      singular="Adjustment"
      disableEscape
      handleClose={handleClose}
      hideAction
		>	
			<Fragment>
				<TableContainer>
					<Table size="small">
						<TableHead>
							<TableRow>
		            <TableCell>Producto</TableCell>
		            <TableCell align="center">Ajuste</TableCell>
		          </TableRow>
		        </TableHead>
		        <TableBody>
		        	{
		        		(data && data.cediPhysicalInventory) && data.cediPhysicalInventory.CediPhysicalInventoryDetails.map((el, i) => 
		      				<TableRow style={{ background: parseFloat(el.difference) >= 3 ? '#ffebeb' : '#fff' }} key={i} >
				            <TableCell>{`${el.Product.name} ${el.ProductUnit.name}`}</TableCell>
				            <TableCell align="center">{el.quantity}</TableCell>
		      				</TableRow>
		      			)
		        	}
		        </TableBody>
					</Table>
				</TableContainer>
				<br/>
				<Divider/>
	      <Grid container spacing={1}>
				  <Grid item xs={12} md={12}>
				  	<Typography variant="subtitle1">
			        Comentarios del ajuste de inventario:
			      </Typography>
						<Typography variant="subtitle2">
							{ (data && data.cediPhysicalInventory) && data.cediPhysicalInventory.description }
			      </Typography>
					</Grid>
				</Grid>
			</Fragment>
		</FormDialog>
	);
}

export default Component;
import React, { useEffect, useState } from 'react';

import { useMutation, useLazyQuery } from '@apollo/client';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import FormDialog from './../../presentationals/FormDialog';
import TextField from './../../presentationals/TextField';
import SelectField from './../../presentationals/SelectField';
import Button from './../../presentationals/Button';

import { parseError } from './../../helpers';
import { snackbar } from './../../apollo/cache';

import GQL_PRODUCT from '../reportProductInventory/_gql.js';

import GQL from './_gql';

const defaultValues = {
	description: '',
	Products: [],
	branchId: 8,
};

const FormComponent = props => {
	const [products, setProducts] = useState([]);
	const [values, setValues] = useState(defaultValues);
	const [errors, setErrors] = useState({});

	const [getProducts, { data: dataProducts }] = useLazyQuery(GQL_PRODUCT.GET, { variables: { limit: 0 } });

	const [createCediPhysicalInventory, { loading }] = useMutation(GQL.CREATE, {
		update(cache, { data }) {
			try{
				const oldQuery = cache.readQuery({ query: GQL.GET });
				cache.writeQuery({
          query: GQL.GET,
          data: { cediPhysicalInventories: {
          	count: parseInt(oldQuery.cediPhysicalInventories.count) + 1,
          	rows: [ data.createCediPhysicalInventory, ...oldQuery.cediPhysicalInventories.rows ]
          } }
        });
			}catch(e){
				console.log(e.message);
			}
		},
		onCompleted: data => {
			props.handleCreate && props.handleCreate(data);
		}
	});

	useEffect(() => {
		if (values.branchId) {
			const companyId = null;
			getProducts({ variables: { limit: 0, branchId: values.branchId, companyId } });
		}
	}, [values.branchId, getProducts]);

	useEffect(() => {
		if(dataProducts) {
			const data = dataProducts.productInventories.rows.map((el,i) => {
				const tmpInventory = el.Inventories.find(elCh => parseInt(elCh.Branch.id) === parseInt(values.branchId));
				const quantity = tmpInventory ? parseFloat(tmpInventory.quantity) : 0;
				const tag = parseInt(el.companyId) === 1 ? '| NRC' : '| CDE';
        const label = `${el.name.toUpperCase()} ${tag}`;
				return { 
					id: el.id,
					productId: el.productId,
					name: label,
					quantity,
				}
			}).filter(tmp => tmp);
			setProducts(data);

			const tmpValues = dataProducts.productInventories.rows.map(el => {
				const tmpInventory = el.Inventories.find(elCh => parseInt(elCh.Branch.id) === parseInt(values.branchId));
				const quantity = tmpInventory ? parseFloat(tmpInventory.quantity) : 0;
				const unitPrice = tmpInventory.unitCost;
				return {
					quantity,
					productId: el.productId,
					productUnitId: el.id,
					unitPrice,
				}
			});
			setValues(values => ({ ...values, Products: tmpValues }))
		}
	},[dataProducts, values.branchId]);

	const handleAction = async e => {
		try {
			const Products = values.Products.map((el, i) => {
				if(parseInt(el.productUnitId) > 0){
					return {
						quantity: !isNaN(parseFloat(el.quantity)) ? parseFloat(el.quantity) : 0,
						productId: el.productId,
						productUnitId: el.productUnitId,
						unitPrice: !isNaN(parseFloat(el.unitPrice)) ? parseFloat(el.unitPrice) : 0,
					}
				} 
				return false;
			}).filter(el => el);

			const variables = {
				description: values.description,
				branchId: values.branchId,
				Products
			};
			await createCediPhysicalInventory({ variables });
			props.handleClose();
			setErrors({});
		}catch(e) {
			if(e.input) {
				setErrors({ [e.name]: e.message });
				return true;
			}
			const parseErrors = parseError(e);
      if(parseErrors[0].name === 'GENERAL_ERROR'){
      	snackbar({
	      	isOpen: true, 
	        time: 3000,
	        label: parseErrors[0].message, 
	        severity: 'error'
	      });
      }
		}
	}

	const handleClose = () => {
		setValues(defaultValues);
		props.handleClose();
	}

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleElementChange = (name, value, id) => {
		const tmpProducts = values.Products.map(el => {
			if(parseInt(el.productUnitId) === parseInt(id)){
				return { 
					...el, 
					quantity: name === 'quantity' ? value : el.quantity,
					unitPrice: name === 'unitPrice' ? value : el.unitPrice
				}
			}
			return el;
		});
		setValues(values => ({ ...values, Products: tmpProducts }));
  }

  const handleReset = () => {
  	const tmp = values.Products.map(el => ({ ...el, quantity: 0, unitPrice: 0 }))
  	setValues(values => ({ ...values, Products: tmp }));
  }

	return (
		<FormDialog 
			isOpen={props.isOpen}
			isLoading={loading}
      title='Ajuste de Inventario CEDI'
      name="cart"
      singular="Cart"
      disableEscape
      width="md"
      handleClose={handleClose}
      handleAction={handleAction}
      rightContent={
      	<Grid container spacing={1}>
      		<Grid item md={3} xs={false} />
      		<Grid item md={6} xs={12}>
      			<SelectField
							label=''
							name='branchId'
							value={values.branchId}
							options={[
								{ label: 'BODEGA NARACHIPS', value: 8 },
								{ label: 'BODEGA CASA DEL ELOTE', value: 24 },
								{ label: 'PRODUCCIÓN', value: 23 },
							]}
							noFormat
							size='small'
							variant='outlined'
							onChange={handleChange}
						/>
      		</Grid>
      		<Grid item md={3} xs={12}>
		        <Button 
		      		label='Resetear'
		      		variant='outlined'
		      		onClick={handleReset}
		      		color='secondary'
		      	/>
			    </Grid>
			  </Grid>
      }
		>
			<Grid container spacing={1}>
				<Grid item xs={6}><strong>Producto</strong></Grid>
				<Grid item xs={3}><strong>Cant. Ajuste</strong></Grid>
				{<Grid item xs={3}><strong>Precio Unitario</strong></Grid>}
			</Grid>
			{
				products.map((el, i) => {
					return (
						<Element
							key={el.id}
							id={el.id}
							name={el.name}
							values={values.Products.find(elCh => parseInt(elCh.productUnitId) === parseInt(el.id))}
							onChange={handleElementChange}
						/>
					);
				}).filter(tmp => tmp)
			}
			<br/>
			<Divider />
			<br/>
			<TextField 
	      name='description'
	      label='Añadir comentarios'
	      variant='outlined'
	      onChange={handleChange}
	      value={values.description}
	      error={errors.description}
	      multiline
	      rows={2}
	      noFormat
	    />
		</FormDialog>
	)
}

const Element = props => {
	const handleChange = e => {
		const name = e.target.name;
		const value = e.target.value;
		props.onChange(name, value, props.id);
	}
	return (
		<Grid container alignItems='center' spacing={1}>
			<Grid item xs={6}>{props.name}</Grid>
			<Grid item xs={3}>
				<TextField
					label=''
					name='quantity'
					value={props.values ? props.values.quantity : ''}
					noFormat
					type='number'
					variant='outlined'
					onChange={handleChange}
				/>
			</Grid>
			<Grid item xs={3}>
				<TextField
					label=''
					name='unitPrice'
					value={props.values ? props.values.unitPrice : ''}
					noFormat
					type='number'
					variant='outlined'
					onChange={handleChange}
				/>
			</Grid>
		</Grid>
	);
}

export default FormComponent;
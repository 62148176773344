import React, { useState } from 'react';

import Typography from '@material-ui/core/Typography';

import FilterDrawer from '../../presentationals/FilterDrawer';

import SelectField from '../../presentationals/SelectField';

import { snackbar } from '../../apollo/cache';

const cityOptions = [
	{ value: -1, label: 'Todas las ciudades' },
	{ value: 'CULIACÁN', label: 'Culiacán' },
	{ value: 'MAZATLÁN', label: 'Mazatlán' },
	{ value: 'LOS MOCHIS', label: 'Los Mochis' },
	{ value: 'HERMOSILLO', label: 'Hermosillo' },
]

const defaultValues = {
	city: -1
}


const FilterDialog = props => {
	const [ values, setValues ] = useState(defaultValues);
	const [ errors, setErrors ] = useState({});

	const handleClose = () => {
		props.handleAction('close');
	}

	const handleAction = (e, data) => {
		try {
			const { startDate: start, endDate: end } = data;
			const branchId = parseInt(data.branchId) > 0 ? data.branchId : null;
			const companyId = parseInt(data.companyId) > 0 ? data.companyId : null;
			if(branchId && values.city !== '') {
				const e = new Error('No se puede colocar una sucursal y una ciudad');
				e.name = 'city';
  			throw e; 
			}
			const owner = parseInt(data.branchId) < -1
				? parseInt(data.branchId) === -2
					? true
					: false
				: null;
			props.handleAction(null, { 
				branchId,
				companyId,
				owner,
				start,
				end,
				city: parseInt(values.city) < 0 ? '' : values.city
			});
			setErrors({});
		}catch(e) {
			setErrors({ [e.name]: e.message });
			snackbar({
        isOpen: true, 
        time: 3000,
        label: e.message, 
        severity: 'error'
      });
		}
	}

	const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

	return (
		<FilterDrawer 
			isOpen={props.isOpen} 
			handleClose={handleClose} 
			handleAction={handleAction}
			showCompanies
			showBranches
			showDates
		>
			<Typography variant="subtitle1">
        Ciudad:
      </Typography>
			<SelectField
        name='city'
        variant='outlined'
        label=''
        value={values.city}
        error={errors.city}
        onChange={handleChange}
        options={cityOptions}
        noFormat
      />
		</FilterDrawer>
	);
}

export default FilterDialog;
import React from 'react';

import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  typography: props => ({
    color: props.color || '#000'
  })
}));

function Copyright(props) {
  
  const classes = useStyles({ color: props.color });

  return (
    <Typography variant="body2" className={classes.typography} align="center">
      {'Copyright © '}
      <Link color="inherit" href="#">
        NaraChips
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );

}

export { Copyright };
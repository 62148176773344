import React from 'react';

import { useLazyQuery } from '@apollo/client';

import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';

import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';

import { actionForm, deleteForm } from './../../../apollo/cache';

import IconButton from './../../IconButton';
import BodyCell from './../helpers/BodyCell';
import format from './../helpers/Format';

import useStyles from './style';

const Component = props => {

	const classes = useStyles();
	
	const [ getById ] = useLazyQuery(props.getById, {
		fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
		onCompleted: data => {
			const variables = {
	      isOpen: true, 
	      action: 'update',
	      defaultData: data[props.operation]
	    };
	    actionForm(variables);
    }
	});

	const handleOpenEdit = (e, params) => {
		getById({ variables: params });
	}

  const handleOpenDelete = (e, params) => {
  	deleteForm({ isOpen: true, params });
  }

  const __function = (e, { onClick, ...params }) => {
  	if(typeof onClick === 'function') {
  		onClick(e, params);
  	}else if(typeof onClick === 'object') {
  		const tmpKey = Object.keys(onClick).reduce((string, key) => {
  			const _split = key.split(':');
  			const tmpKey = _split[0];
  			const tmpValue = _split[1];
  			return (tmpValue === `${params[tmpKey]}`) ? key : string;
  		}, '');
  		onClick[tmpKey](e, params);
  	}
  }

	return (
		<TableBody>
			{ props.records && props.records.map(data => {
				console.log(data);
				return (
				<TableRow key={data.id} tabIndex={-1} hover
					className={data.background ? classes[data.background] : classes.regular} 
				>
					{ props.Rows.map(el => {
						const value = el.key.includes(' ') 
							? el.key.split(' ').reduce((string, elCh) => {
								const tmpData = string[elCh] || el.ifNull || '-';
								return tmpData;
							}, data)
							: data[el.key];
						return (
							<BodyCell key={el.key} align={el.align} noWrap={!el.wrap} format={el.format} >
								{ format(value, el.format) }
							</BodyCell>
						)
					})}
					<BodyCell align="right" noWrap>
						{
							props.actions && props.actions.map(el =>
								((!el.hide || !el.hide.value.includes(`${data[el.hide.key]}`)) && (!el.hide2 || !el.hide2.value.includes(`${data[el.hide2.key]}`))) &&
									<IconButton 
										key={ el.label }
					      		label={ el.label }
					      		disabled={ 
					      			(el.disable && el.disable.value && `${data[el.disable.key]}` === el.disable.value) 
					      			|| (el.disable && el.disable.valueIsNot && `${data[el.disable.key]}` !== el.disable.valueIsNot) 
					      				? true : false
					      		}
					      		action={ __function } 
					      		params={ { id: data.id, ...el.params, ...data, onClick: el.onClick } }
					      		className={ classes.actionButton }
					      		icon={ el.icon }
					      	/>
				      )
						}
						{
							!props.noEdit && (
								<IconButton 
				      		label="Editar" 
				      		action={ handleOpenEdit } 
				      		params={ { id: data.id, ...props.extraParams } }
				      		className={ classes.actionButton }
				      		icon={<EditIcon fontSize="small" />}
				      	/>
				      )

						}
						{
							!props.noDelete && (
				      	<IconButton 
				      		label="Eliminar" 
				      		action={ handleOpenDelete } 
				      		params={ { id: data.id, ...data } }
				      		className={ classes.actionButton }
				      		icon={<DeleteIcon fontSize="small" />}
				      	/>
				      )
						}
  				</BodyCell>
				</TableRow>
			)})}
		</TableBody>
	);
}

export default Component;
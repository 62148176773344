import React, { Fragment, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import Grid from '@material-ui/core/Grid';

import LinearForm from './../../../../presentationals/LinearForm';
import Autocomplete from './../../../../presentationals/Autocomplete';
import TextField from './../../../../presentationals/TextField';

import GQL_PRODUCT from './../../../product/_gql';

const defaultValues = {
	quantity: 0,
	productUnit: null,
	unitPrice: 0,
	discount: 0,
	productId: null,
	concept: '',
}

const Form = props => {
	const [ values, setValues ] = useState(defaultValues);
	const [ products, setProducts ] = useState([]);
	const [ productUnits, setProductUnits ] = useState([]);
	const [ errors, setErrors ] = useState({});

	const { data: productsData } = useQuery(GQL_PRODUCT.GET, { variables: { limit: 0 } });

	useEffect(() => {
		if(productsData && productsData.products){
			const tmpProducts = productsData.products.rows.map(el => ({ value: el.id, label: el.name, cost: el.cost, ProductUnits: el.ProductUnits }));
			setProducts(tmpProducts);
		}
	}, [productsData]);

	useEffect(() => {
    setValues(defaultValues);
  }, [props.codeToClear]);

	const handleAutocompleteChangeProduct = (e, name, value) => {
		const unitPrice = (value && value.cost) ? value.cost : 0;
		const tmp = (value && value.ProductUnits)
			? value.ProductUnits.map(el => ({ value: el.id, label: el.name }))
			: [];
		setProductUnits(tmp);
    setValues(values => ({ ...values, [name]: value, unitPrice, productUnit: null }));
  }

	const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAddProduct = e => {
  	try{

  		if(props.isPurchase && !values.productId){ 
  			const e = new Error('Selecciona un producto.')
  			e.name = 'productId';
  			throw e; 
  		}

  		if(!props.isPurchase && !values.concept){ 
  			const e = new Error('Esciba un producto.')
  			e.name = 'name';
  			throw e; 
  		}

  		if(parseFloat(values.quantity) <= 0 || isNaN(parseFloat(values.quantity))){ 
  			const e = new Error('Proporciona la cantidad de producto.')
  			e.name = 'quantity';
  			throw e; 
  		}

  		if(parseFloat(values.discount) < 0){ 
  			const e = new Error('Proporciona un descuento mayor o igual a cero.')
  			e.name = 'discount';
  			throw e; 
  		}

  		if(parseFloat(values.unitPrice) < 0 ||  isNaN(parseFloat(values.unitPrice))){ 
  			const e = new Error('Proporciona un precio unitario mayor o igual a cero.')
  			e.name = 'unitPrice';
  			throw e; 
  		}

  		if(props.isPurchase && !values.productUnit){ 
  			const e = new Error('Selecciona una unidad de medida.')
  			e.name = 'productUnit';
  			throw e; 
  		}
  		
  		props.handleAdd({
  			product: values.productId,
  			concept: values.concept,
  			quantity: parseFloat(values.quantity),
  			discount: parseFloat(values.discount),
  			unitPrice: parseFloat(values.unitPrice),
  			productUnit: values.productUnit,
  		});

  		setValues(defaultValues);
  		setErrors({});
  	}catch(e) {
  		setErrors({ [e.name]: e.message });
  	}
  	
  }

	return (
		<Fragment>
			<div>
				<LinearForm handleAdd={handleAddProduct} align='center'>
					<Grid container spacing={1}>
						<Grid item xs={12} md={props.isPurchase ? 3 : 6}>
						{
							props.isPurchase ?
								<Autocomplete 
					        name='productId'
					        label='Producto' 
					        variant='outlined'
					        onChange={handleAutocompleteChangeProduct}
					        options={products}
					        value={values.productId}
					        error={errors.productId}
					        noFormat
					      />
					    :
					    	<TextField 
					        name='concept'
					        label='Concepto'
					        variant='outlined'
					        onChange={handleChange}
					        value={values.concept}
					        error={errors.concept}
					        noFormat
					      />
						}
							
						</Grid>
						{
							props.isPurchase &&
							<Grid item xs={12} md={3}>
								<Autocomplete 
					        name='productUnit'
					        label='Unidad' 
					        variant='outlined'
					        onChange={handleAutocompleteChange}
					        options={productUnits}
					        value={values.productUnit}
					        error={errors.productUnit}
					        noFormat
					      />
							</Grid>
						}
						<Grid item xs={12} md={2}>
							<TextField 
				        name='quantity'
				        label='Cant.'
				        type='number' 
				        variant='outlined'
				        onChange={handleChange}
				        value={values.quantity}
				        error={errors.quantity}
				        noFormat
				      />
						</Grid>
						<Grid item xs={12} md={2}>
							<TextField 
				        name='discount'
				        label='Dscto. %'
				        type='number' 
				        variant='outlined'
				        onChange={handleChange}
				        value={values.discount}
				        error={errors.discount}
				        disabled={props.costSettings ? true : false}
				        noFormat
				      />
						</Grid>
						<Grid item xs={12} md={2}>
							<TextField 
				        name='unitPrice'
				        label={'P. Unitario'}
				        type='number' 
				        variant='outlined'
				        onChange={handleChange}
				        value={values.unitPrice}
				        error={errors.unitPrice}
				        noFormat
				      />
						</Grid>
					</Grid>
				</LinearForm>
			</div>
		</Fragment>
	);
}

export default Form;
import React, { useState, useEffect } from 'react';

import Typography from '@material-ui/core/Typography';

import FilterDrawer from './../../presentationals/FilterDrawer';

import SelectField from './../../presentationals/SelectField';

import { filter } from '../../apollo/cache';

const presentationOptions = [
	{ value: 1, label: '1 Lt' },
	{ value: 2, label: '1/2 Lt' },
]

const litersOptions = [
	{ value: 22, label: 'NARACHIPS' },
	{ value: 31, label: 'SALSA NEGRA' },
	{ value: 33, label: 'SALSEADAS' },
]

const halfLitersOptions = [
	{ value: 21, label: 'NARACHIPS' },
	{ value: 30, label: 'SALSA NEGRA' },
	{ value: 32, label: 'SALSEADAS' },
]

const defaultValues = {
	presentation: 1,
	productId: ''
}

const FilterDialog = props => {
	const [ values, setValues ] = useState(defaultValues)
	const [ options, setOptions ] = useState([])

	useEffect(() => {
		setValues(values => ({ ...values, productId: '' }));
		setOptions(values.presentation === 1 ? litersOptions : halfLitersOptions);
	},[values.presentation]);

	const handleClose = () => {
		props.handleAction('close');
	}

	const handleAction = (e, data) => {
		const { startDate: start, endDate: end } = data;
		const productId = !isNaN(values.productId) ? values.productId : null;
		props.handleAction(null, { 
			productId,
			start,
			end,
		});
		filter({ productId, start, end });
	}

	const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

	return (
		<FilterDrawer 
			isOpen={props.isOpen} 
			handleClose={handleClose} 
			handleAction={handleAction}
			showDates
		>
			<Typography variant="subtitle1">
        Presentación
      </Typography>
			<SelectField
        name='presentation'
        variant='outlined'
        label=''
        value={values.presentation}
        onChange={handleChange}
        options={presentationOptions}
        noFormat
      />
			<Typography variant="subtitle1">
        Producto
      </Typography>
			<SelectField
        name='productId'
        variant='outlined'
        label='Producto'
        value={values.productId}
        onChange={handleChange}
        options={options}
        noFormat
      />
		</FilterDrawer>
	);
}

export default FilterDialog;
import React, { useState, useEffect, Fragment } from 'react';

import { useQuery } from '@apollo/client';

import StoreIcon from '@material-ui/icons/StoreOutlined';
import ContactPhoneIcon from '@material-ui/icons/ContactPhoneOutlined';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import PaymentIcon from '@material-ui/icons/PaymentOutlined';

import Module from './../../presentationals/Module';

import GQL from './_gql';
// import GQL_BRANCH from './../branch/_gql';

import FilterDialog from './filter.dialog';

const Rows = [
	{ label: 'Tipo', key: 'type', sort: 'text', format: 'supplierType' },
	{ label: 'Nombre', key: 'name', sort: 'text' },
	{ label: 'RazónSocial', key: 'businessName', sort: 'text' },
	{ label: 'Contacto', key: 'contact', sort: 'text' },
	{ label: 'Telefono', key: 'phone', sort: 'text' },
	{ label: 'Banco', key: 'bank', sort: 'text' },
	{ label: 'NoCta', key: 'accountName', sort: 'text' },
	{ label: 'CLABE', key: 'clabe', sort: 'text' },
];

const typeOptions = [
	{ value: 1, label: 'Servicio' },
	{ value: 2, label: 'Insumos' },
	{ value: 3, label: 'Mantenimiento' },
	{ value: 4, label: 'Publicidad' },
	{ value: 5, label: 'Otros' },
]

const Component = props => {

	const [ isOpen, setIsOpen ] = useState(false);
	const [ filter, setFilter ] = useState({});

	const { data, loading, refetch } = useQuery(GQL.GET);

	useEffect(() => {
		if(Object.keys(filter).length > 0){
			refetch(filter);
		}
	}, [filter, refetch]);

	const handleRows = (offset, limit) => {
		setFilter(filter => ({ ...filter, limit, offset }));
	}

	const handleFilter = () => {
		setIsOpen(true);
	}

	const handleAction = (action, data) => {
		switch(action){
			case 'close':
				setIsOpen(false);
				break;
			default:
				setFilter(filter => ({ ...filter, ...data }));
				break;
		}
	}

	const handleSearch = (value) => {
		setFilter(filter => ({ ...filter, like: value }));
	}
	
	return (
		<Fragment>
			<Module
				moduleCode='CAPR02'
				isLoading={loading}
				name='Proveedores'
				singular='Proveedor'
				operation={['supplier', 'suppliers']}
				Rows={Rows}
				get={GQL.GET}
				handleRows={handleRows}
				getById={GQL.GET_BY_ID}
				create={GQL.CREATE}
				update={GQL.UPDATE}
				delete={GQL.DELETE}
				records={data && data.suppliers}
				filter={handleFilter}
				handleSearch={handleSearch}
				fields={[
					{ 
						icon: <StoreIcon />,
						field: 'select',
						name: 'type', 
						label: 'Tipo de Proveedor',
						options: typeOptions
					},
					{ 
						name: 'name', 
						label: 'Nombre Comercial',
					},
					{ 
						name: 'businessName', 
						label: 'Razón Social'
					},
					{ 
						icon: <ContactPhoneIcon />,
						name: 'contact', 
						label: 'Contacto',
					},
					{ 
						name: 'phone', 
						label: 'Teléfono'
					},
					{ 
						icon: <HomeIcon />,
						name: 'addressLine1', 
						label: 'Calle y Número'
					},
					{ 
						name: 'addressLine2', 
						label: 'Colonia'
					},
					{ 
						name: 'city', 
						label: 'Ciudad'
					},
					{ 
						name: 'zipCode', 
						label: 'C.P.'
					},
					{ 
						name: 'stateId', 
						label: 'Estado',
						defaultValue: 1,
						show: true,
					},
					{ 
						name: 'countryId', 
						label: 'Estado',
						defaultValue: 1,
						show: true,
					},
					{ 
						icon: <PaymentIcon />,
						name: 'bank', 
						label: 'Nombre del Banco'
					},
					{ 
						name: 'accountName', 
						label: 'Número de Cuenta'
					},
					{ 
						name: 'account', 
						label: 'Beneficiario de la Cuenta'
					},
					{ 
						name: 'clabe', 
						label: 'CLABE'
					},
				]}
			/>
			<FilterDialog isOpen={isOpen} handleAction={handleAction} />
		</Fragment>
	);
}

export default Component;
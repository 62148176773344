import { gql } from '@apollo/client';

const GET = gql`
  query BranchSales($branchId: ID $month: Int $year: Int $companyId: Int){
    branchSales(branchId: $branchId month: $month year: $year companyId: $companyId) {
      count
      rows {
        Branch {
          name
        }
        Sales {
          date
          totalSale
          cost
        }
      }
    }
  }
`;

const obj = { GET };
export default obj;
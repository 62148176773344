import React, { useState } from 'react';

import Typography from '@material-ui/core/Typography';

import FilterDrawer from './../../presentationals/FilterDrawer';
import SelectField from './../../presentationals/SelectField';

import { filter } from '../../apollo/cache';

const defaultValues = {
	status: -1
}

const statusOptions = [
	{ value: -1, label: 'TODAS'},
	{ value: true, label: 'SALDADAS'},
	{ value: false, label: 'NO SALDADAS'},
];

const FilterDialog = props => {

	const [ values, setValues ] = useState(defaultValues);

	const handleClose = () => {
		props.handleAction('close');
	}

	const handleAction = (e, data) => {
		const { startDate: start, endDate: end } = data;
		const paid = parseInt(values.status) === -1 ? null : values.status;
		props.handleAction(null, { paid, start, end });
		filter({ start, end, paid });
	}

	const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

	return (
		<FilterDrawer 
			isOpen={props.isOpen} 
			handleClose={handleClose} 
			handleAction={handleAction}
			showDates
		>	
			<Typography variant="subtitle1">
        Estatus de CxC:
      </Typography>
			<SelectField
        name='status'
        variant='outlined'
        label=''
        value={values.status}
        onChange={handleChange}
        options={statusOptions}
        noFormat
      />
		</FilterDrawer>
	);
}

export default FilterDialog;
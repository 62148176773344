import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import FormDialog from './../../presentationals/FormDialog';

import GQL from './_gql';

const Component = props => {

	const [ getBranchOrder, { data } ] = useLazyQuery(GQL.GET_BY_ID,{ fetchPolicy: 'network-only' });

	useEffect(() => {
		props.id && getBranchOrder({ variables: { id: props.id } });
	},[props.id]); // eslint-disable-line

	const handleClose = () => props.handleClose();

	return (
		<FormDialog 
			isOpen={props.isOpen}
      title={`Resumen de Orden (${props.extraData.folio})`}
      name="adjustment"
      singular="Adjustment"
      disableEscape
      width='md'
      handleClose={handleClose}
      hideAction
		>	
			<TableContainer>
				<Table size="small">
					<TableHead>
						<TableRow>
	            <TableCell>Producto</TableCell>
	            <TableCell>Clasificación</TableCell>
	            <TableCell align="center">Solicitado</TableCell>
	          </TableRow>
	        </TableHead>
	        <TableBody>
	        	{
	        		(data && data.branchOrder) && data.branchOrder.BranchOrderDetails.map((el, i) => {
	        			return (
		      				<TableRow key={i}
		      				>
				            <TableCell>{el.Product.name}</TableCell>
				            <TableCell>{el.Product.Clasification.name}</TableCell>
				            <TableCell align="center">{`${el.baseUnitQuantity || 0} (${el.BaseUnit ? el.BaseUnit.name : '-'})`}</TableCell>
		      				</TableRow>
		      			)
	        		})
	        	}
	        </TableBody>
				</Table>
			</TableContainer>
		</FormDialog>
	);
}

export default Component;
import React, { Fragment, useState } from 'react';

import Form from './form';
import Table from './table';

const Cart = props => {

	const [ cartItems, setCartItems ] = useState([]);

	const addItemToCart = data => {
		const total = parseFloat(data.quantity) * parseFloat(data.unitPrice);
		const tmpDiscount = parseFloat(total) * (parseFloat(data.discount) / 100);
		setCartItems(cartItems => ([ ...cartItems, { ...data, total, tmpDiscount } ]));
		props.getCart([ ...cartItems, { ...data, total, tmpDiscount } ]);
	};

	const removeItemFromCart = position => {
		const tmpCartItems = cartItems
			.map((el, i) => (parseInt(i) !== parseInt(position)) && el)
			.filter(el => el);
		setCartItems(tmpCartItems);
		props.getCart(tmpCartItems);
	}

	return (
		<Fragment>
			<Form isPurchase={props.isPurchase} handleAdd={addItemToCart} codeToClear={props.codeToClear} />
			<Table
				iva={props.iva}
				isPurchase={props.isPurchase}
				cartItems={cartItems}
				removeItem={removeItemFromCart}
				codeToClear={props.codeToClear}
			/>
		</Fragment>
	);
}

export default Cart
import React, { useEffect, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import TextField from './../../presentationals/TextField';

import FormDialog from '../../presentationals/FormDialog';

import GQL from './_gql';

const defaultValues = {
  Products: [],
};

const ConfirmComponent = props => {

  const [values, setValues] = useState(defaultValues);
  const [confirm, setConfirm] = useState(false);
  const [products, setProducts] = useState([]);
  const [others, setOthers] = useState([]);
  
  const [ authPurchaseOrder, { loading } ] = useMutation(GQL.AUTH_PURCHASE_ORDER);
  const [ getPurchaseOrder, { data, loading: loadingProducts } ] = useLazyQuery(GQL.GET_BY_ID,{ fetchPolicy: 'network-only' });
  
  useEffect(() => {
    if (props.params && parseInt(props.params) > 0) {
      getPurchaseOrder({ variables: { id: props.params }});
    }
  }, [props.params]);
  
  useEffect(() => {
    if (data && data.purchaseOrder) {
      const defaultProducts = data.purchaseOrder.PurchaseOrderDetails.map(el => ({
        id: el.id,
        quantity: el.quantity,
      }));
      setValues(values => ({ ...values, Products: defaultProducts }));

      const productsTmp = data.purchaseOrder.PurchaseOrderDetails.filter(tmp => tmp.Product);
      setProducts(tmp => productsTmp);
      const othersTmp = data.purchaseOrder.PurchaseOrderDetails.filter(tmp => !tmp.Product);
      setOthers(tmp => othersTmp);
    }
  }, [data]);

  const handleClose = () => {
    props.handleClose();
  };
  const handleAction = async () => {
    try {
      if (!confirm) {
        setConfirm(true);
        return
      }
      const Products = values.Products.map(el => ({
        purchaseOrderDetailId: el.id,
        quantity: isNaN(parseFloat(el.quantity)) ? 0 : parseFloat(el.quantity),
      }));
      await authPurchaseOrder({ variables: { id: props.params, Products } });
      handleClose();
      setConfirm(false);
    } catch(e) {
      console.log(e);
    }
  };
  const handleElementChange = (name, value, id) => {
    const tmpProducts = values.Products.map(el => {
      if(parseInt(el.id) === parseInt(id)){
        return { ...el, quantity: name === 'quantity' ? value : el.quantity };
      }
      return el;
    });
    setValues(values => ({ ...values, Products: tmpProducts }));
  };

  return (
  <FormDialog 
    isOpen={props.isOpen}
    isLoading={loadingProducts || loading}
    title='Confirmar'
    name="cart"
    singular="Cart"
    disableEscape
    width="md"
    handleClose={handleClose}
    handleAction={handleAction}
    actionLabel={confirm ? 'Confirmar' : 'Guardar'}
  >
    {(products && products.length > 0) && (
      <>
        <Typography variant="subtitle1" display="block">
          <strong>PRODUCTOS DE ALMACÉN</strong>
        </Typography>
        <br/>
        <Grid container spacing={1}>
          <Grid item xs={6}><strong>Producto</strong></Grid>
          <Grid item xs={2}><strong>Unidad</strong></Grid>
          <Grid item xs={2} align='center'><strong>Cant. Solicitada</strong></Grid>
          <Grid item xs={2}><strong>Recibida</strong></Grid>
        </Grid>
        {
          (products) && (
            products.map(el => (
              <Element
                key={el.id}
                id={el.id}
                unit={el.ProductUnit ? el.ProductUnit.name : null}
                name={el.Product ? el.Product.name : el.concept}
                quantity={el.quantity}
                values={values.Products.find(elCh => parseInt(elCh.id) === parseInt(el.id))}
                onChange={handleElementChange}
              />
            ))
          )
        }
        <br/>
      </>
    )}
    {(others && others.length > 0) && (
      <>
        <Typography variant="subtitle1" display="block">
          <strong>OTROS</strong>
        </Typography>
        <br/>
        <Grid container spacing={1}>
          <Grid item xs={6}><strong>Producto</strong></Grid>
          <Grid item xs={2} />
          <Grid item xs={2} align='center'><strong>Cant. Solicitada</strong></Grid>
          <Grid item xs={2} align='center'><strong>Recibida</strong></Grid>
        </Grid>
        {
          (others) && (
            others.map(el => (
              <Element
                key={el.id}
                id={el.id}
                unit={el.ProductUnit ? el.ProductUnit.name : null}
                name={el.Product ? el.Product.name : el.concept}
                quantity={el.quantity}
                values={values.Products.find(elCh => parseInt(elCh.id) === parseInt(el.id))}
                onChange={handleElementChange}
              />
            ))
          )
        }
      </>
    )}
  </FormDialog>
  )
};

const Element = props => {
  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    props.onChange(name, value, props.id);
  }
  return (
    <Grid container alignItems='center' spacing={1}>
      <Grid item xs={6}>{props.name}</Grid>
      <Grid item xs={2}>{props.unit || ''}</Grid>
      <Grid item xs={2} align='center'>{props.quantity}</Grid>
      {props.unit 
        ? <Grid item xs={2}>
            <TextField
              label=''
              name='quantity'
              value={props.values ? props.values.quantity : ''}
              noFormat
              type='number'
              variant='outlined'
              onChange={handleChange}
            />
          </Grid>
      : <Grid item xs={2} align='center'>{props.quantity}</Grid>}
    </Grid>
  );
}

export default ConfirmComponent;

import { gql } from '@apollo/client';

const GET = gql`
  query ProductMovementLogs(
    $start: Date, $end: Date, $limit: Int, $offset: Int, $productId: ID, $branchId: ID
  ) {
    productMovementLogs(
      start: $start, end: $end limit: $limit, offset: $offset, productId: $productId, branchId: $branchId
    ) {
      count
      rows {
        id
        description
        createdAt
        input
        output
        balance
        Product {
          name
        }
        ProductUnit {
          name
        }
      }
    }
  }
`;

const object = {
  GET
};

export default object;

import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import Typography from '@material-ui/core/Typography';

import FilterDrawer from './../../presentationals/FilterDrawer';
import SelectField from './../../presentationals/SelectField';
import GQL_PAYMENTMETHOD from './../paymentMethod/_gql';
import { filter } from '../../apollo/cache';

const defaultValues = {
	paymentMethodId: -2
}

const FilterDialog = props => {
	const [ values, setValues ] = useState(defaultValues);
	const [ paymentMethods, setPaymentMethods ] = useState([]);

	const handleClose = () => {
		props.handleAction('close');
	}

	const { data } = useQuery(GQL_PAYMENTMETHOD.GET);

	useEffect(() => {
		if(data) {
			const tmp = [ { value: -2, label: 'TODOS' }, { value: -1, label: 'EFECTIVO' }, ...data.paymentMethods.rows.map(el => ({ value: el.id, label: el.name.toUpperCase() }))];
			setPaymentMethods(tmp);
		}
	},[data])

	const handleAction = (e, data) => {
		const { startDate: start, endDate: end } = data;
		const branchId = parseInt(data.branchId) > 0 ? data.branchId : null;
		const companyId = parseInt(data.companyId) > 0 ? data.companyId : null;
		const paymentMethodId = parseInt(values.paymentMethodId) !== -2 ? values.paymentMethodId : null; 
		props.handleAction(null, { 
			branchId,
			companyId,
			start,
			end,
			paymentMethodId
		});
		filter({ branchId, companyId, start, end, paymentMethodId });
	}

	const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

	return (
		<FilterDrawer 
			isOpen={props.isOpen} 
			handleClose={handleClose} 
			handleAction={handleAction}
			showBranches
			showCompanies
			showDates
		>
			<Typography variant="subtitle1">
        Formas de Pago
      </Typography>
			<SelectField
        name='paymentMethodId'
        variant='outlined'
        label=''
        value={values.paymentMethodId}
        onChange={handleChange}
        options={paymentMethods}
        noFormat
      />
    </FilterDrawer>
	);
}

export default FilterDialog;
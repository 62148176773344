import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';

import FormDialog from './../../presentationals/FormDialog';
// import Button from './../../presentationals/Button';

// import DownloadXLS from './xls.document';
// import DownloadPDF from './pdf.a4';

import GQL from './_gql';

const Component = props => {
	const [ getRequisition, { data }] = useLazyQuery(GQL.GET_BY_ID,{
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		props.id && getRequisition({ variables: { id: props.id } });
	},[props.id]); // eslint-disable-line

	const handleClose = () => props.handleClose();

	// const downloadXLS = () => {
	// 	props.id && DownloadXLS({ id: props.id });
	// }

 //  const downloadPDF = () => {
	// 	props.id && DownloadPDF({ id: props.id });
	// }

	return (
		<FormDialog 
			isOpen={props.isOpen}
			title={`Requisición (${props.extraData.folio})`}
      name="ProductRequisition"
      singular="Requisition"
      width='md'
      disableEscape
      handleClose={handleClose}
      hideAction
		>	
			<TableContainer>
				<Table size="small">
					<TableHead>
						<TableRow>
	            <TableCell>Producto</TableCell>
	            <TableCell>Clasificación</TableCell>
	            <TableCell>Unidad</TableCell>
	            <TableCell align='center'>Mín</TableCell>
	            <TableCell align='center'>Máx</TableCell>
	            <TableCell align="center">Solicitado</TableCell>
	            <TableCell align="center">InvActual</TableCell>
	            <TableCell align="center">InvFuturo</TableCell>
	          </TableRow>
	        </TableHead>
	        <TableBody>
	        	{
	        		(data && data.requisition) && data.requisition.ProductRequisitions.map((el, i) => {
	        			if (el.quantity !== 0) {
		        			const minimum = el.Product.BranchInventoryBases.length > 0 ? parseFloat(el.Product.BranchInventoryBases[0].minimum) : 0;
		        			const maximum = el.Product.BranchInventoryBases.length > 0 ? parseFloat(el.Product.BranchInventoryBases[0].maximum) : 0;
		        			const currentInventory = el.Product.BranchInventoryBases.length > 0 ? parseFloat(el.Product.BranchInventoryBases[0].quantity) : 0;
		        			const futureInventory = parseFloat(el.quantity) + currentInventory;
		        			return (
			      				<TableRow 
			      					style={{ 
			      						background: (parseFloat(futureInventory) < maximum && parseFloat(futureInventory) > minimum) ? '#fff' : '#ffebeb' 
			      					}} 
			      					key={i}
			      				>
					            <TableCell>{el.Product.name}</TableCell>
					            <TableCell>{el.Product.Clasification.name}</TableCell>
					            <TableCell>{el.ProductUnit.name}</TableCell>
					            <TableCell align='center'><strong>{minimum}</strong></TableCell>
					            <TableCell align='center'><strong>{maximum}</strong></TableCell>
					            <TableCell align="center">{el.quantity}</TableCell>
					            <TableCell align="center">{currentInventory}</TableCell>
					            <TableCell align="center"><strong>{futureInventory}</strong></TableCell>
			      				</TableRow>
			      			)
	        			} else {
	        				return(<></>)
	        			}
	        		})
	        	}
	        </TableBody>
				</Table>
			</TableContainer>
			<br/>
			{
        (data && data.requisition.description) && (
          <Grid container spacing={1}>
            <Grid item xs={12}><strong>Comentarios</strong></Grid>
            <Grid item xs={12}>{data.requisition.description}</Grid>
          </Grid>
        )
      }
		</FormDialog>
	);
}

export default Component;
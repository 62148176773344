import React from 'react';

import FilterDrawer from './../../presentationals/FilterDrawer';

import { filter } from './../../apollo/cache';

const FilterDialog = props => {
	const handleClose = () => {
		props.handleAction('close');
	}

	const handleAction = (e, data) => {
		try {
			const { startDate: start, endDate: end } = data;
			const branchId = parseInt(data.branchId) > 0 ? data.branchId : null;
			props.handleAction(null, { 
				branchId,
				start,
				end,
			});
			filter({ branchId, start, end });
		}catch(e) {
			console.log(e);
		}
	}

	return (
		<FilterDrawer 
			isOpen={props.isOpen} 
			handleClose={handleClose} 
			handleAction={handleAction}
			showBranches
			showDates
		/>
	);
}

export default FilterDialog;
import React from 'react';

import FilterDrawer from './../../presentationals/FilterDrawer';

import { filter } from '../../apollo/cache';

const FilterDialog = props => {

	const handleClose = () => {
		props.handleAction('close');
	}

	const handleAction = (e, data) => {
		const { startDate: start, endDate: end } = data;
		props.handleAction(null, { start, end });
		filter({ start, end });
	}

	return (
		<FilterDrawer
			isOpen={props.isOpen}
			handleClose={handleClose}
			handleAction={handleAction}
			showDates
		/>
	);
}

export default FilterDialog;
import { gql } from '@apollo/client';

const GET = gql`
  query Events($status: [Int] $start: Date $end: Date $limit: Int $offset: Int $like: String){
    events(status: $status start: $start end: $end limit: $limit offset: $offset like: $like) {
      count
      rows {
        id
        folio
        serial
        date
        description
        price
        total
        cost
        background
        EventPackage {
          name
        }
        Customer {
          name
          phone
        }
        SaleNote {
          total
          SaleNotePayments {
            total
          }
        }
      }
    }
  }
`;

const GET_AUTOCOMPLETE = gql`
  query EventsAuto{
    events {
      rows {
        id
        folio
        serial
      }
    }
  }
`;

const GET_BY_ID = gql`
  query Event($id: ID!){
    event(id: $id) {
      id
      price
      discount
      date
      description
      customerId
    }
  }
`;

const GET_BY_ID_PDF = gql`
  query Event($id: ID!){
    event(id: $id) {
      id
      price
      discount
      date
      address
      createdAt
      description
      folio
      serial
      total
      EventPackage {
        name
        description
      }
      Customer {
        name
        phone
      }
      SaleNote {
        total
        SaleNotePayments {
          total
        }
      }
      Employee {
        name
      }
      Employee1 {
        name
      }
    }
  }
`;

const DELETE = gql`
  mutation DeleteEvent($id: ID!) {
    deleteEvent(id: $id) {
      id
    }
  }
`;

const CREATE = gql`
  mutation CreateEvent(
    $date: Date!
    $address: String
    $eventPackageId: ID!
    $customerId: ID!
    $employeeId: ID!
    $employeeId1: ID!
    $advance: Float!
    $paymentForm: String!
    $discount: Float!
    $description: String
  ) {
    createEvent(input: {
      eventPackageId: $eventPackageId
      advance: $advance
      paymentForm: $paymentForm
      discount: $discount
      date: $date
      address: $address
      description: $description
      customerId: $customerId
      employeeId: $employeeId
      employeeId1: $employeeId1
    }) {
      id
      folio
      serial
      date
      description
      price
      total
      EventPackage {
        name
      }
      Customer {
        name
        phone
      }
      SaleNote {
        total
        SaleNotePayments {
          total
        }
      }
    }
  }
`

const DELTE_EVENT_PAYMENT = gql`
  mutation DeleteEventPayment($id: ID!) {
    deleteEventPayment(id: $id) {
      id
      folio
      serial
      paymentForm
      subtotal
      taxes
      total
      eventId
      createdAt
    }
  }
`

const CREATE_EVENT_PAYMENT = gql`
  mutation CreateEventPayment(
    $total: Float!
    $paymentForm: String!
    $eventId: ID!
  ) {
    createEventPayment(input: {
      total: $total
      paymentForm: $paymentForm
      eventId: $eventId
    }) {
      id
      folio
      serial
      paymentForm
      subtotal
      taxes
      total
      eventId
      createdAt
    }
  }
`

const UPDATE_EVENT_STATUS = gql`
  mutation UpdateEventStatus(
    $id: ID!
    $status: Int!
  ) {
    updateEventStatus(
      id: $id
      status: $status
    ) {
      id
    }
  }
`

const object = { GET, GET_BY_ID, DELETE, CREATE, GET_BY_ID_PDF, DELTE_EVENT_PAYMENT, CREATE_EVENT_PAYMENT, UPDATE_EVENT_STATUS, GET_AUTOCOMPLETE };
export default object;
import React, { useState, useEffect } from "react";
import { format } from "date-fns";

import { useMutation, gql, useQuery } from '@apollo/client';

import FormDialog from './../FormDialog';
import TextField from './../TextField';
import SelectField from './../SelectField';
import Autocomplete from './../Autocomplete';
import DateField from './../DateField';
import DateTimeField from './../DateTimeField';

import { form, actionForm, snackbar } from './../../apollo/cache';
import { parseError } from './../../helpers';

const defaultSnackbar = { isOpen: true, time: 3000 };

const GET_LOCAL = gql`
  query ActionForm {
    actionForm @client
  }
`;

const dataActionForm = actionForm(); 

const ActionForm = props => { 

  const [ values, setValues ] = useState({});
  const [ errors, setErrors ] = useState({});

  const { data } = useQuery(GET_LOCAL);

  const [ mutation ] = useMutation(
    data.actionForm.action === 'update' ? props.update : props.create,
    {
      update(cache, { data: response }) {
        try{
          if(data.actionForm.action === 'update') return false;

          const dataResponse = response[Object.keys(response)[0]];
          
          const oldQuery = cache.readQuery({ query: props.get, variables: { ...props.cacheVariables } });
          
          cache.writeQuery({
            query: props.get,
            variables: { ...props.cacheVariables },
            data: { 
              [props.operation]: { 
                ...oldQuery[props.operation],
                count: oldQuery[props.operation].count + 1, 
                rows: [ dataResponse, ...oldQuery[props.operation].rows ] 
              }
            }
          });
        }catch(e){
          console.log(e.message);
        }
      },
      onCompleted: response => {
        props.handleAction && props.handleAction(response[Object.keys(response)[0]]);
      }
    }
  );

  useEffect(() => {
    const tmpValues = getDefaultValues(props.fields);
    setValues(tmpValues);
  }, []); // eslint-disable-line

  //Send data to local state
  useEffect(() => {
    if(Object.keys(values).length > 0) {
      const tmp = Object.keys(values).reduce((object, key) => {
        const tmpProps = props.fields.find(el => (el.name === key && el.localState === true));
        const tmpValue = tmpProps && { [key]: values[key] };
        return { ...object, ...tmpValue };
      }, {});
      form(tmp);
    }
  }, [values]); // eslint-disable-line

  //Data from server
  useEffect(() => {
    if(Object.keys(data.actionForm.defaultData).length > 0){
      setValues(values => ({ ...values, ...data.actionForm.defaultData }));
    }
  }, [data.actionForm.defaultData]); 

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleDateChange = (name, value) => {
    const tmpValue = format(value, 'yyyy/MM/dd')
    setValues(values => ({ ...values, [name]: tmpValue }));
  }

  const handleDateTimeChange = (name, value) => {
    const tmpValue = format(value, 'yyyy/MM/dd HH:mm')
    setValues(values => ({ ...values, [name]: tmpValue }));
  }

  // const handleCheckBox = (el, name) => {
  //   const tmpValue = values[name] ? { ...values[name], [el]: !values[name][el] } : { [el]: true };
  //   setValues(values => ({ ...values, [name]: tmpValue }));
  // }

  const handleCloseForm = () => {
    form({});
    actionForm({ ...dataActionForm, isOpen: false, action: '', defaultData: {} });
    const tmpValues = getDefaultValues(props.fields);
    setValues(tmpValues);
    setErrors({});
  }

  const handleAction = async () => {
    try {
      
      const usedKeys = Object.keys(values);
      const variables = props.fields.reduce((newArray, el) => (
        { 
          ...newArray, 
          [el.name]: usedKeys.includes(el.name) 
            ? parseFormat(values[el.name], el.type || el.field)
            : defaultFormat(el.type || el.field) 
        }
      ), {});

      await mutation({ 
        variables: data.actionForm.action === 'update' 
          ? { ...variables, id: parseInt(values.id) }
          : { ...variables, ...props.params } 
      });
      
      snackbar({ ...defaultSnackbar, label: 'Operación realizada con éxito.' });
      handleCloseForm();
    } catch(e) {
      console.log(e);
      const parseErrors = parseError(e);
      parseErrors.forEach(el => {
        if(el.name === 'BAD_USER_INPUT') {
          setErrors(el.message);
        } else {
          snackbar({ ...defaultSnackbar, label: el.message, severity: 'error' });
        }
      });
    }
  }
  
  return (
    <FormDialog
      title={`${data.actionForm.action === 'update' ? 'Actualizar' : 'Almacenar'} ${props.singular}`}
      actionLabel={data.actionForm.action === 'update' && 'Actualizar'}
      isOpen={data.actionForm.isOpen}
      isLoading={false}
      handleClose={handleCloseForm}
      handleAction={handleAction}
    >
      { 
        props.fields && props.fields.map(({ localState, type, noShowOnUpdate, defaultValue, ...el }) => {
          if(noShowOnUpdate && data.actionForm.action === 'update') {
            return false;
          }

          if(Object.keys(values).find(key => key === el.noShow)) {
            return false;
          }      

          if(!el.show || Object.keys(values).find(key => key === el.show)){
            switch(el.field){
              case 'select': return (
                <SelectField 
                  key={el.name}
                  name={el.name}
                  label={el.label}
                  icon={el.icon}
                  value={(['boolean', 'number', 'string'].includes(typeof values[el.name]) && el.options.length > 0) ? values[el.name] : ''}
                  onChange={handleChange}
                  options={el.options}
                  error={errors[el.name]}
                  { ...el }
                />
              )
              case 'date': return (
                <DateField 
                  key={el.name}
                  name={el.name}
                  label={el.label}
                  icon={el.icon}
                  value={values[el.name] || new Date()}
                  onChange={handleDateChange}
                  options={el.options}
                  error={errors[el.name]}
                  variant={el.variant || 'standard'}
                  { ...el }
                />
              )
              case 'dateTime': return (
                <DateTimeField
                  key={el.name}
                  name={el.name}
                  label={el.label}
                  icon={el.icon}
                  value={values[el.name] || new Date()}
                  onChange={handleDateTimeChange}
                  options={el.options}
                  error={errors[el.name]}
                  variant={el.variant || 'standard'}
                  { ...el }
                />
              )
              case 'autocomplete': return (
                <Autocomplete 
                  key={el.name}
                  name={el.name}
                  label={el.label}
                  icon={el.icon}
                  value={values[el.name] || null}
                  onChange={handleAutocompleteChange}
                  options={el.options}
                  error={errors[el.name]}
                  { ...el }
                />
              )
              default: return (
                <TextField 
                  type={type || 'text'}
                  key={el.name}
                  icon={el.icon}
                  name={el.name}
                  label={el.label}
                  value={(['number', 'string'].includes(typeof values[el.name])) ? values[el.name] : ''}
                  onChange={handleChange}
                  error={errors[el.name]}
                  multiline={el.multiline || false}
                  { ...el }
                />
              )
            }
          }
          
          return false;

        })
      }
    </FormDialog>
    
  )
}

function defaultFormat(format) {
  switch(format){
    case 'number':
      return 0;
    case 'boolean':
      return false;
    case 'ID':
    case 'autocomplete':
      return null;
    default:
      return '';
  }
}

function parseFormat(value, format) {
  switch(format){
    case 'number': 
      return !isNaN(parseFloat(value)) ? parseFloat(value) : 0;
    case 'ID':
      return value !== '' ? value : null;
    case 'autocomplete':
      return value.value;
    default:
      return value;
  }
}

function getDefaultValues(fields) {
  const tmp = fields.reduce((newArray, el) => (
      el.defaultValue 
        ? { ...newArray, [el.name]: el.defaultValue } 
        : newArray
    ), {});
  
  return tmp;
}

export default ActionForm;
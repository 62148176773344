import React, { useState } from 'react';

import { useMutation } from '@apollo/client';

import Grid from '@material-ui/core/Grid';

import LinearForm from './../../../presentationals/LinearForm';
import TextField from './../../../presentationals/TextField';

import GQL from './../_gql';

const defaultValues = {
	name: '',
	price: ''
}

const Form = props => {

	const [ values, setValues ] = useState(defaultValues);
	const [ errors, setErrors ] = useState({});

	const [ createMeasure, { loading } ] = useMutation(GQL.CREATE_MEASURE, {
		update(cache, { data }) {
      try{        
        const oldQuery = cache.readQuery({ query: GQL.GET_MEASURES, variables: { id: props.id } });
        cache.writeQuery({
          query: GQL.GET_MEASURES,
          variables: { id: props.id },
          data: {
            product: {
              "__typename":"Product",
              Measures: [
                data.createProductMeasure,
                ...oldQuery.product.Measures
              ]
            }
          }
        });
      }catch(e){
        console.log(e.message);
      }
    }
	});

	const handleAction = async () => {
		try{
  		if(!values.name || values.name.trim() === ''){ 
  			const e = new Error('El nombre es obligatorio.')
  			e.name = 'name';
  			throw e; 
  		}
  		if(!values.price || values.price === ''){ 
  			const e = new Error('El precio es obligatorio.')
  			e.name = 'price';
  			throw e; 
  		}
  		await createMeasure({ variables: { id: props.id, name: values.name, price: parseFloat(values.price) } })
  	}catch(e) {
  		setErrors({ [e.name]: e.message });
  	}
	}

	const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

	return (
		<LinearForm loading={loading} handleAdd={handleAction} align='center'>
			<Grid container spacing={1}>
				<Grid item xs={8}>
          <TextField 
            name='name'
            label='Nombre'
            variant='outlined'
            onChange={handleChange}
            value={values.name}
            error={errors.name}
            size='small'
            noFormat
            autoFocus
          />
        </Grid>
        <Grid item xs={4}>
          <TextField 
            name='price'
            label='Precio'
            variant='outlined'
            onChange={handleChange}
            value={values.price}
            error={errors.price}
            size='small'
            type='number'
            noFormat
          />
        </Grid>
			</Grid>
		</LinearForm>
	);
}

export default Form;
import React, { Fragment, useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import Grid from '@material-ui/core/Grid';

import { parseError } from './../../../../helpers';
import { snackbar } from './../../../../apollo/cache';

import LinearForm from './../../../../presentationals/LinearForm';
import Autocomplete from './../../../../presentationals/Autocomplete';
import TextField from './../../../../presentationals/TextField';

import GQL_PRODUCT from './../../../reportProductInventory/_gql';
import GQL from './../../_gql';

const defaultValues = {
	quantity: 1,
	productUnit: null,
	unitPrice: 0,
	discount: 0,
}

const Form = props => {
	const [values, setValues] = useState(defaultValues);
	const [products, setProducts] = useState([]);
	const [errors, setErrors] = useState({});

	const { data: dataProducts } = useQuery(GQL_PRODUCT.GET, { variables: { limit: 0, branchId: 8 } });
	const [ addProductOrder ] = useMutation(GQL.ADD_PRODUCT_ORDER);

	useEffect(() => {
		if(dataProducts){
			const data = dataProducts.productInventories.rows.map((el,i) => {
				const tag = parseInt(el.companyId) === 1 ? '| NRC' : '| CDE';
        const label = `${el.name.toUpperCase()} ${tag}`;
				return {
					value: el.id,
					label,
					productId: el.productId,
				};
			}).filter(tmp => tmp);
			setProducts(data);
		}
	}, [dataProducts]);

	useEffect(() => {
    setValues(defaultValues);
  }, [props.codeToClear]);

	const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAddProduct = e => addProduct();

  const addProduct = async () => {
  	try{
  		if(!values.productUnit){ 
  			const e = new Error('Selecciona una unidad de medida.')
  			e.name = 'productUnit';
  			e.inmput = true;
  			throw e; 
  		}
  		if(parseFloat(values.quantity) <= 0 || isNaN(parseFloat(values.quantity))){ 
  			const e = new Error('Proporciona la cantidad de producto.')
  			e.name = 'quantity';
  			e.inmput = true;
  			throw e; 
  		}
  		if(parseFloat(values.discount) < 0){ 
  			const e = new Error('Proporciona un descuento mayor o igual a cero.')
  			e.name = 'discount';
  			e.inmput = true;
  			throw e; 
  		}
  		if(parseFloat(values.discount) > 100){ 
  			const e = new Error('El descuento no puede ser mayor a cero.')
  			e.name = 'discount';
  			e.inmput = true;
  			throw e; 
  		}
  		if(parseFloat(values.unitPrice) < 0 ||  isNaN(parseFloat(values.unitPrice))){ 
  			const e = new Error('Proporciona un precio unitario mayor o igual a cero.')
  			e.name = 'unitPrice';
  			e.inmput = true;
  			throw e; 
  		}

  		const variables = {
				id: props.id,
				quantity: parseFloat(values.quantity),
				unitPrice: parseFloat(values.unitPrice),
				discount: parseFloat(values.discount),
				productId: values.productUnit.productId,
				productUnitId: values.productUnit.value
			}

			await addProductOrder({ variables });
			await props.getCart();
			
  		setValues(defaultValues);
  		setErrors({});
  	}catch(e) {
  		if(e.inmput) {
  			setErrors({ [e.name]: e.message });
  			return true;
  		}
  		const parseErrors = parseError(e);
      if(parseErrors[0].name === 'GENERAL_ERROR'){
      	snackbar({
	      	isOpen: true, 
	        time: 3000,
	        label: parseErrors[0].message, 
	        severity: 'error'
	      });
      }
  	}
  	
  }

	return (
		<Fragment>
			<div>
				<LinearForm handleAdd={handleAddProduct} align='center'>
					<Grid container spacing={1}>
						<Grid item xs={12} md={12}>
							<Autocomplete 
				        name='productUnit'
				        label='Unidad' 
				        variant='outlined'
				        onChange={handleAutocompleteChange}
				        options={products}
				        value={values.productUnit}
				        error={errors.productUnit}
				        noFormat
				      />
						</Grid>
						<Grid item xs={12} md={4}>
							<TextField 
				        name='quantity'
				        label='Cant.'
				        type='number' 
				        variant='outlined'
				        onChange={handleChange}
				        value={values.quantity}
				        error={errors.quantity}
				        noFormat
				      />
						</Grid>
						<Grid item xs={12} md={4}>
							<TextField 
				        name='discount'
				        label='Dscto. %'
				        type='number' 
				        variant='outlined'
				        onChange={handleChange}
				        value={values.discount}
				        error={errors.discount}
				        disabled={props.costSettings ? true : false}
				        noFormat
				      />
						</Grid>
						<Grid item xs={12} md={4}>
							<TextField 
				        name='unitPrice'
				        label={'P. Unitario'}
				        type='number' 
				        variant='outlined'
				        onChange={handleChange}
				        value={values.unitPrice}
				        error={errors.unitPrice}
				        noFormat
				      />
						</Grid>
					</Grid>
				</LinearForm>
			</div>
		</Fragment>
	);
}

export default Form;
import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import FormDialog from './../../presentationals/FormDialog';

import GQL from './_gql';

const Component = props => {

	const [ getProduction, { data }] = useLazyQuery(GQL.GET_BY_ID,{ fetchPolicy: 'network-only' });

	useEffect(() => {
		props.id && getProduction({ variables: { id: props.id } });
	},[props.id]); // eslint-disable-line

	const handleClose = () => props.handleClose();

	return (
		<FormDialog 
			isOpen={props.isOpen}
			title={`Unidades utilizadas`}
      name="ProductProduction"
      singular="ProductProduction"
      disableEscape
      handleClose={handleClose}
      hideAction
		>	
			<TableContainer>
				<Table size="small">
					<TableHead>
	          <TableRow>
	            <TableCell>Producto</TableCell>
	            <TableCell>Unidad</TableCell>
	            <TableCell align="center">Unidades</TableCell>
	            <TableCell align="center">Merma</TableCell>
	          </TableRow>
	        </TableHead>
	        <TableBody>
	        	{
	        		(data && data.production) && data.production.ProductionDetails.map((el, i) => 
	      				<TableRow key={i}>
			            <TableCell>{el.Product.name}</TableCell>
			            <TableCell>{el.ProductUnit.name}</TableCell>
			            <TableCell align="center">{el.unitsBase}</TableCell>
			            <TableCell align="center">{el.wasteBase}</TableCell>
	      				</TableRow>
	      			)
	        	}
	        </TableBody>
				</Table>
			</TableContainer>
		</FormDialog>
	);
}

export default Component;
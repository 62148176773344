import * as XLSX from 'xlsx';
import format from 'date-fns/format';
import locale from 'date-fns/locale/es';
import getMonth from 'date-fns/getMonth';

const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

const xls = (data, month, name) => {

	const tmp = `${name}_${months[month || getMonth(new Date()) ]}`.toUpperCase();
	const filename = `${tmp}.xlsx`;
		
	const firstHeader = [
		'SUCURSALES',
		...data[0].Sales.reduce((array,el) => (
			[ ...array, el.date !== -1  ? format(new Date(el.date),'MMM-dd',{ locale }).toUpperCase() : 'Total', '' ]
		),[])
	];

	const secondHeader = [
		'',
		...data[0].Sales.reduce((array,el) => (
			[ ...array, "Importe", "Costo" ]
		),[])
	];
	let index = 0;
	const merge = [
		{ s: { r:0, c:0 }, e: { r:1, c:0 } },
		...data[0].Sales.reduce((array,el,i) => {
			const tmp1 = index + 1;
			const tmp2 = index + 2;
			index += 2;
			return [ ...array, { s: { r: 0, c: tmp1 }, e: { r: 0, c: tmp2 } }]
		},[])
	];

	const dataRows = data.map(el => {
		const totals = el.Sales.reduce((array,tmp) => (
			[ ...array, parseFloat(tmp.totalSale), parseFloat(tmp.cost) ]	
		),[]);
		return [ el.Branch.name.toUpperCase(), ...totals ] 
	}); 
	
	const rows = [
	  firstHeader,
	  secondHeader,
	  ...dataRows
	];
	
	const ws = XLSX.utils.aoa_to_sheet(rows);

	if(!ws['!merges']) ws['!merges'] = [];
	ws['!merges'] = merge;

	const wb = XLSX.utils.book_new();

	XLSX.utils.book_append_sheet(wb,ws,`${name}_${months[getMonth(new Date())]}`);
	
	XLSX.writeFile(wb, filename);
}

export default xls;

import React, { Fragment, useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import Grid from '@material-ui/core/Grid';

import LinearForm from './../../../../presentationals/LinearForm';
import Autocomplete from './../../../../presentationals/Autocomplete';
import TextField from './../../../../presentationals/TextField';
import SelectField from './../../../../presentationals/SelectField';

import GQL_PRODUCT from '../../../reportProductInventory/_gql.js';

const defaultValues = {
	quantity: 0,
	productUnit: null,
	unitPrice: 0,
	discount: 0,
	concept: '',
	type: 1,
	iva: 16
};

const typeOptions = [
	{ value: 1, label: 'Producto' },
	{ value: 2, label: 'Libre' },
];

const ivaOptions = [
	{ value: 16, label: '16%' },
	{ value: 8, label: '8%' },
	{ value: 0, label: '0%' },
	{ value: -1, label: 'Exento' },
]

const Form = props => {
	const [values, setValues] = useState(defaultValues);
	const [products, setProducts] = useState([]);
	const [errors, setErrors] = useState({});

	const [getProducts, { data: dataProducts }] = useLazyQuery(GQL_PRODUCT.GET, { variables: { limit: 0 } });

	useEffect(() => {
		if (props.branchId) {
			const companyId = null;
			getProducts({ variables: { limit: 0, branchId: props.branchId, companyId } });
		}
	}, [props.branchId]);

	useEffect(() => {
		if(dataProducts && props.branchId){
			const data = dataProducts.productInventories.rows.map((el,i) => {
				const tag = parseInt(el.companyId) === 1 ? '| NRC' : '| CDE';
        const label = `${el.name.toUpperCase()} ${tag}`;
				return { 
					value: el.id,
					label,
					cost: el.unitCost,
					productId: el.productId,
					iva: parseInt(el.iva) || 0
				}
			}).filter(tmp => tmp);
			setProducts(data);
			setValues(defaultValues);
			setErrors({});
		}
	}, [dataProducts]);

	useEffect(() => {
    setValues(defaultValues);
  }, [props.codeToClear]);

	const handleAutocompleteChangeProduct = (e, name, value) => {
		const unitPrice = (value && value.cost) ? parseFloat(value.cost) : 0;
    setValues(values => ({ ...values, [name]: value, unitPrice: parseFloat(unitPrice) }));
  }

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAddProduct = e => {
  	try{
  		if(parseInt(values.type) === 1 && !values.productUnit){
  			const e = new Error('Selecciona un producto.')
  			e.name = 'productUnit';
  			throw e; 
  		}

  		if(parseInt(values.type) === 2 && ![-1, 0, 8, 16].includes(parseInt(values.iva))){
  			const e = new Error('Selecciona un IVA.');
  			e.name = 'iva';
  			throw e; 
  		}

  		if(parseInt(values.type) === 2 && values.concept === ''){
  			const e = new Error('Escribe un concepto.')
  			e.name = 'concept';
  			throw e; 
  		}

  		if(parseFloat(values.quantity) <= 0 || isNaN(parseFloat(values.quantity))){ 
  			const e = new Error('Proporciona la cantidad de producto.')
  			e.name = 'quantity';
  			throw e; 
  		}

  		if(parseFloat(values.discount) < 0){ 
  			const e = new Error('Proporciona un descuento mayor o igual a cero.')
  			e.name = 'discount';
  			throw e; 
  		}

  		if(parseFloat(values.unitPrice) < 0 ||  isNaN(parseFloat(values.unitPrice))){ 
  			const e = new Error('Proporciona un precio unitario mayor o igual a cero.')
  			e.name = 'unitPrice';
  			throw e;
  		}
  		const product = {
  			concept: parseInt(values.type) === 2 ? values.concept : '',
  			quantity: parseFloat(values.quantity),
  			discount: parseFloat(values.discount),
  			unitPrice: parseFloat(values.unitPrice),
  			productUnit: parseInt(values.type) === 1 ? values.productUnit : null,
  			iva: parseInt(values.iva),
  		};
  		setValues(defaultValues);
  		setErrors({});
  		props.handleAdd(product);
  	}catch(e) {
  		setErrors({ [e.name]: e.message });
  	}
  }

	return (
		<Fragment>
			<div>
				<LinearForm handleAdd={handleAddProduct} align='center'>
					<Grid container spacing={1}>
						<Grid item xs={12} md={2}>
							<SelectField
				        name='type'
				        label='Tipo'
				        variant='outlined'
				        onChange={handleChange}
				        options={typeOptions}
				        value={values.type}
				        error={errors.type}
				        size='small'
				        noFormat
				      />
						</Grid>
						{
							parseInt(values.type) === 2 &&
							<Grid item xs={12} md={2}>
								<SelectField 
					        name='iva'
					        label='IVA' 
					        variant='outlined'
					        onChange={handleChange}
					        options={ivaOptions}
					        value={values.iva}
					        error={errors.iva}
					        size='small'
					        noFormat
					      />
							</Grid>
						}
						{
							parseInt(values.type) === 1
								? <Grid item xs={10} md={10}>
										<Autocomplete 
							        name='productUnit'
							        label='Producto' 
							        variant='outlined'
							        onChange={handleAutocompleteChangeProduct}
							        options={products}
							        value={values.productUnit}
							        error={errors.productUnit}
							        noFormat
							      />
									</Grid>
								: <Grid item xs={10} md={8}>
										<TextField 
							        name='concept'
							        label='Concepto'
							        variant='outlined'
							        onChange={handleChange}
							        value={values.concept}
							        error={errors.concept}
							        noFormat
							      />
									</Grid>
						}
						
						<Grid item xs={12} md={4}>
							<TextField 
				        name='quantity'
				        label='Cant.'
				        type='number' 
				        variant='outlined'
				        onChange={handleChange}
				        value={values.quantity}
				        error={errors.quantity}
				        noFormat
				      />
						</Grid>
						<Grid item xs={12} md={4}>
							<TextField 
				        name='discount'
				        label='Dscto. %'
				        type='number' 
				        variant='outlined'
				        onChange={handleChange}
				        value={values.discount}
				        error={errors.discount}
				        disabled={props.costSettings ? true : false}
				        noFormat
				      />
						</Grid>
						<Grid item xs={12} md={4}>
							<TextField 
				        name='unitPrice'
				        label={'P. Unitario'}
				        type='number' 
				        variant='outlined'
				        onChange={handleChange}
				        value={values.unitPrice}
				        error={errors.unitPrice}
				        noFormat
				      />
						</Grid>
					</Grid>
				</LinearForm>
			</div>
		</Fragment>
	);
}

export default Form;
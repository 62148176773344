import React, { Fragment } from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';

import Table from './../../presentationals/Table/helpers/Table';
import { currencyFormat } from '../../helpers';

const TableComponent = props => {

  return (
    <>
      { props.loading && <LinearProgress color='secondary' />}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            {
              (props.data && props.data.wasteReport.count > 0) && props.data.wasteReport.rows[0].Wastes.map((el, i) => {
                return (
                  <TableCell
                    key={el.Branch.name}
                    align='center'
                    colSpan={2}
                    style={{
                      background: (i % 2 === 0) ? '#c9c9c9' : '#fafafa',
                      borderColor: (i % 2 === 0) ? '#989898' : '#ddd',
                      minWidth: 10,
                    }}
                  >
                    <Typography variant='body2' noWrap>
                      <strong>{el.Branch.name}</strong>
                    </Typography>
                  </TableCell>
                );
              })
            }
          </TableRow>
          <TableRow>
            <TableCell style={{ top: 33 }}>
              Producto
            </TableCell>
            {
              (props.data && props.data.wasteReport.count > 0) && props.data.wasteReport.rows[0].Wastes.map((el, i) => {
                return (
                  <Fragment key={i}>
                    <TableCell
                      align='center'
                      style={{
                        background: (i % 2 === 0) ? '#c9c9c9' : '#fafafa',
                        borderColor: (i % 2 === 0) ? '#989898' : '#ddd',
                        top: 33,
                      }}
                    >
                      Cant.
                    </TableCell>
                    <TableCell
                      align='right'
                      style={{
                        background: (i % 2 === 0) ? '#c9c9c9' : '#fafafa',
                        borderColor: (i % 2 === 0) ? '#989898' : '#ddd',
                        top: 33,
                      }}
                    >
                      Costo
                    </TableCell>
                  </Fragment>
                );
              })
            }
          </TableRow>
        </TableHead>
        <TableBody>
        {
          props.data && props.data.wasteReport.rows.map(el => {
            return (
              <TableRow key={el.Product.id}>
                <TableCell>
                  <Typography variant='body2' noWrap>
                    {el.Product.name.toUpperCase()}
                  </Typography>
                </TableCell>
                {
                  el.Wastes.map((elCh, ii) => {
                    return (
                      <Fragment key={`${el.id}-${ii}`}>
                        <TableCell
                          align='center'
                          style={{
                            background: (ii % 2 === 0) ? '#ddd' : '#fafafa',
                            borderColor: (ii % 2 === 0) ? '#989898' : '#ddd',
                          }}
                        >
                          {parseFloat(parseFloat(elCh.qty).toFixed(3))}
                        </TableCell>
                        <TableCell
                          align='right'
                          style={{
                            background: (ii % 2 === 0) ? '#ddd' : '#fafafa',
                            borderColor: (ii % 2 === 0) ? '#989898' : '#ddd',
                          }}
                        >
                          {currencyFormat(parseFloat(elCh.cost))}
                        </TableCell>
                      </Fragment>
                    );
                  })
                }
              </TableRow>
            );
          })
        }
        </TableBody>
      </Table>
    </>
  );
};

export default TableComponent;

import React, { useState, useEffect, Fragment } from 'react';

import { useQuery } from '@apollo/client';

import Module from './../../presentationals/Module';

import GQL from './_gql';

const Rows = [
	{ label: 'Nombre', key: 'name', sort: 'text' },
	{ label: 'Teléfono', key: 'phone', sort: 'text' },
];

const Component = props => {

	const [ filter, setFilter ] = useState({});

	const { data, loading, refetch } = useQuery(GQL.GET);

	useEffect(() => {
		if(Object.keys(filter).length > 0){
			refetch(filter);
		}
	}, [filter, refetch]);

	const handleRows = (offset, limit) => {
		setFilter(filter => ({ ...filter, limit, offset }));
	}

	const handleSearch = (value) => {
		setFilter(filter => ({ ...filter, like: value }));
	}
	
	return (
		<Fragment>
			<Module
				isLoading={loading}
				name='Clientes'
				singular='Cliente'
				operation={['customer', 'customers']}
				Rows={Rows}
				get={GQL.GET}
				handleRows={handleRows}
				getById={GQL.GET_BY_ID}
				create={GQL.CREATE}
				update={GQL.UPDATE}
				delete={GQL.DELETE}
				records={data && data.customers}
				handleSearch={handleSearch}
				fields={[
					{ 
						name: 'name', 
						label: 'Nombre',
						defaultValue: ''
					},
					{ 
						name: 'phone', 
						label: 'Teléfono',
						defaultValue: ''
					},
				]}
			/>
		</Fragment>
	);
}

export default Component;
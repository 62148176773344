import React from 'react';

import TextFieldMUI from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

const TextField = ({ noFormat, icon, error, ...props }) => 
  <Grid container spacing={0} alignItems="flex-end">
    <Grid item xs={ noFormat ? false : 1 }>
      { icon || "" }
    </Grid>
    <Grid item xs={noFormat ? 12 : 10}>
      <TextFieldMUI 
        name={props.name}
        label={props.label}
        variant={ props.variant || "standard"}
        fullWidth={props.fullWidth || true}
        autoFocus={props.autoFocus || false}
        onChange={props.onChange}
        value={props.value}
        error={typeof error === 'string' ? true : false}
        helperText={(error && error !== '') && error}
        autoComplete="off"
        size={props.size || "small"}
        { ...props }
      />
    </Grid>
  </Grid>

export default TextField;
import React, { useEffect } from 'react';

import { useLazyQuery, useReactiveVar } from '@apollo/client';

import makeStyles from '@material-ui/core/styles/makeStyles';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';

import { filter, count, totals } from '../../../../apollo/cache';
import { currencyFormat } from '../../../../helpers';

import ActionButton from './ActionButton';
import DeleteButton from './DeleteButton';
import EditButton from './EditButton';
import TableCell from './TableCell';
import format from './format';

const useStyles = makeStyles(theme => ({
  actionButton: { padding: theme.spacing(0.4) },
  danger: { backgroundColor: '#ffebeb' },
  warning: { backgroundColor: '#ffffd6' },
  info: { backgroundColor: '#b8dcff' },
  regular: { backgroundColor: '#fff' }
}));

const BodyComponent = props => {

	const classes = useStyles();

	const filterVar = useReactiveVar(filter);

	const [getMore, { data: response, loading }] = useLazyQuery(props.gql);
	useEffect(() => {
		props.handleLoading(loading);
	}, [loading]);

	useEffect(() => {
		getMore({ variables: { ...props.defaultVariables, ...filterVar } });
	}, [filterVar]);

	useEffect(() => {
		if (response && response[props.operation[1]].count >= 0) {
			count(response[props.operation[1]].count);
		}
		if (response && response[props.operation[1]].totals) {
			const newTotals = response[props.operation[1]].totals.map(el => (
				{ value: `${currencyFormat(el.amount)}`, label: `${el.name}${el.qty ? `(${el.qty})` : ''}` }
			));
			totals(newTotals);
		}
	}, [response]);

	return (
		<TableBody>
			{response && response[props.operation[1]].rows.map(data => (
				<TableRow key={data.id} tabIndex={-1} className={data.background ? classes[data.background] : classes.regular}  hover>
					{
						props.Rows.map(el => {
							const value = el.key.includes(' ')
								? el.key.includes(' && ')
									? el.key.split(' && ').reduce((stringPincipal, elPincipal) => {
											const res = elPincipal.split(' ').reduce((string, elCh) => {
												const tmpData = string[elCh] || el.ifNull || '-';
												return tmpData;
											}, data);
											return stringPincipal !== '' ? `${stringPincipal}${el.strcat || ' '}${res}` : res;
										}, '')
									: el.key.split(' ').reduce((string, elCh) => {
											const tmpData = string[elCh] || el.ifNull || '-';
											return tmpData;
										}, data)
								: data[el.key];
							return (
								<TableCell key={el.key} align={el.align} noWrap={!el.wrap} format={el.format}>
									{ format(value, el.format) }
								</TableCell>
							);
						})
					}
					<TableCell align='right' noWrap>
						{ !data.deletedAt && (
							<>
								<ActionButton data={data} />
								{ (!props.noEdit && props.edit) && <EditButton operation={props.operation[0]} id={data.id} getById={props.edit} extraParams={props.extraParams} /> }
								{ !props.noDelete && <DeleteButton data={data} /> }
							</>
						)}
					</TableCell>
				</TableRow>
			))}
		</TableBody>
	);
};

export default BodyComponent;

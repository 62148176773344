import React, { useState } from 'react';

import FormDialog from './../../FormDialog';
import TextField from './../../TextField';

const SearchDialog = props => {
	
	const [ values, setValues ] = useState('');

	const handleCloseForm = e => {
		props.handleClose();
		setValues('');
	}

	const handleAction = e => {
		props.handleAction(values);
		props.handleClose();
	}

	const handleChange = e => {
    setValues(e.target.value);
  }

	return (
		<FormDialog
			isOpen={props.isOpen}
			title='Aplicar Búsqueda'
      handleClose={handleCloseForm}
      handleAction={handleAction}
      actionLabel='Buscar'
		>
			<TextField
				autoFocus
				variant='outlined'
				name='search'
				label='Busqueda'
				onChange={handleChange}
				value={values}
				noFormat
			/>
		</FormDialog>
	);
};

export default SearchDialog;
import React, { useState } from 'react';

import Typography from '@material-ui/core/Typography';

import FilterDrawer from './../../presentationals/FilterDrawer';
import SelectField from './../../presentationals/SelectField';

import { filter } from '../../apollo/cache';

const statusOptions = [
	{ value: -1, label: 'Todos' },
	{ value: 1, label: 'Pendientes' },
	{ value: 2, label: 'Autorizados' },
	{ value: 0, label: 'Declinados' }
];

const typeOptions = [
	{ value: -1, label: 'Todos' },
	{ value: 1, label: 'Entrada' },
	{ value: 2, label: 'Salida' },
];

const defaultValues = {
	status: 1,
	type: -1
}

const FilterDialog = props => {
	const [ values, setValues ] = useState(defaultValues);

	const handleClose = () => {
		props.handleAction('close');
	}

	const handleAction = (e, data) => {
		const { startDate: start, endDate: end } = data;
		props.handleAction(null, { start, end, status: values.status, type: values.type });
		filter({ start, end, type: values.type === -1 ? [] : [values.type], status: values.status === -1 ? [] : [values.status] });
	}

	const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

	return (
		<FilterDrawer
			isOpen={props.isOpen}
			handleClose={handleClose}
			handleAction={handleAction}
			showDates
		>
		  <Typography variant="subtitle1">
        Estatus:
      </Typography>
      <SelectField
        name='status'
        variant='outlined'
        value={values.status}
        onChange={handleChange}
        options={statusOptions}
        noFormat
      />
		  <Typography variant="subtitle1">
        Tipo:
      </Typography>
      <SelectField
        name='type'
        variant='outlined'
        value={values.type}
        onChange={handleChange}
        options={typeOptions}
        noFormat
      />
   
		</FilterDrawer>
	);
}

export default FilterDialog;
import { gql } from '@apollo/client';

const GET = gql`
  query CashClosings($start: Date $end: Date $limit: Int $offset: Int $owner: Boolean $branchId: ID $companyId: Int){
    cashClosings(start: $start end: $end limit: $limit offset: $offset owner: $owner branchId: $branchId companyId: $companyId) {
      count
      rows {
        id
        realBalance
        virtualBalance
        card
        difference
        openedAt
        clientCreatedAt
        User {
          name
        }
        Branch {
          name
          companyId
        }
      }
    }
  }
`;

const obj = { GET };
export default obj;
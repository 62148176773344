import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import FormDialog from './../../presentationals/FormDialog';

import GQL from './_gql';

const Component = props => {
	const [ getCediInventoryMovement, { data }] = useLazyQuery(GQL.GET_BY_ID,{
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		props.id && getCediInventoryMovement({ variables: { id: props.id } });
	},[props.id]); // eslint-disable-line

	const handleClose = () => props.handleClose();

	return (
		<FormDialog 
			isOpen={props.isOpen}
      title={`Resumen de movimiento (${props.extraData.folio})`}
      name="adjustment"
      singular="Adjustment"
      disableEscape
      handleClose={handleClose}
      hideAction
		>	
			<TableContainer>
				<Table size="small">
					<TableHead>
						<TableRow>
	            <TableCell>Producto</TableCell>
	            <TableCell>Unidad</TableCell>
	            <TableCell align="center">Cant.</TableCell>
	          </TableRow>
	        </TableHead>
	        <TableBody>
	        	{
	        		(data && data.cediInventoryMovement) && data.cediInventoryMovement.CediInventoryMovementDetails.map((el, i) => 
	      				<TableRow key={i}>
			            <TableCell>{el.Product.name}</TableCell>
			            <TableCell>{el.ProductUnit.name}</TableCell>
			            <TableCell align="center">{el.quantity}</TableCell>
	      				</TableRow>
	      			)
	        	}
	        </TableBody>
				</Table>
			</TableContainer>
		</FormDialog>
	);
}

export default Component;
import React, { useState, useEffect, Fragment } from 'react';

import { useQuery, useLazyQuery } from '@apollo/client';

import dateFormat from 'date-fns/format';
import parseISO from 'date-fns/parseISO'

import PaymentIcon from '@material-ui/icons/PaymentOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import LinkMui from '@material-ui/core/Link';
import TableBody from '@material-ui/core/TableBody';
import TableMui from '@material-ui/core/Table';
import TableRowMui from '@material-ui/core/TableRow';
import TableHeadMui from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';

import GQL from './_gql';

import Header from './../../presentationals/Table/header';
import Table from './../../presentationals/Table/helpers/Table';
import TableHead from './../../presentationals/Table/tableHead';
import IconButton from './../../presentationals/IconButton';

import { currencyFormat } from './../../helpers';
import PaymentFormDialog from './payment.form.dialog';
import FilterDialog from './filter.dialog';
import PDF from './../saleNote/pdf.a4';

const Rows = [
	{ label: 'Cliente', key: 'Customer name' },
	{ label: 'Cargo', key: 'charge', format: 'currency', align: 'right' },
	{ label: 'Abono', key: 'payment', format: 'currency', align: 'right' },
	{ label: 'Saldo', key: 'balance', format: 'currency', align: 'right' },
];

const Component = props => {
	// const [ saleNotes, setSaleNotes ] = useState({ count: 0, rows: [] });
	const [ filter, setFilter ] = useState({});
	const [ isOpen, setIsOpen ] = useState(false);

	const { data, refetch } = useQuery(GQL.GET_CUSTOMER, {
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if(Object.keys(filter).length > 0){
			refetch && refetch(filter);
		}
	}, [filter, refetch]);

	const handleFilter = () => {
		setIsOpen(true);
	}

	const handleAction = (action, data) => {
		switch(action){
			case 'close':
				setIsOpen(false);
				break;
			default:
				setFilter(filter => ({ ...filter, ...data }));
				break;
		}
	}

	//ADCU01
	
	return (
		<Fragment>
			<Header
				title='Cuentas por Cobrar'
				noCreate
				qty={data && data.customerReceivable.count}
				filter={handleFilter}
				moduleCode='ADCU01'
			/>
			<Table>
				<TableHead dropDownOption Rows={Rows} noActionButtons/>
				<TableBody>
					{ data && data.customerReceivable.rows.map(el => <TableRow key={el.id} el={el} filter={filter} />) }
				</TableBody>
			</Table>
			<FilterDialog isOpen={isOpen} handleAction={handleAction} />
		</Fragment>
	);
}

function TableRow(props){
	const [ isOpenPayment, setIsOpenPayment ] = useState([false,null]);
	const [ extraData, setExtraData ] = useState({ folio: '', customer: '' });
	const [ isOpen, setIsOpen ] = useState(false);

	const [ getReceivable, { data, loading } ] = useLazyQuery(GQL.GET);

	useEffect(() => {
		if(Object.keys(props.filter).length > 0){
			getReceivable({ variables: { ...props.filter, customerId: props.el.id } });
		}
	// eslint-disable-next-line
	}, [props.filter]);

	const handleClick = () => {
		setIsOpen(!isOpen);
		if(!isOpen){
			getReceivable({ variables: { ...props.filter, customerId: props.el.id } });
		}
	}

	const handlePrint = params => {
		if(!params.id) return false;
		PDF(params.id);
	}

	const handleOpenPayment = (e, data) => {
		setExtraData({ folio: `${data.serial}-${data.folio}`, customer: data.Customer.name });
		setIsOpenPayment([true,data.id]);
	}
	const handleClosePayment = () => setIsOpenPayment([false,null]);

	if(loading){ 
		return (
			<TableRowMui style={{ background: '#fff' }}>
				<TableCell align='center' colSpan={5}>
					Espere un momento...
				</TableCell>
			</TableRowMui>
		); 
	}

	return (
		<Fragment>
			<TableRowMui style={{ background: '#fff' }}>
				<TableCell align='center' padding='none'>
					<IconButton 
						icon={isOpen ? <KeyboardArrowUpIcon fontSize="small" /> : <KeyboardArrowDownIcon fontSize="small" />} 
						onClick={handleClick} 
						size='small'
					/>
				</TableCell>
				<TableCell>{ props.el.Customer.name }</TableCell>
				<TableCell align='right'>{ currencyFormat(props.el.charge) }</TableCell>
				<TableCell align='right'>{ currencyFormat(props.el.payment) }</TableCell>
				<TableCell align='right'>{ currencyFormat(props.el.balance) }</TableCell>
			</TableRowMui>
			<TableRowMui style={{ background: '#f5f5f5' }}>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
					<Collapse in={isOpen} timeout="auto" unmountOnExit>
						<Box margin={1}>
							<Typography variant="h6" gutterBottom component="div">
                CxC
              </Typography>
              <TableMui style={{ background: '#fff', borderRadius: 5 }} size="small" aria-label="cxc">
              	<TableHeadMui>
              		<TableRowMui>
	              		<TableCell>Fecha</TableCell>
	              		<TableCell>Tipo</TableCell>
	              		<TableCell>Folio</TableCell>
	              		<TableCell>Cargo</TableCell>
	              		<TableCell>Abono</TableCell>
	              		<TableCell>Saldo</TableCell>
	              		<TableCell padding='none'></TableCell>
              		</TableRowMui>
              	</TableHeadMui>
            		<TableBody>
            			{
            				data && data.saleNotes.rows.map(el => 
            					<TableRowMui key={el.id}>
		            				<TableCell>{dateFormat(parseISO(el.createdAt), 'dd/MM/yyyy HH:mm:ss')}</TableCell>
		            				<TableCell>{typeToLabel(el.type)}</TableCell>
		            				<TableCell>{<Link params={{ id: el.id }} handleClick={handlePrint} label={`${el.serial}-${el.folio}`} />}</TableCell>
		            				<TableCell>{currencyFormat(el.charge)}</TableCell>
		            				<TableCell>{currencyFormat(el.payment)}</TableCell>
		            				<TableCell>{currencyFormat(el.balance)}</TableCell>
		            				<TableCell padding='none'>
		            					<IconButton 
									      		label="Emitir Abono" 
									      		action={handleOpenPayment} 
									      		params={el}
									      		size='small'
									      		icon={<PaymentIcon />}
									      	/>
		            				</TableCell>
		            			</TableRowMui>
            				)
            			}
            		</TableBody>
              	
              </TableMui>
						</Box>
					</Collapse>
				</TableCell>
			</TableRowMui>
			<PaymentFormDialog
				isOpen={isOpenPayment[0]}
				id={isOpenPayment[1]}
				customerId={props.el.id}
				handleClose={handleClosePayment}
				extraData={extraData}
			/>
		</Fragment>
	)

}

function Link(props){

	const handleClick = () => {
		props.handleClick(props.params);
	}

	return (
		<LinkMui onClick={handleClick}>{props.label}</LinkMui>
	)

}

function typeToLabel(type){
	switch(parseInt(type)){
		case 1: return 'Libre';
		case 2: return 'Orden de Compra';
		case 3: return 'Regalías';
		case 4: return 'Cargos';
		case 5: return 'Eventos';
		default: return 'Libre';
	}
}

export default Component;
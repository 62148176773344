import React, { useState, useEffect, Fragment } from 'react';

import { useQuery } from '@apollo/client';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutlineOutlined';

import Module from './../../presentationals/Module';

import GQL from './_gql';

const Rows = [
	{ label: 'Fecha', key: 'createdAt', format: 'dateTime' },
	{ label: 'Tipo', key: 'type', sort: 'text', format: 'supportTicketType' },
	{ label: 'Código', key: 'moduleCode', sort: 'text' },
	{ label: 'Usuario', key: 'User name', sort: 'text' },
	{ label: 'Descripción', key: 'description', sort: 'text' },
];

const typeOptions = [
	{ value: 1, label: 'GENERAL' },
	{ value: 2, label: 'RENDIMIENTO' },
	{ value: 3, label: 'DISEÑO' },
	{ value: 4, label: 'INFORMATIVO' }
];

const Component = props => {

	const [ filter, setFilter ] = useState({});

	const { data, loading, refetch } = useQuery(GQL.GET);
	
	useEffect(() => {
		if(Object.keys(filter).length > 0){
			refetch(filter);
		}
	}, [filter, refetch]);

	const handleRows = (offset, limit) => {
		setFilter(filter => ({ ...filter, limit, offset }));
	}

	const handleSearch = (value) => {
		setFilter(filter => ({ ...filter, like: value }));
	}
	
	return (
		<Fragment>
			<Module
				moduleCode='CARE01'
				isLoading={loading}
				name='Reportar una Falla'
				singular='Reportar una Falla'
				operation={['supportTicket', 'supportTickets']}
				Rows={Rows}
				get={GQL.GET}
				handleRows={handleRows}
				getById={GQL.GET_BY_ID}
				create={GQL.CREATE}
				noEdit
				noDelete
				records={data && data.supportTickets}
				handleSearch={handleSearch}
				fields={[
					{ 
						icon: <ErrorOutlineIcon />,
						autoFocus: true,
						name: 'type', 
						label: 'Tipo',
						field: 'select',
						options: typeOptions
					},
					{ 
						name: 'moduleCode', 
						label: 'Código o nombre del módulo'
					},
					{ 
						name: 'description', 
						label: 'Descripción',
						multiline: true,
						rows: 2
					}
				]}
			/>
		</Fragment>
	);
}

export default Component;
import { gql } from '@apollo/client';

const GET = gql`
	query TransferProductDetails(
		$order: [String], $limit: Int, $offset: Int, $start: Date, $end: Date,
		$transferBranchId: ID, $productId: ID, $branchId: ID
	) {
		transferProductDetails(
			order: $order, limit: $limit, offset: $offset,start: $start, end: $end,
			transferBranchId: $transferBranchId, productId: $productId, branchId: $branchId
		) {
			count
			totals {
        id name qty amount
      }
			rows {
				id
				quantity
				total
				createdAt
				Product {
					name
					Clasification {
						name
					}
				}
				Transfer {
					id
					folio
					serial
					Branch {
						name
					}
					TransferBranch {
						name
					}
					User {
						name
					}
				}
			}
		}
	}
`;

export default { GET };

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  addButton: { 
  	boxShadow: 'none',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  fabProgress: {
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  element: {
  	width: '50%'
  }
}));

export default useStyles;
import React, { useState, useEffect, Fragment } from 'react';

import { useQuery } from '@apollo/client';

import Module from './../../presentationals/Module';
import AppsIcon from '@material-ui/icons/AppsOutlined';

import GQL from './_gql';

import ProductDialog from './product.dialog';

const Rows = [
	{ label: 'Nombre', key: 'name', sort: 'text' },
	{ label: 'Descripción', key: 'description', sort: 'text' },
	{ label: 'Compañía', key: 'company', format: 'companyFormat', align: 'center' },
	{ label: 'Precio', key: 'price', format: 'currency' },
	{ label: 'Costo', key: 'cost', format: 'currency', align: 'right' },
];

const companyOptions = [
	{ label: 'NARACHIPS', value: 1 },
	{ label: 'CASA DEL ELOTE', value: 2 },
]

const Component = props => {

	const [ filter, setFilter ] = useState({});
	const [ openProduct, setIsOpenProduct ] = useState([false, null]);

	const { data, loading, refetch } = useQuery(GQL.GET);

	useEffect(() => {
		if(Object.keys(filter).length > 0){
			refetch(filter);
		}
	}, [filter, refetch]);

	const handleRows = (offset, limit) => {
		setFilter(filter => ({ ...filter, limit, offset }));
	}

	const handleSearch = (value) => {
		setFilter(filter => ({ ...filter, like: value }));
	}

	const handleProduct = (e,data) => {
		// setExtraData({ eventPackageId: data.id });
		setIsOpenProduct([true,data.id]);
	}

	const handleClose = () => setIsOpenProduct([false,null]);
	const reload = () => refetch(filter);

	
	return (
		<Fragment>
			<Module
				moduleCode='CAPA01'
				isLoading={loading}
				name='Paquetes para Eventos'
				singular='Paquete para Eventos'
				operation={['eventPackage', 'eventPackages']}
				Rows={Rows}
				get={GQL.GET}
				handleRows={handleRows}
				getById={GQL.GET_BY_ID}
				create={GQL.CREATE}
				update={GQL.UPDATE}
				delete={GQL.DELETE}
				records={data && data.eventPackages}
				handleSearch={handleSearch}
				fields={[
					{ 
						name: 'name', 
						label: 'Nombre'
					},
					{ 
						name: 'description', 
						label: 'Descripción'
					},
					{ 
						name: 'price', 
						label: 'Precio',
						defaultValue: 0,
						type: 'number',
					},
					{ 
						name: 'company', 
						label: 'Compañía',
						defaultValue: 1,
						field: 'select',
						options: companyOptions
					}
				]}
				actions={[
					{ icon: <AppsIcon/>, onClick: handleProduct, label: 'Componentes del Pqte.' }
				]}
			/>
			<ProductDialog
				reload={reload}
				isOpen={openProduct[0]}
				id={openProduct[1]}
				handleClose={handleClose}
			/>
		</Fragment>
	);
}

export default Component;
import React, { useEffect, useState } from 'react';

import { useMutation, useLazyQuery } from '@apollo/client';

import Grid from '@material-ui/core/Grid';

import LinearForm from '../../presentationals/LinearForm';
import Autocomplete from '../../presentationals/Autocomplete';
import FormDialog from './../../presentationals/FormDialog';
import TextField from './../../presentationals/TextField';

import { parseError } from './../../helpers';
import { snackbar } from './../../apollo/cache';

import GQL_PRODUCT from '../reportProductInventory/_gql.js';

import GQL from './_gql';

const defaultValues = {
  Products: [],
  productUnit: null,
  quantity: '',
};

const FormComponent = props => {
  
  const [productsA, setProductsA] = useState([]);
  const [products, setProducts] = useState([]);
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});

  const [getProducts, { data: dataProducts }] = useLazyQuery(GQL_PRODUCT.GET, {
    fetchPolicy: 'network-only',
  });
  const [getRequisition, { data: dataRequisition }] = useLazyQuery(GQL.GET_BY_ID, {
    fetchPolicy: 'network-only',
  });

  const [confirmRequisition, { loading }] = useMutation(GQL.CONFIRM_STATUS);
  dataRequisition && console.log(dataRequisition.requisition.description);
  useEffect(() => {
    if (props.id) {
      getRequisition({ variables: { id: props.id } });
    }
  }, [props.id]);

  useEffect(() => {
    if (dataRequisition) {
      getProducts({ variables: { limit: 0, branchId: parseInt(dataRequisition.requisition.companyId) === 1 ? 8 : 24 } });
    }
  }, [dataRequisition]);

  useEffect(() => {
    if(dataRequisition && dataProducts) {
      const data = dataRequisition.requisition.ProductRequisitions.map((el,i) => {
        const inventoryData = dataProducts.productInventories.rows.find(tmp => parseInt(tmp.id) === parseInt(el.productUnitId));
        const inventoryQty = inventoryData ? inventoryData.Inventories[0].quantity : 0;

        return {
          id: el.id,
          name: `${el.Product.name.trim()} ${el.ProductUnit.name.trim()}`.toUpperCase().trim(),
          quantity: el.quantity,
          inventory: inventoryQty,
        }
      }).filter(tmp => tmp);
      setProducts(data);

      const tmpValues = dataRequisition.requisition.ProductRequisitions.map(el => {
        return {
          id: el.id,
          quantity: !isNaN(parseFloat(el.sendQuantity))
            ? el.sendQuantity
            : !isNaN(el.quantity)
              ? el.quantity
              : 0,
        }
      }).filter(tmp => tmp);
      console.log(tmpValues);
      setValues(values => ({ ...values, Products: tmpValues }))
    }
  },[dataRequisition, dataProducts]);

  useEffect(() => {
    if(dataProducts){
			const data = dataProducts.productInventories.rows.map((el,i) => {
        const inventoryData = dataProducts.productInventories.rows.find(tmp => parseInt(tmp.id) === parseInt(el.productUnitId));
        const inventoryQty = inventoryData ? inventoryData.Inventories[0].quantity : 0;
        const tag = parseInt(el.companyId) === 1 ? '| NRC' : '| CDE';
        const label = `${el.name.toUpperCase()} ${tag}`;
				return {
					value: el.id,
					label,
					productId: el.productId,
          inventory: inventoryQty,
				};
			}).filter(tmp => tmp);
			setProductsA(data);
		}
  }, [dataProducts]);

  const handleAction = async e => {
    try {
      const Products = values.Products.map((el, i) => {
        if(parseInt(el.id) > 0){
          return {
            id: el.id,
            productUnitId: el.productUnitId ? el.productUnitId : null,
            quantity: !isNaN(parseFloat(el.quantity)) ? parseFloat(el.quantity) : 0,
          }
        } 
        return false;
      }).filter(el => el);

      const variables = {
        id: props.id,
        Products
      };
      await confirmRequisition({ variables });
      props.handleClose();
    }catch(e) {
      const parseErrors = parseError(e);
      if(parseErrors[0].name === 'GENERAL_ERROR'){
        snackbar({
          isOpen: true, 
          time: 3000,
          label: parseErrors[0].message, 
          severity: 'error'
        });
      }
    }
  }

  const handleClose = () => {
    setValues(defaultValues);
    props.handleClose();
  };

  const handleElementChange = (name, value, id) => {
    const tmpProducts = values.Products.map(el => {
      if(parseInt(el.id) === parseInt(id)){
        return { ...el, quantity: name === 'quantity' ? value : el.quantity };
      }
      return el;
    });
    setValues(values => ({ ...values, Products: tmpProducts }));
  };

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAddProduct = () => {
    try {
      if(!values.productUnit){ 
  			const e = new Error('Selecciona una unidad de medida.')
  			e.name = 'productUnit';
  			e.inmput = true;
  			throw e; 
  		}
      if(parseFloat(values.quantity) <= 0 || isNaN(parseFloat(values.quantity))){ 
  			const e = new Error('Proporciona la cantidad de producto.')
  			e.name = 'quantity';
  			e.inmput = true;
  			throw e; 
  		}
      const newProduct = {
        id: values.productUnit.value,
        name: `${values.productUnit.label.trim()}`.toUpperCase(),
        quantity: values.quantity,
        inventory: values.productUnit.inventory,
      };
      setProducts(products => ([...products, newProduct]));
      setValues(values => ({ ...values, Products: [...values.Products, { id: values.productUnit.value, productUnitId: values.productUnit.value, quantity: values.quantity }] }));
    } catch (e) {
      if(e.inmput) {
  			setErrors({ [e.name]: e.message });
  			return true;
  		}
      console.log(e);
    }
  };

  return (
    <FormDialog 
      isOpen={props.isOpen}
      isLoading={loading}
      title='Confirmar'
      name="cart"
      singular="Cart"
      disableEscape
      width="md"
      handleClose={handleClose}
      handleAction={handleAction}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <LinearForm handleAdd={handleAddProduct} align='center'>
            <Grid container spacing={1}>
						  <Grid item xs={8} md={8}>
                <Autocomplete 
                  name='productUnit'
                  label='Producto' 
                  variant='outlined'
                  onChange={handleAutocompleteChange}
                  options={productsA}
                  value={values.productUnit}
                  error={errors.productUnit}
                  noFormat
                />
              </Grid>
              <Grid item xs={4} md={4}>
                <TextField 
                  name='quantity'
                  label='Cant.'
                  type='number' 
                  variant='outlined'
                  onChange={handleChange}
                  value={values.quantity}
                  error={errors.quantity}
                  noFormat
                />
              </Grid>
            </Grid>
          </LinearForm>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={6}><strong>Producto</strong></Grid>
        <Grid item xs={2} align='center'><strong>Cant. Solicitada</strong></Grid>
        <Grid item xs={2} align='center'><strong>En Inventario</strong></Grid>
        <Grid item xs={2}><strong>Cant. Enviada</strong></Grid>
      </Grid>
      {
        products.map((el, i) => {
          return (
            <Element
              key={el.id}
              id={el.id}
              name={el.name}
              quantity={el.quantity}
              inventory={el.inventory}
              values={values.Products.find(elCh => parseInt(elCh.id) === parseInt(el.id))}
              onChange={handleElementChange}
            />
          );
        }).filter(tmp => tmp)
      }
      {
        (dataRequisition && dataRequisition.requisition.description) && (
          <Grid container spacing={1}>
            <Grid item xs={12}><strong>Comentarios</strong></Grid>
            <Grid item xs={12}>{dataRequisition.requisition.description}</Grid>
          </Grid>
        )
      }
    </FormDialog>
  )
}

const Element = props => {
  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    props.onChange(name, value, props.id);
  }
  return (
    <Grid container alignItems='center' spacing={1}>
      <Grid item xs={6}>{props.name}</Grid>
      <Grid item xs={2} align='center'>{props.quantity}</Grid>
      <Grid item xs={2} align='center'>{props.inventory}</Grid>
      <Grid item xs={2}>
        <TextField
          label=''
          name='quantity'
          value={props.values ? props.values.quantity : ''}
          noFormat
          type='number'
          variant='outlined'
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  );
}

export default FormComponent;
import { gql } from '@apollo/client';

const GET = gql`
  query PaymentMethods {
    paymentMethods {
      count
      rows {
        id
        name
      }
    }
  }
`;

const GET_BY_ID = gql`
  query PaymentMethod($id: ID!) {
    paymentMethod(id: $id) {
      id
      name
      isCash
    }
  }
`;

const DELETE = gql`
  mutation DeletePaymentMethod($id: ID!) {
    deletePaymentMethod(id: $id) {
      id
    }
  }
`;

const CREATE = gql`
  mutation CreatePaymentMethod(
    $name: String!
    $isCash: Boolean!
  ) {
    createPaymentMethod(input: {
      name: $name
      isCash: $isCash
    }) {
      id
      name
      isCash
    }
  }
`
const UPDATE = gql`
  mutation UpdatePaymentMethod(
    $id: ID!
    $name: String!
    $isCash: Boolean!
  ) {
    updatePaymentMethod(
      id: $id, 
      input: {
        name: $name
        isCash: $isCash
      }
    ) {
      id
      name
    }
  }
`
const obj = { GET, GET_BY_ID, DELETE, CREATE, UPDATE };
export default obj;


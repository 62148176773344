import { gql } from '@apollo/client';

const GET = gql`
	query BranchPhysicalInventories(
		$limit: Int $offset: Int $status: [Int]
	){
		branchPhysicalInventories(limit: $limit offset: $offset status: $status) {
			count
			rows {
				id
				status
				folio
				serial
				createdAt
				branchId
				User {
					name
				}
			}
		}
	}
`;

const CREATE = gql`
	mutation CreateBranchPhysicalInventory(
		$description: String!
		$branchId: ID
		$Products: [BranchInventoryDetailData]
	) {
		createBranchPhysicalInventory(input: {
			description: $description
			branchId: $branchId
			Products: $Products
		}) {
			id
			status
			folio
			serial
			createdAt
			branchId
			User {
				name
			}
		}
	}
`;

const GET_BY_ID = gql`
	query BranchPhysicalInventory($id: ID!){
		branchPhysicalInventory(id: $id) {
			id
			description
			BranchPhysicalInventoryDetails {
				id
				quantity
				stock
				lastStock
				difference
				Product {
					name
				}
				BaseUnit {
					name
				}
				lastCost
			}
		}
	}
`;

const DELETE = gql`
	mutation DeleteBranchPhysicalInventory($id: ID!, $authCommentary: String){
		deleteBranchPhysicalInventory(id: $id, authCommentary: $authCommentary) {
			id
			status
			folio
			serial
			createdAt
			branchId
			User {
				name
			}
		}
	}
`;

const UPDATE_STATUS = gql`
	mutation UpdateBranchPhysicalInventoryStatus($id: ID!, $status: Int!, $authCommentary: String){
		updateBranchPhysicalInventoryStatus(id: $id, status: $status, authCommentary: $authCommentary) {
			id
			status
			folio
			serial
			createdAt
			branchId
			User {
				name
			}
		}
	}
`;

const object = { CREATE, GET, GET_BY_ID, DELETE, UPDATE_STATUS }

export default object;

import { gql } from '@apollo/client';

const GET_BRANCHES = gql`
  query Branches($owner: Boolean, $limit: Int) {
    branches(owner: $owner, limit: $limit) {
      count
      rows {
        id
        name
      }
    }
  }
`;

const obj = { GET_BRANCHES };
export default obj;

import React, { Fragment, useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';

import LinearForm from './../../../../presentationals/LinearForm';
import Autocomplete from './../../../../presentationals/Autocomplete';
import TextField from './../../../../presentationals/TextField';

const defaultValues = {
	unitCompoundId: null,
	quantity: 0,
	waste: 0
};

const Form = props => {
	const [values, setValues] = useState(defaultValues);
	const [errors, setErrors] = useState({});

	useEffect(() => {
    setValues(defaultValues);
  }, [props.codeToClear]);

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleAddProduct = e => {
  	try{
  		if(!values.unitCompoundId){ 
  			const e = new Error('Selecciona la unidad de cual se compone.')
  			e.name = 'unitCompoundId';
  			throw e; 
  		}

  		if((parseFloat(values.quantity) <= 0 || isNaN(parseFloat(values.quantity)))){ 
  			const e = new Error('La cantidad debe ser un número positivo.')
  			e.name = 'quantity';
  			throw e; 
  		}

  		if(parseFloat(values.waste) < 0 || isNaN(parseFloat(values.waste))){
  			const e = new Error('La merma debe ser un número positivo o cero.')
  			e.name = 'waste';
  			throw e; 
  		}
  		
  		props.handleAdd({
  			unitCompoundId: values.unitCompoundId,
  			quantity: parseFloat(values.quantity),
  			waste: parseFloat(values.waste)
  		});

  		setValues(defaultValues);
  		setErrors({});
  	}catch(e) {
  		setErrors({ [e.name]: e.message });
  	}
  	
  }
  
	return (
		<Fragment>
			<div>
				<LinearForm handleAdd={handleAddProduct} align='center'>
					<Grid container spacing={1}>
						<Grid item xs={12} md={6}>
							<Autocomplete 
				        name='unitCompoundId'
				        label='Compuesto por' 
				        variant='outlined'
				        onChange={handleAutocompleteChange}
				        options={props.products}
				        value={values.unitCompoundId}
				        error={errors.unitCompoundId}
				        noFormat
				        size='medium'
				      />
				    </Grid>
						<Grid item xs={12} md={3}>
				    	<TextField 
				        name='quantity'
				        label='Cant.'
				        variant='outlined'
				        type='number'
				        onChange={handleChange}
				        value={values.quantity}
				        error={errors.quantity}
				        noFormat
				        size='medium'
				      />
				    </Grid>
						{
							<Grid item xs={12} md={3}>
					    	<TextField 
					        name='waste'
					        label='Merma'
					        variant='outlined'
					        type='number'
					        onChange={handleChange}
					        value={values.waste}
					        error={errors.waste}
					        noFormat
					        size='medium'
					      />
					    </Grid>
						}
					</Grid>
				</LinearForm>
			</div>
		</Fragment>
	);
}

export default Form;
import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import Typography from '@material-ui/core/Typography';

import FilterDrawer from './../../presentationals/FilterDrawer';
import SelectField from '../../presentationals/SelectField';
import Autocomplete from '../../presentationals/Autocomplete';

import { filter } from './../../apollo/cache';

import GQL_BRANCHES from '../branch/_gql';
import GQL_PRODUCTS from '../product/_gql';

const FilterDialog = props => {
	const [values, setValues] = useState({ branchId: -1, transferBranchId: -1, productId: null });
	const [products, setProducts] = useState([]);

	const { data: dataBranches } = useQuery(GQL_BRANCHES.GET, { variables: { limit: 0 } });
	const { data: dataProducts } = useQuery(GQL_PRODUCTS.GET_AUTOCOMPLETE, { variables: { limit: 0 } });

	useEffect(() => {
    if (dataProducts) {
      const tmp = dataProducts.products.rows.map(el => ({
        label: `${el.name} ${parseInt(el.companyId) === 2 ? ' - CASA DEL ELOTE' : ''}`.trim().toUpperCase(),
        value: el.id,
      }));
      setProducts(tmp);
    }
  }, [dataProducts]);

	const handleClose = () => {
		props.handleAction('close');
	};

	const handleAction = (e, data) => {
		try {
			const { startDate: start, endDate: end } = data;
			const variables = { 
				branchId: parseInt(values.branchId) !== -1 ? values.branchId : null,
      	transferBranchId: parseInt(values.transferBranchId) !== -1 ? values.transferBranchId : null,
      	productId: values.productId ? values.productId.value : null,
				start,
				end,
			};
			props.handleAction(null, variables);
			filter(variables);
		}catch(e) {
			console.log(e);
		}
	};

	const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

	return (
		<FilterDrawer
			isOpen={props.isOpen}
			handleClose={handleClose}
			handleAction={handleAction}
			showDates
		>
			<Typography variant='subtitle1'>
        Suc. Origen:
      </Typography>
      <SelectField
        name='branchId'
        variant='outlined'
        onChange={handleChange}
        size='medium'
        value={values.branchId}
        options={[
          { value: -1, label: 'AMBAS' },
          { value: 8, label: 'BODEGA NARACHIPS' },
          { value: 24, label: 'BODEGA CASA DEL ELOTE' },
        ]}
        noFormat
      />
      <Typography variant='subtitle1'>
        Suc. Destino:
      </Typography>
      <SelectField
        name='transferBranchId'
        variant='outlined'
        onChange={handleChange}
        size='medium'
        value={values.transferBranchId}
        options={dataBranches ? [
          { value: -1, label: 'TODAS' },
          ...dataBranches.branches.rows.map(el => ({
            value: el.id,
            label: `${el.name} ${parseInt(el.companyId) === 2 ? ' - CASA DEL ELOTE' : ''}`.trim().toUpperCase(),
          }))
        ] : []}
        noFormat
      />
      <Typography variant='subtitle1'>
        Producto:
      </Typography>
      <Autocomplete
        name='productId'
        onChange={handleAutocompleteChange}
        options={products}
        value={values.productId}
        size='medium'
        variant='outlined'
        noFormat
      />
		</FilterDrawer>
	);
}

export default FilterDialog;

import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import locale from 'date-fns/locale/es';

const xls = (data, name, filter) => {
	const start = filter.start 
  	? format(filter.start, 'dd-MMM-yyyy', { locale }).toUpperCase()
  	: format(new Date(), 'dd-MMM-yyyy', { locale }).toUpperCase();
  const end = filter.end 
  	? format(filter.end, 'dd-MMM-yyyy', { locale }).toUpperCase()
  	: format(new Date(), 'dd MMM yyyy', { locale }).toUpperCase();

	const filename = `${name}_${start}_AL_${end}.xlsx`;

	const firstHeader = [
		'FORMAS DE PAGO',
		...data[0].Sales.reduce((array,el) => (
			[ ...array, parseInt(el.Branch.id) !== -1 ? el.Branch.name.toUpperCase() : 'TOTAL' ]
		),[])
	];

	const dataRows = data.map(el => {
		const totals = el.Sales.reduce((array,tmp) => (
			[ ...array, parseFloat(tmp.total) ]
		),[]);
		return [ el.PaymentMethod.name.toUpperCase(), ...totals ] 
	}); 

	const rows = [
	  firstHeader,
	  ...dataRows
	];

	const ws = XLSX.utils.aoa_to_sheet(rows);
	const wb = XLSX.utils.book_new();

	XLSX.utils.book_append_sheet(wb,ws,`${name}`);

	XLSX.writeFile(wb, filename);
}

export default xls;
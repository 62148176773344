import React from 'react';

import EventIcon from '@material-ui/icons/EventOutlined';

import { filterForm } from '../../../../apollo/cache';

import IconButton from '../../../IconButton';

import useStyles from './style';

const FilterButtonComponent = () => {
	const classes = useStyles();
	const handleFilter = () => {
    filterForm(true);
	};
	return (
		<IconButton
      label='Filtrar'
      action={handleFilter}
      className={classes.paddingX}
      icon={<EventIcon className={classes.icon} />}
    />
	);
};

export default FilterButtonComponent;

import React, { Fragment, useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';

import Form from './form';
import Table from './table';
import GQL from './../_gql';

const Cart = props => {
	const [ cartItems, setCartItems ] = useState([]);
	const [ isBase, setIsBase ] = useState(false);

	const [ getEvent, { data } ] = useLazyQuery(GQL.GET_BY_ID,{ fetchPolicy: 'network-only' });

	useEffect(() => {
		if(data && data.product){
			setCartItems(data.product.ProductUnits);	
			if(data.product.ProductUnits.length === 0){
				setIsBase(true);
			}else {
				setIsBase(false);
			}
		}
	}, [data]);

	const getCart = async () => {
		try {
			await getEvent({ variables: { id: props.id } });
		}catch(e) {
			console.log(e);
		}
	}

	useEffect(() => {
		props.id && getCart();
		// eslint-disable-next-line
	},[props.id]);

	return (
		<Fragment>
			<Form codeToClear={props.codeToClear} isBase={isBase} id={props.id} getCart={getCart} />
			{ !isBase && <Table cartItems={cartItems} codeToClear={props.codeToClear} id={props.id} getCart={getCart} /> }
		</Fragment>
	);
}

export default Cart
import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';

import EditIcon from '@material-ui/icons/EditOutlined';

import makeStyles from '@material-ui/core/styles/makeStyles';

import { actionForm } from '../../../../apollo/cache';

import IconButton from '../../../IconButton';

const useStyles = makeStyles(Theme => ({
  actionButton: { padding: Theme.spacing(0.4) },
}));

const EditButtonComponent = props => {

	const classes = useStyles();

	const [isOpen, setIsOpen] = useState(false);

	const [getById, { data }] = useLazyQuery(props.getById);

	useEffect(() => {
		if (isOpen && data) {
			const variables = {
				isOpen: true,
				action: 'update',
				defaultData: data[props.operation],
			};
			actionForm(variables);
			setIsOpen(false);
		}
	}, [isOpen, data]);

	const handleOpenEdit = async (e, params) => {
		await getById({ variables: params });
		setIsOpen(true);
	};

	return (
		<IconButton
			label='Editar'
			action={handleOpenEdit}
			params={{ id: props.id, ...props.extraParams }}
			className={classes.actionButton}
			icon={<EditIcon fontSize='small' />}
		/>
	);
};

export default EditButtonComponent;

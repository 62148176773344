import React, { Fragment, useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';

import Grid from '@material-ui/core/Grid';

import LinearForm from './../../../../presentationals/LinearForm';
import SelectField from './../../../../presentationals/SelectField';
import TextField from './../../../../presentationals/TextField';

import { parseError } from './../../../../helpers';
import { snackbar } from './../../../../apollo/cache';

import GQL from './../../_gql';

const defaultValues = {
	total: 0,
	paymentForm: '01',
}

const paymentFormOptions = [ 
  {value: "01", label: "Efectivo"},
  {value: "100", label: "Depósito"},
  {value: "02", label: "Cheque nominativo"},
  {value: "03", label: "Transferencia electrónica de fondos"},
  {value: "04", label: "Tarjeta de crédito"},
  {value: "05", label: "Monedero electrónico"},
  {value: "06", label: "Dinero electrónico"},
  {value: "15", label: "Condonación"},
  {value: "17", label: "Compensación"},
  {value: "23", label: "Novación"},
  {value: "28", label: "Tarjeta de débito"},
  {value: "30", label: "Aplicación de anticipos"},
  {value: "31", label: "Intermediario pagos"},
  {value: "99", label: "Por definir"}
];

const Form = props => {
	const [ values, setValues ] = useState(defaultValues);
	const [ errors, setErrors ] = useState({});
  
	const [ createExpensePayment ] = useMutation(GQL.CREATE_EXPENSE_PAYMENT,{
    onCompleted: data => {
      props.getCart();
    }
  });

	useEffect(() => {
    setValues(defaultValues);
  }, [props.codeToClear]);

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAction = async e => {
  	try{
  		if(parseFloat(values.total) <= 0 || isNaN(parseFloat(values.total))){ 
  			const e = new Error('Proporciona un total mayor a cero.')
  			e.name = 'total';
        e.fields = true;
  			throw e; 
  		}

  		if(!values.paymentForm){ 
  			const e = new Error('Selecciona una forma de pago.')
  			e.name = 'paymentForm';
        e.fields = true;
  			throw e; 
  		}
  		
  		await createExpensePayment({
        variables: { ...values, total: parseFloat(values.total), expenseId: props.id },
      });
  		setValues(defaultValues);
  		setErrors({});
  	}catch(e) {
      if(e.fields) {
  		  setErrors({ [e.name]: e.message });
        return true;
      }
      const parseErrors = parseError(e);
      if(parseErrors[0].name === 'GENERAL_ERROR'){
        snackbar({
          isOpen: true, 
          time: 3000,
          label: parseErrors[0].message, 
          severity: 'error'
        });
      }
  	}
  	
  }

	return (
		<Fragment>
			<div>
				<LinearForm handleAdd={handleAction} align='center'>
					<Grid container spacing={1}>
						<Grid item xs={12} md={4}>
							<TextField 
				        name='total'
				        label='Abono'
				        type='number' 
				        variant='outlined'
				        onChange={handleChange}
				        value={values.total}
				        error={errors.total}
				        noFormat
				      />
						</Grid>
						<Grid item xs={12} md={8}>
							<SelectField 
				        name='paymentForm'
				        label='Forma de Pago' 
				        variant='outlined'
				        onChange={handleChange}
				        options={paymentFormOptions}
				        value={values.paymentForm}
				        error={errors.paymentForm}
                size='small'
				        noFormat
				      />
						</Grid>
					</Grid>
				</LinearForm>
			</div>
		</Fragment>
	);
}

export default Form;
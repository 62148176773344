import { gql } from '@apollo/client';

const GET = gql`
  query TransferDetails($limit: Int, $offset: Int, $start: Date, $end: Date, $branchId: ID, $transferBranchId: ID, $productId: ID){
    transferDetails(limit: $limit offset: $offset start: $start end: $end branchId: $branchId, transferBranchId: $transferBranchId, productId: $productId) {
      count
      rows {
        id 
        name
        Clasification { name }
        TransferDetails {
          Branch { id name }
          quantity
          cost
          unitCost
        }
      }
      totals {
        quantity unitCost cost
      }
    }
  }
`;

const obj = { GET };
export default obj;
import { gql } from '@apollo/client';

const GET = gql`
  query Inventories($owner: Boolean, $city: String, $branchId: ID $companyId: Int) {
    inventories(owner: $owner, city: $city, branchId: $branchId companyId: $companyId) {
      count
      rows {
        id
        ProductUnit {
          name
          Product {
            name
          }
        }
        Inventories {
          Branch {
            id
            name
          }
          qty
          cost
        }      
      }
    }
  }
`;

const obj = { GET };
export default obj;
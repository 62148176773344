import { gql } from '@apollo/client';

const LOGIN = gql`
  mutation Login($username: String! $password: String!) {
    login(
      input: {
        username: $username 
        password: $password
      }
    ) {
      refreshToken 
      accessToken
      session {
        id username name role
      }
    }
  }
`;
 
export { LOGIN };
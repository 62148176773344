import React, { useState, useEffect, Fragment } from 'react';

import { useQuery, useMutation } from '@apollo/client';

import TodayIcon from '@material-ui/icons/TodayOutlined';
import PersonIcon from '@material-ui/icons/PersonOutline';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCartOutlined';
import RoomIcon from '@material-ui/icons/RoomOutlined';
import AddIcon from '@material-ui/icons/Add';
import Link from '@material-ui/core/Link';
import DoneAllIcon from '@material-ui/icons/DoneAllOutlined';

import IconButton from '@material-ui/core/IconButton';

import Module from './../../presentationals/Module';
import ConfirmDialog from './../../presentationals/ConfirmDialog';

import GQL from './_gql';
import GQL_CUSTOMER from './../customer/_gql';
import GQL_EMPLOYEE from './../employee/_gql';
import GQL_PACKAGE from './../eventPackage/_gql';

import FilterDialog from './filter.dialog';
import CustomerDialog from './../customer/customer.dialog.js';

import PDF from './pdf.a4';

const Rows = [
	{ label: 'Fecha', key: 'date', format: 'dateTime' },
	{ label: 'Folio', key: 'folio', sort: 'text' },
	{ label: 'Cliente', key: 'Customer name', sort: 'text' },
	{ label: 'Paquete', key: 'EventPackage name', sort: 'text' },
	{ label: 'Costo', key: 'cost', format: 'currency' },
	{ label: 'Cargo', key: 'SaleNote total', format: 'currency', align: 'right' },
	{ label: 'Abono', key: 'payment', format: 'currency', align: 'right' },
	{ label: 'Saldo', key: 'balance', format: 'currency', align: 'right' },
];

const paymentFormOptions = [ 
  {value: "01", label: "Efectivo"},
  {value: "100", label: "Depósito"},
  {value: "02", label: "Cheque nominativo"},
  {value: "03", label: "Transferencia electrónica de fondos"},
  {value: "04", label: "Tarjeta de crédito"},
  {value: "05", label: "Monedero electrónico"},
  {value: "06", label: "Dinero electrónico"},
  {value: "15", label: "Condonación"},
  {value: "17", label: "Compensación"},
  {value: "23", label: "Novación"},
  {value: "28", label: "Tarjeta de débito"},
  {value: "30", label: "Aplicación de anticipos"},
  {value: "31", label: "Intermediario pagos"},
  {value: "99", label: "Por definir"}
];

const defaultConfirm = [false, { id: null, status: 2 }];

const currentDate = new Date();

const Component = props => {

	const [ customerOptions, setCustomerOptions ] = useState([]);
	const [ employeesOptions, setEmployeesOptions ] = useState([]);
	const [ packagesOptions, setPackagesOptions ] = useState([]);
	const [ isOpen, setIsOpen ] = useState(false);
	const [ isCustomerOpen, setIsCustomerOpen ] = useState(false);
	const [ filter, setFilter ] = useState({});
	const [ events, setEvents ] = useState({ count: 0, rows: [] });
	const [ defaultCustomer, setDefaultCustomer ] = useState(null);
	const [ isOpenConfirmDialog, setIsOpenConfirmDialog ] = useState(defaultConfirm);
	
	const { data, loading, refetch } = useQuery(GQL.GET,{ variables: { status: [1] } });
	const { data: customers } = useQuery(GQL_CUSTOMER.GET_AUTOCOMPLETE, { variables: { limit: 0 } });
	const { data: employees } = useQuery(GQL_EMPLOYEE.GET_AUTOCOMPLETE,{ variables: { limit: 0, role: [1] } });
	const { data: packages } = useQuery(GQL_PACKAGE.GET_AUTOCOMPLETE, { variables: { limit: 0 } });
	
	const [ updateEventStatus ] = useMutation(GQL.UPDATE_EVENT_STATUS, {
		onCompleted: () => {
			setIsOpenConfirmDialog(defaultConfirm);
			setFilter(filter => ({ ...filter, status: [1] }));
		}
	});

	useEffect(() => {
		if(data) {
			const rows = data.events.rows.map(el => {
				const payment = el.SaleNote.SaleNotePayments.reduce((total,tmp) => (total = parseFloat(total) + parseFloat(tmp.total)),0)
				return { 
					...el,
					folio: <Link onClick={() => handlePdf(el.id)}>{ `${el.serial}-${el.folio}` }</Link>,
					folioLabel: `${el.serial}-${el.folio}`, payment,
					balance: parseFloat(el.SaleNote.total) - parseFloat(payment),
				}
			});
			setEvents({ count: data.events.count, rows })
		}
	},[data]);

	useEffect(() => {
		if(customers && customers.customers){
			const tmpCustomers = customers.customers.rows.map(el => ({ value: el.id, label: el.name }));
			setCustomerOptions(tmpCustomers);
		}
	}, [customers]);

	useEffect(() => {
		if(employees && employees.users){
			const tmpEmployees = employees.users.rows.map(el => ({ value: el.id, label: el.name }));
			setEmployeesOptions(tmpEmployees);
		}
	}, [employees]);

	useEffect(() => {
		if(packages && packages.eventPackages){
			const tmpPackages = packages.eventPackages.rows.map(el => ({ value: el.id, label: el.name }));
			setPackagesOptions(tmpPackages);
		}
	}, [packages]);

	useEffect(() => {
		if(Object.keys(filter).length > 0){
			refetch(filter);
		}
	}, [filter, refetch]);

	const handleRows = (offset, limit) => {
		setFilter(filter => ({ ...filter, limit, offset }));
	}

	const handleFilter = () => {
		setIsOpen(true);
	}

	const handleAction = (action, data1) => {
		switch(action){
			case 'close':
				setIsOpen(false);
				break;
			default:
				const { status, ...data } = data1;
				setFilter(filter => ({ 
					...filter, 
					...data,
					status: status === -1 ? [] : [status]
				}));
				break;
		}
	}

	const handleSearch = (value) => {
		setFilter(filter => ({ ...filter, like: value }));
	}

	const handleOpenCustomer = () => {
		setIsCustomerOpen(isCustomerOpen => !isCustomerOpen);
	}

	const handleCustomerAction = data => {
		const getCustomer = customerOptions.find(el => parseInt(el.value) === parseInt(data));
		setDefaultCustomer(getCustomer);
		handleOpenCustomer();
	}

	const handleCloseEvent = (e,{id}) => setIsOpenConfirmDialog([true, { id, status: 2 }]);
  const handleCloseConfirm = () => setIsOpenConfirmDialog(defaultConfirm);

	const handleActionConfirm = async ({ id }) => {
		try {
  		await updateEventStatus({ variables: { id, status: 2 } });
  	}catch(e) {
  		console.log(e);
  	}
	}

	const handleEventAction = async data => {
		await PDF(data.id);
	}

	const handlePdf = (id) => {
		if(!id) return false;
		PDF(id);
	}
	
	return (
		<Fragment>
			<Module
				moduleCode='OPEV01'
				handleAction={handleEventAction}
				isLoading={loading}
				name='Eventos'
				singular='Evento'
				operation={['event', 'events']}
				Rows={Rows}
				get={GQL.GET}
				handleRows={handleRows}
				create={GQL.CREATE}
				noEdit
				// delete={GQL.DELETE}
				noDelete
				records={events}
				filter={handleFilter}
				handleSearch={handleSearch}
				cacheVariables={{ status: [1] }}
				actions={[
					{ 
						icon: <DoneAllIcon fontSize="small" />,
						label: "Cerrar Evento",
						onClick: handleCloseEvent
					},
				]}
				fields={[
					{ 
						icon: <TodayIcon />,
						field: 'dateTime',
						name: 'date', 
						label: 'Fecha y hora del evento',
						defaultValue: currentDate
					},
					{
						icon: <AddShoppingCartIcon />,
						name: 'eventPackageId',
						label: 'Paquete',
						field: 'autocomplete',
						options: packagesOptions,
					},
					{ 
						name: 'description', 
						label: 'Detalles del paquete',
						multiline: true
					},
					{
						icon: <PersonIcon />,
						name: 'customerId',
						label: 'Cliente',
						field: 'autocomplete',
						options: customerOptions,
						defaultValue: defaultCustomer,
						extra: <AddButton onClick={handleOpenCustomer} />
					},
					{
						name: 'employeeId',
						label: 'Responsable del evento',
						field: 'autocomplete',
						options: employeesOptions,
					},
					{
						name: 'employeeId1',
						label: 'Responsable del traslado',
						field: 'autocomplete',
						options: employeesOptions,
					},
					{ 
						icon: <RoomIcon />,
						name: 'address', 
						label: 'Domicilio del evento',
						multiline: true
					},
					{ 
						name: 'advance', 
						label: 'Anticipo',
						type: 'number'
					},
					{ 
						name: 'paymentForm', 
						label: 'Forma de Pago',
						field: 'select',
						options: paymentFormOptions
					},
					{ 
						name: 'discount', 
						label: 'Descuento',
						defaultValue: 0,
						type: 'number',
						show: true,
					}
				]}
			/>
			<ConfirmDialog
				isOpen={isOpenConfirmDialog[0]}
				handleClose={handleCloseConfirm}
				handleAction={handleActionConfirm}
				params={isOpenConfirmDialog[1]}
			/>
			<FilterDialog isOpen={isOpen} handleAction={handleAction} />
			<CustomerDialog 
				isOpen={isCustomerOpen} 
				handleAction={handleCustomerAction} 
				handleClose={handleOpenCustomer}
				hideSearch 
				object='contacto'
				title='Datos del Contacto' 
			/>
		</Fragment>
	);
}

function AddButton(props) {
	return (
		<IconButton aria-label="add" onClick={props.onClick} color='secondary' size="small">
      <AddIcon fontSize="inherit" size="small" />
    </IconButton>
	);
}

export default Component;
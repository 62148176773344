import React, { useState, useEffect, Fragment } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import ListAltIcon from '@material-ui/icons/ListAltOutlined';
import CloseIcon from '@material-ui/icons/Close';
import LockIcon from '@material-ui/icons/LockOutlined';
import AppsIcon from '@material-ui/icons/AppsOutlined';

import Module from './../../presentationals/Module';
import ConfirmDialog from './../../presentationals/ConfirmDialog';
import DelteConfirmDialog from './../../presentationals/ConfirmDialog';

import { parseError } from './../../helpers';
import { snackbar } from './../../apollo/cache';

import GQL from './_gql';

import Form from './form.dialog';
import FilterDialog from './filter.dialog';
import SummaryDialog from './summary.dialog';

const Rows = [
	{ label: 'Fecha', key: 'createdAt', format: 'date' },
	{ label: 'Folio', key: 'folio', sort: 'text' },
	{ label: 'Tipo', key: 'type', format: 'cediInventoryMovementType', sort: 'text' },
	{ label: 'Responsable', key: 'User name', sort: 'text' },
	{ label: 'Justificación', key: 'description', sort: 'text' },
];

const defaultSummaryDialog = [false,0,{ folio: ''}];
const defaultConfirm = [false, { id: null, status: 2 }];
const defaultConfirmDelete = [false,null];

const Component = props => {
	const [isOpen, setIsOpen] = useState(false);
	const [isOpenSummary, setIsOpenSummary] = useState(defaultSummaryDialog);
	// const [formIsOpen, setFormIsOpen] = useState(false);
	const [formIsOpen, setFormIsOpen] = useState([false, null]);
	const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(defaultConfirm);
	const [isOpenConfirmDialogDelete, setIsOpenConfirmDialogDelete] = useState([false,null]);
	const [filter, setFilter] = useState({});
	const [cediInventoryMovements, setCediInventoryMovements] = useState([{ count: 0, rows: [] }]);
	// const [isOpenEdit, setIsOpenEdit] = useState([false, null]);

	const { data, loading, refetch } = useQuery(GQL.GET,{ variables: { status: [1] } });
	const [ updateStatus ] = useMutation(GQL.UPDATE_STATUS, {
		update(cache, { data }){
			cache.evict({ id: `CediInventoryMovement:${data.updateCediInventoryMovementStatus.id}` });
			cache.gc();
		},
		onCompleted: () => setIsOpenConfirmDialog(defaultConfirm)
	});
	const [ deleteCediInventoryMovement ] = useMutation(GQL.DELETE, {
		update(cache, { data }){
			cache.evict({ id: `CediInventoryMovement:${data.deleteCediInventoryMovement.id}` });
			cache.gc();
		},
		onCompleted: () => setIsOpenConfirmDialogDelete(defaultConfirmDelete)
	});

	useEffect(() => {
		if(data && data.cediInventoryMovements) {
			const tmp = data.cediInventoryMovements.rows.map(el => ({ ...el, folio: `${el.serial}-${el.folio}` }));
			setCediInventoryMovements({ count: data.cediInventoryMovements.count, rows: tmp });
		}
	},[data]);

	useEffect(() => {
		if(Object.keys(filter).length > 0){
			refetch(filter);
		}
	}, [filter, refetch]);

	const handleRows = (offset, limit) => {
		setFilter(filter => ({ ...filter, limit, offset }));
	}

	const handleSearch = (value) => {
		setFilter(filter => ({ ...filter, like: value }));
	}

	const handleCreate = () => setFormIsOpen([true, null]);
	const handleCloseCreate = () => setFormIsOpen([false, null]);
	
	const handleCloseSummary = () => setIsOpenSummary(defaultSummaryDialog);
	const handleSummary = (e,{ id, folio }) => {
		setIsOpenSummary([true,id,{folio}]);
	};

	const handleAuth = (e,{id}) => setIsOpenConfirmDialog([true, { id, status: 2 }]);
  const handleCloseConfirm = () => setIsOpenConfirmDialog(defaultConfirm);
	
	const handleEdit = (e,{ id }) => setFormIsOpen([true, id]);

	const handleActionConfirm = async ({ id, status }) => {
  	try {
  		await updateStatus({ variables: { id, status } });
  	}catch(e) {
  		const parseErrors = parseError(e);
      if(parseErrors[0].name === 'GENERAL_ERROR'){
      	snackbar({
	      	isOpen: true, 
	        time: 3000,
	        label: parseErrors[0].message, 
	        severity: 'error'
	      });
      }
  	} 
  }

	const handleDelete = (e,{id}) => setIsOpenConfirmDialogDelete([true, id]);
  const handleCloseConfirmDelete = () => setIsOpenConfirmDialogDelete(defaultConfirmDelete);

	const handleActionConfirmDelete = async id => {
  	try {
  		await deleteCediInventoryMovement({ variables: { id } });
  	}catch(e) {
  		const parseErrors = parseError(e);
      if(parseErrors[0].name === 'GENERAL_ERROR'){
      	snackbar({
	      	isOpen: true, 
	        time: 3000,
	        label: parseErrors[0].message, 
	        severity: 'error'
	      });
      }
  	} 
  }

  const handleFilter = () => setIsOpen(true);
	const handleAction = (action, data1) => {
		switch(action){
			case 'close':
				setIsOpen(false);
				break;
			default:
				const { status, type, ...data } = data1;
				setFilter(filter => ({ 
					...filter, 
					...data,
					status: status === -1 ? [] : [status],
					type: type === -1 ? [] : [type],
				}));
				break;
		}
	}
	
	return (
		<Fragment>
			<Module
				moduleCode='OPEN01'
				isLoading={loading}
				name='Entrada/Salida de inventario'
				singular='Entrada/Salida de inventario'
				operation={['cediInventoryMovement', 'cediInventoryMovements']}
				Rows={Rows}
				get={GQL.GET}
				handleRows={handleRows}
				getById={GQL.GET_BY_ID}
				create={GQL.CREATE}
				noDelete
				noEdit
				cacheVariables={{ status: [1] }}
				records={cediInventoryMovements}
				handleSearch={handleSearch}
				handleCustomCreate={handleCreate}
				filter={handleFilter}
				actions={[
					{ icon: <ListAltIcon/>, onClick: handleSummary, label: 'Resumen' },
					{ icon: <AppsIcon/>, onClick: handleEdit, label: 'Editar Movimiento', hide: { key: 'status', value: ['2'] } },
					{ icon: <LockIcon/>, onClick: handleAuth, label: 'Autorizar', hide: { key: 'status', value: ['2'] } },
					{ icon: <CloseIcon/>, onClick: handleDelete, label: 'Eliminar', hide: { key: 'status', value: ['2'] } },
				]}
			/>
			<ConfirmDialog
				isOpen={isOpenConfirmDialog[0]}
				handleClose={handleCloseConfirm}
				handleAction={handleActionConfirm}
				params={isOpenConfirmDialog[1]}
			/>
			<DelteConfirmDialog
				isOpen={isOpenConfirmDialogDelete[0]}
				handleClose={handleCloseConfirmDelete}
				handleAction={handleActionConfirmDelete}
				params={isOpenConfirmDialogDelete[1]}
			/>
			<FilterDialog isOpen={isOpen} handleAction={handleAction} />
			<Form isOpen={formIsOpen[0]} id={formIsOpen[1]} handleClose={handleCloseCreate} />
			<SummaryDialog isOpen={isOpenSummary[0]} handleClose={handleCloseSummary} id={isOpenSummary[1]} extraData={isOpenSummary[2]} />
		</Fragment>
	);
}

export default Component;
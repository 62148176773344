import React, { useState } from 'react';

import MoreVertIcon from '@material-ui/icons/MoreVertOutlined';

import IconButton from '../../../IconButton';

import Menu from './Menu';
import useStyles from './style';

const MenuButtonComponent = props => {
	const classes = useStyles();
	const [openMenu, setOpenMenu] = useState(null);
	const handleOpenMenu = e => { setOpenMenu(e.currentTarget); };
	const handleCloseMenu = () => { setOpenMenu(null); };
	return (
		<>
			<IconButton
	      label='Más Opciones'
	      action={handleOpenMenu}
	      className={classes.paddingX}
	      aria-controls='module-menu'
	      aria-haspopup='true'
	      icon={<MoreVertIcon className={classes.icon} />}
	    />
	    <Menu
	      return={!!props.return}
	      anchorEl={openMenu}
	      onClose={handleCloseMenu}
	      customXls={props.customXls}
	    />
  </>
	);
};

export default MenuButtonComponent;

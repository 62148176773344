import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
	header: {
		...theme.mixins.toolbar,
		padding: theme.spacing(0.5, 1.5, 1, 1.5),
	  borderBottom: '1px solid #e0e0e0', 
	  position: 'abosulute',
	  zIndex: 1000,
	  backgroundColor: "#fff",
	  [theme.breakpoints.down('xs')]: {
	  	padding: theme.spacing(1, 1.5, 1, 1.5)
    },
	},
	marginRight: { marginRight: theme.spacing(1) },
	paddingX: {
		marginTop: theme.spacing(0.5),
		paddingLeft: theme.spacing(1.6),
		paddingRight: theme.spacing(1.6),
	},
	toolbar: theme.mixins.toolbar,
	moduleCode: {
		marginRight: theme.spacing(1),
	}
}));

export default useStyles;
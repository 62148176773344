import React, { useState, useEffect, Fragment } from 'react';

import { useQuery } from '@apollo/client';

import Module from './../../presentationals/Module';

import GQL from './_gql';

import FilterDialog from './filter.dialog';

const Rows = [
	{ label: 'Fecha', key: 'createdAt', sort: 'text', format: 'dateTime' },
	{ label: 'Descripción', key: 'description', sort: 'text' },
  { label: 'Producto', key: 'Product name', sort: 'text' },
  { label: 'Unidad', key: 'ProductUnit name', sort: 'text' },
  { label: 'Entrada', key: 'input', sort: 'text', align: 'right' },
  { label: 'Salida', key: 'output', sort: 'text', align: 'right' },
  { label: 'Cantidad', key: 'balance', sort: 'text', align: 'right' },
];

const Component = props => {
	const [ isOpen, setIsOpen ] = useState(false);
	const [ filter, setFilter ] = useState({});

	const { data, loading, refetch } = useQuery(GQL.GET);

	useEffect(() => {
		if(Object.keys(filter).length > 0){
			refetch(filter);
		}
	}, [filter, refetch]);

	const handleRows = (offset, limit) => {
		setFilter(filter => ({ ...filter, limit, offset }));
	}

	const handleFilter = () => {
		setIsOpen(true);
	}

	const handleAction = (action, data) => {
		switch(action){
			case 'close':
				setIsOpen(false);
				break;
			default:
				setFilter(filter => ({ ...filter, ...data }));
				break;
		}
	}

	// const handleSearch = (value) => {
	// 	setFilter(filter => ({ ...filter, like: value }));
	// }

	return (
		<Fragment>
			<Module
				moduleCode='MP01'
				isLoading={loading}
				name='Movimentos por Producto'
				singular='Movimentos por Producto'
				operation={['productMovementLogs','productMovementLog']}
				Rows={Rows}
				get={GQL.GET}
				noCreate
				noEdit
				noDelete
				handleRows={handleRows}
				records={(data && data.productMovementLogs) ? data.productMovementLogs : null}
				filter={handleFilter}
			/>
			<FilterDialog isOpen={isOpen} handleAction={handleAction} />
		</Fragment>
	);
}

export default Component;
import React, { useState, useEffect, Fragment } from 'react';

import { useQuery } from '@apollo/client';

import BusinessIcon from '@material-ui/icons/BusinessOutlined';
import PhoneIcon from '@material-ui/icons/PhoneOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import RoomIcon from '@material-ui/icons/RoomOutlined';
import SimCardIcon from '@material-ui/icons/SimCardOutlined';

import Module from './../../presentationals/Module';

import GQL from './_gql';
import Credentials from './credentials.dialog';

const Rows = [
	{ label: 'Nombre', key: 'name', sort: 'text', filter: true },
	{ label: 'Teléfono', key: 'phone', sort: 'text' },
	{ label: 'Tipo', key: 'type', sort: 'text', format: 'branchType' },
	{ label: 'Establecimiento', key: 'owner', sort: 'text', format: 'branchOwner' },
	{ label: 'Representante Legal', key: 'Customer name', sort: 'text' },
	{ label: 'Empresa', key: 'companyId', format: 'company' },
];

const ownerOptions = [
	{ value: true, label: 'Sucursal' },
	{ value: false, label: 'Franquicia' }
];

const typeOptions = [
	{ value: 1, label: 'Punto de Venta' },
	{ value: 2, label: 'Almacén' }
];

const stateOptions = [
	{ value: 1, label: 'Sinaloa' },
	{ value: 2, label: 'Sonora' }
];

const companyOptions = [
	{ value: 1, label: 'Narachips' },
	{ value: 2, label: 'CasaElotes' }
];

const defaultDialog = [false, null];

const Component = props => {
	const [ openCredentials, setOpenCredentials ] = useState(defaultDialog);

	const [ filter, setFilter ] = useState({});

	const { data, loading, refetch } = useQuery(GQL.GET,{ variables: { owner: true } });
	
	useEffect(() => {
		if(Object.keys(filter).length > 0){
			refetch(filter);
		}
	}, [filter, refetch]);

	const handleRows = (offset, limit) => {
		setFilter(filter => ({ ...filter, limit, offset }));
	}

	const handleSearch = (value) => {
		setFilter(filter => ({ ...filter, like: value }));
	}

	const handleCredentials = (e, { id }) => {
  	setOpenCredentials([true, id]);
  }

	const handleCloseCredentials = () => {
  	setOpenCredentials(defaultDialog);
  }

	return (
		<Fragment>
			<Module
				moduleCode='CAPR03'
				isLoading={loading}
				name='Sucursales Propias'
				singular='Sucursal'
				operation={['branch', 'branches']}
				Rows={Rows}
				get={GQL.GET}
				cacheVariables={{ owner: true, ...filter }}
				handleRows={handleRows}
				getById={GQL.GET_BY_ID}
				create={GQL.CREATE}
				update={GQL.UPDATE}
				delete={GQL.DELETE}
				records={data && data.branches}
				handleSearch={handleSearch}
				actions={[
					{ 
						icon: <SimCardIcon/>, 
						label: 'Credenciales para Aplicación de Escritorio', 
						onClick: handleCredentials
					},
				]}
				fields={[
					{ 
						icon: <BusinessIcon />,
						name: 'name', 
						label: 'Nombre',
						autoFocus: true
					},
					{ 
						name: 'prefix', 
						label: 'Prefijo (Serie de documentos)'
					},
					{ 
						name: 'description', 
						label: 'Descripción'
					},
					{ 
						icon: <PhoneIcon />,
						name: 'phone', 
						label: 'Teléfono'
					},
					{ 
						icon: <SettingsIcon />,
						field: 'select',
						name: 'owner', 
						label: 'Establecimiento',
						defaultValue: true,
						options: ownerOptions
					},
					{ 
						field: 'select',
						name: 'type', 
						label: 'Tipo',
						defaultValue: 1,
						options: typeOptions
					},
					{ 
						icon: <RoomIcon />,
						name: 'addressLine1', 
						label: 'Calle y Número'
					},
					{ 
						name: 'addressLine2', 
						label: 'Colonia'
					},
					{ 
						name: 'city', 
						label: 'Ciudad'
					},
					{ 
						name: 'zipCode', 
						label: 'Código Postal'
					},
					{ 
						field: 'select',
						name: 'stateId', 
						label: 'Estado',
						defaultValue: 1,
						options: stateOptions
					},
					{ 
						show: true,
						name: 'countryId', 
						defaultValue: 1,
					},
					{ 
						field: 'select',
						name: 'companyId', 
						label: 'Empresa',
						defaultValue: 1,
						options: companyOptions
					},
				]}
			/>
			<Credentials isOpen={openCredentials[0]} handleClose={handleCloseCredentials} branchId={openCredentials[1]} />
		</Fragment>
	);
}

export default Component;
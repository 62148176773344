import React, { Fragment, useState, useEffect } from 'react';

import { useQuery } from '@apollo/client';

import Header from './../../presentationals/Table/header';

import Table from './_table';
import Filter from './_filter';
import GQL from './_gql';

const Component = props => {
	const [ isOpen, setIsOpen ] = useState(false);
	const [ filter, setFilter ] = useState({});

	const { data: dataWaste, loading, refetch } = useQuery(GQL.GET, {
    fetchPolicy: 'network-only',
  });

	useEffect(() => {
		if(Object.keys(filter).length > 0){
			refetch(filter);
		}
	}, [filter, refetch]);

	const handleAction = (action, data) => {
		switch(action){
			case 'close':
				setIsOpen(false);
				break;
			default:
				setFilter(filter => ({ ...filter, ...data }));
				break;
		}
	};

	const handleFilter = () => setIsOpen(true);

	return (
		<Fragment>
			<Header
				title={`Reporte de Mermas`}
				noCreate
				noEdit
				noDelete
				filter={handleFilter}
				moduleCode='REPI01'
			/>
			<Table data={dataWaste} loading={loading} />
			<Filter isOpen={isOpen} handleAction={handleAction} />
		</Fragment>
	);
}

export default Component;
import React from 'react';

import FilterDrawer from './../../presentationals/FilterDrawer';

const FilterDialog = props => {

	const handleClose = () => {
		props.handleAction('close');
	}

	const handleAction = (e, data) => {
		console.log(data);
		// props.handleAction(null, { branchId });
	}

	return (
		<FilterDrawer 
			isOpen={props.isOpen} 
			handleClose={handleClose} 
			handleAction={handleAction}
			showDates
		/>
	);
}

export default FilterDialog;
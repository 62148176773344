import React, { Fragment, useState, useEffect } from 'react';

import Form from './form';
import Table from './table';

const Cart = props => {
	const [cartItems, setCartItems] = useState([]);

	const addItemToCart = data => {
		// const withoutTaxes = parseFloat((parseFloat(data.unitPrice) / ((parseFloat(props.iva < 0 ? 0 : props.iva) / 100) + 1)).toFixed(2));
		// const subtotal = parseFloat((parseFloat(data.quantity) * parseFloat(withoutTaxes)).toFixed(2));
		// const total = parseFloat((parseFloat(data.quantity) * parseFloat(data.unitPrice)).toFixed(2));
		// const tmpDiscount = parseFloat((parseFloat(subtotal) * (parseFloat(data.discount) / 100)).toFixed(2));
		// const taxes = parseFloat((parseFloat(parseFloat(subtotal) - parseFloat(tmpDiscount)) * (parseFloat(props.iva < 0 ? 0 : props.iva) / 100)).toFixed(2));
		const iva = !data.productUnit ? data.iva < 0 ? 0 : data.iva : data.productUnit.iva < 0 ? 0 : data.productUnit.iva;
		const withoutTaxes = parseFloat(data.unitPrice);
		const subtotal = parseFloat((parseFloat(data.quantity) * parseFloat(withoutTaxes)).toFixed(2));
		const tmpDiscount = parseFloat((parseFloat(subtotal) * (parseFloat(data.discount) / 100)).toFixed(2));
		const taxes = parseFloat((parseFloat(parseFloat(subtotal) - parseFloat(tmpDiscount)) * (parseFloat(iva) / 100)).toFixed(2));
		const total = parseFloat((parseFloat(subtotal) - parseFloat(tmpDiscount) + parseFloat(taxes)).toFixed(2));
		setCartItems(cartItems => ([ ...cartItems, { ...data, withoutTaxes, taxes, subtotal, total, tmpDiscount } ]));
		props.getCart([ ...cartItems, { ...data, withoutTaxes, taxes, subtotal, total, tmpDiscount } ]);
	};

	// useEffect(() => {
	// 	const tmp = cartItems.map(data => {
	// 		const withoutTaxes = parseFloat((parseFloat(data.unitPrice) / ((parseFloat(props.iva < 0 ? 0 : props.iva) / 100) + 1)).toFixed(2));
	// 		const subtotal = parseFloat((parseFloat(data.quantity) * parseFloat(withoutTaxes)).toFixed(2));
	// 		const total = parseFloat((parseFloat(data.quantity) * parseFloat(data.unitPrice)).toFixed(2));
	// 		const tmpDiscount = parseFloat((parseFloat(subtotal) * (parseFloat(data.discount) / 100)).toFixed(2));
	// 		const taxes = parseFloat((parseFloat(parseFloat(subtotal) - parseFloat(tmpDiscount)) * (parseFloat(props.iva < 0 ? 0 : props.iva) / 100)).toFixed(2));

	// 		return { ...data, withoutTaxes, taxes, subtotal, total, tmpDiscount };
	// 	});
	// 	setCartItems(tmp);
	// 	props.getCart(tmp);
	// 	// eslint-disable-next-line
	// },[props.iva]);

	useEffect(() => {
		setCartItems([]);
	},[props.branchId]);

	const removeItemFromCart = position => {
		const tmpCartItems = cartItems
			.map((el, i) => (parseInt(i) !== parseInt(position)) && el)
			.filter(el => el);
		setCartItems(tmpCartItems);
		props.getCart(tmpCartItems);
	}

	return (
		<Fragment>
			<Form handleAdd={addItemToCart} codeToClear={props.codeToClear} branchId={props.branchId} />
			<Table
				iva={props.iva}
				cartItems={cartItems}
				removeItem={removeItemFromCart}
				codeToClear={props.codeToClear}
			/>
		</Fragment>
	);
}

export default Cart
import React, { useState } from 'react';

import FormDialog from './../../presentationals/FormDialog';

import Cart from './cart';

const Component = props => {
	const [ codeToClear, setCodeToClear ] = useState(null);

	const handleClose = () => {
		setCodeToClear(null);
		props.reload();
		props.handleClose();
	}

	return (
		<FormDialog 
			isOpen={props.isOpen}
      title='Componentes del Paquete'
      name="products"
      singular="product"
      disableEscape
      handleClose={handleClose}
      hideAction
		>	
			<Cart codeToClear={codeToClear} id={props.id} />
		</FormDialog>
	);
}

export default Component;
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
	container: {
		height: '63vh',
		maxHeight: '63vh',
	},
}));

export default useStyles;

import React, { Fragment } from 'react';

import clsx from 'clsx';

import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Avatar from '@material-ui/core/Avatar';

import ProfileMenu from './profileMenu';
import ListItems from './listItems';
import useStyles from './style';

import logo from './../../files/logo.png';

const owner = localStorage.getItem('name');

const Dashboard = ({ Routes }) => {
  
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Menu />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <div className={classes.container}>
          <Routes />
        </div>
      </main>
    </div>
  );
};

const Menu = props => {
  
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [profileOpen, setProfileOpen] = React.useState([false, null]);
  
  const handleDrawerAction = () => {
    setOpen(!open);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  
  const handleProfileOpen = e => {
    setProfileOpen([true, e.currentTarget]);
  }
  const handleProfileClose = e => {
    setProfileOpen([false, null]);
  }

  return (
    <Fragment>
      <AppBar position="absolute" color="transparent" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerAction}
            className={classes.menuButton}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
          <img src={logo} alt="NaraChips" className={classes.menuLogo} />
          <div className={classes.title} />
          <IconButton color="inherit">
            <Badge color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton 
            onClick={handleProfileOpen} 
            aria-controls="profile-menu" 
            aria-haspopup="true"
            className={classes.profileButton}
          >
            <Avatar alt={owner} src="#" className={classes.profile} />
          </IconButton>
          <ProfileMenu anchorEl={profileOpen[1]} isOpen={profileOpen[0]} handleClose={handleProfileClose} />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
        onMouseOver={handleDrawerOpen}
        onMouseLeave={handleDrawerClose}
      >
        <div className={classes.appBarSpacer} />
        <List className={classes.list}><ListItems isOpen={open} /></List>
      </Drawer>
    </Fragment>
  );
};

export default Dashboard;
import React from 'react';

import Container from './../../presentationals/Module.v2/Container';

import Filter from './_filter';
import GQL from './_gql';

const Rows = [
	{ label: 'Producto', key: 'Product name' },
	{ label: 'Cantidad', key: 'quantity', sort: 'text', align: 'center' },
	{ label: 'Unidad', key: 'Product BaseUnit name', sort: 'text', align: 'center' },
	{ label: 'Costo', key: 'cost', sort: 'text', format: 'currency', align: 'right' },
];
const title = 'Reporte de Mermas';
const operation = ['wasteReport', 'wasteReport'];

const Component = () => {
	return (
		<>
			<Container
				title={title}
				operation={operation}
				Rows={Rows}
				get={GQL.GET}
				filter
				noDelete
				noCreate
				noEdit
				noPagination
			/>
			<Filter />
		</>
	);
};

export default Component;

import { gql } from '@apollo/client';

const GET = gql`
  query ProductInventories(
    $limit: Int, $offset: Int, $companyId: ID, $branchId: ID $productId: ID, $date: Date
  ) {
    productInventories(
      limit: $limit, offset: $offset, companyId: $companyId,
      branchId: $branchId,
      productId: $productId
      date: $date
    ) {
      count
      rows {
        id
        productId
        name
        type
        unitCost
        companyId
        iva
        Inventories {
          Branch {
            id
            name
          }
          quantity
          unitCost
          cost
        }
      }
    }
  }
`;

export default { GET };

import { gql } from '@apollo/client';

const GET = gql`
  query SalesWithDiscount($status: [Int] $start: Date $end: Date $owner: Boolean $branchId: ID $like: String $city: String $companyId: Int){
    salesWithDiscount(status: $status start: $start end: $end owner: $owner branchId: $branchId like: $like city: $city companyId: $companyId) {
      count
      rows {
        Discount {
          name
        }
        Sales {
          qty
          discount
          total
          Branch {
            name
          }
        }
      }
    }
  }
`;

const obj = { GET };
export default obj;
import React, { Fragment, useState, useEffect } from 'react';

import Form from './form';
import Table from './table';

const Cart = props => {

	const [cartItems, setCartItems] = useState([]);

	useEffect(() => {
		setCartItems([]);
	}, [props.codeToClear]);

	useEffect(() => {
		if(props.lastProduction[0].length > 0) {
			setCartItems(props.lastProduction[0]);
			const tmp = props.lastProduction[0].map(el => ({ unitCompoundId: el.unitCompoundId, quantity: el.quantity, waste: el.waste }))
			props.getCart(tmp);
		}else {
			setCartItems([]);
			props.getCart([]);
		}
		// eslint-disable-next-line
	},[props.lastProduction])

	const addItemToCart = data => {
		setCartItems(cartItems => ([ ...cartItems, data ]));
		props.getCart([
			...cartItems,
			{ unitCompoundId: data.unitCompoundId, quantity: data.quantity, waste: data.waste }
		]);
	};

	const removeItemFromCart = position => {
		const tmpCartItems = cartItems
			.map((el, i) => (parseInt(i) !== parseInt(position)) && el)
			.filter(el => el);
		setCartItems(tmpCartItems);
		props.getCart(tmpCartItems);
	}

	return (
		<Fragment>
			<Form 
				handleAdd={addItemToCart}
				codeToClear={props.codeToClear}
				products={props.products}
			/>
			<Table
				cartItems={cartItems}
				removeItem={removeItemFromCart}
				codeToClear={props.codeToClear}
			/>
		</Fragment>
	);
}

export default Cart
import { gql } from '@apollo/client';

const GET = gql`
  query Clasifications($limit: Int $offset: Int $like: String){
    clasifications(limit: $limit offset: $offset like: $like) {
      count
      rows {
        id
        name
        show
        inventory
        companyId
      }
    }
  }
`;

const GET_BY_ID = gql`
  query Clasification($id: ID!){
    clasification(id: $id){
      id
      name
      show
      inventory
      companyId
    }
  }
`;

const CREATE = gql`
  mutation CreateClasification(
    $name: String!
    $show: Boolean!
    $inventory: Boolean!
    $companyId: Int
  ){
    createClasification(input:{
      name: $name
      show: $show
      inventory: $inventory
      companyId: $companyId
    }){
      id
      name
      show
      inventory
      companyId
    }
  }
`;

const UPDATE = gql`
  mutation UpdateClasification(
    $id: ID!
    $name: String!
    $show: Boolean!
    $inventory: Boolean!
    $companyId: Int
  ){
    updateClasification(
      id: $id
      input:{
        name: $name
        show: $show
        inventory: $inventory
        companyId: $companyId
      }
    ){
      id
      name
      show
      inventory
      companyId
    }
  }
`;

const DELETE = gql`
  mutation DeleteClasification($id: ID!){
    deleteClasification(id: $id){
      id
      name
      show
      inventory
      companyId
    }
  }
`;


const object = { GET, GET_BY_ID, CREATE, UPDATE, DELETE }
export default object;
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import locale from 'date-fns/locale/es';

const xls = (data, totals, name, filter) => {

	const start = filter.start 
  	? format(filter.start, 'dd-MMM-yyyy', { locale }).toUpperCase()
  	: format(new Date(), 'dd-MMM-yyyy', { locale }).toUpperCase();
  const end = filter.end 
  	? format(filter.end, 'dd-MMM-yyyy', { locale }).toUpperCase()
  	: format(new Date(), 'dd MMM yyyy', { locale }).toUpperCase();

	const filename = `${name}_${start}_AL_${end}.xlsx`;
		
	const firstHeader = [
		'PRODUCTOS',
		'CANT. INICIAL', "",
		'ENTRADAS', "",
		'SALIDAS', "",
		'CANT. FINAL', "",
	];
	const secondHeader = [
		'',
		"Cant.", "Costo",
		"Cant.", "Costo",
		"Cant.", "Costo",
		"Cant.", "Costo"
	];
	const merge = [
		{ s: { r:0, c:0 }, e: { r:1, c:0 } },
		{ s: { r:0, c:1 }, e: { r:0, c:2 } },
		{ s: { r:0, c:3 }, e: { r:0, c:4 } },
		{ s: { r:0, c:5 }, e: { r:0, c:6 } },
		{ s: { r:0, c:7 }, e: { r:0, c:8 } }
	];

	const dataRows = data.map(el => {
		return [
			`${el.Product.name.toUpperCase()} (${el.name})`,
			el.Start.quantity, el.Start.total,
			el.Ins.quantity, el.Ins.total,
			el.Outs.quantity, el.Outs.total,
			el.End.quantity, el.End.total,
		];
	});

	const lastRow = [
		totals.Start.quantity, totals.Start.total,
		totals.Ins.quantity, totals.Ins.total,
		totals.Outs.quantity, totals.Outs.total,
		totals.End.quantity, totals.End.total,
	];

	const rows = [
	  firstHeader,
	  secondHeader,
	  ...dataRows,
	  lastRow
	];

	const ws = XLSX.utils.aoa_to_sheet(rows);

	if(!ws['!merges']) ws['!merges'] = [];
	ws['!merges'] = merge;

	var wb = XLSX.utils.book_new();

	XLSX.utils.book_append_sheet(wb,ws,'MOV. DE INVENTARIO POR SUCURSAL');
	
	XLSX.writeFile(wb, filename);
}

export default xls;
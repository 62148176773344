import React, { Fragment, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import LinearProgress from '@material-ui/core/LinearProgress';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';

import Table from './../../presentationals/Table/helpers/Table';
import { currencyFormat } from '../../helpers';

import GQL from './_gql';

const TableComponent = props => {
  const [getInfo,{ data, loading }] = useLazyQuery(GQL.GET, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (props.branchId) {
      getInfo({ variables: { limit: 0, branchId: props.branchId, date: props.date } });
    }
  },[props.branchId, props.date]);

  return (
    <>
      { loading && <LinearProgress color='secondary' />}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            {
              (data && data.productInventories.count > 0) && data.productInventories.rows[0].Inventories.map((el, i) => {
                return (
                  <TableCell
                    key={el.Branch.name}
                    align='center'
                    colSpan={3}
                    style={{
                      background: (i % 2 === 0) ? '#c9c9c9' : '#fafafa',
                      borderColor: (i % 2 === 0) ? '#989898' : '#ddd',
                      minWidth: 10,
                    }}
                  >
                    <Typography variant='body2' noWrap>
                      <strong>{el.Branch.name}</strong>
                    </Typography>
                  </TableCell>
                );
              })
            }
          </TableRow>
          <TableRow>
            <TableCell style={{ top: 33 }}>
              Producto
            </TableCell>
            {
              (data && data.productInventories.count > 0) && data.productInventories.rows[0].Inventories.map((el, i) => {
                return (
                  <Fragment key={i}>
                    <TableCell
                      align='center'
                      style={{
                        background: (i % 2 === 0) ? '#c9c9c9' : '#fafafa',
                        borderColor: (i % 2 === 0) ? '#989898' : '#ddd',
                        top: 33,
                      }}
                    >
                      Cant.
                    </TableCell>
                    <TableCell
                      align='right'
                      style={{
                        background: (i % 2 === 0) ? '#c9c9c9' : '#fafafa',
                        borderColor: (i % 2 === 0) ? '#989898' : '#ddd',
                        top: 33,
                      }}
                    >
                      C.Unitario
                    </TableCell>
                    <TableCell
                      align='right'
                      style={{
                        background: (i % 2 === 0) ? '#c9c9c9' : '#fafafa',
                        borderColor: (i % 2 === 0) ? '#989898' : '#ddd',
                        top: 33,
                      }}
                    >
                      Total
                    </TableCell>
                  </Fragment>
                );
              })
            }
          </TableRow>
        </TableHead>
        <TableBody>
        {
          data && data.productInventories.rows.map(el => {
            return (
              <TableRow key={el.id}>
                <TableCell>
                  <Typography variant='body2' noWrap>
                    {el.name.toUpperCase()}
                  </Typography>
                </TableCell>
                {
                  el.Inventories.map((elCh, ii) => {
                    return (
                      <Fragment key={`${el.id}-${ii}`}>
                        <TableCell
                          align='center'
                          style={{
                            background: (ii % 2 === 0) ? '#ddd' : '#fafafa',
                            borderColor: (ii % 2 === 0) ? '#989898' : '#ddd',
                          }}
                        >
                          {parseFloat(parseFloat(elCh.quantity).toFixed(3))}
                        </TableCell>
                        <TableCell
                          align='right'
                          style={{
                            background: (ii % 2 === 0) ? '#ddd' : '#fafafa',
                            borderColor: (ii % 2 === 0) ? '#989898' : '#ddd',
                          }}
                        >
                          {currencyFormat(parseFloat(elCh.unitCost))}
                        </TableCell>
                        <TableCell
                          align='right'
                          style={{
                            background: (ii % 2 === 0) ? '#ddd' : '#fafafa',
                            borderColor: (ii % 2 === 0) ? '#989898' : '#ddd',
                          }}
                        >
                          {currencyFormat(parseFloat(elCh.cost))}
                        </TableCell>
                      </Fragment>
                    );
                  })
                }
              </TableRow>
            );
          })
        }
        </TableBody>
      </Table>
    </>
  );
};

export default TableComponent;

import { gql } from '@apollo/client';

const GET = gql`
	query Transfers(
		$limit: Int $offset: Int $status: [Int] $type: [Int], $start: Date, $end: Date, $transferBranchId: ID, $branchId: ID
	){
		transfers(limit: $limit offset: $offset status: $status type: $type, start: $start, end: $end, branchId: $branchId transferBranchId: $transferBranchId) {
			count
			totals {
        id name qty amount
      }
			rows {
				id
				type
				status
				folio
				serial
				createdAt
				background
				Branch {
					name
				}
				TransferBranch {
					name
				}
				User {
					name
				}
				total
			}
		}
	}
`;

const GET_BY_ID_EDIT = gql`
	query Transfer($id: ID!){
		transfer(id: $id) {
			id
			branchId
			transferBranchId
			TransferDetails {
				id
				productId
				productUnitId
				quantity
				Product {
					name
				}
				ProductUnit {
					name
				}
			}
		}
	}
`;

const CREATE = gql`
	mutation CreateTransfer(
		$branchId: ID!
		$transferBranchId: ID!
		$Products: [TransferDetailData]
	) {
		createTransfer(input: {
			branchId: $branchId
			transferBranchId: $transferBranchId
			Products: $Products
		}) {
			id
			status
			folio
			serial
			createdAt
			Branch {
				name
			}
			TransferBranch {
				name
			}
			User {
				name
			}
		}
	}
`;

const UPDATE = gql`
	mutation UpdateTransfer(
		$id: ID!
		$branchId: ID!
		$transferBranchId: ID!
		$Products: [TransferDetailData]
	) {
		updateTransfer(
			id: $id
			input: {
				branchId: $branchId
				transferBranchId: $transferBranchId
				Products: $Products
			}
		) {
			id
			status
			folio
			serial
			createdAt
			Branch {
				name
			}
			TransferBranch {
				name
			}
			User {
				name
			}
		}
	}
`;

const GET_BY_ID = gql`
	query Transfer($id: ID!){
		transfer(id: $id) {
			id
			TransferDetails {
				id
				quantity
				Product {
					name
				}
				ProductUnit {
					name
				}
			}
		}
	}
`;

const GET_BY_ID_TICKET = gql`
	query Transfer($id: ID!){
		transfer(id: $id) {
			id
			folio
			serial
			total
			Branch {
				id
				name
				BranchAddress {
					addressLine1
					addressLine2
					zipCode
					city
					State {
						name
					}
				}
				phone
			}
			TransferBranch {
				id
				name
			}
			createdAt
			User {
				id
				name
			}
			ConfirmUser {
				id
				name
			}
			TransferDetails {
				id
				productId
				quantity
				cost
				total
				Product {
					name
				}
				ProductUnit {
					name
				}
			}
		}
	}
`;

const DELETE = gql`
	mutation DeleteTransfer($id: ID! $authCommentary: String!){
		deleteTransfer(id: $id authCommentary: $authCommentary) {
			id
			status
			folio
			serial
			createdAt
			Branch {
				name
			}
			TransferBranch {
				name
			}
			User {
				name
			}
		}
	}
`;

const CONFIRM_TRANSFER = gql`
	mutation ConfirmTransfer($id: ID!){
		confirmTransfer(id: $id) {
			id
			status
			folio
			serial
			createdAt
			Branch {
				name
			}
			TransferBranch {
				name
			}
			User {
				name
			}
		}
	}
`;

const AUTH_TRANSFER = gql`
	mutation AuthTransfer($id: ID!){
		authTransfer(id: $id) {
			id
			status
			folio
			serial
			createdAt
			Branch {
				name
			}
			TransferBranch {
				name
			}
			User {
				name
			}
		}
	}
`;

const object = { CREATE, UPDATE, GET, GET_BY_ID, GET_BY_ID_TICKET, GET_BY_ID_EDIT, DELETE, CONFIRM_TRANSFER, AUTH_TRANSFER }

export default object;

import React, { useState, useEffect } from 'react';

import { useQuery } from '@apollo/client';

import Typography from '@material-ui/core/Typography';

import FilterDrawer from '../../presentationals/FilterDrawer.v2';
import Autocomplete from '../../presentationals/Autocomplete';

import { filter } from '../../apollo/cache';

import GQL_PRODUCT from '../reportProductInventory/_gql.js';

const defaultValues = {
	productUnitId: null,
};

const FilterDialog = props => {
	const [ values, setValues ] = useState(defaultValues);
	const [ products, setProducts ] = useState([]);

	const { data: dataProducts } = useQuery(GQL_PRODUCT.GET, { variables: { limit: 0 } });

	useEffect(() => {
		if(dataProducts){
			const data = dataProducts.productInventories.rows.map((el,i) => {
				const tag = parseInt(el.companyId) === 1 ? '| NRC' : '| CDE';
        const label = `${el.name.toUpperCase()} ${tag}`;
				return { 
					value: el.id,
					label,
				}
			}).filter(tmp => tmp);
			setProducts(data);
		}
	}, [dataProducts]);

	const handleAction = (e, data) => {
		const { startDate: start, endDate: end } = data;
		const productUnitId = values.productUnitId ? values.productUnitId.value : null;
		filter({ start, end, productUnitId });
	}

	const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

	return (
		<FilterDrawer 
			handleAction={handleAction}
			showDates
		>	
			<Typography variant="subtitle1">
        Producto:
      </Typography>
      <Autocomplete
        size='medium'
        name='productUnitId'
        variant='outlined'
        value={values.productUnitId}
        onChange={handleAutocompleteChange}
        options={products}
        noFormat
      />
		</FilterDrawer>
	);
}

export default FilterDialog;
import React, { useState } from 'react';

import { useMutation } from '@apollo/client';

import FormDialog from '../../presentationals/FormDialog';

import TextField from '../../presentationals/TextField';

import { parseError } from '../../helpers';
import { snackbar } from '../../apollo/cache';

import GQL from './_gql';

const defaultValues = {
	newPassword: '',
	confirmationPassword: '',
}

const CreateDialog = props => {

	const [ values, setValues ] = useState(defaultValues);
  const [ errors, setErrors ] = useState({});

  const [changePassowrd, {loading}] = useMutation(GQL.CHANGE_PASSWORD);

	const handleClose = () => {
		props.handleClose();
	}

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAction = async () => {
    try {
      setErrors({});
      if(values.newPassword === '') {
        setErrors({ newPassword: 'Escribe una nueva contraseña.' });
        return;
      }
      if(values.confirmationPassword === '') {
        setErrors({ confirmationPassword: 'Escribe una nueva contraseña.' });
        return;
      }
      if(values.confirmationPassword !== values.newPassword) {
        setErrors({ confirmationPassword: 'Las contraseñas no coinciden.' });
        return;
      }
      await changePassowrd({ variables: { userId: props.userId, password: values.confirmationPassword } });
      setValues(defaultValues);
      setErrors({});
      handleClose();
    } catch (e) {
      console.log(e.message);
      const parseErrors = parseError(e);
      if(parseErrors[0].name === 'BAD_USER_INPUT'){
        const key = Object.keys(parseErrors[0].message)[0];
        setErrors({ newPassword: parseErrors[0].message[key] });
      }
      if(parseErrors[0].name === 'GENERAL_ERROR'){
      	snackbar({
	      	isOpen: true, 
	        time: 3000,
	        label: parseErrors[0].message, 
	        severity: 'error'
	      });
      }
    }
  }

	return (
		<FormDialog 
			title='Cambio de contraseña'
			isOpen={props.isOpen} 
			handleClose={handleClose}
			handleAction={handleAction}
      isLoading={loading}
		>
			<TextField
        name='newPassword'
        label='Contraseña'
        type='password'
        value={values.newPassword}
        error={errors.newPassword && errors.newPassword}
        onChange={handleChange}
        required
      />
      <TextField
        name='confirmationPassword'
        label='Confirmar Contraseña'
        type='password'
        value={values.confirmationPassword}
        error={errors.confirmationPassword && errors.confirmationPassword}
        onChange={handleChange}
        required
      />
		</FormDialog>
	);
}

export default CreateDialog;
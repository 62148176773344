import React, { useState, useEffect, Fragment } from 'react';

import ListAltIcon from '@material-ui/icons/ListAltOutlined';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdfOutlined';

import { useQuery } from '@apollo/client';

import Module from './../../presentationals/Module';

import GQL from './../expense/_gql';

import FilterDialog from './../expense/filter.dialog';
import FormExpense from './../expense/form.dialog';
import ConceptDialog from './../expense/concept.dialog';

import SalePdf from './sale.pdf.document'

const Rows = [
	{ label: 'Fecha', key: 'date', format: 'date' },
	{ label: 'Folio', key: 'physicalFolio', sort: 'text' },
	{ label: 'Cliente', key: 'Branch name', sort: 'text' },
	{ label: 'Tipo', key: 'type', sort: 'text', format: 'expenseType' },
	{ label: 'Importe', key: 'total', format: 'currency', align: 'right' },
];

const Component = props => {
	const [ isOpen, setIsOpen ] = useState(false);
	const [ isOpenConcepts, setIsOpenConcepts ] = useState([0,false]);
	const [ formIsOpen, setFormIsOpen ] = useState(false);
	const [ filter, setFilter ] = useState({});

	const { data, loading, refetch } = useQuery(GQL.GET,{ variables: { type: [1] } });

	useEffect(() => {
		if(Object.keys(filter).length > 0){
			refetch(filter);
		}
	}, [filter, refetch]);

	const handleRows = (offset, limit) => {
		setFilter(filter => ({ ...filter, limit, offset }));
	}

	const handleFilter = () => {
		setIsOpen(true);
	}

	const handleAction = (action, data) => {
		switch(action){
			case 'close':
				setIsOpen(false);
				break;
			default:
				setFilter(filter => ({ ...filter, ...data }));
				break;
		}
	}

	const handleSearch = (value) => {
		setFilter(filter => ({ ...filter, like: value }));
	}

	const handleCreate = () => setFormIsOpen(true);
	const handleCloseCreate = () => setFormIsOpen(false);
	
	const handleCloseConcepts = () => setIsOpenConcepts([0,false]);
	const handleConcepts = (e,{ id }) => {
		setIsOpenConcepts([id,true]);
	};

	const handlePrintDocAction = async (e, data) => {
		await SalePdf(data.id);
	}

	const handlePrintDocCreate = async data => {
		await SalePdf(data.createExpense.id);
	}

	return (
		<Fragment>
			<Module
				isLoading={loading}
				name='Compras de Insumos'
				singular='Compra de Insumos'
				operation={['expense', 'expenses']}
				Rows={Rows}
				get={GQL.GET}
				handleRows={handleRows}
				getById={GQL.GET_BY_ID}
				create={GQL.CREATE}
				noEdit
				delete={GQL.DELETE}
				records={data && data.expenses}
				filter={handleFilter}
				handleSearch={handleSearch}
				handleCustomCreate={handleCreate}
				actions={[
					{ icon: <PictureAsPdfIcon/>, onClick: handlePrintDocAction, label: 'Nota de Venta'  },
					{ icon: <ListAltIcon/>, onClick: handleConcepts, label: 'Conceptos'  },
				]}
			/>
			<FilterDialog isOpen={isOpen} handleAction={handleAction} />
			<FormExpense handleCreate={handlePrintDocCreate} isOpen={formIsOpen} handleClose={handleCloseCreate} isPurchase />
			<ConceptDialog isOpen={isOpenConcepts[1]} handleClose={handleCloseConcepts} id={isOpenConcepts[0]} />
		</Fragment>
	);
}

export default Component;
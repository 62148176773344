import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	paddingX: {
		marginTop: theme.spacing(0.5),
		paddingLeft: theme.spacing(1.6),
		paddingRight: theme.spacing(1.6),
		[theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(0.5),
			paddingRight: theme.spacing(0.5),
    },
	},
	icon: {
		fontSize: 28,
		[theme.breakpoints.down('xs')]: {
      fontSize: 24,
    },
	},
}));

export default useStyles;

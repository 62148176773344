import React, { Fragment } from 'react';
import { useMutation } from '@apollo/client';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { currencyFormat } from './../../../../helpers/general';

import GQL from './../../_gql';

import IconButton from './helpers/iconButton';

const TableComponent = props => {
	const [ delteProduct ] = useMutation(GQL.DELTE_PRODUCT,{
		onCompleted: () => props.getCart()
	});

	const handleDelete = async (e, id) => {
		try{
  		await delteProduct({ variables: { id } });
  	}catch(e) {
  		console.log(e);
  	}
	}
	
	return (
		<Fragment>
			<TableContainer>
				<Table size="small">
					<TableHead>
	          <TableRow>
	            <TableCell>Acciones</TableCell>
	            <TableCell align='center'>Cant.</TableCell>
	            <TableCell>Producto</TableCell>
	            <TableCell align='right'>Costo U.</TableCell>
	            <TableCell align="right">Costo</TableCell>
	          </TableRow>
	        </TableHead>
	        <TableBody>
	        	{
	        		props.cartItems.map((el, i) => 
	      				<TableRow key={i}>
	      					<TableCell>
	      						<IconButton 
	      							label='Eliminar'
	      							icon={<DeleteOutlineIcon />}
	      							params={el.id}
	      							action={handleDelete}
	      							size='small'
	      						/>
	      					</TableCell>
			            <TableCell align='center'>{el.quantity}</TableCell>
			            <TableCell>{el.Product.name}</TableCell>
			            <TableCell align='right'>{currencyFormat(el.Product.cost)}</TableCell>
			            <TableCell align="right">{currencyFormat(parseFloat(el.Product.cost) * parseFloat(el.quantity))}</TableCell>
	      				</TableRow>
	      			)
	        	}
	        </TableBody>
				</Table>
			</TableContainer>
		</Fragment>
	)
}

export default TableComponent;
import { gql } from '@apollo/client';

const GET = gql`
  query Sales($start: Date $end: Date $limit: Int $offset: Int $owner: Boolean $branchId: ID $like: String $companyId: Int){
    sales(start: $start end: $end limit: $limit offset: $offset owner: $owner branchId: $branchId like: $like companyId: $companyId) {
      count
      rows {
        id
        folio
        name
        total
        discount
        description
        status
        clientCreatedAt
        Branch {
          name
          companyId
        }
        User {
          name
        }
      }
    }
  }
`;

const GET_BY_ID = gql`
  query Sale($id: ID!) {
    sale(id: $id) {
      id
      folio
      name
      total
      description
      status
      clientCreatedAt
      Branch {
        name
        companyId
      }
      User {
        name
      }
      ProductSales {
        id
        qty
        unitPrice
        status
        clientCreatedAt
        Product{
          id
          name
          cost
          price
        }
      }
    }
  }
`;

const obj = { GET, GET_BY_ID };
export default obj;
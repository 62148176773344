import { gql } from '@apollo/client';

const GET = gql`
  query Royalties($owner: Boolean $branchId: ID $months: [Int] $year: Int){
    royalties(owner: $owner branchId: $branchId months: $months, year: $year) {
      count
      rows {
        Branch {
          name
        }
        Sales {
          month
          royalty
          totalSale
          saleNoteId
        }      
      }
    }
  }
`;

const obj = { GET };
export default obj;
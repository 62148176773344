import React from 'react';

import { Router } from 'react-router-dom';

import blue from '@material-ui/core/colors/blue';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

import Dashboard from './containers/dashboard';
import history from './config/routes/history';
import Routes from './config/routes';

import 'typeface-roboto';

const theme = createTheme({
  palette: {
    primary: blue,
  },
});

const Main = () =>
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
      <Router history={history}>
        {
          !localStorage.getItem('token') 
            ? <Routes /> 
            : <Dashboard Routes={Routes} />
        }
      </Router>
  </MuiThemeProvider>

export default Main;
import React, { useState, useEffect } from 'react';

import { useMutation } from '@apollo/client';

import FormDialog from './../../../../presentationals/FormDialog';
import TextField from './../../../../presentationals/TextField';

import GQL from './../../_gql';

const defaultValues = {
	name: '',
	unit: 0,
	baseCost: 0,
}

const Component = props => {
	const [ values, setValues ] = useState(defaultValues);
	const [ errors, setErrors ] = useState({});

	const [ updateProductUnit, { loading } ] = useMutation(GQL.UPDATE_PROUCT_UNIT);

	useEffect(() => {
		if(props.extraData) {
			setValues(props.extraData);
		}
	}, [props.extraData])

	const handleClose = () => {
		props.handleClose();
		setValues(defaultValues);
	}

	const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAction = async () => {
  	try {

  		if(values.name.trim() === '' || !values.name){ 
  			const e = new Error('Proporciona un nombre.');
  			e.name = 'name';
  			throw e; 
  		}
  		if(!values.unit || parseFloat(values.unit) <= 0){ 
  			const e = new Error('Proporciona una cantidad de unidades mayor a cero.');
  			e.name = 'unit';
  			throw e; 
  		}
  		if(parseFloat(values.baseCost) < 0){ 
  			const e = new Error('Proporciona un costo base mayor o igual a cero.');
  			e.name = 'baseCost';
  			throw e; 
  		}
  		await updateProductUnit({ variables: { id: props.id, name: values.name, unit: parseFloat(values.unit), baseCost: parseFloat(values.baseCost) } });
  		handleClose();
  		setErrors({});
  	}catch(e) {
  		setErrors({ [e.name]: e.message });
  	}
  }

  return (
		<FormDialog 
			isOpen={props.isOpen}
      title="Actualizar Unidad"
      name="payment"
      singular="payment"
      disableEscape
      handleClose={handleClose}
      actionLabel="Actualizar"
      handleAction={handleAction}
      isLoading={loading}
		>	
		<TextField
			name='name'
      label='Nombre'
      value={values.name}
      error={errors.name}
      onChange={handleChange}
      noFormat
		/>
		<TextField
			name='unit'
      label='#Unidades'
      type='number'
      value={values.unit}
      error={errors.unit}
      onChange={handleChange}
      noFormat
		/>
		<TextField
			name='baseCost'
      label='Costo Base'
      type='number'
      value={values.baseCost}
      error={errors.baseCost}
      onChange={handleChange}
      noFormat
		/>
		</FormDialog>
	);
}

export default Component;
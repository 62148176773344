import { gql } from '@apollo/client';

const GET = gql`
  query Requisitions($limit: Int $offset: Int, $start: Date, $end: Date $status: [Int]){
    requisitions(start: $start, end: $end, limit: $limit offset: $offset, status: $status) {
      count
      rows {
        id
        folio
        serial
        createdAt
        description
        Branch {
          name
        }
      }
    }
  }
`;

const GET_BY_ID = gql`
  query Requisition($id: ID!){
    requisition(id: $id) {
      folio
      serial
      createdAt
      companyId
      description
      Branch {
        name
      }
      User {
        name
      }
      ProductRequisitions {
        id
        quantity
        sendQuantity
        productUnitId
        Product {
          name
          Clasification {
            name
          }
          BranchInventoryBases {
            maximum
            minimum
            branchId
            quantity
          }
        }
        ProductUnit {
          name
        }
        ProductUnit {
          name
        }
      }
    }
  }
`;

const CONFIRM_STATUS = gql`
  mutation ConfirmRequisition($id: ID!, $Products: [SendProductRequisitionData]) {
    confirmRequisition(id: $id, Products: $Products) {
      id
      ProductRequisitions {
        id
        quantity
        Product {
          name
        }
        ProductUnit {
          name
        }
      }
    }
  }
`;

const CLOSE_REQUISITION = gql`
  mutation CloseRequisition($id: ID!) {
    closeRequisition(id: $id) {
      id
    }
  }
`;

const DELETE = gql`
  mutation DeleteRequisition($id: ID!) {
    deleteRequisition(id: $id) {
      id
    }
  }
`;

const CREATE = gql`
  mutation CreateRequisition(
    $branchId: ID
    $Products: [ProductRequisitionData]
    $description: String
  ){
    createRequisition(input: {
      description: $description
      branchId: $branchId
      Products: $Products
    }) {
      id
      folio
      serial
      createdAt
      Branch {
        id
        name
      }
      ProductRequisitions {
        id
        quantity
        productId
        Product {
          name
        }
        ProductUnit {
          name
        }
      }
    }
  }
`;

const obj = { GET, GET_BY_ID, CLOSE_REQUISITION, CONFIRM_STATUS, CREATE, DELETE };
export default obj;


import React, { Fragment, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import Form from './form';
import Table from './table';
import GQL from './../_gql';

const Cart = props => {
	const [ cartItems, setCartItems ] = useState([]);

	const [ getSaleNote ] = useLazyQuery(GQL.GET_BY_ID,{
		fetchPolicy: 'network-only',
		onCompleted: data => {
			data.saleNote && setCartItems(data.saleNote.SaleNotePayments || []);
		}
	});

	const getCart = async () => {
		try {
			await getSaleNote({ variables: { id: props.id } });
		}catch(e) {
			console.log(e);
		}
	}

	useEffect(() => {
		props.id && getCart();
		// eslint-disable-next-line
	},[props.id]);

	return (
		<Fragment>
			<Form codeToClear={props.codeToClear} customerId={props.customerId} id={props.id} getCart={getCart} />
			<Table cartItems={cartItems} codeToClear={props.codeToClear} customerId={props.customerId} id={props.id} getCart={getCart} />
		</Fragment>
	);
}

export default Cart
import React, { useState, useEffect, Fragment } from 'react';
import { useMutation } from '@apollo/client';
import { format, parseISO } from 'date-fns';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';

import { currencyFormat } from './../../../../helpers/general';

import GQL from './../../_gql';

import IconButton from './helpers/iconButton';
import printSaleNotePayment from './../../payment.pdf';

const defaultValues = {
	total: 0
}

const paymentForms = { 
  "01": "Efectivo",
  "100": "Depósito",
  "02": "Cheque nominativo",
  "03": "Transferencia electrónica de fondos",
  "04": "Tarjeta de crédito",
  "05": "Monedero electrónico",
  "06": "Dinero electrónico",
  "15": "Condonación",
  "17": "Compensación",
  "23": "Novación",
  "28": "Tarjeta de débito",
  "30": "Aplicación de anticipos",
  "31": "Intermediario pagos",
  "99": "Por definir",
};

const TableComponent = props => {
	const [ values, setValues ] = useState(defaultValues);
	const [ deleteSaleNotePayment ] = useMutation(GQL.DELETE_SALE_NOTE_PAYMENT,{
		update(cache, { data }) {
      try{
        const oldQuery = cache.readQuery({ query: GQL.GET, variables: { customerId: props.customerId, paid: false } });
        const payment = parseFloat(data.deleteSaleNotePayment.total);
        const newQuery = oldQuery.saleNotes.rows.map(el => {
          if(parseInt(el.id) === parseInt(props.id)){
            const newPayment = parseFloat(el.payment) - payment;
            const newBalance = parseFloat(el.balance) + payment;
            return { ...el, balance: newBalance, payment: newPayment };
          }
          return el
        });
        cache.writeQuery({
          query: GQL.GET,
          variables: { customerId: props.customerId, paid: false },
          data: { saleNotes: { ...oldQuery.saleNotes, rows: newQuery } }
        });

        const oldQuery1 = cache.readQuery({ query: GQL.GET_CUSTOMER });
        const newQuery1 = oldQuery1.customerReceivable.rows.map(el => {
          if(parseInt(el.id) === parseInt(props.customerId)){
            const newPayment = parseFloat(el.payment) - payment;
            const newBalance = parseFloat(el.balance) + payment;
            return { ...el, payment: newPayment, balance: newBalance };
          }
          return el;
        });
        cache.writeQuery({
          query: GQL.GET_CUSTOMER,
          data: { customerReceivable: { ...oldQuery1.customerReceivable, rows: newQuery1 } }
        });
      }catch(e){
        console.log(e.message);
      }
    },
		onCompleted: () => props.getCart()
	});

	useEffect(() => {
    setValues(defaultValues);
  }, [props.codeToClear]);

  useEffect(() => {
		const tmp = props.cartItems.reduce((object, el) => {
			return {
				total: parseFloat(object.total) + parseFloat(el.total)
			};
		}, defaultValues);
		setValues(tmp);
	}, [props.cartItems]);


	const handleDeletePayment = async (e, id) => {
		try{
  		await deleteSaleNotePayment({ variables: { id } });
  	}catch(e) {
  		console.log(e);
  	}
	}

	const handlePrint = async id => {
		try{
  		await printSaleNotePayment(props.id, id);
  	}catch(e) {
  		console.log(e);
  	}
	}
	
	return (
		<Fragment>
			<TableContainer>
				<Table size="small">
					<TableHead>
	          <TableRow>
	            <TableCell>Acciones</TableCell>
	            <TableCell>Fecha</TableCell>
	            <TableCell>Folio</TableCell>
	            <TableCell>Forma de Pago</TableCell>
	            <TableCell align="right">Abono</TableCell>
	          </TableRow>
	        </TableHead>
	        <TableBody>
	        	{
	        		props.cartItems.map((el, i) => 
	      				<TableRow key={i}>
	      					<TableCell>
	      						<IconButton 
	      							label='Eliminar'
	      							icon={<DeleteOutlineIcon />}
	      							params={el.id}
	      							action={handleDeletePayment}
	      							size='small'
	      						/>
	      					</TableCell>
			            <TableCell>{format(parseISO(el.createdAt), 'dd/MM/yyyy HH:mm')}</TableCell>
			            <TableCell>{<Link onClick={() => handlePrint(el.id)}>{el.serial}-{el.folio}</Link>}</TableCell>
			            <TableCell>{paymentForms[el.paymentForm]}</TableCell>
			            <TableCell align="right">{currencyFormat(el.total)}</TableCell>
	      				</TableRow>
	      			)
	        	}
	        	<TableRow>
	        		<TableCell colSpan={4} align="right"><strong>Total:</strong></TableCell>
	            <TableCell align="right"><strong>{props.currency} {currencyFormat(values.total)}</strong></TableCell>
	        	</TableRow>
	        </TableBody>
				</Table>
			</TableContainer>
		</Fragment>
	)
}

export default TableComponent;
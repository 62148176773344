import React, { useState, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';

import Grid from '@material-ui/core/Grid';

import SelectField from './../../presentationals/SelectField';
import TextField from './../../presentationals/TextField';
import FormDialog from './../../presentationals/FormDialog';

import { parseError } from './../../helpers';
import { snackbar } from './../../apollo/cache';

import GQL from './_gql';
import Cart from './cart';

const defaultValues = {
	type: 2,
	description: "",
	Products: [],
	branchId: 8,
};

const typesOptions = [
	{ value: 1, label: 'Entrada' },
	{ value: 2, label: 'Salida' },
];

const Component = props => {
	const [values, setValues] = useState(defaultValues);
	const [codeToClear, setCodeToClear] = useState(null);
	const [errors, setErrors] = useState({});
	const [products, setProducts] = useState([]);

	const [createCediInventoryMovement, { loading }] = useMutation(GQL.CREATE, {
		update(cache, { data }){
			const oldQuery = cache.readQuery({ query: GQL.GET, variables: { status: [1] } });
			cache.writeQuery({
	      query: GQL.GET,
	      variables: { status: [1] },
	      data: { cediInventoryMovements: {
	      	...oldQuery.cediInventoryMovements,
	      	rows: [
	      		data.createCediInventoryMovement, ...oldQuery.cediInventoryMovements.rows
	      	]
	      } }
	    });
		},
		onCompleted: data => {
			props.handleCreate && props.handleCreate(data);
		}
	});

	const [updateInventoryMovement, { loading: loadingUpdate }] = useMutation(GQL.UPDATE);

	const [getInventoryMovement, { data: inventoryMovementData }] = useLazyQuery(GQL.GET_BY_ID_EDIT, { fetchPolicy: 'network-only' })
	
	useEffect(() => {
		if (props.id) {
			getInventoryMovement({ variables: { id: props.id } });
		}
	}, [props.id, getInventoryMovement]);

	useEffect(() => {
		if (inventoryMovementData) {
			const Products = inventoryMovementData.cediInventoryMovement.CediInventoryMovementDetails.map(el => {
				const productUnit = {
					productId: el.productId,
					value: el.productUnitId,
					label: `${el.Product.name.trim()} ${el.ProductUnit.name.trim()}`.trim().toUpperCase()
				};
				return { quantity: parseFloat(el.quantity), productUnit };
			});
			setValues(values => ({
				description: inventoryMovementData.cediInventoryMovement.description,
				type: inventoryMovementData.cediInventoryMovement.type,
				branchId: inventoryMovementData.cediInventoryMovement.branchId,
				Products,
			}));
			setProducts(Products);
		}
	}, [inventoryMovementData]);

	const handleClose = () => {
		setValues(defaultValues);
		setCodeToClear(null);
		setProducts([]);
		props.handleClose();
	}

	const handleAction = async () => {
		try {
			if(!values.description){ 
  			const e = new Error('La justificación es obligatoria.')
  			e.name = 'description';
  			e.input = true;
  			throw e; 
  		}
			if(!values.type){ 
  			const e = new Error(' ')
  			e.name = 'type';
  			e.input = true;
  			throw e; 
  		}

			const variables = {
				...values,
				Products: values.Products.map(el => ({
					quantity: parseFloat(el.quantity),
					productId: el.productUnit.productId,
					productUnitId: el.productUnit.value,
				}))
			};

			if (props.id) {
				await updateInventoryMovement({ variables: { ...variables, id: props.id } });
			} else {
				await createCediInventoryMovement({ variables });
			}

			// await createCediInventoryMovement({ variables });
			setValues(defaultValues);
			setCodeToClear(null);
			setErrors({});
			props.handleClose();
			setProducts([]);
		}catch(e) {
			console.log(e);
			if(e.input) {
				setErrors({ [e.name]: e.message });
				return true;
			}
			const parseErrors = parseError(e);
      if(parseErrors[0].name === 'GENERAL_ERROR'){
      	snackbar({
	      	isOpen: true, 
	        time: 3000,
	        label: parseErrors[0].message, 
	        severity: 'error'
	      });
      }
		}
	}

	const getCart = data => {
    setValues(values => ({ ...values, Products: data }));
  }

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  return (
		<FormDialog 
			isOpen={props.isOpen}
			isLoading={loading || loadingUpdate}
      title={`${props.id ? 'Actualizar' : 'Nueva'} entrada/salida de invetario`}
      name="cart"
      singular="Cart"
      disableEscape
      width="md"
      handleClose={handleClose}
      handleAction={handleAction}
      rightContent={
      	<Grid container spacing={1}>
      		<Grid item md={2} xs={false} />
      		<Grid item md={6} xs={12}>
      			<SelectField
							label=''
							name='branchId'
							value={values.branchId}
							options={[
								{ label: 'BODEGA NARACHIPS', value: 8 },
								{ label: 'BODEGA CASA DEL ELOTE', value: 24 },
								{ label: 'PRODUCCIÓN', value: 23 },
							]}
							noFormat
							size='small'
							variant='outlined'
							onChange={handleChange}
						/>
      		</Grid>
				  <Grid item md={4} xs={12}>
		        <SelectField 
			        name='type'
			        label='Tipo' 
			        variant='outlined'
			        onChange={handleChange}
			        options={typesOptions}
			        value={values.type}
			        error={errors.type}
			        noFormat
			        size='small'
			      />
			    </Grid>
		    </Grid>
      }
		>
			<Cart getCart={getCart} codeToClear={codeToClear} branchId={values.branchId} products={products} />
			<br/>
      <Grid container spacing={1}>
			  <Grid item xs={12} md={12}>
					<TextField 
			      name='description'
			      label='Justificación' 
			      variant='outlined'
			      onChange={handleChange}
			      value={values.description}
			      error={errors.description}
			      multiline
			      noFormat
			    />
				</Grid>
			</Grid>
		</FormDialog>
	);
}

export default Component;
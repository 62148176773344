import React, { useState } from 'react';

import Typography from '@material-ui/core/Typography';

import FilterDrawer from '../../presentationals/FilterDrawer.v2';
import SelectField from '../../presentationals/SelectField';

import { filter } from '../../apollo/cache';

const branchOptions = [
  { label: 'BODEGA NARACHIPS', value: 8 },
  { label: 'BODEGA CASA DEL ELOTE', value: 24 },
  { label: 'PRODUCCIÓN', value: 23 },
];

const defaultValues = {
  branchId: 8,
};

const FilterDialog = () => {
  const [values, setValues] = useState(defaultValues);

  const handleAction = (e, data) => {
    const { startDate: start, endDate: end } = data;
    filter({
      branchId: parseInt(values.branchId) !== -1 ? values.branchId : null,
      start,
      end,
    });
  };

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <FilterDrawer
      handleAction={handleAction}
      showDates
    >
      <Typography variant="subtitle1">
        Sucursal:
      </Typography>
      <SelectField
        name='branchId'
        variant='outlined'
        label=''
        value={values.branchId}
        onChange={handleChange}
        options={branchOptions}
        noFormat
      />
    </FilterDrawer>
  );
};

export default FilterDialog;

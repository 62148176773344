import React, { useState, useEffect, Fragment } from 'react';

import { useQuery, useLazyQuery } from '@apollo/client';
// import Link from '@material-ui/core/Link';

import format from 'date-fns/format';
import { es } from 'date-fns/locale';

import Module from './../../presentationals/Module';

import GQL_BRANCH from './../branch/_gql';
import GQL from './_gql';
import FilterDialog from './filter.dialog';
// import PDF from './../saleNote/pdf.a4';

const Rows = [
	{ label: 'Fecha', key: 'createdAt', format: 'date' },
	{ label: 'Concepto', key: 'concept' },
	{ label: 'Cargo', key: 'charge', format: 'currency', align: 'right' },
	{ label: 'Abono', key: 'payment', format: 'currency', align: 'right' },
	{ label: 'Saldo', key: 'balance', format: 'currency', align: 'right' },
];
const currentDate = format(new Date(), 'dd MMM yyyy', { locale: es }).toUpperCase();

const Component = props => {
	const [ isOpen, setIsOpen ] = useState(false);
	const [ branchData, setBranchData ] = useState({});
	const [ title, setTitle ] = useState([currentDate, currentDate]);
	const [ filter, setFilter ] = useState({});

	const [ getBranch ] = useLazyQuery(GQL_BRANCH.GET_BY_ID,{
		fetchPolicy: 'network-only',
		onCompleted: data => {
			setBranchData(data.branch);
		}
	});

	const { data, refetch, loading } = useQuery(GQL.GET,{ 
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if(Object.keys(filter).length > 0){
			refetch(filter);
		}
	}, [filter, refetch]);

	const handleRows = (offset, limit) => {
		setFilter(filter => ({ ...filter, limit, offset }));
	}

	const handleAction = (action, data) => {
		switch(action){
			case 'close':
				setIsOpen(false);
				break;
			default:
				const start = format(data.start, 'dd MMM yyyy', { locale: es }).toUpperCase();
				const end = format(data.end, 'dd MMM yyyy', { locale: es }).toUpperCase();
				
				setTitle([start,end]);
				getBranch({ variables: { id: data.branchId } });
				setFilter(filter => ({ ...filter, ...data }));
				break;
		}
	}

	// const handleSearch = (value) => {
	// 	setFilter(filter => ({ ...filter, like: value }));
	// }

	const handleFilter = () => {
		setIsOpen(true);
	}

	// const handlePdf = (id) => {
	// 	if(!id) return false;
	// 	PDF(id);
	// }
	
	return (
		<Fragment>
			<Module
				moduleCode='ADES01'
				isLoading={loading}
				name={branchData.id ? `Estado de Cuenta ${branchData.name} (${title[0]} al ${title[1]})` : 'Estado de Cuenta'}
				singular='Estado de Cuenta'
				operation={['branchMovement', 'branchMovements']}
				Rows={Rows}
				get={GQL.GET}
				handleRows={handleRows}
				filter={handleFilter}
				noEdit
				noCreate
				noDelete
				records={data && data.branchMovements}
			/>
			<FilterDialog isOpen={isOpen} handleAction={handleAction} />
		</Fragment>
	);
}

export default Component;
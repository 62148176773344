import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) => ({
	container: { 
		flexGrow: 1,
		display: 'flex',
	  alignItems: 'center',
	  justifyContent: 'center',
		background: 'rgba(255,255,255, 0.5)',
	},
	box: {
		display: 'block',
		textAlign: 'center'
	}
}));

const Component = ({params, action, ...props}) => {
	
	const classes = useStyles();

	return (
		<div className={classes.container}>
			<div className={classes.box}>
				<CircularProgress
	        size={60}
	        thickness={3}
	        value={100}
	      />
	      <Typography variant="subtitle1">
	        Espere un momento...
	      </Typography>
			</div>
		</div>
	);

}

export default Component;
import { gql } from '@apollo/client';

const GET = gql`
	query CediInventoryMovements(
		$limit: Int $offset: Int $status: [Int] $type: [Int]
		$start: Date $end: Date
	){
		cediInventoryMovements(start: $start end: $end limit: $limit offset: $offset status: $status type: $type) {
			count
			rows {
				id
				status
				folio
				serial
				type
				description
				createdAt
				User {
					name
				}
			}
		}
	}
`;

const CREATE = gql`
	mutation CreateCediInventoryMovement(
		$type: Int!
		$branchId: ID!
		$description: String!
		$Products: [CediInventoryMovementDetailData]
	) {
		createCediInventoryMovement(input: {
			type: $type
			branchId: $branchId
			description: $description
			Products: $Products
		}) {
			id
			status
			folio
			serial
			type
			createdAt
			User {
				name
			}
		}
	}
`;

const GET_BY_ID = gql`
	query CediInventoryMovement($id: ID!){
		cediInventoryMovement(id: $id) {
			id
			CediInventoryMovementDetails {
				id
				quantity
				Product {
					name
				}
				ProductUnit {
					name
				}
			}
		}
	}
`;

const DELETE = gql`
	mutation DeleteCediInventoryMovement($id: ID!){
		deleteCediInventoryMovement(id: $id) {
			id
			status
			folio
			serial
			type
			createdAt
			User {
				name
			}
		}
	}
`;

const UPDATE_STATUS = gql`
	mutation UpdateCediInventoryMovementStatus($id: ID!, $status: Int!){
		updateCediInventoryMovementStatus(id: $id, status: $status) {
			id
			status
			folio
			serial
			type
			createdAt
			User {
				name
			}
		}
	}
`;

const GET_BY_ID_EDIT = gql`
	query CediInventoryMovement($id: ID!){
		cediInventoryMovement(id: $id) {
			id
			status
			type
			folio
			serial
			description
			createdAt
			branchId
			CediInventoryMovementDetails {
				id
				quantity
				productId
				productUnitId
				Product {
					name
				}
				ProductUnit {
					name
				}
			}
		}
	}
`;

const UPDATE = gql`
	mutation UpdateCediInventoryMovement(
		$id: ID!
		$type: Int!
		$branchId: ID!
		$description: String!
		$Products: [CediInventoryMovementDetailData]
	) {
		updateCediInventoryMovement(id: $id input: {
			type: $type
			branchId: $branchId
			description: $description
			Products: $Products
		}) {
			id
			status
			folio
			serial
			type
			createdAt
			User {
				name
			}
		}
	}
`;

const object = { CREATE, GET, GET_BY_ID, DELETE, UPDATE_STATUS, GET_BY_ID_EDIT, UPDATE }

export default object;

import React from 'react';

import FilterDrawer from './../../presentationals/FilterDrawer';
import { filter } from '../../apollo/cache';

const FilterDialog = props => {

	const handleClose = () => {
		props.handleAction('close');
	}

	const handleAction = (e, data) => {
		const { startDate: start, endDate: end, branchId } = data;
		props.handleAction(null, { start, end, branchId });
		filter({ start, end, branchId });
	}

	return (
		<FilterDrawer 
			isOpen={props.isOpen}
			handleClose={handleClose}
			handleAction={handleAction}
			showDates
			showBranches
		>	
		</FilterDrawer>
	);
}

export default FilterDialog;
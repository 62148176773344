import { gql } from '@apollo/client';

const GET = gql`
  query PurchaseOrders($order: [String], $limit: Int $offset: Int $like: String $status: [Int] $start: Date $end: Date, $paymentForm: [String], $supplierId: ID){
    purchaseOrders(order: $order, limit: $limit, offset: $offset, like: $like, status: $status, start: $start, end: $end, paymentForm: $paymentForm, supplierId: $supplierId) {
      count
      totals {
        id name qty amount
      }
      rows {
        id
        status
        folio
        serial
        total
        createdAt
        paymentForm
        subFolio
        Supplier {
          name
        }
        Branch {
          name
        }
        User {
          id
          username
          name
        }
      }
    }
  }
`;

const GET_BY_ID = gql`
  query PurchaseOrder($id: ID!){
    purchaseOrder(id: $id) {
      id
      subFolio
      PurchaseOrderDetails {
        id
        quantity
        receivedQuantity
        Product {
          name
        }
        concept
        ProductUnit {
          name
        }
      }
    }
  }
`;

const GET_BY_ID_PDF = gql`
  query PurchaseOrder($id: ID!){
    purchaseOrder(id: $id) {
      id
      status
      folio
      serial
      date
      createdAt
      description
      paymentForm
      iva
      subtotal
      discount
      taxes
      total
      subFolio
      Branch {
        name
      }
      User {
        name
      }
      PurchaseOrderDetails {
        concept
        quantity
        unitPrice
        subtotal
        discount
        taxes
        total
        Product {
          name
        }
        ProductUnit {
          name
        }
      }
    }
  }
`;

const CREATE = gql`
  mutation CreatePurchaseOrder(
    $iva: Int
    $date: Date!
    $description: String
    $supplierId: ID!
    $paymentForm: String!
    $Products: [ProductPurchaseOrderData]
    $branchId: ID
    $subFolio: String
  ) {
    createPurchaseOrder(input: {
      iva: $iva
      date: $date
      description: $description
      supplierId: $supplierId
      paymentForm: $paymentForm
      Products: $Products
      branchId: $branchId
      subFolio: $subFolio
    }) {
      id
      status
      folio
      serial
      total
      subFolio
      createdAt
      paymentForm
      Supplier {
        name
      }
      Branch {
        name
      }
      User {
        id
        username
        name
      }
    }
  }
`;

const AUTHORIZE = gql`
  mutation AuthorizePurchaseOrder($id: ID!) {
    authorizePurchaseOrder(id: $id) {
      id
      status
      folio
      serial
      total
      subFolio
      User {
        id
        username
        name
      }
    }
  }
`;

const DELETE = gql`
  mutation DeletePurchaseOrder($id: ID!) {
    deletePurchaseOrder(id: $id) {
      id
    }
  }
`;

const AUTH_PURCHASE_ORDER = gql`
  mutation AuthorizePurchaseOrderV2($id: ID! $Products: [ProductAuth]) {
    authorizePurchaseOrderV2(id: $id Products: $Products) {
      id
      status
    }
  }
`;

const object = {
  GET,
  GET_BY_ID,
  CREATE,
  AUTHORIZE,
  GET_BY_ID_PDF,
  DELETE,
  AUTH_PURCHASE_ORDER
};

export default object;

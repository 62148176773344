import React, { useEffect, useState } from 'react';

import { useQuery, useLazyQuery } from '@apollo/client';

import FormDialog from './../../../presentationals/FormDialog';

import GQL_BRANCH from './../../branch/_gql';
import GQL from './_gql';
import Element from './element';

const Component = props => {

	const [ options, setOptions ] = useState([]);

	const { data: branches, loading: loadingBranches  } = useQuery(GQL_BRANCH.GET_AUTOCOMPLETE, { variables: { limit: 0 } });
	const [ getBaseTotals, { data, loading } ] = useLazyQuery(GQL.GET);
	
	useEffect(() => {
		if(props.id) {
			getBaseTotals({ variables: { productId: props.id } });
		}
	}, [props.id, getBaseTotals]);

	useEffect(() => {
		if(branches && branches.branches){
			const tmpUnitTotals = data ? data.branchInventoryBases : [];
			const tmpBranches = branches.branches.rows.map(el => {
				const tmp = tmpUnitTotals.find(elCh => parseInt(elCh.branchId) === parseInt(el.id));
				if(tmp){
					return { id: el.id, name: el.name, productUnitId: tmp.id, maximum: tmp.maximum, minimum: tmp.minimum };
				}
				return { id: el.id, name: el.name };
			})
			setOptions(tmpBranches)
		}
	}, [branches, data]);

	const handleClose = e => {
		props.handleClose(e, 'close');
	}

	return (
		<FormDialog 
			isOpen={props.isOpen}
      title={`Máximos y mínimos de ${props.name}`}
      disableEscape
      isLoading={(loading || loadingBranches) && true}
      handleClose={handleClose}
      hideAction
		>	
			{options.map(el => 
				<Element 
					key={el.id} 
					productId={props.id} 
					productUnitId={el.productUnitId} 
					maximum={el.maximum}
					minimum={el.minimum}
					branch={{ id: el.id, label: el.name }} 
				/>
			)}
		</FormDialog>
	);
}



export default Component;
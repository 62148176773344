import React, { useState } from 'react';

import { useMutation } from '@apollo/client';

import DeleteConfirmDialog from './../../presentationals/ConfirmDialog';
import TextField from './../../presentationals/TextField';

import { parseError } from './../../helpers';
import { snackbar } from './../../apollo/cache';

import GQL from './_gql';

const DeleteDialog = props => {

	const [ value, setValue ] = useState('');
	const [ error, setError ] = useState('');

	const [ deleteTransfer ] = useMutation(GQL.DELETE, {
		update(cache, { data }){
			cache.evict({ id: `Transfer:${data.deleteTransfer.id}` });
			cache.gc();
		},
		onCompleted: () => handleClose()
	});
	
	const handleActionConfirmDelete = async () => {
  	try {
  		if(value === ''){
  			const commentaryError = new Error('Proporciona el motivo de la declinación.');
  			commentaryError.name = 'formError';
  			throw commentaryError;
  		}
  		await deleteTransfer({ variables: { id: props.params, authCommentary: value } });
  		snackbar({
	    	isOpen: true, 
	      time: 3000,
	      label: 'La declinación de esta transferencia fue exitosa.', 
	      severity: 'success'
	    });
  	}catch(e) {
  		if(e.name === 'formError'){
  			setError(e.message);
  			return;
  		}
  		const parseErrors = parseError(e);
      if(parseErrors[0].name === 'GENERAL_ERROR'){
      	snackbar({
	      	isOpen: true, 
	        time: 3000,
	        label: parseErrors[0].message, 
	        severity: 'error'
	      });
      }
  	} 
  }

  const handleClose = () => {
  	setValue('');
  	setError('');
  	props.handleClose && props.handleClose();
  }

  const handleChange = e => {
    const value = e.target.value;
    setValue(value);
  }

	return (
		<DeleteConfirmDialog
			isOpen={props.isOpen}
			handleClose={handleClose}
			handleAction={handleActionConfirmDelete}
			label='Antes de continuar, comentanos cual es el motivo de tu declinación.'
			actionLabel='Continuar'
		>
			<TextField
				label='Motivo de la declinación'
				name='authCommentary'
				value={value}
				onChange={handleChange}
				variant='outlined'
				multiline
				error={error}
				rows={2}
				size='small'
				noFormat
			/>
		</DeleteConfirmDialog>
	)
}

export default DeleteDialog;
import { gql } from '@apollo/client';

const GET = gql`
  query Productions($offset: Int, $limit: Int, $productUnitId: ID, $type: [Int], $start: Date, $end: Date){
    productions(offset: $offset limit: $limit productUnitId: $productUnitId type: $type, start: $start, end: $end){
      count
      totals {
        id
        name
        amount
        qty
      }
      rows {
        id
        type
        quantity
        createdAt
        initialUnits
        Product {
          name
        }
        ProductUnit {
          name
        }
      }
    }
  }
`;

const GET_LAST_PRODUCTION = gql`
  query Productions($offset: Int, $limit: Int, $productUnitId: ID, $type: [Int]){
    productions(offset: $offset limit: $limit productUnitId: $productUnitId type: $type){
      rows {
        id
        quantity
        Product {
          name
        }
        ProductionDetails {
          id
          quantity
          waste
          wasteBase
          Product {
            name
          }
          ProductUnit {
            id
            name
            type
          }
        }
      }
    }
  }
`;

const GET_BY_ID = gql`
  query Production($id: ID!){
    production(id: $id){
      Product {
        ProductUnits {
          unitCompoundId
          name
        }
      }
      ProductionDetails {
        totalUnits
        quantity
        unitsBase
        wasteBase
        Product {
          name
        }
        ProductUnit {
          name
        }
      }
    }
  }
`;


const CREATE_PRODUCTION = gql`
  mutation CreateProduction(
    $productUnitId: ID!
    $quantity: Float!
    $waste: Float
    $UnitCompounds: [UnitCompoundData]
  ) {
    createProduction(input: {
      productUnitId: $productUnitId
      quantity: $quantity
      waste: $waste
      UnitCompounds: $UnitCompounds
    }) {
      id
      type
      quantity
      createdAt
      Product {
        name
      }
      ProductUnit {
        name
      }
    }
  }
`;

const DELETE = gql`
  mutation DeleteProduction(
    $id: ID!
    $authCommentary: String!
  ) {
    deleteProduction(
      id: $id
      authCommentary: $authCommentary
    ) {
      id
    }
  }
`;

const object = { GET, GET_BY_ID, CREATE_PRODUCTION, GET_LAST_PRODUCTION, DELETE }; 
export default object;
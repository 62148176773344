import React, { Fragment, useState, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';

import Grid from '@material-ui/core/Grid';

import LinearForm from './../../../../presentationals/LinearForm';
import TextField from './../../../../presentationals/TextField';
import Autocomplete from './../../../../presentationals/Autocomplete';
import SelectField from './../../../../presentationals/SelectField';

import GQL from './../../_gql';

const defaultValues = {
	name: "",
  unitCompoundId: null,
	unit: 0,
  isBase: false,
  type: 1
}

const yesNoOptions = [
  { value: 1, label: 'NO' },
  { value: 2, label: 'SI' },
]

const Form = props => {
	const [ values, setValues ] = useState(defaultValues);
	const [ errors, setErrors ] = useState({});
  const [ unitCompounds, setUnitCompounds ] = useState([]);

  const [ getProduct, { data } ] = useLazyQuery(GQL.GET_BY_ID);

	const [ createProductUnit ] = useMutation(GQL.CREATE_PRODUCT_UNIT, {
    update(cache, { data }) {
      try{
        
        const oldQuery = cache.readQuery({ query: GQL.GET_BY_ID, variables: { id: props.id } });

        const productUnitId = parseInt(data.createProductUnit.id);
        const productUnitName = data.createProductUnit.name;
        const productUnit = parseFloat(data.createProductUnit.unit);
        const compoundName = data.createProductUnit.UnitCompound 
          ? data.createProductUnit.UnitCompound.name
          : 'Unidad Base';

        const newQuery = { 
          product: {
            ...oldQuery.product, 
            ProductUnits: [
              ...oldQuery.product.ProductUnits,
              { 
                __typename: "ProductUnit", 
                UnitCompound: { __typename: "ProductUnit", name: compoundName },
                id: productUnitId,
                name: productUnitName, 
                unit: productUnit
              }
            ]
          } 
        }
        
        cache.writeQuery({
          query: GQL.GET_BY_ID,
          variables: { id: props.id },
          data: newQuery
        });

      }catch(e){
        console.log(e.message);
      }
    },
  });

  useEffect(() => {
    if(data && data.product){
      const tmp = data.product.ProductUnits.map(el => ({ label: el.name, value: el.id }));
      setUnitCompounds(tmp);
    }
  }, [data]);

  useEffect(() => {
    props.id && getProduct({ variables: { id: props.id } });
  },[props.id]);// eslint-disable-line

	useEffect(() => {
    setValues(defaultValues);
  }, [props.codeToClear]);

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAction = async e => {
  	try{

      const unit = props.isBase ? 1 : parseFloat(values.unit);
      const unitCompoundId = props.isBase ? null : values.unitCompoundId.value;

  		if(!values.name || values.name.trim() === ''){ 
  			const e = new Error('El nombre es obligatorio.')
  			e.name = 'name';
  			throw e; 
  		}

  		if(parseFloat(unit) <= 0 ||  isNaN(parseFloat(unit))){ 
  			const e = new Error('La cantidad de la unidad debe ser mayor a cero.')
  			e.name = 'unit';
  			throw e; 
  		}

  		await createProductUnit({
        variables: {
          name: values.name, 
          productId: props.id, 
          type: parseInt(values.type),
          unit,
          unitCompoundId,
        },
      });

  		setValues(defaultValues);
  		setErrors({});
  	}catch(e) {
  		setErrors({ [e.name]: e.message });
  	}
  }

	return (
		<Fragment>
			<div>
				<LinearForm handleAdd={handleAction} align='center'>
					<Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <TextField 
                name='name'
                label={`Nombre de la Unidad ${props.isBase ? 'Base' : ''}`}
                variant='outlined'
                onChange={handleChange}
                value={values.name}
                error={errors.name}
                size='small'
                noFormat
                autoFocus
              />
            </Grid>
            {
              !props.isBase &&
              <Grid item xs={12} md={6}>
                <Autocomplete 
                  name='unitCompoundId'
                  label='Compuesto por' 
                  variant='outlined'
                  onChange={handleAutocompleteChange}
                  options={unitCompounds}
                  value={values.unitCompoundId}
                  error={errors.unitCompoundId}
                  noFormat
                />
              </Grid>
            }
            { !props.isBase &&
    						<Grid item xs={12} md={6}>
    							<TextField 
    				        name='unit'
    				        label='# Unidades'
    				        type='number' 
    				        variant='outlined'
    				        onChange={handleChange}
    				        value={values.unit}
    				        error={errors.unit}
    				        noFormat
    				      />
    						</Grid>
            }
            <Grid item xs={12} md={6}>
              <SelectField 
                name='type'
                label='¿Es Armado?' 
                variant='outlined'
                onChange={handleChange}
                options={yesNoOptions}
                value={values.type}
                error={errors.type}
                size='small'
                noFormat
              />
            </Grid>
					</Grid>
				</LinearForm>
			</div>
		</Fragment>
	);
}

export default Form;
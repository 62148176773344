import React, { useState } from 'react';

import Typography from '@material-ui/core/Typography';

import FilterDrawer from './../../presentationals/FilterDrawer';

import SelectField from './../../presentationals/SelectField';

import { filter } from '../../apollo/cache';

const monthsOptions = [
	{ value: -1, label: 'Todos los meses' },
	{ value: 0, label: 'Enero' },
	{ value: 1, label: 'Febrero' },
	{ value: 2, label: 'Marzo' },
	{ value: 3, label: 'Abril' },
	{ value: 4, label: 'Mayo' },
	{ value: 5, label: 'Junio' },
	{ value: 6, label: 'Julio' },
	{ value: 7, label: 'Agosto' },
	{ value: 8, label: 'Septiembre' },
	{ value: 9, label: 'Octubre' },
	{ value: 10, label: 'Noviembre' },
	{ value: 11, label: 'Diciembre' },
];

const defaultValues = {
	months: -1,
	year: '2022',
}

const yearOptions = [
	{value: '2019', label: '2019'},
	{value: '2020', label: '2020'},
	{value: '2021', label: '2021'},
	{value: '2022', label: '2022'},
];

const FilterDialog = props => {
	const [ values, setValues ] = useState(defaultValues)

	const handleClose = () => {
		props.handleAction('close');
	}

	const handleAction = (e, data) => {
		const branchId = parseInt(data.branchId) > 0 ? data.branchId : null;
		const owner = false;
		const months = parseInt(values.months) === -1 ? null : parseInt(values.months);
		props.handleAction(null, { 
			branchId, 
			owner,
			months,
			year: parseInt(values.year)
		});
		filter({ branchId, owner, months, year: parseInt(values.year) });
	}

	const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

	return (
		<FilterDrawer 
			isOpen={props.isOpen} 
			handleClose={handleClose} 
			handleAction={handleAction}
			owner={false}
			showBranches
		>
			<Typography variant="subtitle1">
        Mes:
      </Typography>
			<SelectField
        name='months'
        variant='outlined'
        label=''
        value={values.months}
        onChange={handleChange}
        options={monthsOptions}
        noFormat
      />

			<Typography variant="subtitle1">
        Año:
      </Typography>
			<SelectField
        name='year'
        variant='outlined'
        label=''
        value={values.year}
        onChange={handleChange}
        options={yearOptions}
        noFormat
      />
		</FilterDrawer>
	);
}

export default FilterDialog;
import React, { Fragment, useState, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';

import Header from './../../presentationals/Table/header';

import Table from './_table';
import Filter from './filter.dialog';
import DownloadXLS from './xls.document';
import GQL from './_gql';

const Component = props => {
	const [isOpenFilter, setIsOpenFilter] = useState(false);
	const [branchId, setBranchId] = useState(8);
	const [date, setDate] = useState(null);

	const openFilter = () => setIsOpenFilter(true);
	const handleCloseFilter = () => setIsOpenFilter(false);

	const handleActionFilter = (branchId, date) => {
		setBranchId(branchId);
		setDate(date);
	};

	const [getInfo, { data }] = useLazyQuery(GQL.GET, {
    variables: { limit: 0, branchId: branchId },
    fetchPolicy: 'network-only',
    onCompleted: data => {
    	data && DownloadXLS(data.productInventories.rows);
    }
  });

  useEffect(() => {
  	if (data) {
  		
  	}
  },[data]);

	const downloadXLS = () => getInfo();

	return (
		<Fragment>
			<Header
				title={`Inventario Por Producto`}
				noCreate
				moduleCode='REPI01'
				filter={openFilter}
				customizedXls={downloadXLS}
			/>
			<Table branchId={branchId} date={date} />
			<Filter isOpen={isOpenFilter} handleAction={handleActionFilter} handleClose={handleCloseFilter} />
		</Fragment>
	);
}

export default Component;
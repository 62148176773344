import React, { useState, useEffect, Fragment } from 'react';

import { useQuery } from '@apollo/client';

import EmojiFoodBeverageIcon from '@material-ui/icons/EmojiFoodBeverageOutlined';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import FormatSizeIcon from '@material-ui/icons/FormatSizeOutlined';
import AccountTreeIcon from '@material-ui/icons/AccountTreeOutlined';
import CategoryIcon from '@material-ui/icons/CategoryOutlined';

import Module from './../../presentationals/Module';

import GQL from './_gql';
import GQL_CLASSIFICATIONS from './../classification/_gql';
import GQL_SUPPLIER from './../supplier/_gql';

import FilterDialog from './filter.dialog';
import UnitDialog from './unit.dialog';
import QtyDialog from './qty.dialog';
import MeasureDialog from './Measure';
import TypeDialog from './Type';
import ToppingDialog from './Topping';

const typeOptions = [
	{ value: 1, label: 'Producto elaborado'},
	{ value: 2, label: 'Producto final'},
	{ value: 3, label: 'Insumo'},
];

const yesNoOptions = [
	{ value: 1, label: 'Sí' },
	{ value: 0, label: 'No' },
]

const companyOptions = [
	{ value: 1, label: 'Narachips' },
	{ value: 2, label: 'CasaElote' },
]

const Rows = [
	{ label: 'Código', key: 'id', sort: 'text' },
	{ label: 'Producto', key: 'name', sort: 'text' },
	{ label: 'Tipo', key: 'type', sort: 'text', format: 'productType' },
	{ label: 'UnidadBase', key: 'baseUnit', sort: 'text' },
	{ label: 'Precio', key: 'price', format: 'currency', align: 'right' },
	{ label: 'Costo', key: 'cost', format: 'currency', align: 'right' },
	{ label: 'IVA', key: 'iva', format: 'number', align: 'center' },
	{ label: 'Empresa', key: 'companyId', format: 'company' }
];

// const currentDate = new Date();

const Component = props => {

	const [ classificationOptions, setClassificationOptions ] = useState([]);
	const [ suppliersOptions, setSuppliersOptions ] = useState([]);
	const [ isOpen, setIsOpen ] = useState(false);
	const [ isUnitOpen, setIsUnitOpen ] = useState([false, null, null]);
	const [ isQtyOpen, setIsQtyOpen ] = useState([false, null, null]);
	const [ isToppingOpen, setIsToppingOpen ] = useState([false, null, null]);
	const [ isMeasureOpen, setIsMeasureOpen ] = useState([false, null, null]);
	const [ isTypeOpen, setIsTypeOpen ] = useState([false, null, null]);
	const [ filter, setFilter ] = useState({});
	
	const { data, loading, refetch } = useQuery(GQL.GET);
	
	useQuery(GQL_CLASSIFICATIONS.GET, {
		onCompleted: response => {
			const tmp = response.clasifications.rows.map(el => ({ value: el.id, label: el.name }));
			setClassificationOptions(tmp);
		}
	});
	
	useQuery(GQL_SUPPLIER.GET, {
		onCompleted: response => {
			const tmp = response.suppliers.rows.map(el => ({ value: el.id, label: el.name }));
			setSuppliersOptions(tmp);
		}
	});

	useEffect(() => {
		if(Object.keys(filter).length > 0){
			refetch(filter);
		}
	}, [filter, refetch]);

	const handleRows = (offset, limit) => {
		setFilter(filter => ({ ...filter, limit, offset }));
	}

	const handleFilter = () => {
		setIsOpen(true);
	}

	const handleAction = (action, data) => {
		switch(action){
			case 'close':
				setIsOpen(false);
				break;
			default:
				setFilter(filter => ({ ...filter, ...data }));
				break;
		}
	}

	const handleSearch = (value) => {
		setFilter(filter => ({ ...filter, like: value }));
	}

	const handleUnits = (e, data) => {
		setIsUnitOpen([true, data.id, data.name]);
	}

	const handleUnitClose = () => {
		setIsUnitOpen([false, null, null]);
	}

	const reload = () => refetch({ variables: { status: [1] } });

	const handleQty = (e, data) => {
		if(data === 'close') {
			setIsQtyOpen([false, null, null]);
			return;
		}
		setIsQtyOpen([true, data.id, data.name]);
	}

	const handleMeasure = (e, data) => {
		setIsMeasureOpen([true, data.id, data.name]);
	}

	const handleMeasureClose = () => {
		setIsMeasureOpen([false, null, null]);
	}

	const handleType = (e, data) => {
		setIsTypeOpen([true, data.id, data.name]);
	}

	const handleTypeClose = () => {
		setIsTypeOpen([false, null, null]);
	}

	const handleToppings = (e, data) => {
		setIsToppingOpen([true, data.id, data.name]);
	}

	const handleToppingsClose = () => {
		setIsToppingOpen([false, null, null]);
	}

	return (
		<Fragment>
			<Module
				moduleCode='CAPR01'
				isLoading={loading}
				name='Productos'
				singular='Producto'
				operation={['product', 'products']}
				Rows={Rows}
				get={GQL.GET}
				handleRows={handleRows}
				getById={GQL.GET_BY_ID}
				create={GQL.CREATE}
				update={GQL.UPDATE}
				delete={GQL.DELETE}
				records={data && data.products}
				filter={handleFilter}
				handleSearch={handleSearch}
				actions={[
					{ icon: <CategoryIcon />, label: 'Toppings del Producto', onClick: handleToppings },
					{ icon: <AccountTreeIcon />, label: 'Tipo de Producto', onClick: handleType },
					{ icon: <FormatSizeIcon />, label: 'Medidas del Producto', onClick: handleMeasure },
					{ icon: <ImportExportIcon />, label: 'Máximo y mínimo por sucursal', onClick: handleQty },
					{ icon: <EmojiFoodBeverageIcon />, label: 'Unidades de medida', onClick: handleUnits },
				]}
				fields={[
					{
						name: 'name',
						label: 'Nombre de producto',
					},
					{
						name: 'type',
						label: 'Tipo de producto',
						field: 'select',
						options: typeOptions
					},
					{
						name: 'baseUnit',
						label: 'Unidad base (kg, gr, lt, etc.)',
					},
					{ 
						name: 'price', 
						label: 'Precio de Venta',
						defaultValue: 0,
						type: 'number',
					},
					{ 
						name: 'cost', 
						label: 'Precio de Compra',
						defaultValue: 0,
						type: 'number',
					},
					{
						name: 'clasificationId',
						label: 'Clasificación',
						field: 'select',
						options: classificationOptions
					},
					{
						name: 'supplierId',
						label: 'Proveedor',
						field: 'select',
						options: suppliersOptions
					},
					{
						name: 'inventory',
						label: 'Inventario',
						field: 'select',
						options: yesNoOptions
					},
					{
						name: 'minimum',
						label: 'Minimo',
						type: 'number'
					},
					{
						name: 'maximum',
						label: 'Máximo',
						type: 'number'
					},
					{
						name: 'iva',
						label: 'IVA',
						field: 'select',
						options: [
							{ value: 16, label: '16%' },
							{ value: 8, label: '8%' },
							{ value: 0, label: '0%' },
							{ value: -1, label: 'Exento' },
						]
					},
					{
						name: 'companyId',
						label: 'Empresa',
						field: 'select',
						options: companyOptions
					},
				]}
			/>
			<FilterDialog isOpen={isOpen} handleAction={handleAction} />
			<UnitDialog isOpen={isUnitOpen[0]} id={isUnitOpen[1]} name={isUnitOpen[2]} handleClose={handleUnitClose} reload={reload} />
			<QtyDialog isOpen={isQtyOpen[0]} id={isQtyOpen[1]} name={isQtyOpen[2]} handleClose={handleQty}  />
			<MeasureDialog isOpen={isMeasureOpen[0]} id={isMeasureOpen[1]} name={isMeasureOpen[2]} handleClose={handleMeasureClose} />
			<TypeDialog isOpen={isTypeOpen[0]} id={isTypeOpen[1]} name={isTypeOpen[2]} handleClose={handleTypeClose} />
			<ToppingDialog isOpen={isToppingOpen[0]} id={isToppingOpen[1]} name={isToppingOpen[2]} handleClose={handleToppingsClose} />
		</Fragment>
	);
}

export default Component;
import React, { useState, useEffect, Fragment } from 'react';

import Table from './helpers/Table';
import TableHead from './tableHead';
import TableBody from './tableBody';
import TablePagination from './tablePagination';
import XlsExport from '../XlsExport';

import { sortByType } from './helpers';

const Component = props => {

	const [ direction, setDirection ] = useState({});
	const [ rows, setRows ] = useState([]);

	useEffect(() => {
		setRows(props.records);
	}, [props.records]);

	const handleSort = key => { 
		const { sort } = props.Rows.find(el => el.key === key);
		const tmpDirection = direction[key] || 'asc';
    setDirection({ [key]: tmpDirection === 'asc' ? 'desc' : 'asc' })
		const data = sortByType(rows, key, sort, tmpDirection);
    setRows(data);
	}

	const handleRows = (page, rowsPerPage) => {
		props.handleRows && props.handleRows(page, rowsPerPage);
	}
  
	return (
		<Fragment>
			<Table>
				<TableHead Rows={props.Rows} handleSort={handleSort} direction={direction} />
				<TableBody 
					Rows={props.Rows} 
					records={rows} 
					direction={direction}
					route={props.route} 
					extraParams={props.extraParams}
					actions={props.actions}
					delete={props.delete}
					getById={props.getById}
					operation={props.operation}
					noDelete={props.noDelete}
					noEdit={props.noEdit}
				/>
			</Table>
			{ props.handleRows && <TablePagination count={props.qty} handleRows={handleRows} moduleCode={props.moduleCode} /> }
			<XlsExport
				title={props.title}
				get={props.get}
				keys={props.Rows}
			/>
	  </Fragment>
	);
}

export default Component;
import React, { useState, useEffect, Fragment } from 'react';

import ListAltIcon from '@material-ui/icons/ListAltOutlined';
import PaymentIcon from '@material-ui/icons/PaymentOutlined';

import { useQuery } from '@apollo/client';

import Module from './../../presentationals/Module';

import GQL from './_gql';

import FilterDialog from './filter.dialog';
import FormExpense from './form.dialog';
import ConceptDialog from './concept.dialog';
import PaymentDialog from './payment.form.dialog';

const Rows = [
	{ label: 'Fecha', key: 'date', format: 'date' },
	{ label: 'Folio', key: 'physicalFolio', sort: 'text' },
	{ label: 'Tipo', key: 'type', sort: 'text', format: 'expenseType' },
	{ label: 'Cargo', key: 'total', format: 'currency', align: 'right' },
	{ label: 'Abono', key: 'payment', format: 'currency', align: 'right' },
	{ label: 'Saldo', key: 'balance', format: 'currency', align: 'right' },
];

const defaultExpenses = {
	count: 0,
	rows: []
}

const Component = props => {
	const [ isOpen, setIsOpen ] = useState(false);
	const [ isOpenConcepts, setIsOpenConcepts ] = useState([0,false]);
	const [ formIsOpen, setFormIsOpen ] = useState(false);
	const [ filter, setFilter ] = useState({});
	const [ isOpenPayment, setIsOpenPayment ] = useState([null, false, { supplier: null, folio: null }]);
	const [ expenses, setExpenses ] = useState(defaultExpenses)

	const { data, loading, refetch } = useQuery(GQL.GET,{ variables: { type: [2,3,4], form: 1 } });

	useEffect(() => {
		if(Object.keys(filter).length > 0){
			refetch(filter);
		}
	}, [filter, refetch]);

	useEffect(() => {
		if(data) {
			const tmp = data.expenses.rows.map(el => {
				let payment = 0;
				if(el.paid) {
					payment = parseFloat(el.total);
				} else {
					el.ExpensePayments.forEach(ele => {
						payment += parseFloat(ele.total);
					});
				}
				const balance = parseFloat(el.total) - parseFloat(payment);
				return {
					...el,
					payment,
					balance
				}
			})
			setExpenses({ count: data.expenses.count, rows: tmp });
		}

	}, [data])

	const handleRows = (offset, limit) => {
		setFilter(filter => ({ ...filter, limit, offset }));
	}

	const handleFilter = () => {
		setIsOpen(true);
	}

	const handleAction = (action, data) => {
		switch(action){
			case 'close':
				setIsOpen(false);
				break;
			default:
				setFilter(filter => ({ ...filter, ...data }));
				break;
		}
	}

	const handleSearch = (value) => {
		setFilter(filter => ({ ...filter, like: value }));
	}

	const handleCreate = () => setFormIsOpen(true);
	const handleCloseCreate = () => setFormIsOpen(false);
	
	const handleCloseConcepts = () => setIsOpenConcepts([0,false]);
	const handleConcepts = (e,{ id }) => {
		setIsOpenConcepts([id,true]);
	};

	const handlePayment = (e, data) => {
		setIsOpenPayment([data.id, true, { supplier: data.Supplier.name, folio: data.physicalFolio }])
	}; 
	const handleClosePayments = () => setIsOpenPayment([null, false, { supplier: null, folio: null }]);

	return (
		<Fragment>
			<Module
				moduleCode='ADGA01'
				isLoading={loading}
				name='Gastos Operativos'
				singular='Gasto Operativo'
				operation={['expense', 'expenses']}
				Rows={Rows}
				get={GQL.GET}
				handleRows={handleRows}
				getById={GQL.GET_BY_ID}
				create={GQL.CREATE}
				noEdit
				delete={GQL.DELETE}
				records={data && expenses}
				filter={handleFilter}
				handleSearch={handleSearch}
				handleCustomCreate={handleCreate}
				actions={[
					{ icon: <PaymentIcon/>, onClick: handlePayment, label: 'Pagos' },
					{ icon: <ListAltIcon/>, onClick: handleConcepts, label: 'Conceptos'  },
				]}
			/>
			<FilterDialog isOpen={isOpen} handleAction={handleAction} />
			<FormExpense isOpen={formIsOpen} handleClose={handleCloseCreate} handleOpenPayment={handlePayment} />
			<ConceptDialog isOpen={isOpenConcepts[1]} handleClose={handleCloseConcepts} id={isOpenConcepts[0]} />
			<PaymentDialog isOpen={isOpenPayment[1]} handleClose={handleClosePayments} id={isOpenPayment[0]} extraData={isOpenPayment[2]} />
		</Fragment>
	);
}

export default Component;
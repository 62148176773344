import React from 'react';

import FilterDrawer from '../../presentationals/FilterDrawer.v2';

import { filter } from '../../apollo/cache';

const FilterDialog = () => {
  const handleAction = (e, data) => {
    const { startDate: start, endDate: end } = data;
    filter({
      start,
      end,
    });
  };

  return (
    <FilterDrawer
      handleAction={handleAction}
      showDates
    >
    </FilterDrawer>
  );
};

export default FilterDialog;

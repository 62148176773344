import React, { useState } from 'react';

import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import FilterDrawer from './../../presentationals/FilterDrawer';
import SelectField from './../../presentationals/SelectField';

import { filter } from '../../apollo/cache';

const roleOptions = [
  { value: -1, label: 'Todos los Privilegios' },
  { value: 1, label: 'Administradores' },
  { value: 2, label: 'Operativos' },
];

const defaultValues = {
  role: -1
}

const FilterDialog = props => {
	const [ values, setValues ] = useState(defaultValues);
	
	const handleClose = () => {
		props.handleAction('close');
	}

	const handleAction = (e, data) => {
		const branchId = parseInt(data.branchId) > 0 ? data.branchId : null;
		const owner = parseInt(data.branchId) < -1
			? parseInt(data.branchId) === -2
				? true
				: false
			: null;
		const role = parseInt(values.role) > 0 ? values.role : null;
		props.handleAction(null, { branchId, owner, role });
		filter({ branchId, owner, role });
	}

	const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

	return (
		<FilterDrawer
			isOpen={props.isOpen}
			handleClose={handleClose}
			handleAction={handleAction}
			showBranches
		>	
      <Typography variant="subtitle1">
        Privilegios:
      </Typography>
      <SelectField
        name='role'
        variant='outlined'
        value={values.role}
        onChange={handleChange}
        options={roleOptions}
        noFormat
      />
      <Divider/>
		</FilterDrawer>
	);
}

export default FilterDialog;
import React, { useState } from 'react';

import { useMutation } from '@apollo/client';

import Grid from '@material-ui/core/Grid';

import LinearForm from './../../../presentationals/LinearForm';
import TextField from './../../../presentationals/TextField';

import GQL from './../_gql';

const defaultValues = {
	name: '',
	price: '',
  defaultQty: '',
  type: 1
}

const Form = props => {

	const [ values, setValues ] = useState(defaultValues);
	const [ errors, setErrors ] = useState({});

	const [ createTopping, { loading } ] = useMutation(GQL.CREATE_TOPPING, {
		update(cache, { data }) {
      try{        
        const oldQuery = cache.readQuery({ query: GQL.GET_TOPPINGS, variables: { id: props.id } });
        cache.writeQuery({
          query: GQL.GET_TOPPINGS,
          variables: { id: props.id },
          data: {
            product: {
              "__typename":"Product",
              Toppings: [
                data.createProductTopping,
                ...oldQuery.product.Toppings
              ]
            }
          }
        });
      }catch(e){
        console.log(e.message);
      }
    }
	});

	const handleAction = async () => {
		try{
  		if(!values.name || values.name.trim() === ''){ 
  			const e = new Error('El nombre es obligatorio.')
  			e.name = 'name';
  			throw e; 
  		}
  		if(!values.price || values.price === ''){ 
  			const e = new Error('El precio es obligatorio.')
  			e.name = 'price';
  			throw e; 
  		}
      if(!values.defaultQty || values.defaultQty === ''){ 
        const e = new Error('La cantidad es obligatoria.')
        e.name = 'defaultQty';
        throw e; 
      }
      if(!values.type || values.type === ''){ 
        const e = new Error('El tipo es obligatorio.')
        e.name = 'type';
        throw e; 
      }

  		await createTopping({ variables: { 
        id: props.id, name: values.name, price: parseFloat(values.price), 
        defaultQty: parseFloat(values.defaultQty), 
        type: parseFloat(values.type) 
      } })
  	}catch(e) {
  		setErrors({ [e.name]: e.message });
  	}
	}

	const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

	return (
		<LinearForm loading={loading} handleAdd={handleAction} align='center'>
			<Grid container spacing={1}>
				<Grid item xs={6}>
          <TextField 
            name='name'
            label='Nombre'
            variant='outlined'
            onChange={handleChange}
            value={values.name}
            error={errors.name}
            size='small'
            noFormat
            autoFocus
          />
        </Grid>
        <Grid item xs={2}>
          <TextField 
            name='type'
            label='Tipo'
            variant='outlined'
            onChange={handleChange}
            value={values.type}
            error={errors.type}
            size='small'
            type='number'
            noFormat
          />
        </Grid>
        <Grid item xs={2}>
          <TextField 
            name='defaultQty'
            label='Cant.'
            variant='outlined'
            onChange={handleChange}
            value={values.defaultQty}
            error={errors.defaultQty}
            size='small'
            type='number'
            noFormat
          />
        </Grid>
        <Grid item xs={2}>
          <TextField 
            name='price'
            label='Precio'
            variant='outlined'
            onChange={handleChange}
            value={values.price}
            error={errors.price}
            size='small'
            type='number'
            noFormat
          />
        </Grid>
			</Grid>
		</LinearForm>
	);
}

export default Form;
import { gql } from '@apollo/client';

const GET = gql`
  query SupportTickets($offset: Int, $limit: Int, $type: Int){
    supportTickets(offset: $offset limit: $limit type: $type){
      count
      rows {
        id
        type
        moduleCode
        description
        createdAt
        User {
          name
        }
      }
    }
  }
`;

const GET_BY_ID = gql`
  query SupportTicket($id: ID){
    supportTicket(id: $id){
      id
      type
      moduleCode
      description
    }
  }
`;


const CREATE = gql`
  mutation CreateSupportTicket(
    $type: Int!
    $moduleCode: String
    $description: String
  ) {
    createSupportTicket(input: {
      type: $type
      moduleCode: $moduleCode
      description: $description
    }) {
      id
      type
      moduleCode
      description
      createdAt
      User {
        name
      }
    }
  }
`;

const object = { GET, GET_BY_ID, CREATE }; 
export default object;
import { gql } from '@apollo/client';

const GET = gql`
  query Branches($offset: Int, $limit: Int, $like: String, $owner: Boolean){
    branches(offset: $offset limit: $limit like: $like, owner: $owner){
      count
      rows {
        id
        name
        phone
        owner
        type
        prefix
        companyId
        BranchAddress {
          id
          addressLine1
          addressLine2
          city
          zipCode
          reference
          State {
            id
            name
          }
          Country {
            id
            name
          }
        }
        Customer {
          name
        }
      }
    }
  }
`;

const GET_AUTOCOMPLETE = gql`
  query BranchesAuto($owner: Boolean){
    branches(owner: $owner){
      count
      rows {
        id
        name
        companyId
      }
    }
  }
`;

const GET_BY_ID = gql`
  query Branch($id: ID!) {
    branch(id: $id) {
      id
      name
      phone
      owner
      type
      prefix
      companyId
      BranchAddress {
        addressLine1
        addressLine2
        city
        zipCode
        reference
        State {
          id
          name
        }
        Country {
          id
          name
        }
      }
    }
  }
`;

const CREATE = gql`
  mutation CreateBranch(
    $name: String
    $description: String
    $phone: String
    $prefix: String
    $owner: Boolean
    $type: Int
    $addressLine1: String
    $addressLine2: String
    $city: String
    $zipCode: String
    $reference: String
    $stateId: ID
    $countryId: ID
    $companyId: ID
  ) {
    createBranch(input: {
      name: $name
      description: $description
      phone: $phone
      prefix: $prefix
      owner: $owner
      type: $type
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      city: $city
      zipCode: $zipCode
      reference: $reference
      stateId: $stateId
      countryId: $countryId
      companyId: $companyId
    }) {
      id
      name
      phone
      owner
      type
      prefix
      companyId
      Customer { 
        name
      }
      BranchAddress {
        id
        addressLine1
        addressLine2
        city
        zipCode
        reference
        State {
          id
          name
        }
        Country {
          id
          name
        }
      }
    }
  }
`

const UPDATE = gql`
  mutation UpdateBranch(
    $id: ID!
    $name: String
    $description: String
    $phone: String
    $prefix: String
    $owner: Boolean
    $type: Int
    $addressLine1: String
    $addressLine2: String
    $city: String
    $zipCode: String
    $reference: String
    $stateId: ID
    $countryId: ID
    $customerId: ID
    $companyId: ID
  ) {
    updateBranch(
      id: $id
      input: {
        name: $name
        description: $description
        phone: $phone
        owner: $owner
        type: $type
        prefix: $prefix
        addressLine1: $addressLine1
        addressLine2: $addressLine2
        city: $city
        zipCode: $zipCode
        reference: $reference
        stateId: $stateId
        countryId: $countryId
        customerId: $customerId
        companyId: $companyId
      }
    ) {
      id
      name
      phone
      owner
      type
      prefix
      companyId
      Customer { 
        name
      }
      BranchAddress {
        id
        addressLine1
        addressLine2
        city
        zipCode
        reference
        State {
          id
          name
        }
        Country {
          id
          name
        }
      }
    }
  }
`

const DELETE = gql`
  mutation DeleteBranch($id: ID!) {
    deleteBranch(id: $id) {
      id
    }
  }
`;

const CREATE_CREDENTIALS = gql`
  mutation GenerateLocalCredentials($id: ID!) {
    generateLocalCredentials(id: $id) {
      token
      secret
    }
  }
`

const object = { GET, GET_BY_ID, CREATE, UPDATE, DELETE, GET_AUTOCOMPLETE, CREATE_CREDENTIALS }; 
export default object;
import React, { Fragment, useState, useEffect } from 'react';

import { useQuery, NetworkStatus } from '@apollo/client';

import format from 'date-fns/format';
import { es } from 'date-fns/locale';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';

import Header from './../../presentationals/Table/header';
import Table from './../../presentationals/Table/helpers/Table';
import Loading from './../../presentationals/Loading';

import { currencyFormat } from './../../helpers';

import GQL from './_gql';
import FilterDialog from './filter.dialog';

import DownloadXLS from './xls.document';
import DownloadPDF from './pdf.document';

const currentDate = format(new Date(), 'dd MMM yyyy', { locale: es }).toUpperCase();

const Component = props => {

	const [ isOpen, setIsOpen ] = useState(false);
	const [ filter, setFilter ] = useState({});
	const [ title, setTitle ] = useState([currentDate, currentDate]);
	const [ total, setTotal ] = useState([]);

	const { data, loading, refetch, networkStatus } = useQuery(GQL.GET, {
		notifyOnNetworkStatusChange: true,
    variables: { limit: 0 },
	});

	useEffect(() => {
		if(data && data.purchaseOrderDetailReport){
			const tmp = data.purchaseOrderDetailReport.rows.map(el => {
				const data = el.PurchaseOrderDetails.reduce((array, elCh, i) => {
					array[i] = { 
						quantity: parseInt(array[i] ? array[i].quantity : 0) + parseInt(elCh.quantity),
						cost: parseFloat(array[i] ? array[i].cost : 0) + parseFloat(elCh.cost)
					}
					return array;
				}, []);
				return data;
			});
			const tmp1 = tmp.reduce((array, el, i) => {
				el.forEach((elCh, i) => {
					array[i] = {
						quantity: parseInt(array[i] ? array[i].quantity : 0) + parseInt(elCh.quantity),
						cost: parseFloat(array[i] ? array[i].cost : 0) + parseFloat(elCh.cost)
					}
				})
				return array;
			}, []);

			setTotal(tmp1);
		}
	}, [data]);

	useEffect(() => {
		if(Object.keys(filter).length > 0){
			refetch({ ...filter, limit: 0 });
		}
	}, [filter, refetch]);

	const handleFilter = () => {
		setIsOpen(true);
	}

	const handleAction = (action, data) => {
		switch(action){
			case 'close':
				setIsOpen(false);
				break;
			default:
				const start = format(data.start, 'dd MMM yyyy', { locale: es }).toUpperCase();
				const end = format(data.end, 'dd MMM yyyy', { locale: es }).toUpperCase();
				
				setTitle([start, end]);

				setFilter(filter => ({ ...filter, ...data }));
				break;
		}
	}

	const downloadXLS = () => {
		const totalFix = total.map(el => {
			return {
				quantity: el.quantity,
				cost: el.cost
			}
		});
		DownloadXLS([...data.purchaseOrderDetailReport.rows, { Product: { name: 'TOTAL' }, PurchaseOrderDetails: totalFix }], { ...filter, limit: 0 }, 'DETALLE_ORDEN_COMPRA');
	}

	const downloadPDF = () => {
		data && DownloadPDF(data.purchaseOrderDetailReport.rows, filter, 'VENTAS POR PRODUCTO');
	}

	if(loading || networkStatus === NetworkStatus.refetch) return <Loading />;

	return (
		<Fragment>
			<Header
				title={`Detalle de orden de compra del ${title[0]} al ${title[1]}`}
				noCreate
				customizedXls={downloadXLS}
				customizedPdf={downloadPDF}
				qty={data && data.purchaseOrderDetailReport.count}
				filter={handleFilter}
				moduleCode='REVE01'
			/>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell />
						{
							(data && data.purchaseOrderDetailReport.count > 0) && 
								data.purchaseOrderDetailReport.rows[0].PurchaseOrderDetails.map((el, i) => 
									<TableCell key={el.Branch.name} align='center' colSpan={2} 
										style={{ 
											background: (i % 2 === 0) ? '#c9c9c9' : '#fafafa',
											borderColor: (i % 2 === 0) ? '#989898' : '#ddd',
											minWidth: 10
										}}
									>
										<Typography variant="body2" noWrap>
											<strong>{el.Branch.name}</strong>
										</Typography>
									</TableCell>
								)
						}
					</TableRow>
					<TableRow>
						<TableCell style={{top: 33}}>Producto</TableCell>
						{
							(data && data.purchaseOrderDetailReport.count > 0) && 
								data.purchaseOrderDetailReport.rows[0].PurchaseOrderDetails.map((el, i) => 
									<Fragment key={el.Branch.name}>
										<TableCell 
											align='center' 
											style={{ 
												background: (i % 2 === 0) ? '#c9c9c9' : '#fafafa',
												borderColor: (i % 2 === 0) ? '#989898' : '#ddd',
												top: 33
											}}
										>
											Cant.
										</TableCell>
										<TableCell 
											align='right' 
											style={{ 
												background: (i % 2 === 0) ? '#c9c9c9' : '#fafafa',
												borderColor: (i % 2 === 0) ? '#989898' : '#ddd',
												top: 33,
											}}
										>
											Costo
										</TableCell>
									</Fragment>
								)
						}
					</TableRow>
				</TableHead>
				<TableBody>
					{ data && data.purchaseOrderDetailReport.rows.map((el, i) =>
							<TableRow key={i}>
								<TableCell>
									<Typography variant="body2" noWrap>
										{el.name}
									</Typography>
								</TableCell>
								{
									el.PurchaseOrderDetails.map((elCh, ii) => 
										<Fragment key={ii}>
											<TableCell
												align='center' 
												style={{ 
													background: (ii % 2 === 0) ? '#ddd' : '#fafafa',
													borderColor: (ii % 2 === 0) ? '#989898' : '#ddd'
												}}
											>
												{parseFloat(elCh.quantity).toFixed(2)}
											</TableCell>
											<TableCell
												align='right' 
												style={{ 
													background: (ii % 2 === 0) ? '#ddd' : '#fafafa',
													borderColor: (ii % 2 === 0) ? '#989898' : '#ddd'
												}}
											>
												{currencyFormat(parseFloat(elCh.cost))}
											</TableCell>
										</Fragment>
									)
								}
							</TableRow>
						)
					}
					<TableRow>
						<TableCell style={{ position: 'sticky', bottom: 0, background: '#fafafa', borderTop: '1px solid #ddd' }}>
							<Typography variant="body2" noWrap>
								<strong>TOTAL</strong>
							</Typography>
						</TableCell>
						{
							(data && data.purchaseOrderDetailReport.count > 0) && 
								data.purchaseOrderDetailReport.rows[0].PurchaseOrderDetails.map((el, i) => 
									<Fragment key={el.Branch.name}>
										<TableCell 
											align='center' 
											style={{ 
												position: 'sticky', 
												bottom: 0, 
												background: (i % 2 === 0) ? '#a2a2a2' : '#c0c0c0',
												borderTop: '1px solid #ddd' 
											}}
										>
											{total[i] ? total[i].quantity : 0}
										</TableCell>
										<TableCell 
											align='right' 
											style={{ 
												position: 'sticky', 
												bottom: 0, 
												background: (i % 2 === 0) ? '#a2a2a2' : '#c0c0c0',
												borderTop: '1px solid #ddd' 
											}}
										>
											{total[i] ? currencyFormat(total[i].cost) : currencyFormat(0)}
										</TableCell>
									</Fragment>
								)
						}
					</TableRow>
				</TableBody>
			</Table>
			<FilterDialog isOpen={isOpen} handleAction={handleAction} />
		</Fragment>
	);
}

export default Component;
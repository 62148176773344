import React, { useState } from 'react';

import ExpandMore from '@material-ui/icons/ArrowDropDown';
import ExpandLess from '@material-ui/icons/ArrowDropUp';
import StoreIcon from '@material-ui/icons/Store';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import BugReportIcon from '@material-ui/icons/BugReport';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';

import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

import { makeStyles } from '@material-ui/core/styles';

import { LinkButton } from './helpers';

const useStyles = makeStyles(theme => ({
  subOption: {
    padding: theme.spacing(0.3, 3, 0.5, 2)
  },
  ListItemIcon: {
    minWidth: '40px',
  }
}));

const _privileges = localStorage.getItem('privileges');

const MainListItems = props => { 

  const classes = useStyles();

  const [ openCatalog, setOpenCatalog ] = useState(false);
  const [ openAdmin, setOpenAdmin ] = useState(false);
  const [ openOperative, setOpenOperative ] = useState(false);
  const [ openOperative1, setOpenOperative1 ] = useState(false);
  const [ openOperative2, setOpenOperative2 ] = useState(false);
  const [ openAdminReports, setOpenAdminReports ] = useState(false);
  const [ openBranches, setOpenBranches ] = useState(false);
  // const [ openInventory, setOpenInventory ] = useState(false);

  const handleOpenCatalog = e => {
    e.preventDefault();
    setOpenCatalog(openCatalog => (!openCatalog));
  }

  const handleOpenAdmin = e => {
    e.preventDefault();
    setOpenAdmin(openAdmin => (!openAdmin));
  }

  const handleOpenOperative = e => {
    e.preventDefault();
    setOpenOperative(openOperative => (!openOperative));
  }

  const handleOpenOperative1 = e => {
    e.preventDefault();
    setOpenOperative1(openOperative1 => (!openOperative1));
  }

  const handleOpenOperative2 = e => {
    e.preventDefault();
    setOpenOperative2(openOperative2 => (!openOperative2));
  }

  const handleOpenAdminReports = e => {
    e.preventDefault();
    setOpenAdminReports(openAdminReports => (!openAdminReports));
  }
  const handleOpenBranches = e => {
    e.preventDefault();
    setOpenBranches(openBranches => (!openBranches));
  }
  // const handleOpenInventory = e => {
  //   e.preventDefault();
  //   setOpenInventory(openInventory => (!openInventory));
  // }
  
  return (
    <div>
      <Divider />
      { props.isOpen 
        ? <ListItem className={classes.subOption} button onClick={handleOpenOperative}>
            <ListItemIcon classes={{root: classes.ListItemIcon}}>
              <StoreIcon />
            </ListItemIcon>
            <ListItemText primary="Operativo" />
            {openOperative ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        : <LinkButton
            icon={<StoreIcon />} 
            url='/operativeReport' 
            label='Operativo'
            bold
          />
      }
      {
        ([1,2].includes(parseInt(_privileges)) && props.isOpen) &&
          <Collapse in={openOperative} timeout="auto" unmountOnExit>

            <LinkButton sub url='/event' label='Eventos' />
            <LinkButton sub url='/production' label='Producción' />
            <LinkButton sub url='/transfer' label='Traspaso de Mercancía' />
            <LinkButton sub url='/waste' label='Mermas' />

            <ListItem className={classes.subOption} button onClick={handleOpenOperative1}>
              <ListItemText primary="Op. Sucursales" />
              {openOperative1 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={openOperative1} timeout="auto" unmountOnExit>
              <LinkButton margin url='/branch/inventory' label='Ajuste de Inventario' />
              <LinkButton margin url='/requisition' label='Requisiciones' />
              <LinkButton margin url='/branchOrder' label='Orden de Compra' />
              
            </Collapse>

            <ListItem className={classes.subOption} button onClick={handleOpenOperative2}>
              <ListItemText primary="Op. CEDI" />
              {openOperative2 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={openOperative2} timeout="auto" unmountOnExit>
              <LinkButton margin url='/inventory' label='Ajuste de Inventario' />
              <LinkButton margin url='/inventoryMovement' label='E/S de Inventario' />
              <LinkButton margin url='/purchaseOrder' label='Orden de Compra' />
              <LinkButton margin url='/report/productInventory' label='Inv. Disponible' />
              <LinkButton margin url='/productMovement' label='Movimientos por producto' />
              <LinkButton margin url='/report/reportProductMovement' label='Reporte de Movimientos' />
            </Collapse>
            
          </Collapse>
      }
      
      { props.isOpen 
        ?  parseInt(_privileges) === 1 && <ListItem className={classes.subOption} button onClick={handleOpenAdmin}>
            <ListItemIcon classes={{root: classes.ListItemIcon}}>
              <AccountBalanceIcon />
            </ListItemIcon>
            <ListItemText primary="Administrativo" />
            {openAdmin ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        : parseInt(_privileges) === 1 && <LinkButton
            icon={<AccountBalanceIcon />} 
            url='/adminReport' 
            label='Administrativo'
            bold
          />
      }
      {
        (parseInt(_privileges) === 1 && props.isOpen) &&
          <Collapse in={openAdmin} timeout="auto" unmountOnExit>
            <LinkButton url='/receivable' label='Cuentas por Cobrar' />
            <LinkButton sub url='/expense' label='Gastos Operativos' />
            <LinkButton sub url='/financialMovement' label='Aportación de Socios' />
          </Collapse>
      }
      
      { props.isOpen 
        ? parseInt(_privileges) === 1 && <ListItem className={classes.subOption} button onClick={handleOpenCatalog}>
            <ListItemIcon classes={{root: classes.ListItemIcon}}>
              <FormatListNumberedIcon />
            </ListItemIcon>
            <ListItemText primary="Catálogos" />
            {openCatalog ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        : parseInt(_privileges) === 1 && <LinkButton icon={<FormatListNumberedIcon />} url='/catalogReport' label='Catálogos' bold />
      }
      {
        (parseInt(_privileges) === 1 && props.isOpen) &&
          <Collapse in={openCatalog} timeout="auto" unmountOnExit>
            <LinkButton sub url='/classification' label='Clasificaciones' />
            <LinkButton sub url='/product' label='Productos' />
            <LinkButton sub url='/event/package' label='Paquetes para Eventos' />
            <LinkButton sub url='/supplier' label='Proveedores' />
            <LinkButton sub url='/employee' label='Empleados' />
            <LinkButton sub url='/paymentMethod' label='Metodos de Pago' />
            <ListItem className={classes.subOption} button onClick={handleOpenBranches}>
              <ListItemText primary="Sucursales" />
              {openBranches ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openBranches} timeout="auto" unmountOnExit>
              <LinkButton margin url='/branch/partner' label='Franquicias' />
              <LinkButton margin url='/branch/owner' label='Propias' />
            </Collapse>
          </Collapse>
      }
      { props.isOpen 
        ? parseInt(_privileges) === 1 && <ListItem className={classes.subOption} button onClick={handleOpenAdminReports}>
            <ListItemIcon classes={{root: classes.ListItemIcon}}>
              <TrendingUpIcon />
            </ListItemIcon>
            <ListItemText primary="Reportes" />
            {openAdminReports ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        : parseInt(_privileges) === 1 && <LinkButton icon={<TrendingUpIcon />} url='/' label='Reportes' bold />
      }
      { props.isOpen &&
        <Collapse in={openAdminReports} timeout="auto" unmountOnExit>
          <LinkButton sub url='/report/amount/sales' label='Total de Ventas Por Mes' />
          <LinkButton sub url='/report/royalties' label='Regalías de Clientes' />
          <LinkButton sub url='/report/branch/sales' label='Ventas por mes' />
          <LinkButton sub url='/report/rotationSale' label='Ventas por producto' />
          <LinkButton sub url='/report/rotationTopping' label='Ventas por topping Narachips' />
          <LinkButton sub url='/report/rotationSale/cancelled' label='Ventas Canceladas' />
          <LinkButton sub url='/report/reportPaymentMethodSale' label='Ventas por Formas de Pago' />
          <LinkButton sub url='/report/chipsCombination' label='Trazabilidad de Narachips' />
          <LinkButton sub url='/report/sale' label='Ventas por Folio' />
          <LinkButton sub url='/report/cashClosing' label='Cortes de Caja' />
          <LinkButton sub url='/report/saleWithDiscount' label='Ventas con Descuento' />
          <LinkButton sub url='/report/transferdetails' label='Traspaso Detalle Acumulado' />
          <LinkButton sub url='/report/transferProducts' label='Detalle de Traspaso' />
          <LinkButton sub url='/report/purchaseOrderDetails' label='Detalle Acumulado de Compras' />
          <LinkButton sub url='/report/purchaseOrderProducts' label='Detalle Compras' />
          <LinkButton sub url='/report/waste' label='Detalle de Mermas' />
          <LinkButton sub url='/report/branchWaste' label='Reporte de Mermas' />

          {/*<ListItem className={classes.subOption} button onClick={handleOpenInventory}>
            <ListItemText primary="Inventario" />
            {openInventory ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={openInventory} timeout="auto" unmountOnExit>
            <LinkButton sub url='/report/inventoryAmount' label='Disponible' />
            <LinkButton sub url='/report/inventory' label='Mov. Inventario CEDI' />
            <LinkButton sub url='/report/inventory/branch' label='Mov. Inventario Suc.' />
          </Collapse>*/}
        </Collapse>
      }
      <Divider style={{ height: 3 }} />
      <LinkButton icon={<ContactSupportIcon />} url='/' label='Ayuda' bold />
      <LinkButton icon={<BugReportIcon />} url='/supportTicket' label='Reportar un Error' bold />
      <Divider />
    </div>
  )
}

export default MainListItems;
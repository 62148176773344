import React from 'react';

import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Typography from '@material-ui/core/Typography';

import { useReactiveVar } from '@apollo/client';

import useStyles from './style';

import { subTitle } from '../../../../apollo/cache';

const TitleComponent = props => {
	const subTitleVar = useReactiveVar(subTitle);
	const classes = useStyles();

	const Theme = useTheme();
  const isSmallScreen = useMediaQuery(Theme.breakpoints.down('sm'));

	return (
		<div>
			<Typography className={classes.title} variant={isSmallScreen ? 'h5' : 'h4'}>
				{`${props.name} `}
			</Typography>
			{ subTitleVar !== '' && <small>{`${subTitleVar}`}</small> }
  	</div>
	);
};

export default TitleComponent;

import React, { useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import FormDialog from './../../presentationals/FormDialog';
import TextField from './../../presentationals/TextField';
import SelectField from './../../presentationals/SelectField';
import Button from './../../presentationals/Button';
import { productType } from './../../presentationals/Table/helpers/Format';

import { parseError } from './../../helpers';
import { snackbar, requisitionProducts } from './../../apollo/cache';

import GQL_PRODUCT from './../product/_gql';

import GQL from './_gql';
import ExtraItems from './extraItems.js';

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: 'bold',
		textAlign: 'center',
	},
	container: {
		border: '1px solid #000',
		borderRadius: 5,
		padding: 10,
		marginBottom: 10
	}
}));

const defaultValues = {
	branchId: '',
	description: '',
	Products: []
}

const branches = [
  { label: 'Narachips La Isla', value: '2' },
  { label: 'Narachips Tres Rios', value: '5' },
  { label: 'Elotes La Isla', value: '18' },
  { label: 'Elotes Tres Rios', value: '19' },
];

const FormComponent = props => {
	
	const styles = useStyles();

	const [ products, setProducts ] = useState([]);
	const [ values, setValues ] = useState(defaultValues);
	const [ errors, setErrors ] = useState({});

	const { data: dataProducts} = useQuery(GQL_PRODUCT.GET_INVENTORY, { variables: { type: [1,2,3], limit: 0 } });

  const [ createRequistion, { loading }] = useMutation(GQL.CREATE, {
		update(cache, { data }) {
			const oldQuery = cache.readQuery({ query: GQL.GET, variables: { status: [1] } });
			cache.writeQuery({
        query: GQL.GET,
        variables: { status: [1] },
        data: { 
          requisitions: { 
            ...oldQuery.requisitions,
            count: oldQuery.requisitions.count + 1, 
            rows: [ data.createRequisition, ...oldQuery.requisitions.rows ] 
          }
        }
      });
    },
		onCompleted: data => {
			props.handleCreate && props.handleCreate(data);
		}
	});

	useEffect(() => {
		if(dataProducts && values.branchId) {
			const tmp = [18, 19].includes(parseInt(values.branchId))
				? [100, 101, 53, 9, 128, 144, 246, 142, 95, 146, 147, 57, 58, 99, 140, 2, 278, 49, 252, 19, 18, 16, 10, 8, 7, 129, 1, 65, 139, 14, 92].map(key => {
					const tmpProduct = dataProducts.products.rows.find(el => parseInt(el.id) === key);
					if (!tmpProduct) return null;
					const { type, ProductUnits, ...data } = tmpProduct;
					const tmpProductUnits = ProductUnits.find(elCh => !elCh.unitCompoundId);
					const tmpQtyInventory = tmpProductUnits && tmpProductUnits.BranchInventories.find(elCh => parseInt(elCh.branchId) === parseInt(values.branchId));
					const qty = tmpQtyInventory ? tmpQtyInventory.quantity : 0;
					return { 
						...data,
						type: productType[type],
						unit: tmpProductUnits ? qty + ' ' + tmpProductUnits.name : '-',
						quantity: qty,
						units: ProductUnits.map(elCh => ({ label: elCh.name, value: elCh.id }))
					};
				}).filter(el => el)
				: [2, 5].includes(parseInt(values.branchId))
					? [137, 49, 20, 19, 18, 41, 37, 131, 15, 17, 12, 48, 16, 100, 25, 53, 99, 64, 9, 132, 10, 77, 7, 8, 14, 68, 65, 45, 34, 2, 255, 3, 4, 1, 5, 67, 73, 6, 26, 281].map(key => {
						const tmpProduct = dataProducts.products.rows.find(el => parseInt(el.id) === key);
						if (!tmpProduct) return null;
						const { type, ProductUnits, ...data } = tmpProduct;
						const tmpProductUnits = ProductUnits.find(elCh => !elCh.unitCompoundId);
						const tmpQtyInventory = tmpProductUnits && tmpProductUnits.BranchInventories.find(elCh => parseInt(elCh.branchId) === parseInt(values.branchId));
						const qty = tmpQtyInventory ? tmpQtyInventory.quantity : 0;
						return { 
							...data,
							type: productType[type],
							unit: tmpProductUnits ? qty + ' ' + tmpProductUnits.name : '-',
							quantity: qty,
							units: ProductUnits.map(elCh => ({ label: elCh.name, value: elCh.id }))
						};
					}).filter(el => el)
					: [];
			setProducts(tmp);

			const tmpValues = dataProducts.products.rows.map(el => {
				const { type, ProductUnits, ...data } = el;
				const tmpProductUnits = ProductUnits.find(elCh => !elCh.unitCompoundId);
				const tmpQtyInventory = tmpProductUnits && tmpProductUnits.BranchInventories.find(elCh => parseInt(elCh.branchId) === parseInt(values.branchId));
				const qty = tmpQtyInventory ? tmpQtyInventory.quantity : 0;
				return {
					quantity: qty,
					productId: data.id,
					productUnitId: tmpProductUnits ? tmpProductUnits.id : '',
					unitPrice: 0
				}
			});
			setValues(values => ({ ...values, Products: tmpValues }))
		}
	},[dataProducts, values.branchId]);

	const handleAction = async e => {
		try {
			if(!values.branchId) {
				const e = new Error('')
  			e.name = 'branchId';
  			e.input = true;
  			throw e; 
			}

			if(parseInt(values.branchId.value) === 8) {
				const e = new Error('');
  			e.name = 'branchId';
  			e.input = true;
  			throw e; 
			}

			const Products = values.Products.map((el, i) => {
				if(parseInt(el.productUnitId) > 0){
					return {
						quantity: !isNaN(parseFloat(el.quantity)) ? parseFloat(el.quantity) : 0,
						productId: el.productId,
						productUnitId: el.productUnitId,
					}
				} 
				return false;
			}).filter(el => el);

			const requisitionProductsVar = requisitionProducts();
			
			const Products1 = requisitionProductsVar.map(el => ({
				quantity: !isNaN(parseFloat(el.quantity)) ? parseFloat(el.quantity) : 0,
				productId: el.productUnit.productId,
				productUnitId: el.productUnit.value,
			}));

			const variables = {
				branchId: values.branchId,
				description: values.description,
				Products: [...Products, ...Products1],
			};

			await createRequistion({ variables });

			setValues(defaultValues);
			props.handleClose();
			setErrors({});
		}catch(e) {
			if(e.input) {
				setErrors({ [e.name]: e.message });
				return true;
			}
			const parseErrors = parseError(e);
      if(parseErrors[0].name === 'GENERAL_ERROR'){
      	snackbar({
	      	isOpen: true, 
	        time: 3000,
	        label: parseErrors[0].message, 
	        severity: 'error'
	      });
      }
		}
	}

	const handleClose = () => {
		setValues(defaultValues);
		props.handleClose();
	}

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleElementChange = (name, value, id) => {
		const tmpProducts = values.Products.map(el => {
			if(parseInt(el.productId) === parseInt(id)){
				return { 
					...el, 
					productUnitId: name === 'unit' ? parseInt(value) : el.productUnitId,
					quantity: name === 'quantity' ? value : el.quantity,
					unitPrice: name === 'unitPrice' ? value : el.unitPrice
				}
			}
			return el;
		});
		setValues(values => ({ ...values, Products: tmpProducts }));
  }

  const handleReset = () => {
  	const tmp = values.Products.map(el => ({ ...el, quantity: 0, unitPrice: 0 }))
  	setValues(values => ({ ...values, Products: tmp }));
  }

	return (
		<FormDialog 
			isOpen={props.isOpen}
			isLoading={loading}
      title='Nuevo ajuste de inventario'
      name="cart"
      singular="Cart"
      disableEscape
			fullScreen
      handleClose={handleClose}
      handleAction={handleAction}
      rightContent={
      	<Grid container spacing={1}>
      		<Grid item md={3} xs={false} />
      		<Grid item md={6} xs={12}>
		        <SelectField 
			        name='branchId'
			        label='Sucursal' 
			        variant='outlined'
			        onChange={handleChange}
			        options={branches}
			        value={values.branchId}
			        error={errors.branchId}
			        noFormat
			        size='small'
			      />
			    </Grid>
      		<Grid item md={3} xs={12}>
      			<Button 
		      		label='Resetear'
		      		variant='outlined'
		      		onClick={handleReset}
		      		color='secondary'
		      	/>
      		</Grid>
			  </Grid>
      }
		>
		{ ['18','19'].includes(values.branchId) && (
			<Grid container spacing={5}>
				<Grid item xs={12} md={8}>
					<Grid container alignItems='center' className={styles.container} spacing={1}>
						<Grid item xs={2} className={styles.title}>ABARROTE</Grid>
						<Grid item xs={10}>
							{
								products.map((el, i) => {
									return (i < 13) && (
										<Element 
											key={el.id} 
											id={el.id}
											name={el.name} 
											classification={el.Clasification.name}
											unit={el.unit}
											units={el.units}
											values={values.Products.find(elCh => parseInt(elCh.productId) === parseInt(el.id))}
											onChange={handleElementChange}
										/>
									);
								})
							}
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={styles.container} spacing={1}>
						<Grid item xs={2} className={styles.title}>CEDI</Grid>
						<Grid item xs={10}>
						{
								products.map((el, i) => {
									return (i > 12 && i < 19) && (
										<Element 
											key={el.id} 
											id={el.id}
											name={el.name} 
											classification={el.Clasification.name}
											unit={el.unit}
											units={el.units}
											values={values.Products.find(elCh => parseInt(elCh.productId) === parseInt(el.id))}
											onChange={handleElementChange}
										/>
									);
								})
							}
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={styles.container} spacing={1}>
						<Grid item xs={2} className={styles.title}>DESECHABLE</Grid>
						<Grid item xs={10}>
						{
								products.map((el, i) => {
									return (i > 18 && i < 22) && (
										<Element 
											key={el.id} 
											id={el.id}
											name={el.name} 
											classification={el.Clasification.name}
											unit={el.unit}
											units={el.units}
											values={values.Products.find(elCh => parseInt(elCh.productId) === parseInt(el.id))}
											onChange={handleElementChange}
										/>
									);
								})
							}
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={styles.container} spacing={1}>
						<Grid item xs={2} className={styles.title}>FRESCOS</Grid>
						<Grid item xs={10}>
						{
								products.map((el, i) => {
									return (i > 21 && i < 24) && (
										<Element 
											key={el.id} 
											id={el.id}
											name={el.name} 
											classification={el.Clasification.name}
											unit={el.unit}
											units={el.units}
											values={values.Products.find(elCh => parseInt(elCh.productId) === parseInt(el.id))}
											onChange={handleElementChange}
										/>
									);
								})
							}
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={styles.container} spacing={1}>
						<Grid item xs={2} className={styles.title}>LIMPIEZA</Grid>
						<Grid item xs={10}>
						{
								products.map((el, i) => {
									return (i > 23 && i < 25) && (
										<Element 
											key={el.id} 
											id={el.id}
											name={el.name} 
											classification={el.Clasification.name}
											unit={el.unit}
											units={el.units}
											values={values.Products.find(elCh => parseInt(elCh.productId) === parseInt(el.id))}
											onChange={handleElementChange}
										/>
									);
								})
							}
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={styles.container} spacing={1}>
						<Grid item xs={2} className={styles.title}>VERDURA</Grid>
						<Grid item xs={10}>
						{
								products.map((el, i) => {
									return (i > 24) && (
										<Element 
											key={el.id} 
											id={el.id}
											name={el.name} 
											classification={el.Clasification.name}
											unit={el.unit}
											units={el.units}
											values={values.Products.find(elCh => parseInt(elCh.productId) === parseInt(el.id))}
											onChange={handleElementChange}
										/>
									);
								})
							}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		)}
		{ ['2','5'].includes(values.branchId) && (
			<Grid container spacing={5}>
				<Grid item xs={12} md={8}>
					<Grid container alignItems='center' className={styles.container} spacing={1}>
						<Grid item xs={2} className={styles.title}>ABARROTE</Grid>
						<Grid item xs={10}>
							{
								products.map((el, i) => {
									return (i < 13) && (
										<Element 
											key={el.id} 
											id={el.id}
											name={el.name} 
											classification={el.Clasification.name}
											unit={el.unit}
											units={el.units}
											values={values.Products.find(elCh => parseInt(elCh.productId) === parseInt(el.id))}
											onChange={handleElementChange}
										/>
									);
								})
							}
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={styles.container} spacing={1}>
						<Grid item xs={2} className={styles.title}>BEBIDAS</Grid>
						<Grid item xs={10}>
						{
								products.map((el, i) => {
									return (i > 12 && i < 17) && (
										<Element 
											key={el.id} 
											id={el.id}
											name={el.name} 
											classification={el.Clasification.name}
											unit={el.unit}
											units={el.units}
											values={values.Products.find(elCh => parseInt(elCh.productId) === parseInt(el.id))}
											onChange={handleElementChange}
										/>
									);
								})
							}
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={styles.container} spacing={1}>
						<Grid item xs={2} className={styles.title}>DESECHABLE</Grid>
						<Grid item xs={10}>
						{
								products.map((el, i) => {
									return (i > 16 && i < 24) && (
										<Element 
											key={el.id} 
											id={el.id}
											name={el.name} 
											classification={el.Clasification.name}
											unit={el.unit}
											units={el.units}
											values={values.Products.find(elCh => parseInt(elCh.productId) === parseInt(el.id))}
											onChange={handleElementChange}
										/>
									);
								})
							}
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={styles.container} spacing={1}>
						<Grid item xs={2} className={styles.title}>FRUTA Y VERDURA</Grid>
						<Grid item xs={10}>
						{
								products.map((el, i) => {
									return (i > 23 && i < 25) && (
										<Element 
											key={el.id} 
											id={el.id}
											name={el.name} 
											classification={el.Clasification.name}
											unit={el.unit}
											units={el.units}
											values={values.Products.find(elCh => parseInt(elCh.productId) === parseInt(el.id))}
											onChange={handleElementChange}
										/>
									);
								})
							}
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={styles.container} spacing={1}>
						<Grid item xs={2} className={styles.title}>LIMPIEZA</Grid>
						<Grid item xs={10}>
						{
								products.map((el, i) => {
									return (i > 24 && i < 27) && (
										<Element 
											key={el.id} 
											id={el.id}
											name={el.name} 
											classification={el.Clasification.name}
											unit={el.unit}
											units={el.units}
											values={values.Products.find(elCh => parseInt(elCh.productId) === parseInt(el.id))}
											onChange={handleElementChange}
										/>
									);
								})
							}
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={styles.container} spacing={1}>
						<Grid item xs={2} className={styles.title}>OTROS</Grid>
						<Grid item xs={10}>
						{
								products.map((el, i) => {
									return (i > 26) && (
										<Element 
											key={el.id} 
											id={el.id}
											name={el.name} 
											classification={el.Clasification.name}
											unit={el.unit}
											units={el.units}
											values={values.Products.find(elCh => parseInt(elCh.productId) === parseInt(el.id))}
											onChange={handleElementChange}
										/>
									);
								})
							}
						</Grid>
					</Grid>
					{/* <Grid container alignItems='center' className={styles.container} spacing={1}>
						<Grid item xs={2} className={styles.title}>PRODUCCIÓN</Grid>
						<Grid item xs={10}></Grid>
					</Grid>
					<Grid container alignItems='center' className={styles.container} spacing={1}>
						<Grid item xs={2} className={styles.title}>SALSAS</Grid>
						<Grid item xs={10}></Grid>
					</Grid>
					<Grid container alignItems='center' className={styles.container} spacing={1}>
						<Grid item xs={2} className={styles.title}>TOPPINGS</Grid>
						<Grid item xs={10}></Grid>
					</Grid>
					<Grid container alignItems='center' className={styles.container} spacing={1}>
						<Grid item xs={2} className={styles.title}>UTENSILIOS</Grid>
						<Grid item xs={10}></Grid>
					</Grid> */}
				</Grid>
			</Grid>
		)}
			{/* <Grid container spacing={1}>
				<Grid item xs={6}><strong>Producto</strong></Grid>
				<Grid item xs={3}><strong>Cant. Solicitada</strong></Grid>
				<Grid item xs={3}><strong>Unidad</strong></Grid>
			</Grid> */}
			{
				// products.map(el => {
				// 	return (
				// 		<Element 
				// 			key={el.id} 
				// 			id={el.id}
				// 			name={el.name} 
				// 			classification={el.Clasification.name}
				// 			unit={el.unit}
				// 			units={el.units}
				// 			values={values.Products.find(elCh => parseInt(elCh.productId) === parseInt(el.id))}
				// 			onChange={handleElementChange}
				// 		/>
				// 	);
				// })
			}
			{
				values.branchId && (
				<>
					<br/>
					<Divider />
					<Grid container spacing={1}>
						<Grid item xs={12} md={6}>
							<br/>
							<ExtraItems branchId={values.branchId} />
						</Grid>
						<Grid item xs={12} md={6}>
							<br />
							<TextField 
								name='description'
								label='Añadir comentarios' 
								variant='outlined'
								onChange={handleChange}
								value={values.description}
								error={errors.description}
								multiline
								rows={4}
								noFormat
							/>
						</Grid>
					</Grid>
				</>	
				)
			}
		</FormDialog>
	)

}

const Element = props => {
	const handleChange = e => {
		const name = e.target.name;
		const value = e.target.value;
		props.onChange(name, value, props.id);
	}
	return (
		<Grid container alignItems='center' spacing={1}>
			<Grid item xs={6}>{props.name}</Grid>
      <Grid item xs={3}>
				<TextField
					label=''
					name='quantity'
					value={props.values ? props.values.quantity : ''}
					noFormat
					type='number'
					variant='outlined'
					onChange={handleChange}
				/>
			</Grid>
      <Grid item xs={3}>
				<SelectField
					label=''
					name='unit'
					value={props.values ? props.values.productUnitId : ''}
					options={props.units}
					noFormat
					size='small'
					variant='outlined'
					onChange={handleChange}
				/>
			</Grid>
		</Grid>
	);
}

export default FormComponent;
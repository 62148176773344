import React, { useState } from 'react';

import Container from './../../presentationals/Module.v2/Container';

import Filter from './_filter';
import Action from './_action';
import GQL from './_gql';
import FormExpense from './form.dialog';

const Rows = [
	{ label: 'Fecha', key: 'createdAt', sort: 'text', format: 'dateTime' },
	{ label: 'Folio', key: 'serial && folio', strcat: '-', sort: 'text' },
	{ label: 'Sub Folio', key: 'subFolio', sort: 'text' },
	{ label: 'Responsable', key: 'User name', sort: 'text' },
	{ label: 'Proveedor', key: 'Supplier name', sort: 'text' },
	{ label: 'Destino', key: 'Branch name', sort: 'text' },
	{ label: 'Importe', key: 'total', format: 'currency', align: 'right', sort: 'text', },
	{ label: 'Forma de Pago', key: 'paymentForm', format: 'paymentFormFormat', align: 'right', sort: 'text', },
];
const title = 'Órdenes de Compra';
const operation = ['purchaseOrder', 'purchaseOrders'];

const Component = () => {
	const [formIsOpen, setFormIsOpen] = useState(false);

	const handleCreate = () => setFormIsOpen(true);
  const handleCloseCreate = () => setFormIsOpen(false);

	return (
		<>
			<Container
				title={title}
				operation={operation}
				Rows={Rows}
				get={GQL.GET}
				filter
				noDelete
				handleCustomCreate={handleCreate}
				defaultVariables={{ status:[1] }}
			/>
			<Filter />
			<Action />
			<FormExpense isOpen={formIsOpen} handleClose={handleCloseCreate} />
		</>
	);
};

export default Component;

import React, { useState } from 'react';

import Typography from '@material-ui/core/Typography';

import FilterDrawer from './../../presentationals/FilterDrawer';
import SelectField from './../../presentationals/SelectField';

import { filter } from '../../apollo/cache';

const defaultValues = {
	form: 1,
	paid: -1
}

const formOptions = [ 
  {value: 1, label: "Contado"},
  {value: 2, label: "Crédito"},
	{value: -1, label: "Todos"},
];

const paidOptions = [
	{ value: -1, label: "Todos" },
	{ value: 1, label: "Sí" },
	{ value: 2, label: "No" },
]

const FilterDialog = props => {
	const [ values, setValues ] = useState(defaultValues);

	const handleClose = () => {
		props.handleAction('close');
	}

	const handleAction = (e, data) => {
		const { startDate: start, endDate: end } = data;
		const form = parseInt(values.form) >= 0 ? values.form : null;
		const paid = parseInt(values.paid) < 0
			? null
			: parseInt(values.paid) === 1 ? true : false;
		props.handleAction(null, { start, end, form, paid });
		filter({ start, end, form, paid });
	}
	const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

	return (
		<FilterDrawer
			isOpen={props.isOpen}
			handleClose={handleClose}
			handleAction={handleAction}
			showDates
		>
			<Typography variant="subtitle1">
        Método de pago:
      </Typography>
      <SelectField
        name='form'
        variant='outlined'
        label=''
        value={values.form}
        onChange={handleChange}
        options={formOptions}
        noFormat
      />
			<Typography variant="subtitle1">
        Pagados:
      </Typography>
      <SelectField
        name='paid'
        variant='outlined'
        label=''
        value={values.paid}
        onChange={handleChange}
        options={paidOptions}
        noFormat
      />
     </FilterDrawer>
	);
}

export default FilterDialog;
import { gql } from '@apollo/client';

const GET = gql`
  query Suppliers($limit: Int $offset: Int $like: String){
    suppliers(limit: $limit offset: $offset like: $like) {
      count
      rows {
        id
        type
        contact
        name
        phone
        addressLine1
      }
    }
  }
`;

const GET_BY_ID = gql`
  query Supplier($id: ID!){
    supplier(id: $id) {
      id
      type
      name
      contact
      businessName
      addressLine1
      addressLine2
      city
      zipCode
      phone
      email
      stateId
      countryId
      accountName
      account
      bank
      clabe
    }
  }
`;

const DELETE = gql`
  mutation DeleteSupplier($id: ID!) {
    deleteSupplier(id: $id) {
      id
    }
  }
`;

const CREATE = gql`
  mutation CreateSupplier(
    $type: Int
    $contact: String
    $name: String
    $businessName: String
    $addressLine1: String
    $addressLine2: String
    $city: String
    $zipCode: String
    $phone: String
    $email: String
    $accountName: String
    $account: String
    $bank: String
    $clabe: String
  ) {
    createSupplier(input: {
      type: $type
      contact: $contact
      name: $name
      businessName: $businessName
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      city: $city
      zipCode: $zipCode
      phone: $phone
      email: $email
      accountName: $accountName
      account: $account
      bank: $bank
      clabe: $clabe
    }) {
      id
      type
      contact
      name
      phone
      addressLine1
    }
  }
`
const UPDATE = gql`
  mutation UpdateSupplier(
    $id: ID!
    $type: Int
    $contact: String
    $name: String
    $businessName: String
    $addressLine1: String
    $addressLine2: String
    $city: String
    $zipCode: String
    $phone: String
    $email: String
    $accountName: String
    $account: String
    $bank: String
    $clabe: String
  ) {
    updateSupplier(id: $id, input: {
      type: $type
      contact: $contact
      name: $name
      businessName: $businessName
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      city: $city
      zipCode: $zipCode
      phone: $phone
      email: $email
      accountName: $accountName
      account: $account
      bank: $bank
      clabe: $clabe
    }) {
      id
        type
        contact
        name
        phone
        addressLine1
    }
  }
`

const obj = { GET, GET_BY_ID, DELETE, CREATE, UPDATE };
export default obj;
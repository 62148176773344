import React from 'react';

import FilterDrawer from './../../presentationals/FilterDrawer';

import { filter } from '../../apollo/cache';

const FilterDialog = props => {
	const handleClose = () => {
		props.handleAction('close');
	}

	const handleAction = (e, data) => {
		const { startDate: start, endDate: end } = data;
		const branchId = parseInt(data.branchId) > 0 ? data.branchId : null;
		const companyId = parseInt(data.companyId) > 0 ? data.companyId : null;
		const owner = parseInt(data.branchId) < -1
			? parseInt(data.branchId) === -2
				? true
				: false
			: null;
		props.handleAction(null, { branchId, companyId, owner, start, end });
		filter({ branchId, companyId, owner, start, end });
	}

	return (
		<FilterDrawer 
			isOpen={props.isOpen} 
			handleClose={handleClose} 
			handleAction={handleAction}
			showCompanies
			showBranches
			showDates
		>	
		</FilterDrawer>
	);
}

export default FilterDialog;
import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import ActionForm from '../../presentationals/ActionForm';
import GQL_PRODUCT from '../reportProductInventory/_gql.js';

import GQL from './_gql';
import GQL_BRANCH from '../branch/_gql';

const FormComponent = props => {
  const [products, setProducts] = useState([]);
  const [branches, setBranches] = useState([]);

  const { data: dataProducts } = useQuery(GQL_PRODUCT.GET, { variables: { limit: 0 } });
  const { data: dataBranches } = useQuery(GQL_BRANCH.GET_AUTOCOMPLETE, { variables: { owner: true } });

  useEffect(() => {
    if(dataProducts){
      const data = dataProducts.productInventories.rows.map((el,i) => {
        const tag = parseInt(el.companyId) === 1 ? '| NRC' : '| CDE';
        const label = `${el.name.toUpperCase()} ${tag}`;
        return { 
          value: el.id,
          label,
          cost: el.unitCost,
          productId: el.productId,
        }
      }).filter(tmp => tmp);
      setProducts(data);
    }
  }, [dataProducts]);

  useEffect(() => {
    if(dataBranches){
      const tmpBranches = dataBranches.branches.rows.map(el => ({ value: el.id, label: el.name }));
      setBranches([...tmpBranches]);
    }
  }, [dataBranches]);

  return (
    <ActionForm
      operation={props.operation}
      singular='Merma'
      create={GQL.CREATE}
      fields={[
        {
          name: 'branchId',
          label: 'Sucursal',
          field: 'select',
          autoFocus: true,
          options: branches
        },
        {
          name: 'productUnitId',
          label: 'Producto',
          field: 'autocomplete',
          autoFocus: true,
          options: products
        },
        {
          name: 'quantity',
          label: 'Cantidad',
          type: 'number',
        },
        {
          name: 'description',
          label: 'Descripción',
        },
      ]}
    />
  );
};

export default FormComponent;

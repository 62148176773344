import React, { useState, useEffect, Fragment } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import ListAltIcon from '@material-ui/icons/ListAltOutlined';
import DoneAllIcon from '@material-ui/icons/DoneAllOutlined';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AppsIcon from '@material-ui/icons/AppsOutlined';

import { snackbar } from '../../apollo/cache';

import Module from './../../presentationals/Module';
import { parseError } from './../../helpers';
import ConfirmDialog from './../../presentationals/ConfirmDialog';

import GQL from './_gql';
import Ticket from './_pdf.58mm';
import ProductDialog from './product.dialog';
import CreateForm from './form.dialog.v2';
import FilterDialog from './filter.dialog';
import FormConfirmDialog from './form.confirm.dialog';

const Rows = [
	{ label: 'Fecha', key: 'createdAt', format: 'date' },
	{ label: 'Folio', key: 'folio', sort: 'text' },
	{ label: 'Sucursal', key: 'Branch name', sort: 'text' },
];

const defaultProductDialog = [false, 0, { folio: ''}];

const defaultConfirm = [false, { id: null }];

const Component = props => {
	const [isOpen, setIsOpen] = useState(false);
	const [isOpenSummary, setIsOpenSummary] = useState(defaultProductDialog);
	const [filter, setFilter] = useState({});
	const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState([false, null]);
	const [requisitions, setRequisitions] = useState([{ count: 0, rows: [] }]);
	const [formIsOpen, setFormIsOpen] = useState(false);
	const [isOpenCloseDialog, setIsOpenCloseDialog] = useState(defaultConfirm);

	const { data, loading, refetch } = useQuery(GQL.GET, { variables: { status: [1] } });

	const [closeRequisition] = useMutation(GQL.CLOSE_REQUISITION, {
    update(cache, { data }){
      cache.evict({ id: `Requisition:${data.closeRequisition.id}` });
      cache.gc();
    },
    onCompleted: () => setIsOpenCloseDialog(defaultConfirm)
  });

	useEffect(() => {
		if(data && data.requisitions) {
			const tmp = data.requisitions.rows.map(el => ({ ...el, folio: `${el.serial}-${el.folio}` }));
			setRequisitions({ count: data.requisitions.count, rows: tmp });
		}
	},[data]);

	useEffect(() => {
		if(Object.keys(filter).length > 0){
			refetch(filter);
		}
	}, [filter, refetch]);

	const handleClose = () => setIsOpenSummary(defaultProductDialog);
	const handleProducts = (e,{ id,folio }) => setIsOpenSummary([true,id,{ folio }]);

	const handleRows = (offset, limit) => {
		setFilter(filter => ({ ...filter, limit, offset }));
	}

	const handleSearch = (value) => {
		setFilter(filter => ({ ...filter, like: value }));
	}

  const handleAction = (action, data1) => {
		switch(action){
			case 'close':
				setIsOpen(false);
				break;
			default:
				const { status, ...data } = data1;
				setFilter(filter => ({ 
					...filter, 
					...data,
					status: status === -1 ? [] : [status]
				}));
				break;
		}
	}

	const handleConfirmDialog = (e, { id }) => setIsOpenConfirmDialog([true, id]);
	const handleCloseConfirm = () => setIsOpenConfirmDialog([false, null]);

	const handleCloseRequisition = (e,{id}) => setIsOpenCloseDialog([true, { id }]);
  const handleCloseCloseRequisition = () => setIsOpenCloseDialog(defaultConfirm);

  const handleActionCloseRequisition = async ({ id }) => {
    try {
      await closeRequisition({ variables: { id } });
    }catch(e) {
      console.log(e);
      const parseErrors = parseError(e);
      if(parseErrors[0].name === 'GENERAL_ERROR'){
        snackbar({
          isOpen: true, 
          time: 0,
          label: parseErrors[0].message,
          severity: 'error'
        });
      }
    } 
  }

  const handleCloseCreate = () => setFormIsOpen(false);
  const handleCreate = () => setFormIsOpen(true);

  const handleFilter = () => setIsOpen(true);

  const handleTicket = (e, { id }) => {
    Ticket(id);
  };

	return (
		<Fragment>
			<Module
				moduleCode='OPRE01'
				isLoading={loading}
				name='Requisiciones de Sucursales'
				singular='Requisición de Sucursal'
				operation={['requisition','requisitions']}
				cacheVariables={{ status: [1] }}
				Rows={Rows}
				get={GQL.GET}
				create={GQL.CREATE}
				delete={GQL.DELETE}
				noEdit
				filter={handleFilter}
				handleRows={handleRows}
				records={requisitions}
				handleSearch={handleSearch}
				handleCustomCreate={handleCreate}
				actions={[
					{
		        icon: <ReceiptIcon fontSize="small" />,
		        onClick: handleTicket,
		        label: 'Imprimir Ticket'
		      },
					{ 
						icon: <AppsIcon fontSize="small" />,
						label: "Editar Requisisión",
						onClick: handleConfirmDialog
					},
					{ 
						icon: <DoneAllIcon fontSize="small" />,
						label: "Cerrar Requisisión",
						onClick: handleCloseRequisition
					},
					{ icon: <ListAltIcon/>, onClick: handleProducts, label: 'Productos'  },
				]}
			/>
			<ConfirmDialog
        isOpen={isOpenCloseDialog[0]}
        handleClose={handleCloseCloseRequisition}
        handleAction={handleActionCloseRequisition}
        params={isOpenCloseDialog[1]}
      />
			<FilterDialog isOpen={isOpen} handleAction={handleAction} />
			<ProductDialog isOpen={isOpenSummary[0]} id={isOpenSummary[1]} handleClose={handleClose} extraData={isOpenSummary[2]} />
			<CreateForm isOpen={formIsOpen} handleClose={handleCloseCreate} />
			<FormConfirmDialog isOpen={isOpenConfirmDialog[0]} handleClose={handleCloseConfirm} id={isOpenConfirmDialog[1]} />
		</Fragment>
	);
}

export default Component;
import React, { useState, useEffect, Fragment } from 'react';
import { useQuery } from '@apollo/client';

import ListAltIcon from '@material-ui/icons/ListAltOutlined';
import DoneAllIcon from '@material-ui/icons/DoneAllOutlined';
import CloseIcon from '@material-ui/icons/Close';

import Module from './../../presentationals/Module';

import GQL from './_gql';

import Form from './form.dialog.v2';
import AuthDialog from './auth.dialog';
import SummaryDialog from './summary.dialog';
import DownloadPDF from './pdf.a4';
import FilterDialog from './filter.dialog';

const Rows = [
	{ label: 'Fecha', key: 'createdAt', format: 'date' },
	{ label: 'Folio', key: 'folio', sort: 'text' },
	{ label: 'Responsable', key: 'User name', sort: 'text' },
	{ label: 'Estatus', key: 'status', sort: 'text', format: 'transferStatus' },
];

const defaultSummaryDialog = [false,0,{ folio: ''}];
const defaultConfirm = [false, { id: null, status: 2 }, null];

const Component = props => {
	const [ isOpenSummary, setIsOpenSummary ] = useState(defaultSummaryDialog);
	const [ formIsOpen, setFormIsOpen ] = useState(false);
	const [ isOpen, setIsOpen ] = useState(false);
	const [ isOpenConfirmDialog, setIsOpenConfirmDialog ] = useState(defaultConfirm);
	const [ filter, setFilter ] = useState({});
	const [ branchPhysicalInventories, setBranchPhysicalInventories ] = useState([{ count: 0, rows: [] }]);

	const { data, loading, refetch } = useQuery(GQL.GET);

	useEffect(() => {
		if(data && data.branchPhysicalInventories) {
			const tmp = data.branchPhysicalInventories.rows.map(el => ({ ...el, folio: `${el.serial}-${el.folio}` }));
			setBranchPhysicalInventories({ count: data.branchPhysicalInventories.count, rows: tmp });
		}
	},[data]);

	useEffect(() => {
		if(Object.keys(filter).length > 0){
			refetch && refetch(filter);
		}
	}, [filter, refetch]);

	const handleRows = (offset, limit) => {
		setFilter(filter => ({ ...filter, limit, offset }));
	}

	const handleSearch = (value) => {
		setFilter(filter => ({ ...filter, like: value }));
	}

	const handleCreate = () => setFormIsOpen(true);
	const handleCloseCreate = () => setFormIsOpen(false);
	
	const handleCloseSummary = () => setIsOpenSummary(defaultSummaryDialog);
	const handleSummary = (e,{ id, folio }) => {
		setIsOpenSummary([true,id,{folio}]);
	};

	const handleAuth = (e,{id}) => setIsOpenConfirmDialog([true, { id, status: 2 }, 1]);
  const handleCloseConfirm = () => setIsOpenConfirmDialog(defaultConfirm);

	const handleDelete = (e,{id}) => setIsOpenConfirmDialog([true, { id, status: 0 }, 2]);

  const downloadPDF = () => {
		DownloadPDF();
	}

	const handleFilter = () => {
		setIsOpen(true);
	}

	const handleAction = (action, data1) => {
		switch(action){
			case 'close':
				setIsOpen(false);
				break;
			default:
				const { status, ...data } = data1;
				setFilter(filter => ({ 
					...filter, 
					...data,
					status: status === -1 ? [] : [status]
				}));
				break;
		}
	}
	
	return (
		<Fragment>
			<Module
				moduleCode='OPAJ01'
				isLoading={loading}
				name='Ajuste de Inventario'
				singular='Ajuste de Inventario'
				operation={['branchPhysicalInventory', 'branchPhysicalInventories']}
				Rows={Rows}
				get={GQL.GET}
				handleRows={handleRows}
				getById={GQL.GET_BY_ID}
				create={GQL.CREATE}
				noDelete
				noEdit
				filter={handleFilter}
				records={branchPhysicalInventories}
				handleSearch={handleSearch}
				handleCustomCreate={handleCreate}
				customizedPdf={downloadPDF}
				actions={[
					{ icon: <ListAltIcon />, onClick: handleSummary, label: 'Resumen' },
					{ icon: <DoneAllIcon />, onClick: handleAuth, label: 'Autorizar', hide: { key: 'status', value: ['2', '0'] } },
					{ icon: <CloseIcon />, onClick: handleDelete, label: 'Declinar', hide: { key: 'status', value: ['2', '0'] } },
				]}
			/>
			<AuthDialog
				isOpen={isOpenConfirmDialog[0]}
				handleClose={handleCloseConfirm}
				params={isOpenConfirmDialog[1]}
				action={isOpenConfirmDialog[2]}
			/>
			<FilterDialog isOpen={isOpen} handleAction={handleAction} />
			<Form isOpen={formIsOpen} handleClose={handleCloseCreate} />
			<SummaryDialog isOpen={isOpenSummary[0]} handleClose={handleCloseSummary} id={isOpenSummary[1]} extraData={isOpenSummary[2]} />
		</Fragment>
	);
}

export default Component;
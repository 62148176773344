import { gql } from '@apollo/client';

const GET = gql`
  query Products($limit: Int $offset: Int $like: String){
    products(limit: $limit offset: $offset like: $like) {
      count
      rows {
        id
        name
        cost
        price
        type
        baseUnit
        companyId
        iva
        Measures {
          id name price
        }
        ProductUnits {
          id
          name
          unit
          cost
          baseCost
          unitCompoundId
          type
        }
      }
    }
  }
`;

const GET_INVENTORY = gql`
  query ProductInventories($type: [Int], $limit: Int){
    products(type: $type limit: $limit) {
      count
      rows {
        id
        name
        type
        companyId
        Clasification {
          name
        }
        ProductUnits {
          id
          name
          unitCompoundId
          BranchInventories {
            branchId
            quantity
          }
        }
      }
    }
  }
`;

const GET_INVENTORY_CEDI = gql`
  query ProductInventories($type: [Int]){
    products(type: $type) {
      count
      rows {
        id
        name
        type
        companyId
        currentQuantity
        Clasification {
          name
        }
        ProductUnits {
          id
          name
          unitCompoundId
          CediInventory {
            quantity
          }
        }
      }
    }
  }
`;

const GET_AUTOCOMPLETE = gql`
  query ProductsAuto($limit: Int $offset: Int $like: String){
    products(limit: $limit offset: $offset like: $like) {
      rows {
        id
        name
        companyId
        ProductUnits {
          id
          name
          unitCompoundId
        }
      }
    }
  }
`;

const GET_BY_ID = gql`
  query Product($id: ID!){
    product(id: $id) {
      id
      name
      cost
      price
      type
      path
      clasificationId
      inventory
      baseUnit
      minimum
      maximum
      iva
      supplierId
      companyId
      ProductUnits {
        id
        name
        unit
        cost
        baseCost
        type
        UnitCompound {
          name
        }
      }
    }
  }
`;

const CREATE = gql`
  mutation CreateProduct(
    $name: String!
    $cost: Float!
    $price: Float!
    $type: Int!
    $inventory: Int
    $clasificationId: ID!
    $baseUnit: String
    $supplierId: ID
    $minimum: Float
    $maximum: Float
    $companyId: ID
    $iva: Int
  ) {
    createProduct(input: {
      name: $name 
      cost: $cost 
      price: $price 
      type: $type 
      inventory: $inventory 
      clasificationId: $clasificationId 
      baseUnit: $baseUnit
      supplierId: $supplierId
      minimum: $minimum
      maximum: $maximum
      iva: $iva
      companyId: $companyId
    }) {
      id
      name
      cost
      price
      type
      baseUnit
      companyId
      iva
      ProductUnits {
        id
        name
        unit
        cost
        baseCost
        cost
        baseCost
      }
    }
  }
`

const UPDATE = gql`
  mutation UpdateProduct(
    $id: ID!
    $name: String!
    $cost: Float!
    $price: Float!
    $type: Int!
    $inventory: Int
    $clasificationId: ID!
    $baseUnit: String
    $supplierId: ID
    $companyId: ID
    $minimum: Float
    $maximum: Float
    $iva: Int

  ) {
    updateProduct(id: $id, input: {
      name: $name 
      cost: $cost 
      price: $price 
      type: $type 
      inventory: $inventory 
      clasificationId: $clasificationId 
      baseUnit: $baseUnit
      supplierId: $supplierId
      minimum: $minimum
      maximum: $maximum
      companyId: $companyId
      iva: $iva
    }) {
      id
      name
      cost
      price
      type
      baseUnit
      companyId
      iva
      ProductUnits {
        id
        name
        unit
        cost
        baseCost
      }
    }
  }
`

const DELETE = gql`
  mutation DeleteProduct($id: ID!) {
    deleteProduct(id: $id) {
      id
    }
  }
`

const CREATE_PRODUCT_UNIT = gql`
  mutation CreateProductUnit(
    $productId: ID!
    $unitCompoundId: ID
    $name: String!
    $unit: Float!
    $type: Int
  ) {
    createProductUnit(input: {
      productId: $productId
      unitCompoundId: $unitCompoundId
      name: $name
      unit: $unit
      type: $type
    }) {
      id
      name
      unit
      productId
      cost
      baseCost
      UnitCompound {
        name
      }
    }
  }
`;

const DELETE_PRODUCT_UNIT = gql`
  mutation DeleteProductUnit($id: ID!) {
    deleteProductUnit(id: $id) {
      id
      name
      unit
      productId
      cost
      baseCost
    }
  }
`;

const UPDATE_PROUCT_UNIT = gql`
  mutation UpdateProductUnit($id: ID!, $name: String, $unit: Float, $baseCost: Float) {
    updateProductUnit(id: $id, input: {
      name: $name
      unit: $unit
      baseCost: $baseCost
    }){
      id
      name
      unit
      productId
      cost
      baseCost
      UnitCompound {
        name
      }
    }
  }
`;

const CREATE_MEASURE = gql`
  mutation CreateProductMeasure($id: ID!, $name: String!, $price: Float!) {
    createProductMeasure(id: $id, input: {
      name: $name
      price: $price
    }){
      id
      name
      price
    }
  }
`;

const GET_MEASURES = gql`
  query ProductMeasures($id: ID!){
    product(id: $id) {
      Measures {
        id name price
      }
    }
  }
`;

const DELETE_MEASURE = gql`
  mutation DeleteProductMeasure($id: ID!) {
    deleteProductMeasure(id: $id) {
      id name price
    }
  }
`;

const CREATE_TYPE = gql`
  mutation CreateProductType($id: ID!, $name: String!, $price: Float!) {
    createProductType(id: $id, input: {
      name: $name
      price: $price
    }){
      id
      name
      price
    }
  }
`;

const GET_TYPES = gql`
  query ProductType($id: ID!){
    product(id: $id) {
      Types {
        id name price
      }
    }
  }
`;

const DELETE_TYPE = gql`
  mutation DeleteProductType($id: ID!) {
    deleteProductType(id: $id) {
      id name price
    }
  }
`;

const CREATE_TOPPING = gql`
  mutation CreateProductTopping($id: ID!, $name: String!, $price: Float!, $defaultQty: Int!, $type: Int!) {
    createProductTopping(id: $id, input: {
      name: $name
      price: $price
      defaultQty: $defaultQty
      type: $type
    }){
      id
      name
      price
      defaultQty type
    }
  }
`;

const GET_TOPPINGS = gql`
  query ProductTopping($id: ID!){
    product(id: $id) {
      Toppings {
        id name price defaultQty type
      }
    }
  }
`;

const DELETE_TOPPING = gql`
  mutation DeleteProductTopping($id: ID!) {
    deleteProductTopping(id: $id) {
      id name price defaultQty type
    }
  }
`;

const object = { CREATE_TOPPING, GET_TOPPINGS, DELETE_TOPPING, CREATE_TYPE, GET_TYPES, DELETE_TYPE, DELETE_MEASURE, CREATE_MEASURE, GET_MEASURES, GET, GET_AUTOCOMPLETE, GET_INVENTORY_CEDI, GET_INVENTORY, GET_BY_ID, CREATE, UPDATE, DELETE, CREATE_PRODUCT_UNIT, DELETE_PRODUCT_UNIT, UPDATE_PROUCT_UNIT };
export default object;

import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import FormDialog from './../../presentationals/FormDialog';
import TextField from './../../presentationals/TextField';

import { parseError } from './../../helpers';
import { snackbar } from './../../apollo/cache';

import GQL from './_gql';

const defaultValues = {
	authCommentary: '',
}

const Component = props => {
	const [ values, setValues ] = useState(defaultValues);
	const [ errors, setErrors ] = useState({});

	const [ updateStatus, { loading } ] = useMutation(GQL.UPDATE_STATUS, {
		update(cache, { data }) {
			try{
				const oldQuery = cache.readQuery({ query: GQL.GET });

				const newQuery = oldQuery.branchPhysicalInventories.rows.map(el => {
					if(parseInt(el.id) === parseInt(data.updateBranchPhysicalInventoryStatus.id)){
						return { ...el, status: 2 };
					}
					return el;
				});

				cache.writeQuery({
          query: GQL.GET,
          data: newQuery
        });

			}catch(e){
				console.log(e.message);
			}
		},
		onCompleted: () => handleClose()
	});
	const [ deletePhysicalInventory ] = useMutation(GQL.DELETE, {
		update(cache, { data }) {
			try{
				const oldQuery = cache.readQuery({ query: GQL.GET });

				cache.writeQuery({
          query: GQL.GET,
          data: { branchPhysicalInventories: {
          	...oldQuery.branchPhysicalInventories,
          	count: parseInt(oldQuery.branchPhysicalInventories.count) - 1
          } }
        });

				cache.evict({ id: `BranchPhysicalInventory:${data.deleteBranchPhysicalInventory.id}` });
				cache.gc();
			}catch(e){
				console.log(e.message);
			}
		},
		onCompleted: () => handleClose()
	});

	const handleClose = () => {
		props.handleClose();
		setValues(defaultValues);
	}

	const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAction = async () => {
  	try {
  		if(values.authCommentary.trim() === '') {
  			const e = new Error('El comentario es obligatorio.');
  			e.name = 'authCommentary';
  			e.input = true;
  			throw e; 
  		}
  		parseInt(props.action) === 1
				? await updateStatus({ variables: { authCommentary: values.authCommentary, id: props.params.id, status: 2 } })
				: await deletePhysicalInventory({ variables: { id: props.params.id, authCommentary: values.authCommentary } });

  		handleClose();
  		setErrors({});
  	}catch(e) {
  		if(e.input) {
				setErrors({ [e.name]: e.message });
				return true;
			}
			const parseErrors = parseError(e);
  		if(parseErrors[0].name === 'GENERAL_ERROR'){
      	snackbar({
	      	isOpen: true, 
	        time: 3000,
	        label: parseErrors[0].message, 
	        severity: 'error'
	      });
      }
  	}
  }

  return (
		<FormDialog 
			isOpen={props.isOpen}
      title={`${parseInt(props.action) === 1 ? 'Autorizar' : 'Declinar'} ajuste de inventario`}
      name="payment"
      singular="payment"
      disableEscape
      handleClose={handleClose}
      actionLabel={`${parseInt(props.action) === 1 ? 'Autorizar' : 'Declinar'}`}
      handleAction={handleAction}
      isLoading={loading}
		>	
		<TextField
			name='authCommentary'
      label={parseInt(props.action) === 1 ? 'Comentarios adicionales' : 'Motivo de la declinación'}
      value={values.authCommentary}
      error={errors.authCommentary}
      onChange={handleChange}
      variant='outlined'
      noFormat
		/>
		</FormDialog>
	);
}

export default Component;
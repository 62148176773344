import React, { useState, useEffect, Fragment } from 'react';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { currencyFormat } from './../../../../helpers/general';

import IconButton from './helpers/iconButton';

const defaultValues = {
	subtotal: 0,
	discount: 0,
	iva: 0,
	total: 0
}

const TableComponent = props => {

	const [ values, setValues ] = useState(defaultValues);

	useEffect(() => {
    setValues(defaultValues);
  }, [props.codeToClear]);

	useEffect(() => {
		const tmp = props.cartItems.reduce((object, el) => ({
			subtotal: parseFloat(object.subtotal) + parseFloat(el.subtotal),
			discount: parseFloat(object.discount) + parseFloat(el.tmpDiscount),
			iva: parseFloat(object.iva) + parseFloat(el.taxes),
			total: parseFloat(object.total) + (parseFloat(el.subtotal) - parseFloat(el.tmpDiscount) + parseFloat(el.taxes)),
		}), defaultValues);
		setValues(tmp);
	}, [props.cartItems]);

	const handleDeleteItem = (e, position) => {
		props.removeItem(position);
	}
	
	return (
		<Fragment>
			<TableContainer>
				<Table size="small">
					<TableHead>
	          <TableRow>
	            <TableCell>Acciones</TableCell>
	            <TableCell>Producto</TableCell>
	            <TableCell align="center">Cant.</TableCell>
	            <TableCell align="center">Descto.</TableCell>
	            <TableCell align="right">{'P. Unitario'}</TableCell>
	            <TableCell align="right">IVA</TableCell>
	            <TableCell align="right">{'Importe'}</TableCell>
	          </TableRow>
	        </TableHead>
	        <TableBody>
	        	{
	        		props.cartItems.map((el, i) => 
	      				<TableRow key={i}>
	      					<TableCell>
	      						<IconButton 
	      							label='Eliminar'
	      							icon={<DeleteOutlineIcon />}
	      							params={i}
	      							action={handleDeleteItem}
	      							size='small'
	      						/>
	      					</TableCell>
			            <TableCell>{el.productUnit ? el.productUnit.label : el.concept}</TableCell>
			            <TableCell align="center">{el.quantity}</TableCell>
			            <TableCell align="center">{currencyFormat(el.discount)}%</TableCell>
			            <TableCell align="right">{currencyFormat(el.unitPrice)}</TableCell>
			            <TableCell align="right">{currencyFormat(el.taxes)}</TableCell>
			            <TableCell align="right">{currencyFormat(el.total)}</TableCell>
	      				</TableRow>
	      			)
	        	}
	        	<TableRow>
	        		<TableCell colSpan={6} align="right">Subtotal:</TableCell>
	            <TableCell align="right">{props.currency} {currencyFormat(values.subtotal)}</TableCell>
	        	</TableRow>
	        	<TableRow>
	        		<TableCell colSpan={6} align="right">Dscto:</TableCell>
	            <TableCell align="right">{props.currency} {currencyFormat(values.discount)}</TableCell>
	        	</TableRow>
	        	<TableRow>
	        		<TableCell colSpan={6} align="right">IVA:</TableCell>
	            <TableCell align="right">{props.currency} {currencyFormat(values.iva)}</TableCell>
	        	</TableRow>
	        	<TableRow>
	        		<TableCell colSpan={6} align="right"><strong>Total:</strong></TableCell>
	            <TableCell align="right"><strong>{props.currency} {currencyFormat(values.total)}</strong></TableCell>
	        	</TableRow>
	        </TableBody>
				</Table>
			</TableContainer>
		</Fragment>
	)
}

export default TableComponent;
import React from 'react';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';

import useStyles from './style';

const Component = props => {

	const classes = useStyles();

	return (
		<TableContainer className={classes.container}>
			<Table stickyHeader size='small'>
				{ props.children }
			</Table>
		</TableContainer>
	);
};

export default Component;

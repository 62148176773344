import React, { useState } from 'react';

import Typography from '@material-ui/core/Typography';

import FilterDrawer from './../../presentationals/FilterDrawer';
import SelectField from './../../presentationals/SelectField';

import { filter } from '../../apollo/cache';

const typeOptions = [
	{ value: -1, label: 'Todos los tipos de productos'},
	{ value: 1, label: 'Productos elaborados'},
	{ value: 2, label: 'Productos finales'},
	{ value: 3, label: 'Insumos'},
];

const defaultValues = {
	type: -1
}

const FilterDialog = props => {
	const [ values, setValues ] = useState(defaultValues);

	const handleClose = () => {
		props.handleAction('close');
	}

	const handleAction = (e, data) => {
		props.handleAction(null, { type: data.type });
		filter({ type: data.type === -1 ? [] : [data.type] })
	}

	const handleChange = e => {
    // const name = e.target.name;
    const value = e.target.value;
    // setValues(values => ({ ...values, [name]: value }));
    setValues({ type: value });
  }

	return (
		<FilterDrawer 
			isOpen={props.isOpen} 
			handleClose={handleClose} 
			handleAction={handleAction}
		>
			<Typography variant="subtitle1">
        Tipo de producto:
      </Typography>
			<SelectField 
				id='type'
				value={values.type}
				onChange={handleChange}
				options={typeOptions}
			/>
		</FilterDrawer>
	);
}

export default FilterDialog;
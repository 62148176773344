import React, { useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';

import { exportXls, filter } from '../../apollo/cache';

import XLS from '../XLS/table';

const XlsExportComponent = props => {

  const exportXlsVar = useReactiveVar(exportXls);
  const filterVar = useReactiveVar(filter);

  useEffect(() => {
    if (exportXlsVar.export) {
      XLS(props.title, props.get, filterVar, props.keys);
      exportXls({ export: false });
    }
  }, [exportXlsVar]);

  return (
    <></>
  );
};

export default XlsExportComponent;

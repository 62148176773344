const sortByType = (rows, key, type, direction) => {
  let tmp = rows;
  switch(type){
    case 'text':
      const tmpKey = key.split(' ');
      if(tmpKey.length === 1){
        tmp = rows.slice().sort(function (a, b) {
          if (a[key] > b[key]) { return 1; }
          if (a[key] < b[key]) { return -1; }
          return 0;
        });
      }else if(tmpKey.length === 2){
        tmp = rows.slice().sort(function (a, b) {
          if (a[tmpKey[0]][tmpKey[1]] > b[tmpKey[0]][tmpKey[1]]) { return 1; }
          if (a[tmpKey[0]][tmpKey[1]] < b[tmpKey[0]][tmpKey[1]]) { return -1; }
          return 0;
        });
      }
      return (direction === 'desc') ? tmp.slice().reverse() : tmp;
    case 'date':
    case 'number':
      tmp = rows.slice().sort(function (a, b) {
        return b[key] - a[key];
      });
      return (direction === 'desc') ? tmp.slice().reverse() : tmp;
    default:
      return tmp;
  }
}

const filterRows = (value, keys, rows) => {
  const tmp = rows.filter(data => {
    const isValid = keys.some(key => 
      data[key].toLowerCase().includes(value.toLowerCase())
    );
    return isValid && data;
  });
  return tmp;
}

export { sortByType, filterRows };
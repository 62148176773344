import React, { useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';

import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { totals } from '../../../apollo/cache';

import useStyles from './style';

const TotalsComponent = () => {

	const classes = useStyles();

	const totalsVar = useReactiveVar(totals);

	useEffect(() => {
		return () => {
			totals(null);
		};
	}, []);

	if (!totalsVar) return null;

	return (
		<Card className={classes.root}>
			{
				totalsVar.map(el => (
					<Paper variant='outlined' className={classes.container} key={el.label}>
						<Typography variant='body1'><strong>{el.value}</strong></Typography>
						<Typography variant='body2'>{el.label}</Typography>
					</Paper>
				))
			}
		</Card>
	);
};

export default TotalsComponent;

import React, { useState, useEffect } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';

import Grid from '@material-ui/core/Grid';

import SelectField from './../../presentationals/SelectField';
import FormDialog from './../../presentationals/FormDialog';

import { parseError } from './../../helpers';
import { snackbar } from './../../apollo/cache';

import GQL from './_gql';
import GQL_BRANCH from './../branch/_gql';
import Cart from './cart';

const defaultValues = {
	branchId: 8,
	transferBranchId: '',
	Products: []
};

const Component = props => {
	const [values, setValues] = useState(defaultValues);
	const [products, setProducts] = useState([]);
	const [codeToClear, setCodeToClear] = useState(null);
	const [branches, setBranches] = useState([]);
	const [errors, setErrors] = useState({});

	const [getTransfer, { data: transferData }] = useLazyQuery(GQL.GET_BY_ID_EDIT, { fetchPolicy: 'network-only' });

	useQuery(GQL_BRANCH.GET_AUTOCOMPLETE,{
		variables: { limit: 0, owner: true },
		onCompleted: data => {
			const options = data.branches.rows.map(el => {
				return { value: el.id, label: el.name.trim().toUpperCase() };
			});
			setBranches(options);
		}
	});

	const [createTransfer, { loading }] = useMutation(GQL.CREATE, {
		update(cache, { data }){
			const oldQuery = cache.readQuery({ query: GQL.GET, variables: { status: [1,2], type: [1] } });
			cache.writeQuery({
	      query: GQL.GET,
	      variables: { status: [1,2], type: [1] },
	      data: { transfers: {
	      	...oldQuery.transfers,
	      	rows: [
	      		data.createTransfer, ...oldQuery.transfers.rows
	      	]
	      } }
	    });
		},
		onCompleted: data => {
			props.handleCreate && props.handleCreate(data);
		}
	});
	const [updateTransfer, { loading: loadingUpdate }] = useMutation(GQL.UPDATE);

	useEffect(() => {
		if (props.transferId) {
			console.log('entro');
			getTransfer({ variables: { id: props.transferId } });
		}
	}, [props.transferId]);

	useEffect(() => {
		if (transferData) {
			const Products = transferData.transfer.TransferDetails.map(el => {
				const productUnit = {
					productId: el.productId,
					value: el.productUnitId,
					label: `${el.Product.name.trim()} ${el.ProductUnit.name.trim()}`.trim().toUpperCase()
				};
				return { quantity: parseFloat(el.quantity), productUnit };
			});
			setValues(values => ({ branchId: transferData.transfer.branchId, transferBranchId: transferData.transfer.transferBranchId, Products }));
			setProducts(Products);
		}
	}, transferData);

	const handleClose = () => {
		setValues(defaultValues);
		setProducts([]);
		setCodeToClear(null);
		props.handleClose();
	}

	const handleAction = async () => {
		try {
			if(!values.branchId || values.branchId === ''){
  			const e = new Error(' ');
  			e.name = 'branchId';
  			e.input = true;
  			throw e;
  		}
			if(!values.transferBranchId || values.transferBranchId === ''){
  			const e = new Error(' ');
  			e.name = 'transferBranchId';
  			e.input = true;
  			throw e; 
  		}

			const variables = {
				branchId: values.branchId,
				transferBranchId: values.transferBranchId,
				Products: values.Products.map(el => ({
					quantity: parseFloat(el.quantity),
					productId: el.productUnit.productId,
					productUnitId: el.productUnit.value,
				}))
			};

			if (props.transferId) {
				await updateTransfer({ variables: { ...variables, id: props.transferId } });
			} else {
				await createTransfer({ variables });
			}

			setValues(defaultValues);
			setProducts([]);
			setCodeToClear(null);
			setErrors({});
			props.handleClose();
		}catch(e) {
			console.log(e);
			if(e.input) {
				setErrors({ [e.name]: e.message });
				return true;
			}
			const parseErrors = parseError(e);
      if(parseErrors[0].name === 'GENERAL_ERROR'){
      	snackbar({
	      	isOpen: true, 
	        time: 3000,
	        label: parseErrors[0].message, 
	        severity: 'error'
	      });
      }
		}
	}

	const getCart = data => {
    setValues(values => ({ ...values, Products: data }));
  }

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

	return (
		<FormDialog 
			isOpen={props.isOpen}
			isLoading={loading || loadingUpdate}
      title='Nuevo Traspaso'
      name="cart"
      singular="Cart"
      disableEscape
      width="md"
      handleClose={handleClose}
      handleAction={handleAction}
      rightContent={
      	<Grid container alignItems='center' spacing={1}>
        	<Grid item xs={6}>
        		<SelectField
			        name='branchId'
			        label='Suc. Origen'
			        variant='outlined'
			        onChange={handleChange}
			        options={branches.filter(tmp => [8,24,23].includes(parseInt(tmp.value)))}
			        value={values.branchId}
			        error={errors.branchId}
			        noFormat
			        size='small'
			      />
        	</Grid>
          <Grid item xs={6}>
            <SelectField
			        name='transferBranchId'
			        label='Suc. Destino'
			        variant='outlined'
			        onChange={handleChange}
			        options={branches}
			        value={values.transferBranchId}
			        error={errors.transferBranchId}
			        noFormat
			        size='small'
			      />
          </Grid>
        </Grid>
      }
		>
			<Cart getCart={getCart} codeToClear={codeToClear} branchId={values.branchId && values.branchId} products={products} />
		</FormDialog>
	);
}

export default Component;
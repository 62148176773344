import React from "react";
import { Switch } from "react-router-dom";

import { Authenticated, Unauthenticated } from "./helpers";

import Login from "./../../containers/login";
import BranchOwner from "./../../containers/branch";
import BranchPartner from "./../../containers/branchPartner";
import Employee from "./../../containers/employee";
import RotationSale from "./../../containers/reportRotationSale";
import RotationSaleCancelled from "./../../containers/reportRotationSaleCancelled";
import ReportSale from "./../../containers/reportSale";
import ReportCashClosing from "./../../containers/reportCashClosing";
import Expense from "./../../containers/expense";
import ExpenseType1 from "./../../containers/expenseType1";
import Event from "./../../containers/event";
import FinancialMovement from "./../../containers/financialMovement";
import Supplier from "./../../containers/supplier";
import EventPackage from "./../../containers/eventPackage";
import Customer from "./../../containers/customer";
import Requisition from "./../../containers/requisition";
import PurchaseOrder from "./../../containers/purchaseOrder";
import Product from "./../../containers/product";
import Receivable from "./../../containers/receivable";
import Royalties from "./../../containers/reportRoyalties";
import Sales from "./../../containers/reportBranchSale";
import BranchOrder from "./../../containers/branchOrder";
import BranchMovement from "./../../containers/branchMovement";
import CediPhysicalInventory from "./../../containers/cediPhysicalInventory";
import ReportChipsCombination from "./../../containers/reportChipsCombination";
import SupportTicket from "./../../containers/supportTicket";
import Production from "./../../containers/production";
import Transfer from "./../../containers/transfer";
import CediInventoryMovement from "./../../containers/cediInventoryMovement";
import ReportInventory from "./../../containers/reportInventory";
import ReportInventoryBranch from "./../../containers/reportInventoryBranch";
import BranchPhysicalInventory from "./../../containers/branchPhysicalInventory";
import ReportInventoryAmount from "./../../containers/reportInventoryAmount";
import ReportRotationTopping from "./../../containers/reportRotationTopping";
import Classification from "./../../containers/classification";
import PaymentMethod from "./../../containers/paymentMethod";
import ReportPaymentMethodSale from "./../../containers/reportPaymentMethodSale";
import ReportSaleWithDiscount from "../../containers/reportSaleWithDiscount";
import ProductMovement from "./../../containers/productMovement";
import ReportProductInventory from "./../../containers/reportProductInventory";
import ReportAmountSales from "./../../containers/reportAmountSales";
import ReportPurchaseOrderProducts from "./../../containers/reportPurchaseOrderProducts";
import ReportTransferProducts from "./../../containers/reportTransferProducts";
import ReportPurchaseOrderDetails from "./../../containers/reportPurchaseOrderDetail";
import ReportTransferDetails from "./../../containers/reportTransferDetails";
import ReportWaste from "./../../containers/reportWaste";
import Waste from "./../../containers/waste";
import ReportProductMovement from "./../../containers/reportProductMovement";
import ReportBranchWaste from "./../../containers/reportBranchWaste";


const _privileges = localStorage.getItem('privileges');

const PlatformRoutes = () =>
  <Switch>
    <Unauthenticated path="/public/login" component={Login} />
    <Authenticated exact path="/" component={BranchOwner} />
    <Authenticated exact path="/report/productInventory" component={ReportProductInventory} />
    { parseInt(_privileges) === 1 && <Authenticated exact path="/report/rotationSale/cancelled" component={RotationSaleCancelled} /> }
    { parseInt(_privileges) === 1 && <Authenticated exact path="/report/branch/sales" component={Sales} /> }
    { parseInt(_privileges) === 1 && <Authenticated exact path="/report/reportPaymentMethodSale" component={ReportPaymentMethodSale} /> }
    { parseInt(_privileges) === 1 && <Authenticated exact path="/report/rotationSale" component={RotationSale} /> }
    { parseInt(_privileges) === 1 && <Authenticated exact path="/report/rotationTopping" component={ReportRotationTopping} /> }
    { parseInt(_privileges) === 1 && <Authenticated exact path="/report/sale" component={ReportSale} /> }
    { parseInt(_privileges) === 1 && <Authenticated exact path="/report/cashClosing" component={ReportCashClosing} /> }
    { parseInt(_privileges) === 1 && <Authenticated exact path="/report/inventory/branch" component={ReportInventoryBranch} /> }
    { parseInt(_privileges) === 1 && <Authenticated exact path="/report/inventory" component={ReportInventory} /> }
    { parseInt(_privileges) === 1 && <Authenticated exact path="/report/inventoryAmount" component={ReportInventoryAmount} /> }
    { parseInt(_privileges) === 1 && <Authenticated exact path="/report/saleWithDiscount" component={ReportSaleWithDiscount} /> }
    { parseInt(_privileges) === 1 && <Authenticated exact path="/report/purchaseOrderProducts" component={ReportPurchaseOrderProducts} /> }
    { parseInt(_privileges) === 1 && <Authenticated exact path="/report/transferProducts" component={ReportTransferProducts} /> }

    { parseInt(_privileges) === 1 && <Authenticated exact path="/branch/owner" component={BranchOwner} /> }
    { parseInt(_privileges) === 1 && <Authenticated exact path="/branch/partner" component={BranchPartner} /> }
    { <Authenticated exact path="/branch/inventory" component={BranchPhysicalInventory} /> }
    { parseInt(_privileges) === 1 && <Authenticated exact path="/employee" component={Employee} /> }
    { parseInt(_privileges) === 1 && <Authenticated exact path="/expense" component={Expense} /> }
    { parseInt(_privileges) === 1 && <Authenticated exact path="/expense/type/1" component={ExpenseType1} /> }
    { parseInt(_privileges) === 1 && <Authenticated exact path="/event/package" component={EventPackage} /> }
    { parseInt(_privileges) === 1 && <Authenticated exact path="/paymentMethod" component={PaymentMethod} /> }
    { <Authenticated exact path="/event" component={Event} /> }
    { parseInt(_privileges) === 1 && <Authenticated exact path="/financialMovement" component={FinancialMovement} /> }
    { parseInt(_privileges) === 1 && <Authenticated exact path="/supplier" component={Supplier} /> }
    { parseInt(_privileges) === 1 && <Authenticated exact path="/customer" component={Customer} /> }
    { <Authenticated exact path="/requisition" component={Requisition} /> }
    { <Authenticated exact path="/purchaseOrder" component={PurchaseOrder} /> }
    { parseInt(_privileges) === 1 && <Authenticated exact path="/classification" component={Classification} /> }
    { parseInt(_privileges) === 1 && <Authenticated exact path="/product" component={Product} /> }
    { parseInt(_privileges) === 1 && <Authenticated exact path="/receivable" component={Receivable} /> }
    { parseInt(_privileges) === 1 && <Authenticated exact path="/report/royalties" component={Royalties} /> }
    { parseInt(_privileges) === 1 && <Authenticated exact path="/report/amount/sales" component={ReportAmountSales} /> }
    { <Authenticated exact path="/branchOrder" component={BranchOrder} /> }
    { parseInt(_privileges) === 1 && <Authenticated exact path="/branchMovement" component={BranchMovement} /> }
    { <Authenticated exact path="/inventory" component={CediPhysicalInventory} /> }
    { parseInt(_privileges) === 1 && <Authenticated exact path="/report/chipsCombination" component={ReportChipsCombination} /> }
    { parseInt(_privileges) === 1 && <Authenticated exact path="/report/waste" component={ReportWaste} /> }
    { <Authenticated exact path="/supportTicket" component={SupportTicket} /> }
    { <Authenticated exact path="/production" component={Production} /> }
    { <Authenticated exact path="/transfer" component={Transfer} /> }
    { <Authenticated exact path="/waste" component={Waste} /> }
    { <Authenticated exact path="/inventoryMovement" component={CediInventoryMovement} /> }
    { <Authenticated exact path="/productMovement" component={ProductMovement} /> }
    { <Authenticated exact path="/report/purchaseOrderDetails" component={ReportPurchaseOrderDetails} /> }
    { <Authenticated exact path="/report/transferDetails" component={ReportTransferDetails} /> }
    { <Authenticated exact path="/report/reportProductMovement" component={ReportProductMovement} /> }
    { <Authenticated exact path="/report/branchWaste" component={ReportBranchWaste} /> }
    
  </Switch>

export default PlatformRoutes;

import React, { useState } from 'react';

import Typography from '@material-ui/core/Typography';

import FilterDrawer from './../../presentationals/FilterDrawer';

import SelectField from './../../presentationals/SelectField';

import { filter } from '../../apollo/cache';

const monthsOptions = [
	{ value: -1, label: 'Todos los meses' },
	{ value: 0, label: 'Enero' },
	{ value: 1, label: 'Febrero' },
	{ value: 2, label: 'Marzo' },
	{ value: 3, label: 'Abril' },
	{ value: 4, label: 'Mayo' },
	{ value: 5, label: 'Junio' },
	{ value: 6, label: 'Julio' },
	{ value: 7, label: 'Agosto' },
	{ value: 8, label: 'Septiembre' },
	{ value: 9, label: 'Octubre' },
	{ value: 10, label: 'Noviembre' },
	{ value: 11, label: 'Diciembre' },
];

const yearOptions = [
	{ value: 2020, label: '2020' },
	{ value: 2021, label: '2021' },
	{ value: 2022, label: '2022' },
	{ value: 2023, label: '2023' },
];

const defaultValues = {
	month: -1,
	year: 2021
}

const FilterDialog = props => {
	const [ values, setValues ] = useState(defaultValues)

	const handleClose = () => {
		props.handleAction('close');
	}

	const handleAction = (e, data) => {
		const branchId = parseInt(data.branchId) > 0 ? data.branchId : null;
		const companyId = parseInt(data.companyId) > 0 ? data.companyId : null;
		const month = parseInt(values.month) === -1 ? null : parseInt(values.month);
		props.handleAction(null, { 
			branchId,
			companyId,
			month,
			year: values.year
		});
		filter({ branchId, companyId, month, year: values.year });
	}

	const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

	return (
		<FilterDrawer 
			isOpen={props.isOpen} 
			handleClose={handleClose} 
			handleAction={handleAction}
			showBranches
			showCompanies
		>
			<Typography variant="subtitle1">
        Mes:
      </Typography>
			<SelectField
        name='month'
        variant='outlined'
        label=''
        value={values.month}
        onChange={handleChange}
        options={monthsOptions}
        noFormat
      />
      <br/>
			<Typography variant="subtitle1">
        Año:
      </Typography>
			<SelectField
        name='year'
        variant='outlined'
        label=''
        value={values.year}
        onChange={handleChange}
        options={yearOptions}
        noFormat
      />
		</FilterDrawer>
	);
}

export default FilterDialog;
import React, { Fragment, useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import Grid from '@material-ui/core/Grid';

import LinearForm from './../../../../presentationals/LinearForm';
import Autocomplete from './../../../../presentationals/Autocomplete';
import TextField from './../../../../presentationals/TextField';

import GQL_PRODUCT from './../../../reportProductInventory/_gql';

const defaultValues = {
	quantity: 0,
	productUnit: null,
};

const Form = props => {
	const [values, setValues] = useState(defaultValues);
	const [products, setProducts] = useState([]);
	const [errors, setErrors] = useState({});
	const [getProducts, { data: dataProducts }] = useLazyQuery(GQL_PRODUCT.GET, { variables: { limit: 0 } });

	useEffect(() => {
		if (props.branchId) {
			setValues(defaultValues);
			setErrors({});
			getProducts({ variables: { limit: 0, branchId: props.branchId } });
		}
	}, [props.branchId, getProducts]);

	useEffect(() => {
		if(dataProducts){
			const data = dataProducts.productInventories.rows.map((el,i) => {
				const tag = parseInt(el.companyId) === 1 ? '| NRC' : '| CDE';
        const label = `${el.name.toUpperCase()} ${tag}`;
				return {
					value: el.id,
					label,
					productId: el.productId,
				};
			}).filter(tmp => tmp);
			setProducts(data);
		}
	}, [dataProducts]);
	
	useEffect(() => {
    setValues(defaultValues);
  }, [props.codeToClear]);

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }
  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAddProduct = e => {
  	try{
  		if(!values.productUnit){ 
  			const e = new Error('Selecciona una unidad de medida.')
  			e.name = 'productUnit';
  			throw e; 
  		}
  		if(parseFloat(values.quantity) <= 0 || isNaN(parseFloat(values.quantity))){ 
  			const e = new Error('Proporciona la cantidad de producto.')
  			e.name = 'quantity';
  			throw e; 
  		}
  		props.handleAdd({
  			productUnit: values.productUnit,
  			quantity: parseFloat(values.quantity),
  		});

  		setValues(defaultValues);
  		setErrors({});
  	}catch(e) {
  		setErrors({ [e.name]: e.message });
  	}
  	
  }

	return (
		<Fragment>
			<div>
				<LinearForm handleAdd={handleAddProduct} align='center'>
					<Grid container spacing={1}>
						<Grid item xs={12} md={10}>
							<Autocomplete 
				        name='productUnit'
				        label='Producto'
				        variant='outlined'
				        onChange={handleAutocompleteChange}
				        options={products}
				        value={values.productUnit}
				        error={errors.productUnit}
				        noFormat
				        size='medium'
				      />
						</Grid>
						<Grid item xs={12} md={2}>
							<TextField 
				        name='quantity'
				        label='Cant.'
				        type='number'
				        variant='outlined'
				        onChange={handleChange}
				        value={values.quantity}
				        error={errors.quantity}
				        noFormat
				        size='medium'
				      />
						</Grid>
					</Grid>
				</LinearForm>
			</div>
		</Fragment>
	);
}

export default Form;
import { gql } from '@apollo/client';

const GET = gql`
  query BranchRotationSale($status: [Int] $start: Date $end: Date $owner: Boolean $branchId: ID $like: String $city: String $companyId: Int){
    branchRotationSale(status: $status start: $start end: $end owner: $owner branchId: $branchId like: $like city: $city companyId: $companyId) {
      count
      rows {
        Product {
          name
          cost
        }
        Sales {
          Branch {
            name
          }
          qty
          cost
          total
        }
      }
    }
  }
`;

const obj = { GET };
export default obj;
import { makeStyles } from '@material-ui/core/styles';

import backgroundImage from "./../../files/back.jpg";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 2),
    ...theme.mixins.toolbar,
  },
  appBar: {
    color: '#fff',
    backgroundImage: `url(${backgroundImage})`,
    boxShadow: 'none',
    borderBottom: '1px solid #e0e0e0',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    color: '#fff',
    marginRight: 18,
    padding: 7
  },
  drawerPaper: {
    background: '#fff',
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    background: '#fff',
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    marginLeft: theme.spacing(7),
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0),
    },
  },
  container: {
    margin: 0,
    padding: 0,
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    flexGrow: 1
  },
  logo: {
    width: '20%'
  },
  list: {
    padding: 0,
    overflow: 'auto',
  },
  profileButton: {
    padding: 3,
    marginLeft: 3
  },
  profile: {
    background: '#500000'
  },
  menuLogo: {
    width: '4%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  hideButton: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}));

export default useStyles;
import React, { useState, useEffect, Fragment } from 'react';

import { useQuery, useMutation } from '@apollo/client';

import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroidOutlined';

import makeStyles from '@material-ui/core/styles/makeStyles';

import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import FormDialog from './../../presentationals/FormDialog';
import Autocomplete from './../../presentationals/Autocomplete';
import TextField from './../../presentationals/TextField';

import GQL from './_gql';

const useStyles = makeStyles((theme: Theme) => ({
  tab: { 
    backgroundColor: '#f5f5f5', 
    padding: theme.spacing(0, 3, 0, 3), 
  },
  form: { padding: theme.spacing(3) }
}));

const defaultValue = {
  customer: null,
  name: '',
  phone: ''
};

const defaultError = {
  name: '',
  phone: ''
}

const Customer = props => {

  const classes = useStyles();

  const [ customers, setCustomers ] = useState([]);
  const [ values, setValues ] = useState(defaultValue);
  const [ errors, setErrors ] = useState(defaultError);
  const [ isNewCustomer, setIsNewCustomer ] = useState(0);
  const [ hideSearch, setHideSearch ] = useState(false);

  const { data: customerData } = useQuery(GQL.GET_AUTOCOMPLETE, { variables: { limit: 0 } });
  const [ createCustomer, { loading } ] = useMutation(GQL.CREATE, {
    update(cache, { data: response }) {
      try{
        const dataResponse = response.createCustomer;
        const oldQuery = cache.readQuery({ query: GQL.GET });
        cache.writeQuery({
          query: GQL.GET,
          data: { 
            customers: { 
              ...oldQuery.customers,
              count: oldQuery.customers.count + 1, 
              rows: [ ...oldQuery.customers.rows, dataResponse ] 
            }
          }
        });
      }catch(e){
        console.log(e.message);
      }
    },
    onCompleted: response => {
      props.handleAction(response.createCustomer.id);
    }
  });

  useEffect(() => {
    if(props.hideSearch){
      setIsNewCustomer(1);
      setHideSearch(true);
    }else {
      setIsNewCustomer(0);
      setHideSearch(false);
    }
  }, [props.hideSearch]);

  useEffect(() => {
    if(customerData){
      const tmp = customerData.customers.rows.map(el => ({
        label: `${el.id} ${el.name}`, 
        value: el.id
      }));
      setCustomers(tmp);
    } 
  }, [customerData]);


  const handleAction = async e => {

    if(isNewCustomer && values.name === '') {
      setErrors({ ...defaultError, name: `Proporciona el nombre del ${ props.object ? props.object.toLowerCase() : 'representante legal'}.` });
      return;
    }

    if(isNewCustomer && values.phone === '') {
      setErrors({ ...defaultError, phone: `Proporciona el teléfono del ${ props.object ? props.object.toLowerCase() : 'representante legal'}.` });
      return;
    }

    if(!isNewCustomer && !values.customer) {
      setErrors({ ...defaultError, customer: `Selecciona un ${ props.object ? props.object.toLowerCase() : 'representante legal'} para el establecimiento.` });
      return;
    }

    const { customer, ...newCustomer } = values;

    if(isNewCustomer){
      await createCustomer({ variables: newCustomer });
    }else {
      props.handleAction(customer.value);
    }
    
  }

  const handleClose = () => {
    setValues(defaultValue);
    props.handleClose();
  }

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleTask = (e, value) => {
    setIsNewCustomer(value);
    setValues(defaultValue);
  }
  
  return (
    <FormDialog 
      title={props.title || 'Representante Legal'}
      isOpen={props.isOpen || false}
      isLoading={loading}
      handleClose={handleClose}
      handleAction={handleAction}
      noPadding
    > 
      <Grid container spacing={0}>
        <Grid item xs={12} className={classes.tab}> 
          <Tabs
            value={isNewCustomer}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTask}
          >
            <Tab icon={<SearchIcon />} label="Consultar" disabled={hideSearch ? true : false} />
            <Tab icon={<AddIcon />} label="Nuevo" />
          </Tabs>
        </Grid>
      </Grid>
      <Grid container spacing={0} className={classes.form}>
        <Grid item xs={12}>
        {
          isNewCustomer === 0
            ? <Autocomplete 
                name='customer'
                label='Búsqueda por nombre' 
                variant='outlined'
                onChange={handleAutocompleteChange}
                options={customers}
                value={values.customer}
                error={errors.customer}
                autoFocus
                noFormat
              />
            : <Fragment>
                <TextField 
                  icon={<AccountCircleIcon />}
                  name='name'
                  label='Nombre'
                  onChange={handleChange}
                  value={values.name}
                  error={errors.name}
                  autoFocus
                />
                <TextField 
                  icon={<PhoneAndroidIcon />}
                  name='phone'
                  label='Teléfono'
                  type='phone'
                  onChange={handleChange}
                  value={values.phone}
                  error={errors.phone}
                />  
              </Fragment>   
        }
        </Grid>
      </Grid>
    </FormDialog>
  );
}

export default Customer;
import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import Autocomplete from './../../presentationals/Autocomplete';
import SelectField from './../../presentationals/SelectField';
import DateField from './../../presentationals/DateField';
import TextField from './../../presentationals/TextField';
import FormDialog from './../../presentationals/FormDialog';

import { parseError } from './../../helpers';
import { snackbar } from './../../apollo/cache';

import GQL from './_gql';
import GQL_SUPPLIER from './../supplier/_gql';
// import GQL_BRANCH from './../branch/_gql';

import Cart from './cart';
import PDF from './pdf.a4';

const defaultValues = {
	iva: 16,
	date: new Date(),
	description: '',
	supplierId: null,
	paymentForm: '01',
	Products: [],
	branchId: 8,
	subFolio: '',
}

// const typeOptions = [
// 	{ value: 2, label: 'Producción' },
// 	{ value: 3, label: 'Ventas' },
// 	{ value: 4, label: 'Administración' },
// ];

// const ivaOptions = [
// 	{ value: 16, label: '16%' },
// 	{ value: 8, label: '8%' },
// 	{ value: 0, label: '0%' },
// 	{ value: -1, label: 'Exento' },
// ]

const paymentFormOptions = [ 
  {value: "00", label: "Caja Chica"},
  {value: "01", label: "Efectivo"},
  {value: "100", label: "Depósito"},
  {value: "02", label: "Cheque nominativo"},
  {value: "03", label: "Transferencia electrónica de fondos"},
  {value: "04", label: "Tarjeta de crédito"},
  {value: "05", label: "Monedero electrónico"},
  {value: "06", label: "Dinero electrónico"},
  {value: "15", label: "Condonación"},
  {value: "17", label: "Compensación"},
  {value: "23", label: "Novación"},
  {value: "28", label: "Tarjeta de débito"},
  {value: "30", label: "Aplicación de anticipos"},
  {value: "31", label: "Intermediario pagos"},
  {value: "99", label: "Por definir"}
];

const Component = props => {
	const [values, setValues] = useState(defaultValues);
	const [codeToClear, setCodeToClear] = useState(null);
	const [suppliers, setSuppliers] = useState([]);
	const [errors, setErrors] = useState({});

	const [createPurchase, { loading }] = useMutation(GQL.CREATE, {
		update(cache, { data }) {
			try{
				const oldQuery = cache.readQuery({ query: GQL.GET, variables: { status: [1] } });
				cache.writeQuery({
          query: GQL.GET,
          variables: { status: [1] },
          data: { purchaseOrders: {
          	count: parseInt(oldQuery.purchaseOrders.count) + 1,
          	rows: [ data.createPurchaseOrder, ...oldQuery.purchaseOrders.rows ]
          } }
        });
			}catch(e){
				console.log(e.message);
			}
		},
		onCompleted: data => {
			props.handleCreate && props.handleCreate(data);
			PDF(data.createPurchaseOrder.id);
		}
	});
	useQuery(GQL_SUPPLIER.GET,{
		variables: { limit: 0 },
		onCompleted: data => {
			const tmp = data.suppliers.rows.map(el => ({ value: el.id, label: el.name }));
			setSuppliers(tmp);
		}
	});

	useEffect(() => {
		setValues(values => ({ ...values, Products: [] }));
		setCodeToClear(null);
	}, [values.branchId]);

	const handleClose = () => {
		setValues(defaultValues);
		setCodeToClear(null);
		props.handleClose();
	}

	const handleAction = async () => {
		try {
			if(!values.branchId){ 
  			const e = new Error('Selecciona una sucursal.')
  			e.name = 'branchId';
  			throw e; 
  		}
			if(!values.supplierId){ 
  			const e = new Error('Selecciona un proveedor.')
  			e.name = 'supplierId';
  			throw e; 
  		}
			if(!values.date){ 
  			const e = new Error('Proporciona una fecha de compra.')
  			e.name = 'date';
  			throw e; 
  		}
			if(!values.paymentForm){ 
  			const e = new Error('Selecciona una forma de pago.')
  			e.name = 'paymentForm';
  			throw e; 
  		}
			if(!values.subFolio || values.subFolio.trim() === '') { 
  			const e = new Error('El sub folio es obligatorio.');
  			e.name = 'subFolio';
  			throw e; 
  		}

			const variables = {
				...values,
				branchId: parseInt(values.branchId),
				iva: parseInt(values.iva) !== -1 ? parseFloat(values.iva) : null,
				supplierId: values.supplierId ? values.supplierId.value : null,
				Products: values.Products.map(el => ({
					quantity: el.quantity,
					unitPrice: el.unitPrice,
					discount: el.discount,
					productId: el.productUnit ? el.productUnit.productId : null,
					productUnitId: el.productUnit ? el.productUnit.value : null,
					concept: el.concept,
					iva: !el.productUnit && parseInt(el.iva) !== -1 ? parseFloat(el.iva) : null,
				}))
			};

			await createPurchase({ variables });
			setValues(defaultValues);
			setCodeToClear(null);
			setErrors({});
			props.handleClose();
		}catch(e) {
			console.log(e);
			if(e.name !== 'Error') {
				setErrors({ [e.name]: e.message });
				return;
			}
			
			const parseErrors = parseError(e);
      if(parseErrors[0].name === 'GENERAL_ERROR'){
      	snackbar({
	      	isOpen: true, 
	        time: 3000,
	        label: parseErrors[0].message, 
	        severity: 'error'
	      });
	      return;
      }
		}
	}

	const getCart = data => {
    setValues(values => ({ ...values, Products: data }));
  }

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(values => ({ ...values, [name]: value }));
  }

  const handleDateChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  }

	return (
		<FormDialog 
			isOpen={props.isOpen}
			isLoading={loading}
      title={"Nueva Compra de CEDI"}
      name="cart"
      singular="Cart"
      disableEscape
      width="md"
      handleClose={handleClose}
      handleAction={handleAction}
      rightContent={
      	<Grid container spacing={1}>
      		<Grid item md={6} xs={false} />
      		<Grid item md={6} xs={12}>
      			<SelectField
							label=''
							name='branchId'
							value={values.branchId}
							options={[
								{ label: 'BODEGA NARACHIPS', value: 8 },
								{ label: 'BODEGA CASA DEL ELOTE', value: 24 },
							]}
							noFormat
							size='small'
							variant='outlined'
							onChange={handleChange}
						/>
      		</Grid>
			  </Grid>
      }
		>	
			<Grid container spacing={1}>
				<Grid item xs={12} md={3}>
					<Autocomplete 
		        name='supplierId'
		        label='Proveedor'
		        variant='outlined'
		        onChange={handleAutocompleteChange}
		        options={suppliers}
		        value={values.supplierId}
		        error={errors.supplierId}
		        noFormat
		      />
				</Grid>
				<Grid item xs={12} md={3}>
					<DateField 
		        variant='outlined'
            name='date'
            onChange={handleDateChange}
            size='small'
            value={values.date}
            error={errors.date}
            noFormat
		      />
				</Grid>
				<Grid item xs={12} md={3}>
					<SelectField 
		        name='paymentForm'
		        label='Forma de Pago'
		        variant='outlined'
		        onChange={handleChange}
		        options={paymentFormOptions}
		        value={values.paymentForm}
		        error={errors.paymentForm}
		        size='small'
		        noFormat
		      />
				</Grid>
				{/*<Grid item xs={12} md={2}>
					<SelectField 
		        name='iva'
		        label='IVA' 
		        variant='outlined'
		        onChange={handleChange}
		        options={ivaOptions}
		        value={values.iva}
		        error={errors.iva}
		        size='small'
		        noFormat
		      />
				</Grid>*/}
				<Grid item xs={12} md={3}>
					<TextField 
		        name='subFolio'
		        label='Sub Folio' 
		        variant='outlined'
		        onChange={handleChange}
		        value={values.subFolio}
		        error={errors.subFolio}
		        size='small'
		        noFormat
		      />
				</Grid>
			</Grid>
			<br/>
			<Divider/>
			<br/>
			<Cart
        getCart={getCart}
        iva={parseInt(values.iva) !== -1 ? parseInt(values.iva) : 0}
        codeToClear={codeToClear}
        branchId={values.branchId}
      />
      <br/>
      <Grid container spacing={1}>
			  <Grid item xs={12} md={12}>
					<TextField 
			      name='description'
			      label='Descripción' 
			      variant='outlined'
			      onChange={handleChange}
			      value={values.description}
			      multiline
			      noFormat
			    />
				</Grid>
			</Grid>
		</FormDialog>
	);
}

export default Component;
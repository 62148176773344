import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';

import ListAltIcon from '@material-ui/icons/ListAltOutlined';
import DoneAllIcon from '@material-ui/icons/DoneAllOutlined';
import CloseIcon from '@material-ui/icons/Close';
import PrintIcon from '@material-ui/icons/PrintOutlined';

import { actions } from '../../apollo/cache';

import ConfirmDialog from './confirm.dialog';
import DelteConfirmDialog from './../../presentationals/ConfirmDialog';

import { parseError } from './../../helpers';
import { snackbar } from './../../apollo/cache';

import GQL from './_gql';
import PDF from './pdf.a4';

import ConceptDialog from './concept.dialog';

const defaultConfirmDelete = [false,null];

const ActionComponent = () => {
  const [isOpenConcepts, setIsOpenConcepts] = useState([0,false]);
  const [isOpenAuth, setIsOpenAuth] = useState([0,false]);
  const [isOpenConfirmDialogDelete, setIsOpenConfirmDialogDelete] = useState(defaultConfirmDelete);

  const [deletePurchaseOrder] = useMutation(GQL.DELETE,{
    update(cache, { data }) {
      cache.evict({ id: 'PurchaseOrder:' + data.deletePurchaseOrder.id });
      cache.gc();
    },
    onCompleted: () => setIsOpenConfirmDialogDelete(defaultConfirmDelete)
  });

  const handleActionConfirmDelete = async id => {
    try {
      await deletePurchaseOrder({ variables: { id } });
    }catch(e) {
      const parseErrors = parseError(e);
      if(parseErrors[0].name === 'GENERAL_ERROR'){
        snackbar({
          isOpen: true, 
          time: 0,
          label: parseErrors[0].message, 
          severity: 'error'
        });
      }
    } 
  };

  const handleCloseConcepts = () => setIsOpenConcepts([0,false]);
  const handleConcepts = (e,{ id }) => {
    setIsOpenConcepts([id,true]);
  };
  
  const handleCloseAuth = () => setIsOpenAuth([0,false]);
  const handleAuth = (e,{ id }) => {
    setIsOpenAuth([id,true]);
  };

  const handleDelete = (e,{id}) => setIsOpenConfirmDialogDelete([true, id]);
  const handleCloseConfirmDelete = () => setIsOpenConfirmDialogDelete(defaultConfirmDelete);

  const handlePdf = (e, { id }) => PDF(id);
  
  useEffect(() => {
    actions([
      {
        icon: <ListAltIcon/>,
        onClick: handleConcepts,
        label: 'Conceptos'
      },
      {
        icon: <PrintIcon/>,
        onClick: handlePdf,
        label: 'Imprimir'
      },
      {
        icon: <DoneAllIcon/>,
        onClick: handleAuth, label: 'Autorizar',
        disable: { key: 'status', value: [0, 2] },
      },
      {
        icon: <CloseIcon/>,
        onClick: handleDelete,
        label: 'Eliminar',
        disable: { key: 'status', value: [2] },
      },
    ].filter(el => el));
  }, []);

  return (
    <>
      <ConceptDialog isOpen={isOpenConcepts[1]} handleClose={handleCloseConcepts} id={isOpenConcepts[0]} />
      <ConfirmDialog isOpen={isOpenAuth[1]} handleClose={handleCloseAuth} params={isOpenAuth[0]} />
      <DelteConfirmDialog
        isOpen={isOpenConfirmDialogDelete[0]}
        handleClose={handleCloseConfirmDelete}
        handleAction={handleActionConfirmDelete}
        params={isOpenConfirmDialogDelete[1]}
      />
    </>
  );
};

export default ActionComponent;
